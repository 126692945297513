import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../../validate-pattern';
import { Subscription } from 'rxjs';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-book-a-free-step-2-modal',
  templateUrl: './book-a-free-step-2.component.html',
  styleUrls: ['./book-a-free-step-2.component.scss']
})
export class BookAFreeStep2ModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  phoneNo: any;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '60px',
      'height': '50px',
      'margin-right': '20px',
      'border-radius': '10px',
      'border': 'solid 2px #000000',
    }
  };
  otpModal: FormGroup;
  subscription: Subscription;
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public _leadsquaredService: LeadsquaredService, public userService: UserService, private modalService: ModalService,public commonService:CommonService) { }
  get f() { return this.Form.controls; }
  ngOnInit(): void {
    this.commonService.GetPhoneNoOtp().subscribe(response => {
      return this.phoneNo = response
    });

    this.Form = this.fb.group({
      role_id: ['2'],
      phone: [this.phoneNo, [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
    });
    this.otpModal = this.fb.group({
      otp1: ['', [Validators.minLength(1)]],
      otp2: ['', [Validators.minLength(1)]],
      otp3: ['', [Validators.minLength(1)]],
      otp4: ['', [Validators.minLength(1)]]
    });
  }
  otpValue = ''
  onOtpChange(otp) {
    this.otpValue = otp;
  }
  onSubmitOtp() {
    this.submitted = true;
    if (this.otpValue == undefined || this.otpValue== '' || this.otpValue == null) {
      this._employeeService.showError('Please enter valid otp...')
    } else {
      this.btnsubmitted = true;
      var requestData = this.Form.value;
          requestData.otp=this.otpValue
      this.userService.userPhoneOtpVerifyBookFreeClass('otp_verify', this.Form.value).then(data => {
        console.log('data:::::::', data);
        if (data.replyCode == "error") {
          this.commonService.GetPanel().subscribe(response => {
            console.log('response::::Panel', response);
            const hostname = window && window.location && window.location.hostname;
            if (/^learningbix.com/.test(hostname)) {
              this.subscription = this._leadsquaredService.BookFreeClass(this.phoneNo, 1).subscribe((message:any) => { localStorage.setItem('LEED', JSON.stringify(message)); return message.Message.RelatedId });
            }
            if (response == 'pie') {
              this.modalService.setBookAFreeStep2Modal(false);
              this.modalService.setbookDemoClassPieModal(true);
            } else {
              this.modalService.setBookAFreeStep2Modal(false);
              this.modalService.setbookDemoClassBixModal(true);
            }
          })
          this.Form.reset();
          this.otpValue = '';
          this.submitted = false;
          this.btnsubmitted = false;
        } else if (data.replyCode == "success") {
          this.token.saveUser(JSON.stringify(data.data));
          this.token.saveSid(JSON.stringify(data.sid));
          this.commonService.setLoggedInStatus(true);
          this.userService.checkStudentSubscription(data.sid);
          this.Form.reset();
          this.otpValue = '';
          this.submitted = false;
          this.btnsubmitted = false;
        }else {
          this.btnsubmitted = false;
          this._employeeService.showError(data.replyMsg)
        }
      });
    }
  }
  onEditPhone() {
    this.modalService.setBookAFreeStep2Modal(false);
    this.modalService.setBookAFreeModal(true);
  }
  onLoginEmail() {
    this.modalService.setloginphoneBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }
  onCloseModal() {
    this.modalService.setBookAFreeStep2Modal(false);
  }
  onRegisterPopup() {
    this.modalService.setloginphoneOtpBixModal(false);
    this.modalService.setregisterBixModal(true);
  }
  onResendOtp() {
    this._employeeService.post('otp_generate', this.Form.value).subscribe((data:any) => {
      if (!data.error) {
        if (data.replyCode == 'success') {
          this._employeeService.showSuccess(data.replyMsg);
        } else {
          this._employeeService.showError(data.replyMsg)
        }
      }else {
        this._employeeService.showError(data.error.replyMsg)
      }
    });
  }
}
