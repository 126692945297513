import { Component, OnInit } from '@angular/core';
import { BaseServiceService } from 'src/app/service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

    ProjectsList:any;
  constructor( public _employeeService: BaseServiceService,) {
    this.GetProjectsList();
   }


  ngOnInit(): void {
  }
  GetProjectsList() {
    var self = this;
    self._employeeService.post('group_post_list', {featured:1}).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.ProjectsList = res.data;
        }
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
}
