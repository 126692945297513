<div class="modal popup" id="Payment_Popup" tabindex="-1" aria-labelledby="Payment_Popup" aria-hidden="true"
    data-backdrop="false" style="backdrop-filter: blur(10px);">
    <div class="modal-dialog modal-md">
        <div class="">
            <div class="modal-body pt-0 pb-5 book-class-modal" style="height: 300px;">
                <div class="row align-items-center">
                    <div class="loading"></div>
                </div>
            </div>
        </div>
    </div>
</div>