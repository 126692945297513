<div class="modal fade" id="basicModel30Sec" tabindex="-1" aria-labelledby="basicModel30Sec" aria-hidden="true"
  data-backdrop="false">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <!-- <h5 class="modal-title" id="bookFreeClassModalLabel">Modal title</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseModal30Sec()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0 pb-5 book-class-modal">
        <div class="row align-items-center">
          <div class="col-md-12">
            <iframe class="embed-responsive-item" [src]="safeSrcVideoUrl" webkitallowfullscreen mozallowfullscreen
              allowfullscreen></iframe>


          </div>
          <div class="col-md-12 text-center">
            <button class="btn-book7DayTrial" data-toggle="modal" data-target="#bookFreeClassModal"
              (click)="onBook7DayTrial()">Book your Trial</button>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>