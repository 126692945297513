import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../../validate-pattern';
import { Subscription } from 'rxjs';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
import { FacebookLoginProvider, SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { CommonService } from 'src/app/service/common.service';

export class Socialusers {  
  provider: string;  
  id: string;  
  email: string;  
  name: string;  
  image: string;  
  token?: string;  
  idToken?: string;  
} 
@Component({
  selector: 'app-login-email-modal',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.scss']
}) 
export class LoginEmailBixModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  socialusers = new Socialusers();
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public _leadsquaredService: LeadsquaredService, public userService: UserService, private modalService: ModalService, private authService: SocialAuthService,public common : CommonService) { }
  
  ngOnInit(): void {
    this.Form = this.fb.group({
      role_id: ['2'],
      email: ['', [Validators.required]],
    })
  }
  get f() { return this.Form.controls; }
  onLoginEmailPassword(){
    this.modalService.SetloginemailPasswordBixModal(true);
    this.modalService.setloginemailBixModal(true);
  }
  onCloseModal() {
    this.modalService.setloginemailBixModal(false);
  }
  onLogin() {
    console.log('validateEmail:::::',this.validateEmail(this.Form.value.email))
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      this.btnsubmitted = true;
      if (this.validateEmail(this.Form.value.email)) {
        this.userService.userEmailOtpGenerateLogin(this.Form.value).then(data => {
          if (data.replyCode == "success") {
            this._employeeService.showSuccess(data.replyMsg);
            this.modalService.setloginemailBixModal(false);
            this.modalService.setloginphoneOtpBixModal(true);
            this.common.SetPhoneNoOtp(this.Form.value.email);
            this.common.SettypeOtp('email');
            // this.Form.reset();
            this.submitted = false;
            this.btnsubmitted = false;
          }else{
            this.btnsubmitted = false;
            this._employeeService.showError(data.replyMsg)
          }
        });
      } else {
        var sendRequestData:any = {};
            sendRequestData.role_id = '2';
            sendRequestData.phone = this.Form.value.email;
        this.userService.userPhoneLogin('otp_generate', sendRequestData).then(data => {
          if (data.replyCode == "success") {
            this._employeeService.showSuccess(data.replyMsg);
            this.modalService.setloginemailBixModal(false);
            this.modalService.setloginphoneOtpBixModal(true);
            this.common.SetPhoneNoOtp(this.Form.value.email);
            this.Form.reset();
            this.submitted = false;
            this.btnsubmitted = false;
          } else {
            this.btnsubmitted = false;
            this._employeeService.showError(data.replyMsg)
          }
        });
      }
      
    }
  }
  
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "F") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "G") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.authService.signIn(socialPlatformProvider);
    this.authService.authState.subscribe((user) => {
      if (user != undefined || user != null) {
        if (user.provider == "GOOGLE") { this.SocilaLogin(user, 'Google') }
        else if (user.provider == "FACEBOOK") {this.SocilaLogin(user, 'Fcebook')}
      }
    });

  }

  SocilaLogin(user, type) {
    let requestData = {
      social_type: type, // Fcebook , Google
      social_id: user.id
    }
    this.userService.userSocialLogin('social_login', requestData).then(data => {
      // console.log('SEnD:::', data);
      if (data.replyCode == "success") {
        this.Form.reset();
        this.submitted = false;
        this.btnsubmitted = false;
      }else{
        this.btnsubmitted = false;
        this._employeeService.showError(data.replyMsg)
      }
    });
  }

  onLoginMobile() {
    this.modalService.setloginemailBixModal(false);
    this.modalService.setloginphoneBixModal(true);
  }
  forgotPassword() {
    this.modalService.setloginemailBixModal(false);
    this.modalService.setforgotBixModal(true);
  }
  onRegisterPopup() {
    this.modalService.setloginemailBixModal(false);
    this.modalService.setregisterBixModal(true);
    
  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
}
