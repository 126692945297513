import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../../validate-pattern';
import { Subscription } from 'rxjs';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-book-a-free-modal',
  templateUrl: './book-a-free.component.html',
  styleUrls: ['./book-a-free.component.scss']
})
export class BookAFreeModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  subscription: Subscription;
  bixpieFlag: boolean = false;
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public _leadsquaredService: LeadsquaredService, public userService: UserService, private modalService: ModalService,public commonService:CommonService) { }
  get f() { return this.Form.controls; }
  ngOnInit(): void {
    this.commonService.GetPanel().subscribe(data => {
      if (data == 'pie') {
        this.bixpieFlag = true;
      } else {
        this.bixpieFlag = false;
      }
    });
    this.Form = this.fb.group({
      role_id: ['2'],
      phone: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
    });
    
  }
  onProceed() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      this.btnsubmitted = true;
      var requestData: any = this.Form.value;
      console.log('LOGIN::::FORM', this.Form.value);
      this.userService.userPhoneLogin('otp_generate', this.Form.value).then(data => {
        // console.log('SEnD:::', data);
        if (data.replyCode == "success") {
          this.modalService.setBookAFreeModal(false);
          this.modalService.setBookAFreeStep2Modal(true);
          this.commonService.SetPhoneNoOtp(this.Form.value.phone);
          this.Form.reset();
          this.submitted = false;
          this.btnsubmitted = false;
          console.log('this.Form.value.phone::::', requestData.phone);
          const hostname = window && window.location && window.location.hostname;
          if (/^learningbix.com/.test(hostname)) {
            this.subscription = this._leadsquaredService.BookFreeClass(requestData.phone, 0).subscribe((message:any) => { localStorage.setItem('LEED', JSON.stringify(message)); return message.Message.RelatedId });
          }
        
        } else {
          this.btnsubmitted = false;
          this._employeeService.showError(data.replyMsg)
        }
      });
    }
  }
  onLoginEmail() {
    this.modalService.setloginphoneBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }
  onCloseModal() {
    this.modalService.setBookAFreeModal(false);
    // this.modalService.setloginemailBixModal(true);
  }
  onRegisterPopup() {
    this.modalService.setloginphoneBixModal(false);
    this.modalService.setregisterBixModal(true);

  }
}
