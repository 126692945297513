<div class="">
    <section class="blogs" style="margin: 0 auto;" id="blog_section">
        <div class="container">
            <div class="section-title section-blog">
                <h2 class="mb-0">
                    Latest on our blog
                </h2>
                <p>Explore creative content from our reads</p>
            </div>
            <div class="row">



                <div class="col-lg-4 col-md-6 col-sm-12 mt-4 mb-2 d-flex flex-column"
                    *ngFor="let blog_post of BlogList.slice(0,3)" (click)="onBlogRedirectLink(blog_post.link)"
                    style="cursor: pointer;">
                    <div class="card">
                        <!-- <div class="row mb-3">
                  <div class="col-md-2 pr-0">
                    <img src="{{blog_post._embedded['wp:featuredmedia'][0]['source_url']}}" class="img-fluid"  alt="" style="width: 100%;">
                  </div>
                  <div class="col-md-10">
                    <h5 class="mb-0">{{blog_post._embedded['author'][0]['name']}}</h5>
                    <p class="mb-0">{{blog_post.date |date}}</p>
                  </div>
                </div> -->
                        <img src="{{blog_post._embedded['wp:featuredmedia'][0]['source_url']}}" class="img-fluid mb-3" alt="" style="    max-width: 100%;
                max-height: 212px;
                min-height: 212px;">
                        <h3>{{blog_post.title['rendered']}}</h3>
                        <!-- <p class="mb-0" [innerHtml]="blog_post.excerpt['rendered']"></p> -->
                    </div>
                </div>


            </div>
            <div class="row justify-content-center">
                <div class="col-7 text-center mt-3 mb-4">
                    <a href="https://blog.learningbix.com/" type="button" class="btn btn-outline-info btn-view-more"
                        target="_blank">View All Blogs</a>
                </div>
            </div>
        </div>
    </section>
</div>