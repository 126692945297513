<div class="modal popup" id="modelConfirmType" tabindex="-1" role="dialog" aria-labelledby="modelConfirmType"
    aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 3px 10px;"
                    (click)="onCloseModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2" style="height: auto;">
                <div class="row mt-1 mb-4">
                    <div class="col-12">
                        <h1 style="font-size: 20px; text-align: center;" class="my-3">Select Login Penel</h1>
                    </div>
                    <div class="col-12">
                        <div class="row text-center">
                            <div class="col-6">
                                <button class="btn btn-primary btn-book-class my-2" (click)="gotoProfile('Bix')">
                                    Learning Bix
                                </button>

                            </div>
                            <div class="col-6">

                                <button class="btn btn-primary btn-book-class my-2" (click)="gotoProfile('Pie')">
                                    Learning Pie
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>