<!-- Main Banner -->
<section class="main-banner py-5">
    <div class="container my-5">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
            <div class="col-lg-6 mb-3 mb-lg-0">
                <h1 class="font-weight-normal display-3">Best Selling <br><span
                        class="font-weight-bold text-warning">DIY Robotics</span> & <span
                        class="font-weight-bold text-warning">Coding Product</span> from LearningBix</h1>
            </div>
            <div class="col-lg-5 mb-3 mb-lg-0">
                <div class="card p-3 rounded shadow">
                    <h4 class="font-weight-bold mb-3">Redeem Your Code Here.</h4>
                    <form [formGroup]="CodeForm">
                        <div class="form-group">
                            <label class="mb-0" for="exampleInputName">Full Name</label>
                            <input placeholder="Enter your full name" type="text"
                                class="form-control bg-white border border-dark" id="exampleInputName" formControlName="name" [ngClass]="{'is-invalid':submitted && formControlErrors.name.errors }"/>
                        </div>
                        <div class="form-group">
                            <label class="mb-0" for="exampleInputEmail1">Email</label>
                            <input type="email" class="form-control bg-white border border-dark"
                                placeholder="Enter your email address" id="exampleInputEmail1" formControlName="email" [ngClass]="{'is-invalid':submitted && formControlErrors.email.errors }"/>
                        </div>
                        <div class="form-group">
                            <label class="mb-0" for="exampleInputTel1">Contact No.</label>
                            <input type="tel" class="form-control bg-white border border-dark"
                                placeholder="Enter your contact no" id="exampleInputTel1" formControlName="phone" [ngClass]="{'is-invalid':submitted && formControlErrors.phone.errors }"/>
                        </div>
                        <div class="form-group">
                            <label class="mb-0" for="exampleInputTel1">Code</label>
                            <input type="tel" class="form-control bg-white border border-dark"
                                placeholder="Enter your contact no" id="exampleInputTel1" formControlName="code" [ngClass]="{'is-invalid':submitted && formControlErrors.code.errors }"/>
                        </div>
                        <button type="submit" class="btn btn-dark w-100" (click)="onSubmit()">Submit</button>
                    </form>
                </div>

            </div>
        </div>
    </div>
</section>


<!-- What Enterpreneurs Choose LearningBix -->
<div class="container-fluid bg-light py-5">
    <div class="container">
        <div class="col-lg-8 mx-auto text-center mb-5">
            <h2 class="font-weight-bold mb-3 display-4">6 Reasons Why
                <span class="text-warning">Parents</span> Choose
                <span class="text-warning">LearningBix</span>
            </h2>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-1.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">21<sup>st</sup> Century Skills</h4>
                <p>Help kids inculcate the necessary STEM skills and make them ready for a tech-driven world of future
                </p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-2.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Innovative Kits</h4>
                <p>LBix kits are appreciated for being as close to real life applications as possible with readily available DIY MDF/Cardboard structures to impart desired STEM learning outcomes.</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-3.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Trusted Brand</h4>
                <p>LearningBIX is a Trusted Brand with 2000+ Partner Schools running LBIS (LearningBIX Innovation Space)</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-4.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Package Customization</h4>
                <p>Tailor-made multi-lingual curriculum to offer both Micro-Degrees and crash courses on different technologies to cater to children of different age groups from 2 to 18 years.</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-5.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Technology Intervention</h4>
                <p>World-class technology solution for Franchisee Partners, Teachers and students in form of proprietary LMS, Learning App, Play App and Web applications.</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-6.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Award Winning Curriculum</h4>
                <p>LearningBIX Robotics and Coding courses are NEP complaint and are indexed to NCERT/CBSE/IB/ICSE Board curriculum. </p>
            </div>
        </div>
    </div>
</div>
