import { CommonService } from './../../service/common.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { BaseServiceService, TokenService } from '../../service';
import { environment } from 'src/environments/environment';

import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../validate-pattern';
declare var Razorpay: any;
declare var $: any;
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
import { EnvService } from 'src/app/service/env.service';
@Component({
  selector: 'app-pie-header',
  templateUrl: './pie-header.component.html',
  styleUrls: ['./pie-header.component.scss'],
  providers: [DatePipe],
})
export class PieHeaderComponent implements OnInit {
  UserLoginFlag: boolean = false;
  AgeGroupList: any = [];
  learningStatus: boolean = true;
  constructor(public _employeeService: BaseServiceService, public common: CommonService, private modalService: ModalService, private envService: EnvService) {
    this.modalService.SetModalOnOff(true);
    
    this.common.SetPanel('pie');
    

    
    if (localStorage.getItem('user')) {
      var userData = JSON.parse(localStorage.getItem('user'));
      if (userData.learning == 1) {
        this.learningStatus = false;
      }else{
        this.learningStatus = true;
      }
    }
  }
 
  ngOnInit(): void {
    this.modalService.GetModalOnOff().subscribe(data => {
      if (!data) {
        this.modalService.SetBasicModal(true);
      }
    })
    this._employeeService.post('age_group_list_dropdown', { learning: '1' }).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupList = res.data;
        this.onChangeAgeCourse(this.AgeGroupList[0].id, '1')
      }
    });
    this.common.getLoggedInStatus().subscribe(data => {
      if (data == true) {
        this.UserLoginFlag = true;
      } else {
        this.UserLoginFlag = false;
      }
    })
  }
  onRedirect() {
    if (localStorage.getItem('user')) {
      var userData = JSON.parse(localStorage.getItem('user'))
      if (userData.learning == 1) {
        window.location.href = `${this.envService.env}/student/dashboard-learning`;
      } else {
        window.location.href = `https://student.learningbix.com/dashboard`;
      }
    }
  }
  onOpenPieModal() {
    this.modalService.SetModalOnOff(true);
    this.modalService.setBookAFreeModal(true);
    // this.modalService.setbookDemoClassPieModal(true);
  }
  onLoginPanelPopup() {
    this.modalService.SetModalOnOff(true);
    this.modalService.setloginemailBixModal(true);
  }
  onChangeAgeCourse(ageid, type) {
    // console.log('ageid::::', ageid);
    if (type === '1') {
      this.common.SetRouteType('course-pie');
    } else {
      this.common.SetRouteType('course11');
    }
    this.common.SetAgeCourseType(ageid);
  }
}
