// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://api.learningbix.com:8112/',
  // api_url: 'https://api-dev.learningbix.com:8113/',
	leadsquared_api_url : 'https://api-in21.leadsquared.com/v2/',
 	imageBase_api_url : 'https://learningbixstorage098.blob.core.windows.net/learningbixcom/',

  razorPayoptions: {
     "key": "rzp_test_oFkUxCCeHdiAI1",//"rzp_test_gb5YJeNiTcjBJO", // Enter the Key ID generated from the Dashboard
    //"key": "rzp_live_vuRB5ZPOXktGqv",//"rzp_test_gb5YJeNiTcjBJO", // Enter the Key ID generated from the Dashboard
    "currency": "INR",
    "name": "Learnning Bix",
    "description": "Learnning Bix",
    "image": "",
    //"order_id": "order_9A33XWu170gUtm",This is a sample Order ID. Create an Order using Orders API. (https://razorpay.com/docs/payment-gateway/orders/integration/#step-1-create-an-order). Refer the Checkout form table given below
    "prefill": {
      "name": "",
      "email": "",
      "contact": "",
      "amount": ""
    },
    "notes": {
      "address": ""
    },
    "theme": {
      "color": "#F37254"
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
