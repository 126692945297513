<div class="modal popup" id="loginWithMobileModal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel"
    aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="max-width: 85%;">
            <div class="modal-header" style="border-bottom: none;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 3px 10px;"
                    (click)="onCloseModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2" style="height: 450px;">
                <div class="row p-4">
                    <div class="col-6" style="margin: 50px 0;">
                        <div class="col">
                            <h4 style="margin: 50px 0;" class="heading text-center" *ngIf="!bixpieFlag">Welcome to <br> Learning Bix</h4>
                            <h4 style="margin: 50px 0;" class="heading text-center" *ngIf="bixpieFlag">Welcome to <br> LearningPie</h4>
                            <img  src="../../../../assets/imgs/illustration_svg.png" class="img-fluid" alt="Login Banner">
                        </div>
                        
                    </div>
                    <div class="col-6 text-center">
                        <img src="./../../../../assets/imgs/logo-pie.png" alt="" class="img-fluid" *ngIf="bixpieFlag">
                        <img src="./../../../../assets/imgs/logo.png" alt="" class="img-fluid" *ngIf="!bixpieFlag">
                        <h4 class="mt-5 text-center">Book Your Free Class</h4>
                        <form [formGroup]="Form" class="mt-5">
                            <div class="form-group">
                                <input type="tel" placeholder="Enter Phone" class="form-control pl-0" id="Phone" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" appNumbersOnly maxlength="10">
                                <div *ngIf="submitted && f.phone.errors">
                                    <small *ngIf="f.phone.errors.required" class="text-danger">
                                        This Field is required
                                    </small>
                                    <small [hidden]="!f.phone.errors.patternMobileInvalid" class="error_input">Phone
                                        Number should be
                                        valid and 10 digit.</small>
                                </div>
                            </div>
                            <button class="btn btn-block btn-login mt-4 col-6 mx-auto" (click)="onProceed()"
                            [disabled]="btnsubmitted" [disabled]="!(this.Form.value.phone.length >= 10)">Send OTP</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>