import { Component, OnInit } from '@angular/core';
import { BaseServiceService } from 'src/app/service';
import { DomSanitizer} from '@angular/platform-browser';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  showHideFlag: boolean = false;
  tnCData:any;
  constructor(public common: CommonService, public _employeeService: BaseServiceService, private sanitizer: DomSanitizer) {
    this.getCmsValue();
    this.common.GetshowhideFlag().subscribe(data => {
      if (data) {
        this.showHideFlag = data;
        console.log('showHideFlag::::::', this.showHideFlag);
      }
    })
   }


  ngOnInit(): void {
  }
  getCmsValue() {
    var self = this;
    let requestData={
      keyword:''
    }
      self._employeeService.post('cms_list', requestData).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            let dataSystem;
            dataSystem=res.data;
            let tncDataList= dataSystem.filter(item =>item.slug=='privacy-policy')
            this.tnCData=tncDataList[0]
            
          } else {
            // self._employeeService.showError(res.replyMsg)
          }
        } else {
          // self._employeeService.showError(res.error.replyMsg)
        }
      }, error => {
        self._employeeService.showError("Oops! Something went wrong!");
      });
    }
    getDescrption(HtmlDescrption){
      return this.sanitizer.bypassSecurityTrustHtml(HtmlDescrption);
    }
}
