<div class="modal popup" id="applyCouponCode" tabindex="-1" aria-labelledby="applyCouponCode" aria-hidden="true"
  data-backdrop="false">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header border-0">
        <!-- <h5 class="modal-title" id="bookFreeClassModalLabel">Modal title</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0 pb-5 book-class-modal">
        <div class="row align-items-center">
          <div class="col-md-12">

            <div class="form-group">
              <label for="couponCode"> Enter Coupon Code</label>
              <input type="input" placeholder="Enter Coupon Code" class="form-control font_size-15"
                name="couponCodeValue" [(ngModel)]="couponCodeValue" (input)="onChangeCouponCode($event.target.value)">
            </div>
          </div>
          <div class="col-md-12 pl-3" *ngIf="isApply">
            <p *ngIf="isInvalid" class="text-danger">Invalid coupon Code</p>
            <p *ngIf="!isInvalid"> You saved Rs. {{TotalDiscountValue}}</p>
          </div>
          <div class="col-md-12 d-flex">
            <a href="javascript:void(0);" *ngIf="!isApply" class="skip-payment" (click)="SkipCouponCode()">Skip to
              payment</a>
            <a href="javascript:void(0);" *ngIf="!isApply" class="apply-payment" (click)="ApplyCouponCode()">Apply</a>
            <h4 *ngIf="isApply" style="margin: 16px;">Pay Rs. {{Amount}}/-<span
                style="color:red; ">Rs.<s>{{Amount+TotalDiscountValue}}/-</s></span></h4>
            <a href="javascript:void(0);" *ngIf="isApply" class="pay-payment" (click)="PayNowPayment()">Pay Now</a>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>