import { CommonService } from './../../service/common.service';
import { environment } from './../../../environments/environment';
import { StarRatingComponent } from 'ng-starrating';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { BaseServiceService } from 'src/app/service';
import { ActivatedRoute } from '@angular/router';
declare var Razorpay: any;
declare var $: any;

import { Subscription } from 'rxjs';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailPatternValidator, MobilePatternValidator } from 'src/app/validate-pattern';
import { UserService } from 'src/app/service/user.service';
import { EnvService } from 'src/app/service/env.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [DatePipe]
})
export class CheckoutComponent implements OnInit {
  UserID: any;
  public couponCodeValue = '';
  public couponValue = 0;
  public TotalPayAmount;
  public isApply = false;
  public TotalDiscountValue = 0;
  public isInvalid = false;
  RegisterFormPie: FormGroup; submitted = false; RegisterPiebtnsubmitted = false;
  Amount: number = 0;
  UserData: any;
  checkoutValue: any;
  pieAgeGroupList: any = [];
  Loader_Flag: boolean = false; interval;
  subscription: Subscription;
  checkLoginStatus: boolean = false;
  clearCoupon: boolean = false;
  @ViewChild('Payment_Popup', { static: false }) Payment_Popup: ElementRef;

  countryList: any = [];
  stateList: any = [];
  cityList: any = [];
  sponsorCouponTest: boolean = false;
  panCardNo: any = '';
  DOB: any = '';
  FName: any = '';
  Pincode: any = '';
  LazyPaymentModal:boolean=false;
  constructor(public fb: FormBuilder, public activatedRoute: ActivatedRoute, public _employeeService: BaseServiceService, public common: CommonService, public _leadsquaredService: LeadsquaredService, public userService: UserService, private envService: EnvService, private _datePipe: DatePipe, private sanitizer: DomSanitizer,public modalService:ModalService) {
    
    this.RegisterFormPie = this.fb.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      // age: ['', [Validators.required]],
      state: ['', [Validators.required]],
      // gst: [''],
      city: ['', [Validators.required]],
      country: ['India', [Validators.required]],
      pincode: [''],
      phone_no: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    });
  }
  get fRegisterPie() { return this.RegisterFormPie.controls; }
  next_Sunday: Date;
  courseStartDate: string | number | Date;
  phoneNoFlag: boolean = false;
  UserDataDynamically: any;
  date: Date;
  date1: Date;
  date2: Date;
  SubscriptionStartDate: Date;
  getCountryList() {
    
  }
  getStateList(id) {
    var requestData = {
      country_id: id,
      keyword: ''
    }
    this._employeeService.post('state_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        this.common.SetStateData(res.data);
      }
    });
  }
  getCityList(id) {
    var requestData = {
      state_id: id,
      keyword: ''
    }
    this._employeeService.post('city_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        this.common.SetCityData(res.data);
      }
    });
  }
  onChangeCountry(countryName) {
    console.log('onChangeCountry::::countryName:::::', countryName);
    var filterCountry = this.countryList.filter(x => x.name == countryName);
    console.log('filterCountry:::::', filterCountry);
    if (filterCountry.length > 0) {
      this.getStateList(filterCountry[0].id);
    }
  }
  onChangeState(stateName) {
    // console.log('stateName:::::', stateName);
    var filterState = this.stateList.filter(x => x.name == stateName);
    // console.log('filterState:::::', filterState);
    if (filterState.length > 0) {
      this.getCityList(filterState[0].id);
    }
  }
  getUpdateData() {
    this._employeeService.post('user_details', { id: this.UserID }).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.UserDataDynamically = res.data;
        this.UserData = res.data;
        if (res.data.phone != "") {
          this.phoneNoFlag = true;
        } else {
          this.phoneNoFlag = false;
        }
        // console.log('res.data:::::', res.data);
        localStorage.removeItem('user');
        localStorage.setItem('sid', JSON.stringify(res.sid));
        localStorage.setItem('user', JSON.stringify(res.data));
        this.RegisterFormPie.patchValue({
          // age: res.data.age_group_id,
          phone_no: res.data.phone,
          email: res.data.email,
          name: res.data.name,
          address: res.data.address,
          // gst: res.data.gst,
          state: res.data.state,
          city: res.data.city,
          country: res.data.country,
          id: res.data.id,
          learning: res.data.learning,
          pincode: res.data.pincode
        })
        
        // setTimeout(() => {
          this.onChangeCountry(res.data.country);
        // }, 1000);
        // setTimeout(() => {
        //   this.onChangeState(res.data.state);
        // }, 2000);
      }
    });
  }
  closestMonday = (date) => {
    let toDay_date = new Date(); // current date
    var curr_date = new Date(date); // current date
    //  console.log('date', curr_date);
    var day_info = 8.64e+7; // milliseconds per day
    var days_to_monday = 7 - curr_date.getDay(); // days left to closest Monday
    var monday_in_sec = curr_date.getTime() + days_to_monday * day_info; // aleary Monday in seconds from 1970 x
    this.next_Sunday = new Date(monday_in_sec); // Monday in date object
    this.next_Sunday.setHours(0, 0, 0);
    this.courseStartDate = this.next_Sunday;
    return this.next_Sunday;
  }
  getDescrption(HtmlDescrption) {
    return this.sanitizer.bypassSecurityTrustHtml(HtmlDescrption);
  }
  calculateSave(item) {
    let fackPrice = parseInt(item.fake_price);
    let actualPrice = parseInt(item.amount);
    let discountAmount = fackPrice - actualPrice
    let percent = (discountAmount * 100 / fackPrice);
    return `${percent.toFixed(2)}%`
  }
  ngOnInit(): void {
    this._employeeService.post('info_demo_class_settings', { id: '1' }).subscribe((res: any) => {
      if (!res.error) {
        var classSetting = res.data;
        let subscribeDay = +classSetting.shipping_days;
        this.getSubscribtionUser(subscribeDay);
      }
    });

    this._employeeService.post('country_list', { keyword: '' }).subscribe((res: any) => {
      if (!res.error) {
        this.countryList = res.data;
        console.log('countryList:::',this.countryList)
        this.common.getLoggedInStatus().subscribe(data => {
          console.log('LOGIN:::FLAG:::', data);
          if (data) {
            this.checkLoginStatus = true;
            this.UserData = JSON.parse(localStorage.getItem('user'));
            this.UserID = this.UserData.id;
            this.getUpdateData(); 
          } else {
            this.onChangeCountry('India');
            this.checkLoginStatus = false;
          }
        })
      }
    });
    this.checkoutValue = JSON.parse(localStorage.getItem('checkout'));
    this.Amount = this.checkoutValue.amount;
    

    this._employeeService.post('age_group_list_dropdown', { learning: '1' }).subscribe((res: any) => {
      if (!res.error) {
        this.pieAgeGroupList = res.data;
      }
    });
    this.common.GetCountryData().subscribe(data => {
      if (data) {
        // console.log('COUNTRY::::LIST:', this.countryList);
      }
    })
    this.common.GetStateData().subscribe(data => {
      if (data) {
        this.stateList = data;
        // console.log('STATE::::LIST:', this.stateList);
        if (this.checkLoginStatus) {
          this.onChangeState(this.RegisterFormPie.value.state);
        }
      }
    })
    this.common.GetCityData().subscribe(data => {
      if (data) {
        this.cityList = data;
      }
    });

    this.common.getLoggedInStatus().subscribe(log =>{
      console.log('Loged In Status:::::',log);
      if(log){
        var localUserData = JSON.parse(localStorage.getItem('sid'));
        this.checkStudentSubscription(localUserData);
      }else{
        
      }
    })
    
    this.modalService.GetLazyPayload().subscribe(log =>{
      console.log('GetLazyPayload:::::',log);
      this.LazyPaymentModal=log;
    })
  }
  onChangeCouponCode(value) {
    this.isInvalid = false
    if (value.length > 3) {
      this.isApply = false;
    }
  }
  ApplyCouponCode() {
    if (this.couponCodeValue == '') {
      this._employeeService.showError("Enter Coupon Code...");
    } else {
      if (localStorage.getItem('user')) {
        this.clearCoupon = false;
        let self = this;
        let requestData = {
          amount: this.checkoutValue.amount,
          uid: '',
          course_id: this.checkoutValue.id,
          age_group_id: this.checkoutValue.age_group_id,
          code: this.couponCodeValue,
          learning: this.checkoutValue.learning,
          course_type: this.checkoutValue.course_type,
        }
        this._employeeService.post('apply_coupon', requestData).subscribe((res: any) => {
          if (res.replyCode == 'success') {
            self.isApply = true;
            this.TotalPayAmount = this.checkoutValue.amount;
            this.clearCoupon = true;
            this.TotalDiscountValue = res.discount;
            let actualAmount = (this.TotalPayAmount - res.discount);
            this.Amount = actualAmount
            this.UserDataDynamically
            if (localStorage.getItem('referral_code')) {
              this.sponsorCouponTest = true;
            } else {
              this.sponsorCouponTest = false;
            }
            this._employeeService.showSuccess(res.replyMsg);
          } else {
            self.isInvalid = true;
            this._employeeService.showError(res.replyMsg);
          }
        }, error => {
          this.TotalDiscountValue = 0;
          this.Amount = this.TotalPayAmount
          self.isInvalid = true;
          this._employeeService.showError("Oops! Something went wrong!");
        });
      } else {
        this._employeeService.showError("Enter Fill Details...");
      }

    }

  }
  ClearCouponCode() {
    this.couponCodeValue = ''
    this.clearCoupon = false;
    this.isApply = false;
  }
  SkipCouponCode() {
    this.Amount = this.TotalPayAmount;
    $('#applyCouponCode').modal('hide');
    // this.payNow(event)

  }
  PayNowPayment() {
    $('#applyCouponCode').modal('hide');
    // this.payNow(event)

  }
  onRegister() {
    var self = this;
    self.submitted = true;
    // console.log('self.RegisterFormPie.:::::::', self.RegisterFormPie.value)
    if (self.RegisterFormPie.invalid) {
      this.modalService.SetLoader(false);
      return;
    } else {
      var courseDetails = JSON.parse(localStorage.getItem('checkout'))
      this.RegisterPiebtnsubmitted = true;
      let requestUrl;
      let sendRequestData;
      var split_string = this.RegisterFormPie.value.name.split(' ');
      // console.log('split_string:::::', split_string);
      if (split_string.length >= 2) {
        if (this.UserData) {
          requestUrl = 'update_student_profile';
          sendRequestData = {
            "phone_no": this.RegisterFormPie.value.phone_no,
            "email": this.RegisterFormPie.value.email,
            'name': this.RegisterFormPie.value.name,
            'address': this.RegisterFormPie.value.address,
            'state': this.RegisterFormPie.value.state,
            'city': this.RegisterFormPie.value.city,
            'country': this.RegisterFormPie.value.country,
            "learning": '0',
            "id": this.UserData.id,
            "dob": this.UserData.dob,
            "gender": this.UserData.gender,
            "parents_name": this.UserData.parents_name,
            "school_name": this.UserData.school_name,
            "time_zone": this.UserData.time_zone,
            "sponsor_code": this.UserData.sponsor_code,
            "pincode": this.RegisterFormPie.value.pincode
          }
        } else {
          requestUrl = 'register_student';
          sendRequestData = {
            "phone_no": this.RegisterFormPie.value.phone_no,
            "email": this.RegisterFormPie.value.email,
            'name': this.RegisterFormPie.value.name,
            'address': this.RegisterFormPie.value.address,
            'state': this.RegisterFormPie.value.state,
            'city': this.RegisterFormPie.value.city,
            'country': this.RegisterFormPie.value.country,
            "learning": '1',
            "sponsor_code": this._leadsquaredService.getSponsorCode(),
            "pincode": this.RegisterFormPie.value.pincode
          }
        }
        this.modalService.SetLoader(true);
        // this.Payment_Popup.show();
        // $('#Payment_Popup').modal('show');
        self._employeeService.post(requestUrl, sendRequestData).subscribe((res: any) => {
          if (res.replyCode == "success") {
            if (res.replyMsg == "Profile updated successfully") {
            } else {
              localStorage.setItem('sid', JSON.stringify(res.sid));
              localStorage.setItem('user', JSON.stringify(res.data));
              this.UserID = res.data.id;
              this.UserData = res.data;
            }
            // console.log('this.UserData:::::', this.UserData)
            this.getUpdateData();
            this.checkLoginStatus = true;
            this.common.setLoggedInStatus(true);
            this.common.getLoggedInStatus().subscribe(data => {
              // console.log('data::::::::', data);
              if (data) {
                if (this.Payment_Type == 2) {
                  this.modalService.SetLoader(false);
                  this.modalService.SetLazyPayload(true);
                  // $('#Lazy_Pay_Payment_Popup').modal('show');
                } else {
                  setTimeout(() => {
                    this.getPayment();
                  }, 1000);
                }
              }
            })
            this._employeeService.showSuccess(res.replyMsg);
          } else {
            this._employeeService.showError(res.replyMsg);
          }
        });
      } else {
        this._employeeService.showError('Please enter full name.');
      }


    }
  }
  Payment_Type: any = 1;
  onSelectPaymentType(type) {
    this.Payment_Type = type;
  }
  onCloseModal() {
    this.modalService.SetLoader(false);
                  this.modalService.SetLazyPayload(false);
  }
  onPaySubmit() {
    this.onRegister();
    this.common.getLoggedInStatus().subscribe(data => {
      // console.log('data::::::::', data);
    })
    // console.log('checkLoginStatus::::::', this.checkLoginStatus);
    // console.log('Payment_Type::::::', this.Payment_Type);
    // this.onRegister();

  }
  getPayment() {
    
    var split_string = this.UserData.name.split(' ');
    var lName = '';
    if (split_string.length > 2) {
      lName = split_string[1] + ' ' + split_string[2];
    } else {
      lName = split_string[1] || '';
    }
    let self = this;
    let requestData: any = {
      firstname: split_string[0],
      lastname: lName,
      email: this.UserData.email,
      phone: this.UserData.phone,
      amount: this.Amount,
      productinfo: this.checkoutValue.course_name,
      sid: JSON.parse(localStorage.getItem('sid')),
      transaction_id: new Date().getTime(),
      pan: this.panCardNo,
      dob: this.DOB,
      parent_name: this.FName,
      address: this.UserData.address,
      city: this.UserData.city,
      pincode: this.UserData.pincode
    }
    // console.log('this.Payment_Type::::::::', this.Payment_Type);
    var url: any;
    console.log('This.date1::::::',this.date1);
    console.log('This.date2::::::',this.date2);
    this.compareDate(this.date1,this.date2);
    console.log('This.Subsciption::::::',this.SubscriptionStartDate);
    let coursedate: any;
    if(this.checkoutValue.course_type ==3){
      coursedate= this._datePipe.transform(new Date(), 'yyyy-MM-dd');
    }else{
      coursedate = this._datePipe.transform(new Date(this.SubscriptionStartDate), 'yyyy-MM-dd');
    }
    return;
    
    this.SubscriptionStartDate = coursedate;
    if (this.Payment_Type == 1 || this.Payment_Type == 3) {
      url = 'make_payment';
      this.Loader_Flag = true;
      if (this.checkoutValue.terms == "1") {
        requestData.subvention_eligibility = "3month";
      } else if (this.checkoutValue.terms == "1,2") {
        requestData.subvention_eligibility = "3month,6month";
      } else {
        requestData.subvention_eligibility = "3month,6month,9month";
      }
      this._employeeService.post(url, requestData).subscribe((res: any) => {
        self.isApply = true;
        // console.log('res::::::::', res);
        if (res.replyCode == 'success') {

          const pay_form: any = document.createElement('form');
          pay_form.name = 'PayUmoney';
          pay_form.method = 'post';
          pay_form.action = `https://secure.payu.in/_payment`;
          pay_form.target = `_blank`;
          var data = {
            "surl": `${this.envService.env}/payment-success`,
            "furl": `${this.envService.env}/payment-failure`,
            "key": "L65Dbu",
            "txnid": requestData.transaction_id,
            "amount": requestData.amount,
            "productinfo": requestData.productinfo,
            "firstname": requestData.firstname,
            "Lastname": requestData.lastname,
            "email": requestData.email,
            "phone": requestData.phone,
            "address1": this.RegisterFormPie.value.address,
            "address2": "",
            "city": this.UserData.city,
            "state": this.UserData.state,
            "country": this.UserData.country,
            "Zipcode": this.UserData.pincode,
            "Pg": "",
            "hash": res.hash,
            "subvention_amount": requestData.amount,
            "subvention_eligibility": requestData.subvention_eligibility
          }
          const myParams = Object.keys(data);
          for (let i = 0; i < myParams.length; i++) {
            const key = myParams[i];
            let my_tb: any = document.createElement('input');
            my_tb.type = 'hidden';
            my_tb.name = key;
            my_tb.value = data[key];
            pay_form.appendChild(my_tb);
          };
          document.body.appendChild(pay_form);
          pay_form.submit();
          this.interval = setInterval(() => {
            this._employeeService.post('check_payment_status', { transaction_id: requestData.transaction_id }).subscribe((res: any) => {
              // console.log('res::::::::', res);
              if (res.data.transaction_details[requestData.transaction_id].status == 'success') {
                var split_string = this.UserData.name.split(' ');
                var senRequestLeeddata: any = this.UserData;
                senRequestLeeddata.first_name = split_string[0];
                senRequestLeeddata.learning = this.checkoutValue.learning;
                if (split_string.length > 2) {
                  senRequestLeeddata.last_name = split_string[1] + ' ' + split_string[2];
                } else {
                  senRequestLeeddata.last_name = split_string[1] || '';
                }
                clearInterval(this.interval);

                let requestDatatx = {
                  transaction_id: requestData.transaction_id,
                  price: this.Amount,
                  course_id: this.checkoutValue.id,
                  student_id: this.UserData.id,
                  created_by: this.UserData.id,
                  course_start_date: this.SubscriptionStartDate,
                  parents_name: this.UserData.parents_name,
                  student_name: this.UserData.name,
                  course_name: this.checkoutValue.course_name,
                  phone: this.UserData.phone,
                  learning: this.checkoutValue.learning

                }

                this._employeeService.post('student_subscription', requestDatatx).subscribe((res: any) => {
                  if (res.replyCode == 'success') {
                    const hostname = window && window.location && window.location.hostname;
                    if (/^learningbix.com/.test(hostname)) {
                      this.subscription = this._leadsquaredService.AddLeadCapturePurchase(senRequestLeeddata, this.checkoutValue, this.Amount, this.SubscriptionStartDate).subscribe(message => {
                        // console.log(message)
                      })
                    }

                    this._employeeService.showSuccess(res.replyMsg);
                    this.userService.checkStudentSubscription(JSON.parse(localStorage.getItem('sid')));
                    // window.location.href = `${this.envService.env}/student/pie/Dashboard`;
                    this.modalService.SetLoader(false);
                    // localStorage.removeItem('checkout');
                  } else {
                    this._employeeService.showError(res.error.replyMsg);
                  }
                }, error => {
                  this._employeeService.showError("Oops! Something went wrong!");
                });
                this.Loader_Flag = false;
              } else if (res.data.transaction_details[requestData.transaction_id].status == 'failure') {
                this.modalService.SetLoader(false);
                location.reload();
              }
            }, error => {
            });
          }, 5000);
          window.open(res.payulink, "_blank");
        } else {
        }
      }, error => {
      });
    } else if (this.Payment_Type == 2) {
      url = 'make_payment_lazypay';
      this._employeeService.post(url, requestData).subscribe((res: any) => {
        self.isApply = true;
        // console.log('res::::::::', res);
        if (res.replyCode == 'success') {
          if (res.payulink.status == 400) {
            this._employeeService.showError(res.payulink.message);
          } else {
            this.modalService.SetLazyPayload(false);
            window.open(res.payulink.redirectUrl, "_blank");
          }
          // this.interval = setInterval(() => {
          //   this._employeeService.post('check_payment_status', { transaction_id: requestData.transaction_id }).subscribe((res: any) => {
          //     console.log('res::::::::', res);
          //     if (res.replyCode == 'success') {
          //       var split_string = this.UserData.name.split(' ');
          //       var senRequestLeeddata: any = this.UserData;
          //       senRequestLeeddata.first_name = split_string[0];
          //       senRequestLeeddata.learning = this.checkoutValue.learning;
          //       if (split_string.length > 2) {
          //         senRequestLeeddata.last_name = split_string[1] + ' ' + split_string[2];
          //       } else {
          //         senRequestLeeddata.last_name = split_string[1] || '';
          //       }
          //       if (res.payulink.result[0].postBackParam.status == "success") {
          //         clearInterval(this.interval);
          //         this._employeeService.post('student_subscription', requestData).subscribe((res: any) => {
          //           if (res.replyCode == 'success') {
          //             this.subscription = this._leadsquaredService.AddLeadCapturePurchase(senRequestLeeddata, this.checkoutValue, this.Amount, this.courseStartDate).subscribe(message => {
          //             })
          //             this._employeeService.showSuccess(res.replyMsg);
          //             window.location.href = `${this.envService.env}/student/pie/Dashboard`;
          //             $('#Payment_Popup').modal('show');
          //             localStorage.removeItem('checkout');
          //           } else {
          //             this._employeeService.showError(res.error.replyMsg);
          //           }
          //         }, error => {
          //           this._employeeService.showError("Oops! Something went wrong!");
          //         });
          //       }
          //       this.Loader_Flag = false;
          //     } else {
          //     }
          //   }, error => {
          //   });
          // }, 5000);

        } else {
        }
      }, error => {
      });
    }
  }
  PayNowPaymentSubmit() {
    if (this.panCardNo == '') {
      this._employeeService.showError('All field are required.');
    } else if (this.DOB == '') {
      this._employeeService.showError('All field are required.');
    } else if (this.FName == '') {
      this._employeeService.showError('All field are required.');
    } else {
      // this.onRegister();
      setTimeout(() => {
        this.getPayment();
      }, 1000);
    }

  }
  checkStudentSubscription(sid){
    var userSID=sid;
    // var userSID="MAd";
    this._employeeService.post('check_student_subscription',{sid:userSID}).subscribe((data:any) =>{
      console.log('data::::::',data)
      if(data.data.length>0){
        var start_date = data.data[data.data.length-1].course_start_date;
        var terms = data.data[data.data.length-1].terms;
        // this.getAlreadySubscribtionUser(start_date,terms);
        console.log('Date::::Calculation',this.getAlreadySubscribtionUser(start_date,terms));
      }else{
        // console.log('Date::::Calculation',this.getAlreadySubscribtionUser(start_date,terms));
      }
    })
  }
  getAlreadySubscribtionUser(start_date,terms){
    this.date = new Date(start_date);
    console.log('demo:::this.date',this.date);
    const newTerms:any=terms.split(',');
    console.log('terms::::newTerms',newTerms);
    this.date.setDate( this.date.getDate() + newTerms.length * 90 );
    console.log('demo:::this.date:::::New',this.date);
    // this.date.setDate(this.date.getDate() + (((1 + 7 - this.date.getDay()) % 7) || 7));
    // console.log('Monday::::calculation::::',this.date);
    return this.nextSundayDate2(this.date,0);;
  }
  nextSundayDate1(date,dayIndex) {
    var today = new Date(date);
    today.setDate(today.getDate() + (dayIndex - 1 - today.getDay() + 7) % 7 + 1);
    this.date1=today;
    return this.date1;
  }
  nextSundayDate2(date,dayIndex) {
    console.log('Date::::2::::',date,dayIndex)
    var today = new Date(date);
    today.setDate(today.getDate() + (dayIndex - 1 - today.getDay() + 7) % 7 + 1);
    this.date2=today;
    return this.date2;
  }
  getSubscribtionUser(shipping_days){
    this.date = new Date();
    this.date.setDate( this.date.getDate() + shipping_days );
    return this.nextSundayDate1(this.date,0);
  }
  compareDate(d1,d2){
    let date1:any;
    let date2:any;
    let returnDate:any;
    console.log('compareDate::::::Date:::1',date1);
    console.log('compareDate::::::Date:::2',date2);
    if(d1){
      date1 = new Date(d1);
    }
    if(d2){
      date2 = new Date(d2);
    }else{
      date2 = new Date();
    }
    if(date1 > date2){
        console.log(`${d1} is greater than ${d2}`);
        this.SubscriptionStartDate=d1;
        return this.SubscriptionStartDate
    } else{
        console.log(`${d2} is greater than ${d1}`)
        this.SubscriptionStartDate=d2;
        return this.SubscriptionStartDate
    }
  }
}
