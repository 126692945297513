import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseServiceService, TokenService } from '../../service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
@Component({
  selector: 'app-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.scss']
})
export class BasicModalComponent implements OnInit {
  safeSrcVideoUrl: SafeResourceUrl;
  constructor(public _employeeService: BaseServiceService, public token: TokenService, public userService: UserService, private modalService: ModalService, private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this.safeSrcVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/540034347');
    console.log('safeSrcVideoUrl:::::', this.safeSrcVideoUrl);
  }
  onCloseModal30Sec() {
    this.modalService.SetBasicModal(false);
  }
  onBook7DayTrial() {
    this.modalService.SetBasicModal(false);

  }
}
