import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../validate-pattern';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
declare var Razorpay: any;

@Component({
  selector: 'app-apply-coupon-modal',
  templateUrl: './apply-coupon-modal.component.html',
  styleUrls: ['./apply-coupon-modal.component.scss'],
  providers: [DatePipe]
})
export class ApplyCouponModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  subscription: Subscription;
  public couponCodeValue = '';public isInvalid = false;public isApply = false;public TotalDiscountValue = 0;Amount: number = 0;public TotalPayAmount;courseStartDate;
  currentDate: any; currentTime: any; isCurrentTime = false;classSetting: any;next_Sunday;couponStatus = 0;
  options: any = environment.razorPayoptions;
  courseDetailsData: any;
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public userService: UserService, private modalService: ModalService, private _datePipe: DatePipe) { }
  ngOnInit(): void {
    this._employeeService.post('info_demo_class_settings', { id: '1' }).subscribe((res: any) => {
      if (!res.error) {
        this.classSetting = res.data;
        this.couponStatus = this.classSetting.coupon_status;
        let subscribeDay = +this.classSetting.shipping_days
        let today_Date = new Date();
        let courseStartDate = new Date(new Date(today_Date).getTime() + subscribeDay * 24 * 60 * 60 * 1000);
        this.closestMonday(courseStartDate)
      }
    });
    this.Form = this.fb.group({
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    })
  }
  closestMonday = (date) => {
    let toDay_date = new Date(); // current date
    var curr_date = new Date(date); // current date
    var day_info = 8.64e+7; // milliseconds per day
    var days_to_monday = 7 - curr_date.getDay(); // days left to closest Monday
    var monday_in_sec = curr_date.getTime() + days_to_monday * day_info; // aleary Monday in seconds from 1970 x
    this.next_Sunday = new Date(monday_in_sec); // Monday in date object
    this.next_Sunday.setHours(0, 0, 0);
    // console.log('next_Sunday', this.next_Sunday)
    this.courseStartDate = this.next_Sunday;
    return this.next_Sunday;
  }
  get f() { return this.Form.controls; }
  
  onChangeCouponCode(value) {
    this.isInvalid = false
    if (value.length > 3) {
      this.isApply = false;
    }
  }
  SkipCouponCode() {
    this.Amount = this.TotalPayAmount;
    // this.payNow(event)
  }
  ApplyCouponCode() {
    if (localStorage.getItem('checkout')) {
      this.courseDetailsData = JSON.parse(localStorage.getItem('checkout'));
    }
    if (sessionStorage.getItem('user')) {
      var userData = JSON.parse(localStorage.getItem('user'));
    }
    let requestData = {
      amount:  this.courseDetailsData.amount,
      uid: userData.id,
      course_id:  this.courseDetailsData.id,
      age_group_id:  this.courseDetailsData.age_group_id,
      code: this.couponCodeValue,
      learning:  this.courseDetailsData.learning,
      course_type:  this.courseDetailsData.course_type,
    }

    this._employeeService.post('apply_coupon', requestData).subscribe((res: any) => {
      this.isApply = true;
      if (res.replyCode == 'success') {
        this.TotalDiscountValue = res.discount;
        let actualAmount = (this.TotalPayAmount - res.discount);
        this.Amount = actualAmount
        this._employeeService.showSuccess(res.replyMsg);
      } else {
        this.TotalDiscountValue = 0;
        this.Amount = this.TotalPayAmount
        this.isInvalid = true;
        this._employeeService.showError(res.replyMsg);
      }
    }, error => {
      this.TotalDiscountValue = 0;
      this.Amount = this.TotalPayAmount
      this.isInvalid = true;
      this._employeeService.showError("Oops! Something went wrong!");
    });
  }
  onCloseModal() {
    this.modalService.setAppyCouponModal(false);
    // this.modalService.setloginemailBixModal(true);
  }
  PayNowPayment() {
    // this.payNow(event)
  }
}
