<!-- <link href="//netdna.bootstrapcdn.com/bootstrap/3.2.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css"> -->
<script src="//netdna.bootstrapcdn.com/bootstrap/3.2.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>

<nav class="navbar navbar-expand-lg navbar-light bg-white sticky-top">
    <div class="container-fluid">
        <a class="navbar-brand" href="/"><img src="assets/imgs/logo-pie.png" alt=""></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav m-auto">
                <li class="nav-item mr-4">
                    <a class="nav-link" href="javascript:void(0);" routerLink="/">Home</a>
                </li>
                <!-- <li class="nav-item mr-4">
                    <a class="nav-link" href="javascript:void(0);" (click)="redirect('blog');">Blog</a>
                </li> -->
                <!-- <li class="nav-item mr-4">
                  <a class="nav-link" href="javascript:void(0);" routerLink="/review">Review</a>
                </li> -->
                <li class="nav-item mr-4">
                    <a class="nav-link" href="javascript:void(0);" (click)="redirect('program');">Programs</a>
                </li>
                <li class="nav-item mr-4">
                    <a class="nav-link" href="javascript:void(0);" (click)="redirect('pricing');">Pricing</a>
                </li>
                <li class="nav-item mr-4">
                    <a class="nav-link" href="tel:80 10 55 44 00" style="display: flex; align-items: center;"><img src="assets/imgs/phone-icon.png">&nbsp;91-8010554400</a>
                </li>
            </ul>

            <button class="btn btn-primary btn-book mr-3" type="button" (click)="onOpenPieModal()">Book a free
                class</button>

            <button class="btn btn-outline-primary btn-login" type="button" (click)="onLoginPanelPopup()" *ngIf="!UserLoginFlag">Login</button>&nbsp;

            <button class="btn btn-outline-primary btn-login" type="button" (click)="onRedirect()" *ngIf="UserLoginFlag">My
                Profile</button>&nbsp;
        </div>
    </div>
</nav>
<section>
    <div class="container">
        <div class="row my-4">
            <div class="col-12 text-center">
                <h4>LearningPie@HOME-Our Happy Parents</h4>
                <p>Listen what parents talk about LearningPie, online preschool program</p>
            </div>
        </div>
        <div class="row" *ngIf="TestimonialData">
            <div class="col-lg-6 col-md-6 col-sm-12 mb-5">
                <div class="card-explore-section" style="height: auto;">
                    <div class="section_header text-left">
                        <h4 class="name mb-0">{{TestimonialData[0].name}}</h4>
                        <p class="position mb-0">{{TestimonialData[0].position}}</p>
                    </div>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe id="myFrame" [src]="safeSrc1" class="iframefull embed-responsive-item" frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                    </div>
                    <div class="section_description" style="z-index: 9999;">
                        <p class="description mb-0" style="word-break: break-word;">
                            <span *ngIf="!TestimonialData[0].class_view">
                                    <span
                                        *ngIf="TestimonialData[0].description.length >190">"{{TestimonialData[0].description.slice(0,190)}}..."</span>
                            <span *ngIf="!(TestimonialData[0].description.length >190)">"{{TestimonialData[0].description.slice(0,190)}}"</span>
                            </span>
                            <span *ngIf="TestimonialData[0].class_view">"{{TestimonialData[0].description}}"</span>
                            <ng-container *ngIf="TestimonialData[0].description.length >190">
                                <a style="color: blue;" *ngIf="!TestimonialData[0].class_view" (click)="onChangeDescriptionView(TestimonialData[0])">View More&nbsp;<i
                                            class="fas fa-angle-down"></i></a>
                                <a style="color: blue;" *ngIf="TestimonialData[0].class_view" (click)="onChangeDescriptionView(TestimonialData[0])">View Less&nbsp;<i
                                            class="fas fa-angle-up"></i></a>
                            </ng-container>

                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-5">
                <div class="card-explore-section" style="height: auto;">
                    <div class="section_header text-left">
                        <h4 class="name mb-0">{{TestimonialData[1].name}}</h4>
                        <p class="position mb-0">{{TestimonialData[1].position}}</p>
                    </div>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe id="myFrame" [src]="safeSrc2" class="iframefull embed-responsive-item" frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                    </div>
                    <div class="section_description" style="z-index: 9999;">
                        <p class="description mb-0" style="word-break: break-word;">
                            <span *ngIf="!TestimonialData[1].class_view">
                                    <span
                                        *ngIf="TestimonialData[1].description.length >190">"{{TestimonialData[1].description.slice(0,190)}}..."</span>
                            <span *ngIf="!(TestimonialData[1].description.length >190)">"{{TestimonialData[1].description.slice(0,190)}}"</span>
                            </span>
                            <span *ngIf="TestimonialData[1].class_view">"{{TestimonialData[1].description}}"</span>
                            <ng-container *ngIf="TestimonialData[1].description.length >190">
                                <a style="color: blue;" *ngIf="!TestimonialData[1].class_view" (click)="onChangeDescriptionView(TestimonialData[1])">View More&nbsp;<i
                                            class="fas fa-angle-down"></i></a>
                                <a style="color: blue;" *ngIf="TestimonialData[1].class_view" (click)="onChangeDescriptionView(TestimonialData[1])">View Less&nbsp;<i
                                            class="fas fa-angle-up"></i></a>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-5">
                <div class="card-explore-section" style="height: auto;">
                    <div class="section_header text-left">
                        <h4 class="name mb-0">{{TestimonialData[2].name}}</h4>
                        <p class="position mb-0">{{TestimonialData[2].position}}</p>
                    </div>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe id="myFrame" [src]="safeSrc3" class="iframefull embed-responsive-item" frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                    </div>
                    <div class="section_description" style="z-index: 9999;">
                        <p class="description mb-0" style="word-break: break-word;">
                            <span *ngIf="!TestimonialData[2].class_view">
                                    <span
                                        *ngIf="TestimonialData[2].description.length >190">"{{TestimonialData[2].description.slice(0,190)}}..."</span>
                            <span *ngIf="!(TestimonialData[2].description.length >190)">"{{TestimonialData[2].description.slice(0,190)}}"</span>
                            </span>
                            <span *ngIf="TestimonialData[2].class_view">"{{TestimonialData[2].description}}"</span>
                            <ng-container *ngIf="TestimonialData[2].description.length >190">
                                <a style="color: blue;" *ngIf="!TestimonialData[2].class_view" (click)="onChangeDescriptionView(TestimonialData[2])">View More&nbsp;<i
                                            class="fas fa-angle-down"></i></a>
                                <a style="color: blue;" *ngIf="TestimonialData[2].class_view" (click)="onChangeDescriptionView(TestimonialData[2])">View Less&nbsp;<i
                                            class="fas fa-angle-up"></i></a>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-5">
                <div class="card-explore-section" style="height: auto;">
                    <div class="section_header text-left">
                        <h4 class="name mb-0">{{TestimonialData[3].name}}</h4>
                        <p class="position mb-0">{{TestimonialData[3].position}}</p>
                    </div>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe id="myFrame" [src]="safeSrc4" class="iframefull embed-responsive-item" frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                    </div>
                    <div class="section_description" style="z-index: 9999;">
                        <p class="description mb-0" style="word-break: break-word;">
                            <span *ngIf="!TestimonialData[3].class_view">
                                    <span
                                        *ngIf="TestimonialData[3].description.length >190">"{{TestimonialData[3].description.slice(0,190)}}..."</span>
                            <span *ngIf="!(TestimonialData[3].description.length >190)">"{{TestimonialData[3].description.slice(0,190)}}"</span>
                            </span>
                            <span *ngIf="TestimonialData[3].class_view">"{{TestimonialData[3].description}}"</span>
                            <ng-container *ngIf="TestimonialData[3].description.length >190">
                                <a style="color: blue;" *ngIf="!TestimonialData[3].class_view" (click)="onChangeDescriptionView(TestimonialData[3])">View More&nbsp;<i
                                            class="fas fa-angle-down"></i></a>
                                <a style="color: blue;" *ngIf="TestimonialData[3].class_view" (click)="onChangeDescriptionView(TestimonialData[3])">View Less&nbsp;<i
                                            class="fas fa-angle-up"></i></a>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-5">
                <div class="card-explore-section" style="height: auto;">
                    <div class="section_header text-left">
                        <h4 class="name mb-0">{{TestimonialData[4].name}}</h4>
                        <p class="position mb-0">{{TestimonialData[4].position}}</p>
                    </div>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe id="myFrame" [src]="safeSrc5" class="iframefull embed-responsive-item" frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                    </div>
                    <div class="section_description" style="z-index: 9999;">
                        <p class="description mb-0" style="word-break: break-word;">
                            <span *ngIf="!TestimonialData[4].class_view">
                                    <span
                                        *ngIf="TestimonialData[4].description.length >190">"{{TestimonialData[4].description.slice(0,190)}}..."</span>
                            <span *ngIf="!(TestimonialData[4].description.length >190)">"{{TestimonialData[4].description.slice(0,190)}}"</span>
                            </span>
                            <span *ngIf="TestimonialData[4].class_view">"{{TestimonialData[4].description}}"</span>
                            <ng-container *ngIf="TestimonialData[4].description.length >190">
                                <a style="color: blue;" *ngIf="!TestimonialData[4].class_view" (click)="onChangeDescriptionView(TestimonialData[4])">View More&nbsp;<i
                                            class="fas fa-angle-down"></i></a>
                                <a style="color: blue;" *ngIf="TestimonialData[4].class_view" (click)="onChangeDescriptionView(TestimonialData[4])">View Less&nbsp;<i
                                            class="fas fa-angle-up"></i></a>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-5">
                <div class="card-explore-section" style="height: auto;">
                    <div class="section_header text-left">
                        <h4 class="name mb-0">{{TestimonialData[5].name}}</h4>
                        <p class="position mb-0">{{TestimonialData[5].position}}</p>
                    </div>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe id="myFrame" [src]="safeSrc6" class="iframefull embed-responsive-item" frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                    </div>
                    <div class="section_description" style="z-index: 9999;">
                        <p class="description mb-0" style="word-break: break-word;">
                            <span *ngIf="!TestimonialData[5].class_view">
                                    <span
                                        *ngIf="TestimonialData[5].description.length >190">"{{TestimonialData[5].description.slice(0,190)}}..."</span>
                            <span *ngIf="!(TestimonialData[5].description.length >190)">"{{TestimonialData[5].description.slice(0,190)}}"</span>
                            </span>
                            <span *ngIf="TestimonialData[5].class_view">"{{TestimonialData[5].description}}"</span>
                            <ng-container *ngIf="TestimonialData[5].description.length >190">
                                <a style="color: blue;" *ngIf="!TestimonialData[5].class_view" (click)="onChangeDescriptionView(TestimonialData[5])">View More&nbsp;<i
                                            class="fas fa-angle-down"></i></a>
                                <a style="color: blue;" *ngIf="TestimonialData[5].class_view" (click)="onChangeDescriptionView(TestimonialData[5])">View Less&nbsp;<i
                                            class="fas fa-angle-up"></i></a>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-5">
                <div class="card-explore-section" style="height: auto;">
                    <div class="section_header text-left">
                        <h4 class="name mb-0">{{TestimonialData[6].name}}</h4>
                        <p class="position mb-0">{{TestimonialData[6].position}}</p>
                    </div>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe id="myFrame" [src]="safeSrc7" class="iframefull embed-responsive-item" frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                    </div>
                    <div class="section_description" style="z-index: 9999;">
                        <p class="description mb-0" style="word-break: break-word;">
                            <span *ngIf="!TestimonialData[6].class_view">
                                    <span
                                        *ngIf="TestimonialData[6].description.length >190">"{{TestimonialData[6].description.slice(0,190)}}..."</span>
                            <span *ngIf="!(TestimonialData[6].description.length >190)">"{{TestimonialData[6].description.slice(0,190)}}"</span>
                            </span>
                            <span *ngIf="TestimonialData[6].class_view">"{{TestimonialData[6].description}}"</span>
                            <ng-container *ngIf="TestimonialData[6].description.length >190">
                                <a style="color: blue;" *ngIf="!TestimonialData[6].class_view" (click)="onChangeDescriptionView(TestimonialData[6])">View More&nbsp;<i
                                            class="fas fa-angle-down"></i></a>
                                <a style="color: blue;" *ngIf="TestimonialData[6].class_view" (click)="onChangeDescriptionView(TestimonialData[6])">View Less&nbsp;<i
                                            class="fas fa-angle-up"></i></a>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-5">
                <div class="card-explore-section" style="height: auto;">
                    <div class="section_header text-left">
                        <h4 class="name mb-0">{{TestimonialData[7].name}}</h4>
                        <p class="position mb-0">{{TestimonialData[7].position}}</p>
                    </div>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe id="myFrame" [src]="safeSrc8" class="iframefull embed-responsive-item" frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                    </div>
                    <div class="section_description" style="z-index: 9999;">
                        <p class="description mb-0" style="word-break: break-word;">
                            <span *ngIf="!TestimonialData[7].class_view">
                                    <span
                                        *ngIf="TestimonialData[7].description.length >190">"{{TestimonialData[7].description.slice(0,190)}}..."</span>
                            <span *ngIf="!(TestimonialData[7].description.length >190)">"{{TestimonialData[7].description.slice(0,190)}}"</span>
                            </span>
                            <span *ngIf="TestimonialData[7].class_view">"{{TestimonialData[7].description}}"</span>
                            <ng-container *ngIf="TestimonialData[7].description.length >190">
                                <a style="color: blue;" *ngIf="!TestimonialData[7].class_view" (click)="onChangeDescriptionView(TestimonialData[7])">View More&nbsp;<i
                                            class="fas fa-angle-down"></i></a>
                                <a style="color: blue;" *ngIf="TestimonialData[7].class_view" (click)="onChangeDescriptionView(TestimonialData[7])">View Less&nbsp;<i
                                            class="fas fa-angle-up"></i></a>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>

        </div>


        <!-- <div class="col">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let testimonial of [0,1,2,3,4,5,6];let i= index">
                    <div class="col col-sm-4">
                        <img src="../../../assets/imgs/Concept.png" alt="" class="img-fluid">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div> -->
        <div class="row my-4" *ngIf="images">
            <div class="col-12 text-center">
                <h4>LearningPie@HOME- Our Happy Kids </h4>
                <p>We are so glad that our online preschool program has 100000+ happy kids!!</p>
            </div>
        </div>
        <div class="col-12">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let itemdata of images;let i = index;">
                    <div class="slide">
                        <img src="{{itemdata.path}}" class="img-fluid review_img" />
                    </div>
                </ng-template>
            </owl-carousel-o>
            <!-- <owl-carousel
                [options]="{items: 4, dots: false, nav: false,loop:true, autoplay: true, responsive : responsiveOptions1,navText : [&#x27;&#x3c;&#x27;,&#x27;&#x3e;&#x27;]}"
                [items]="images" [carouselClasses]="['owl-theme', 'sliding', 'owl-nav','owl-con']">
                <div class="item carousel-cell my-owl" style="padding: 10px 10px; cursor: pointer;"
                    *ngFor="let itemdata of images;let i = index;">
                    <div class="row m-0">
                        <div class="w-100">
                            <img src="{{itemdata.path}}" class="img-fluid review_img" />
                        </div>
                    </div>
                </div>
            </owl-carousel> -->
        </div>
        <!-- <div class="carousel-reviews broun-block">
            <div class="container">
                <div class="row" >
                    <div id="carousel-reviews" class="carousel slide" data-ride="carousel"  style="width: 100%;margin: 0 auto;display: block;text-align: center;">
                        <div class="carousel-inner">
                            <div class="item row active" style="display: flex;">
                                <div class="col col-sm-4">
                                    <img [src]="images[currentImage]?.path" alt="" class="img-fluid review_img review_img">
                                </div>
                                <div class="col col-sm-4 hidden-xs">
                                    <img [src]="images[currentImage+1]?.path" alt="" class="img-fluid review_img">
                                </div>
                                <div class="col col-sm-4 hidden-sm hidden-xs">
                                    <img [src]="images[currentImage+2]?.path" alt="" class="img-fluid review_img">
                                </div>
                            </div>
                        </div>
                        <a class="left carousel-control" role="button" data-slide="prev" (click)="onPrevious()"
                            *ngIf="currentImage > 0" style="transform-origin: 0 50%;transform: rotate(0deg) translate(-50%, 50%);background: none;">
                            <img src="../../../assets/images/left-arrow.png" alt="" width="35px" height="35px">
                        </a>
                        <a class="right carousel-control" role="button" data-slide="next" (click)="onNext()" *ngIf="currentImage < 6" style="    transform-origin: 100% 50%;transform: rotate(0deg) translate(50%, 50%);background: none;">
                            <img src="../../../assets/images/right-arrow.png" alt="" width="35px" height="35px">
                        </a>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</section>

<section class="footer-about">
    <img src="../../../assets/imgs/pie-footer-wave.jpg" class="w-100" alt="">
</section>
<section class="footer-top">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h3 class="foo-link-title">Our Specialities</h3>

                <ul class="foo-links Our_Specialites">
                    <li><label class="foo-nav-link">360 degree excursions.</label></li>
                    <li><label class="foo-nav-link">Robotics, STEM & DIY sessions.</label></li>
                    <li><label class="foo-nav-link">Parenting Seminars & Counselling Sessions.</label></li>
                    <li><label class="foo-nav-link">Gratitude & Mindfulness Sessions for Kids.</label>
                    </li>
                </ul>
                <ul class="social-links my-2">
                    <li>
                        <a href="https://www.facebook.com/learningpiepreschool" target="_blank"><img src="assets/imgs/facebook-f.svg" class="img-fluid" alt=""></a>
                    </li>

                    <li>
                        <a href="https://twitter.com/learningpie1" target="_blank"><img src="assets/imgs/twitter.svg" class="img-fluid" alt=""></a>
                    </li>

                    <li>
                        <a href="https://www.linkedin.com/in/learning-pie-20a34720b/" target="_blank"><img src="assets/imgs/linkedin-in.svg" class="img-fluid" alt=""></a>
                    </li>

                    <li>
                        <a href="https://www.youtube.com/channel/UCA7Wdl0Nk5bDNSHtSsd1qVQ" target="_blank"><img src="assets/imgs/Icons/white-youtube-logo.png" class="img-fluid" alt="" style="width:40px"></a>
                    </li>

                    <li>
                        <a href="https://www.instagram.com/learningpie_/" target="_blank"><img src="assets/imgs/Icons/insta.png" class="img-fluid" alt="" style="width:40px"></a>
                    </li>
                </ul>

            </div>
            <div class="col-md-4">
                <h3 class="foo-link-title text-center">We Accept</h3>
                <div class="w-100 text-center">
                    <img class="my-2 img-fluid" src="assets/imgs/Icons/weacceptpart1.png" alt="">
                    <img class="my-2 img-fluid" src="assets/imgs/Icons/weacceptpart2.png" alt="">
                </div>


            </div>

            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12 right-footer-section">
                        <h3 class="foo-link-title">Important Links</h3>
                        <ul class="foo-links">
                            <li><a href="/contacts-us" class="foo-nav-link">Contact us</a> </li>
                            <li><a href="/page/privacy-policy" class="foo-nav-link">Privacy Policy</a> </li>
                            <li><a href="/page/terms" class="foo-nav-link">Terms & Conditions</a> </li>
                            <li><a href="https://blog.learningbix.com/" target="_blank" class="foo-nav-link">Blogs</a>
                            </li>
                            <!-- <li><a href="#" class="foo-nav-link">FAQs</a></li> -->
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>