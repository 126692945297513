<app-header id="main_header"></app-header>
<header class="header" id="header">
	<section class="banner home_page">
		<div id="carouselExampleCaptions1" class="carousel slide" data-ride="carousel">
			<ol class="carousel-indicators">
				<li data-target="#carouselExampleCaptions1" data-slide-to="0" class="active"></li>
				<li data-target="#carouselExampleCaptions1" data-slide-to="1"></li>
				<li data-target="#carouselExampleCaptions1" data-slide-to="2"></li>
				<li data-target="#carouselExampleCaptions1" data-slide-to="3"></li>
			</ol>
			<div class="carousel-inner doyouknow">
				<div class="carousel-item active">
					<div class="d-block w-100">
						<div class="banner row align-items-center ml-auto mr-auto">
							<div class="col-12 m-0 p-0 slider-img"> <img src="assets/imgs/fwdbannerspng/1.jpg"
									class="img-fluid" alt="" style="max-width: 100%;"> </div>
						</div>
					</div>
				</div>
				<div class="carousel-item">
					<div class="d-block w-100">
						<div class="banner row align-items-center ml-auto mr-auto">
							<div class="col-12 m-0 p-0 slider-img"> <img src="assets/imgs/fwdbannerspng/2.jpg"
									class="img-fluid" alt="" style="max-width: 100%;"> </div>
						</div>
					</div>
				</div>
				<div class="carousel-item">
					<div class="d-block w-100">
						<div class="banner row align-items-center ml-auto mr-auto">
							<div class="col-12 m-0 p-0 slider-img"> <img src="assets/imgs/fwdbannerspng/3.jpg"
									class="img-fluid" alt="" style="max-width: 100%;"> </div>
						</div>
					</div>
				</div>
				<div class="carousel-item">
					<div class="d-block w-100">
						<div class="banner row align-items-center ml-auto mr-auto">
							<div class="col-12 m-0 p-0 slider-img"> <img src="assets/imgs/fwdbannerspng/4.jpg"
									class="img-fluid" alt="" style="max-width: 100%;"> </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="book-demo-form toggle-form">
		<div class="card form_cardHome">
			<h2 class="my-0">Get In Touch </h2>
			<form [formGroup]="AddForm" class="custome-form">
				<div class="input-group my-3">
					<div class="input-group-prepend"> <span class="input-group-text" id="basic-sname"
							[ngClass]="{ 'is-invalid': submitted && f.parents_name.errors }"><i
								class="fas fa-user"></i></span> </div>
					<input type="text" class="form-control" placeholder="Enter Parent Name" required
						aria-label="Username" aria-describedby="basic-sname"
						[ngClass]="{ 'is-invalid': submitted && f.parents_name.errors }" formControlName="parents_name">
				</div>
				<div class="input-group my-3">
					<div class="input-group-prepend"> <span class="input-group-text" id="basic-sname"
							[ngClass]="{ 'is-invalid': submitted && f.name.errors }"><i class="fas fa-user"></i></span>
					</div>
					<input type="text" class="form-control" placeholder="Enter Child Name" required
						aria-label="Username" aria-describedby="basic-sname"
						[ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name">
				</div>
				<div class="input-group my-3">
					<select class="custome-contory-code" id="exampleFormControlSelect1">
						<option>91</option>
					</select>
					<div class="input-group-prepend"> <span class="input-group-text" id="basic-mobile"><i
								class="fas fa-mobile-alt"></i></span> </div>
					<input type="text" required class="form-control mobile" placeholder="Contact No"
						aria-label="Username" maxlength="10" appNumbersOnly aria-describedby="basic-mobile"
						[ngClass]="{ 'is-invalid': submitted && f.phone_no.errors }" formControlName="phone_no">
				</div>
				<div class="input-group my-3">
					<div class="input-group-prepend"> <span class="input-group-text" id="basic-email"><i
								class="fas fa-envelope"></i></span> </div>
					<input type="email" class="form-control" placeholder="Email" required
						[ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email"
						aria-label="Useremail" aria-describedby="basic-email">
				</div>
				<div class="text-center my-3">
					<h6 class="my-2 text-left" *ngIf="!isCoding && !isPreSchool && !isRobotics">I am interested in
						(Select One)
					</h6>
					<ng-container *ngFor="let item of classType; let ind= index">
						<button type="button" class="custome-selectBtn {{seletedClasstype==item.value?'active-btn':''}}"
							(click)="onSelectClassType(item.value)">{{item.name}}</button>
					</ng-container>
				</div>
				<section *ngIf="!isCoding && !isPreSchool && !isRobotics" class="my-1 text-center">
					<div class="text-center mt-3 mb-2">
						<button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted"
							class="btn btn-form-submit">Contact Us </button>
					</div>
				</section>
				<section *ngIf="isCoding" class="my-1 text-center">
					<h6 class="my-1 text-left">Select Age Group (yrs) <span *ngIf="ageCodingRequired"
							style="color:red;">*(Required)</span></h6>
					<ng-container *ngFor="let item of codingAgeGroup; let ind= index" class="my-3">
						<button type="button"
							class="custome-selectBtn my-3 {{seletedAgeGroup==item.id?'active-btn':''}}"
							(click)="onSelectAgeGroup(item)">{{item.age_from}}-{{item.age_to}}</button>
					</ng-container>
					<div class="row my-0">
						<div class="col-6">
							<h6 class="d-block text-left">Demo Date</h6>
							<input type="date" class="form-control unstyled" formControlName="demo_class_date"
								placeholder="Demo Date" required min="{{maxDate}}"
								(change)="dateSelected($event.target.value)">
						</div>
						<div class="col-6">
							<h6 class="d-block text-left">Demo Time</h6>
							<select class="form-control unstyled" id="time_slot" formControlName="time_slot"
								[ngClass]="{ 'is-invalid': submitted && f.time_slot.errors }"
								(change)="onTimeSlot($event.target.value)">
								<option selcted value="" disabled>Select Slot</option>
								<option [value]="time_slot.value" *ngFor="let time_slot of Time_Slot_Array">
									{{time_slot.name}}</option>
							</select>
						</div>
					</div>
					<div class="text-center mt-3 mb-2">
						<button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted"
							class="btn btn-form-submit">Book a free Class</button>
					</div>
					<div class="row my-2"> <span class="text-terms">By registering here, you agree to our <a
								href="/page/terms" target="_blank">Terms
								of use </a> and <a href="page/privacy-policy" target="_blank">Privacy Policy</a>
						</span> </div>
				</section>
				<section *ngIf="isPreSchool" class="my-3 text-center">
					<h6 class="my-1 text-left">Select Age Group (yrs) <span *ngIf="ageRequired"
							style="color:red;">*(Required)</span></h6>
					<ng-container *ngFor="let item of preSchoolAgeGroup; let ind= index" class="my-3">
						<button type="button"
							class="custome-selectBtn my-3  {{seletedAgeGroup==item.id?'active-btn':''}}"
							(click)="onSelectAgeGroup(item)">{{item.age_from}}-{{item.age_to}}</button>
					</ng-container>
					<div class="text-center">
						<button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted"
							class="btn btn-form-submit">Book your 7 day Trial</button>
					</div>
					<div class="row my-2"> <span class="text-terms">By registering here, you agree to our <a
								href="/page/terms" target="_blank">Terms
								of use </a> and <a href="/page/privacy-policy" target="_blank">Privacy Policy</a>
						</span> </div>
				</section>
				<section *ngIf="isRobotics" class="my-3 text-center">
					<h6 class="my-1 text-left">Select Age Group (yrs)</h6>
					<ng-container *ngFor="let item of roboticsAgeGroup; let ind= index" class="my-3">
						<button type="button"
							class="custome-selectBtn my-3 {{seletedAgeGroup==item.id?'active-btn':''}}"
							(click)="onSelectAgeGroup(item)">{{item.name}}</button>
					</ng-container>
					<div class="row my-2">
						<h6 class="my-1 text-center">Coming Soon- World's best designed and awarded kits in India.</h6>
					</div>
					<div class="text-center">
						<button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted"
							class="btn btn-form-submit">Notify Me</button>
					</div>
					<div class="row my-2"> <span class="text-terms">By registering here, you agree to our<a
								href="/page/terms" target="_blank">Terms of
								use </a> and <a href="/page/privacy-policy" target="_blank">Privacy Policy</a>
						</span> </div>
				</section>
			</form>
		</div>
	</div>
</header>

<!-- What we offer -->
<section class=" mt-1 mb-2">
	<div class="container">
		<div class="section-title whatdoes py-2">
			<h2>
				What we Offer?
			</h2>
			<!-- <p> Ignite the curiosity with a ‘trainer led’, uniquely designed online tech enabled experiential learning
				program by experts across the globe.</p> -->
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-12 my-3">
				<div class="card card-explore-section">
					<div class="card-body">
						<h5 class="card-title">
							<!-- <img src="assets/imgs/benifit-icon.png" alt=""> -->
							<i class="fas fa-robot"></i>
						</h5>
						<h6 class="card-subtitle mb-2">Robotics</h6>
						<p class="card-text mb-0">AI/IOT/AR enabled award winning</p>
						<p class="card-text mb-0">Robotics & STEM kits.</p>
						<br> <a href="javascript:void(0);" routerLink="/robotics" class="card-link">Explore</a>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12 my-3">
				<div class="card card-explore-section">
					<div class="card-body">
						<h5 class="card-title">
							<i class="fas fa-graduation-cap"></i>
							<!-- <img src="assets/imgs/benifit-icon.png" alt=""> -->
						</h5>
						<h6 class="card-subtitle mb-2">Pre-School</h6>
						<p class="card-text mb-0">100% DIY Activity, Early age </p>
						<p class="card-text mb-0">experiential learning program.</p>
						<br> <a href="https://learningpie.in/" (click)="onPreSchool('course-pie')"
							class="card-link">Explore</a>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12 my-3">
				<div class="card card-explore-section">
					<div class="card-body">
						<h5 class="card-title">
							<!-- <img src="assets/imgs/benifit-icon.png" alt=""> -->
							<i class="fas fa-code"></i>

						</h5>
						<h6 class="card-subtitle mb-2">Coding</h6>
						<p class="card-text mb-0">Teacher guided Live 1 to 1 Coding.</p>
						<p class="card-text mb-0">AI/ML based course structure.</p>
						<br> <a href="javascript:void(0);" routerLink="/coding" class="card-link">Explore</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- What kids achieve -->
<section class="achivements mt-5" id="achivements">
	<div class="container">
		<div class="section-title whatdoes py-2">
			<h2>
				What does the kid achieve?
			</h2>
			<p> Ignite the curiosity with a ‘trainer led’, uniquely designed online tech enabled experiential learning
				program by experts across the globe.</p>
		</div>
		<div class="row pt-0 pb-5 text-center">
			<div class="col mb-2">
				<div class="card cardachieve">
					<div class="card-image"> <img src="assets/imgs/Page-1.svg" class="img-fluid rounded" alt=""> </div>
					<h4>Analytical skills</h4>
					<!-- <p>Improve logical thinking and boost intelligence</p> -->
				</div>
			</div>
			<div class="col mb-2">
				<div class="card cardachieve">
					<div class="card-image"> <img src="assets/imgs/knowledge.svg" class="img-fluid rounded" alt="">
					</div>
					<h4>Critical thinking</h4>
					<!-- <p>Improve logical thinking and boost intelligence</p> -->
				</div>
			</div>
			<div class="col mb-2">
				<div class="card cardachieve">
					<div class="card-image"> <img src="assets/imgs/Page-1.svg" class="img-fluid rounded" alt=""> </div>
					<h4>Sequential Problem solving</h4>
					<!-- <p>Improve logical thinking and boost intelligence</p> -->
				</div>
			</div>
			<div class="col mb-2">
				<div class="card cardachieve">
					<div class="card-image"> <img src="assets/imgs/share (1).svg" class="img-fluid rounded" alt="">
					</div>
					<h4>Lateral problem solutioning</h4>
					<!-- <p>Improve logical thinking and boost intelligence</p> -->
				</div>
			</div>
			<div class="col mb-2">
				<div class="card cardachieve">
					<div class="card-image"> <img src="assets/imgs/knowledge.svg" class="img-fluid rounded" alt="">
					</div>
					<h4>Improve Confidence</h4>
					<!-- <p>Improve logical thinking and boost intelligence</p> -->
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Learningbix experience -->
<section class="experience" id="experience">
	<div class="container">
		<div class="section-title">
			<h2>
				The LearningBix Experience!
			</h2>
			<!-- <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor, aliquet sed urna,</p> -->
		</div>
		<div class="row align-items-center">
			<div class="col-lg-4 col-md-12 col-sm-12">
				<div class="card mb-3 pl-3 pr-3 mb-3">
					<div class="row no-gutters justify-content-center">
						<div class="col-md-4 col-sm-12 card-img-box"> <img src="assets/imgs/experience/Facilitate.jpg"
								class="card-img circular--landscape" alt="..."> </div>
						<div class="col-md-8 card-experience-box">
							<div class="card-body">
								<h5 class="card-title">Facilitate</h5>
								<p class="card-text">Children have a higher learning curve than adults and they learn
									futuristic technologies much faster.</p>
							</div>
						</div>
					</div>
				</div>
				<div class="card mb-3 pl-3 pr-3 mb-3">
					<div class="row no-gutters justify-content-center">
						<div class="col-md-4 col-sm-12 card-img-box"> <img src="assets/imgs/experience/logical.jpg"
								class="card-img circular--landscape" alt="..."> </div>
						<div class="col-md-8 card-experience-box">
							<div class="card-body">
								<h5 class="card-title">Logic-ify</h5>
								<p class="card-text">Help your child form logic patterns and engage them in technology
									which boosts their reasoning abilities.</p>
							</div>
						</div>
					</div>
				</div>
				<div class="card mb-3 pl-3 pr-3 mb-3">
					<div class="row no-gutters justify-content-center">
						<div class="col-md-4 col-sm-12 card-img-box"> <img src="assets/imgs/experience/creativity.jpg"
								class="card-img circular--landscape" alt="..."> </div>
						<div class="col-md-8 card-experience-box">
							<div class="card-body">
								<h5 class="card-title">Creativity</h5>
								<p class="card-text">Flexible & customized curriculum to boost imagination & critical
									thinking to create meaning form of ideas.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-12 col-sm-12 text-center"> <img src="assets/imgs/Group-9169.png"
					class="img-fluid" alt=""> </div>
			<div class="col-lg-4 col-md-12 col-sm-12">
				<div class="card mb-3 pl-3 pr-3 mb-3">
					<div class="row no-gutters justify-content-center">
						<div class="col-md-4 col-sm-12 card-img-box"> <img src="assets/imgs/experience/gamification.jpg"
								class="card-img circular--landscape" alt="..."> </div>
						<div class="col-md-8 card-experience-box">
							<div class="card-body">
								<h5 class="card-title">Gamify</h5>
								<p class="card-text">The learning and courses at LearningBIX are gamified to leave
									subtle impressions that last a lifetime.</p>
							</div>
						</div>
					</div>
				</div>
				<div class="card mb-3 pl-3 pr-3 mb-3">
					<div class="row no-gutters justify-content-center">
						<div class="col-md-4 col-sm-12 card-img-box"> <img src="assets/imgs/experience/extrapolate.jpg"
								class="card-img circular--landscape" alt="..."> </div>
						<div class="col-md-8 card-experience-box">
							<div class="card-body">
								<h5 class="card-title">Extrapolate</h5>
								<p class="card-text">Kids start to code, design models, and form circuits boosting their
									ability to predict patterns and form scientific extrapolation.</p>
							</div>
						</div>
					</div>
				</div>
				<div class="card mb-3 pl-3 pr-3 mb-3">
					<div class="row no-gutters justify-content-center">
						<div class="col-md-4 col-sm-12 card-img-box"> <img
								src="assets/imgs/experience/problem_solving.jpg" class="card-img circular--landscape"
								alt="..."> </div>
						<div class="col-md-8 card-experience-box">
							<div class="card-body">
								<h5 class="card-title">Problem Solving</h5>
								<p class="card-text">100% DIY. Applying innovative ideas to reach goal brings a sense of
									achievement & boost the confidence.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- pre school -->
<!-- <app-early-learning></app-early-learning> -->

<!-- coding -->
<!-- <app-course></app-course> -->

<!-- Chart -->
<section class=" mt-1 mb-2 py-5">
	<div class="container">
		<div class="section-title whatdoes py-2">
			<h2>
				Why Robotics for kids?
			</h2>
			<!-- <p> Ignite the curiosity with a ‘trainer led’, uniquely designed online tech enabled experiential learning
				program by experts across the globe.</p> -->
		</div>
		<div class="">
			<!-- <div class="col-md-6 col-sm-12 my-3 bg-light rounded p-3">
				<div class="meter">
					<span style="width: 80%"></span>
				</div>
				<h6>Improve Confidence</h6>

				<div class="meter orange">
					<span style="width: 85%"></span>
				</div>
				<h6>Critical thinking</h6>

				<div class="meter red">
					<span style="width: 93%"></span>
				</div>
				<h6>Problem solving</h6>

				<div class="meter blue">
					<span style="width: 90%"></span>
				</div>
				<h6>Analytical skills</h6>
			</div> -->
			<div class="mb-4 d-flex flex-wrap">
				<div class="col-lg-3 col-md-6 mb-3 mb-lg-0 text-center d-flex flex-column">
					<div class="card p-3 rounded shadow">
						<h5 class="mb-3 font-weight-bold text-warning">Inculcating Futuristic skills</h5>
						<p class="mb-0">The future of jobs is changing and futuristic skills are needed to thrive. It is estimated that in the future, robotics will become an important component in a number of ways. To assure success for the younger generation and to make them ready for the 21st-century workplace, Robotics will help inculcate futuristic skills in them.</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 mb-3 mb-lg-0 text-center d-flex flex-column">
					<div class="card p-3 rounded shadow">
						<h5 class="mb-3 font-weight-bold text-warning">Developing problem-solving skills and creativity in Children</h5>
						<p class="mb-0">Understanding of Robotics & Coding enables curious young minds to become problem solvers.  From putting together simple blocks to circuit building and making things work, they learn to code and program in a simple and fun-filled way. This helps develop their creativity and problem-solving skills.</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 mb-3 mb-lg-0 text-center d-flex flex-column">
					<div class="card p-3 rounded shadow">
						<h5 class="mb-3 font-weight-bold text-warning">Building self-confidence</h5>
						<p class="mb-0">Robotics programming helps build self-confidence in kids. When kids build powerful circuits and invent something, it gives them the confidence that they have the ability to make great mechanical products. It boosts their self-esteem and they feel self-motivated to do great things.</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 mb-3 mb-lg-0 text-center d-flex flex-column">
					<div class="card p-3 rounded shadow">
						<h5 class="mb-3 font-weight-bold text-warning">Robotics is fun</h5>
						<p class="mb-0">Robotics programming is fun! Robotics & coding help children learn many key concepts playfully. It is easier for parents and teachers to make kids get engaged in hands-on learning activities. Robotics and coding help kids learn concepts and develop skills that make them future-ready.</p>
					</div>
				</div>
			</div>
			<div class="text-center">
				<button class="btn btn-form-submit">Book a Free Trial</button>
			</div>
		</div>
	</div>
</section>

<!-- Platform Comparison -->
<section class=" mt-1 mb-2 py-5">
	<div class="container">
		<div class="section-title whatdoes py-2">
			<h2>
				LearningBix vs Other Platforms
			</h2>
			<!-- <p> Ignite the curiosity with a ‘trainer led’, uniquely designed online tech enabled experiential learning
				program by experts across the globe.</p> -->
		</div>
		<table class="table table-bordered">
			<thead>
				<tr class="table-warning text-center">
					<th scope="col"></th>
					<th scope="col">LearningBix</th>
					<th scope="col">Other Platforms</th>

				</tr>
			</thead>
			<tbody>
				<tr>
					<th scope="row">Class Size</th>
					<td>1 student</td>
					<td>Up to 6 students</td>
				</tr>

				<tr>
					<th scope="row">Individual Attention</th>
					<td><img src="assets/icons/Correct-tick.svg"></td>
					<td><img src="assets/icons/Wrong-cross.svg"></td>
				</tr>

				<tr>
					<th scope="row">Fixed Class Schedule</th>
					<td><img src="assets/icons/Correct-tick.svg"></td>
					<td><img src="assets/icons/Wrong-cross.svg"></td>
				</tr>

				<tr>
					<th scope="row">Individual Projects</th>
					<td><img src="assets/icons/Correct-tick.svg"></td>
					<td><img src="assets/icons/Wrong-cross.svg"></td>
				</tr>

				<tr>
					<th scope="row">Personalised 1 on 1 Learning Experience</th>
					<td><img src="assets/icons/Correct-tick.svg"></td>
					<td><img src="assets/icons/Wrong-cross.svg"></td>
				</tr>

				<tr>
					<th scope="row">Learning in an interactive & social setting</th>
					<td><img src="assets/icons/Correct-tick.svg"></td>
					<td><img src="assets/icons/Wrong-cross.svg"></td>
				</tr>

				<tr>
					<th scope="row">Class Recordings covering key concepts</th>
					<td><img src="assets/icons/Correct-tick.svg"></td>
					<td><img src="assets/icons/Wrong-cross.svg"></td>
				</tr>

				<tr>
					<th scope="row">One cover up class per month</th>
					<td><img src="assets/icons/Correct-tick.svg"></td>
					<td><img src="assets/icons/Wrong-cross.svg"></td>
				</tr>

				<tr>
					<th scope="row">Group Projects</th>
					<td><img src="assets/icons/Correct-tick.svg"></td>
					<td><img src="assets/icons/Wrong-cross.svg"></td>
				</tr>

				<tr>
					<th scope="row">Tynker Subscription*</th>
					<td><img src="assets/icons/Correct-tick.svg"></td>
					<td><img src="assets/icons/Wrong-cross.svg"></td>
				</tr>

				<tr>
					<th scope="row">Skill development</th>
					<td>Critical thinking, problem solving, teamwork & collaboration</td>
					<td>Critical thinking & problem solving</td>
				</tr>
			</tbody>
		</table>
	</div>
</section>

<!-- Robotics & Coding -->
<div class="variety-of-projects">
    <div class="container">
        <div class="row">

            <div class="col-lg-12">
                <div class="heading">
                    Best Selling DIY Robotics & Coding Product from LearningBix
                    <!-- {{roboticsSectionData[4].title}} -->
                </div>
            </div>
        </div>

        <div class="row padding-50-b-t">
            <div class="col-xl-3 col-lg-4 col-sm-6 col-12" *ngFor="let productList of roboticsCodingProduct">
                <div class="card-box stem-hover" (click)="onViewProduct(productList)">
                    <div>
                        <img class="card-image text-center" [src]="allowurl(_employeeService.imageBase+productList?.image)" width="100%">
                    </div>

                    <h6 class="package-title float-left" style="height: 60px;">{{productList.title}}</h6>

                    <div class="rating col-sm-12 col-md-12" style="display: inline-block; padding: 0;">
                        {{productList.rating}}/5
                       
                        <img class="star" src="../../../assets/imgs/star.png"  alt="star" *ngFor="let staritem of counter(productList?.rating)">

                        <!-- <img class="star" src="../../../assets/imgs/star.png"  alt="star" > -->
                        <!-- <img class="star" src="../../../assets/imgs/star.png" alt="star">
                        <img class="star" src="../../../assets/imgs/star.png" alt="star">
                        <img class="star" src="../../../assets/imgs/star.png" alt="star"> -->
                        <span class="float-right" style="color: #36a9e6; font-weight: bold;">Rs {{productList.mrp}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center col-sm-4 col-12 mx-auto">
            <button class="btn btn-round btn-primary" [routerLink]="['/robotics']">Explore More</button>
        </div>
    </div>
</div>

<!-- Stem Product -->
<div class="variety-of-projects">
    <div class="container">
        <div class="row">

            <div class="col-lg-12">
                <div class="heading">
                    Best Selling DIY ROBOTICS & STEM Kits from LearningBix
                    <!-- {{roboticsSectionData[4].title}} -->
                </div>
            </div>
        </div>

        <div class="row padding-50-b-t">
            <div class="col-xl-3 col-lg-4 col-sm-6 col-12" *ngFor="let productList of stemProduct">
                <div class="card-box stem-hover" (click)="onViewProduct(productList)">
                    <div>
                        <img class="card-image text-center" [src]="allowurl(_employeeService.imageBase+productList?.image)" width="100%">
                    </div>

                    <h6 class="package-title float-left" style="height: 60px;">{{productList.title}}</h6>

                    <div class="rating col-sm-12 col-md-12" style="display: inline-block; padding: 0;">
                        {{productList.rating}}/5
                       
                        <img class="star" src="../../../assets/imgs/star.png"  alt="star" *ngFor="let staritem of counter(productList?.rating)">

                        <!-- <img class="star" src="../../../assets/imgs/star.png"  alt="star" > -->
                        <!-- <img class="star" src="../../../assets/imgs/star.png" alt="star">
                        <img class="star" src="../../../assets/imgs/star.png" alt="star">
                        <img class="star" src="../../../assets/imgs/star.png" alt="star"> -->
                        <span class="float-right" style="color: #36a9e6; font-weight: bold;">Rs {{productList.mrp}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center col-sm-4 col-12 mx-auto">
            <button class="btn btn-round btn-primary" [routerLink]="['/robotics/stem']">Explore More</button>
        </div>
    </div>
</div>




<!-- <section class="projects" id="projects">
  <div class="container">
    <div class="section-title pt-5 mb-0">
      <h2 class="">
        Projects by Students
      </h2>
      <p>Innovative Projects developed by “MAKERS” of technology</p>
    </div>
    <div class="row">
      <ng-container *ngFor="let project_list of ProjectsList;let i =index">
        <div class="col-lg-4 col-sm-6 col-xs-12 my-3" *ngIf="i<3">
          <div class="card pt-4 pb-4 pl-3 pr-3">
            <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{project_list.post_image}}"
              class="img-fluid mb-3" alt="" style="    width: 100%;
              max-height: 200px;
              min-height: 200px;">
            <h3>{{project_list.post_title}}</h3>
            <p class="mb-0">{{project_list.post_description}}</p>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row justify-content-center">
      <div class="col-7 text-center mt-5 mb-2">
        <a href="javascript:void(0);" class="btn btn-outline-info btn-view-more" routerLink="/projects"
          target="_blank">View All
          Projects</a>
      </div>
    </div>
    <div class="jumbo-card align-items-center">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-4 col-sm-12 text-center"><img src="assets/imgs/jumbo-thumbnail.png"
            class="img-fluid" alt=""></div>
        <div class="col-lg-9 col-md-8 col-sm-12 text-center">
          <h4>Still Confused? Know what suits you well. Personalised coaching solutions in Coding and Robotics.</h4>
          <button type="button" class="btn btn-book-class" data-toggle="modal" data-target="#bookFreeClassModal">Book a
            free class</button>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- Testimonials -->
<app-testimonials id="testimonials"></app-testimonials>

<!-- Partner Schools -->
<section class="expert-educators" id="educators">
	<div class="">
		<div class="section-title">
			<h2 class="mb-0">
				Our Partner Schools
			</h2>
			<p>LearningBIX runs technology intervention programs for over a million students, spread over 1000+ schools,
				across the length and breadth of India</p>
		</div>
		<div class="row text-center">
			<div class="col-12" style="background: #fff">
				<div class="row justify-content-md-center py-5">
					<div class="col col-md-8"> <img src="assets/imgs/schoolspartner.png" alt="" srcset=""
							class="img-fluid"> </div>
				</div>
			</div>
			<!-- <div class="col-md col-sm-12 my-3">
        <img src="assets/imgs/sourabh.png" class="img-fluid mb-3" alt="">
        <h4 class="mb-1">Sourabh Goyal</h4>
        <p class="mb-0" style="font-size: 19px;">Coding Expert</p>
        <p class="mb-0" style="font-size: 19px;">B.Tech MNIT, Jaipur</p>
      </div>


      <div class="col-md col-sm-12 my-2">
        <img src="assets/imgs/amanpreet.png" class="img-fluid mb-3" alt="">
        <h4 class="mb-1">Amanpreet Singh</h4>
        <p class="mb-0" style="font-size: 19px;">Robotics Expert</p>
        <p class="mb-0" style="font-size: 19px;">B.Tech BITS</p>
      </div>
      <div class="col-md col-sm-12 my-2">
        <img src="assets/imgs/anshita.png" class="img-fluid mb-3" alt="">
        <h4 class="mb-1">Anshita Agarwal</h4>
        <p class="mb-0" style="font-size: 19px;">Early Childhood Education, West Yorkshire, UK</p>
      </div>
      <div class="col-md col-sm-12 my-2">
        <img src="assets/imgs/himanshu.png" class="img-fluid mb-3" alt="">
        <h4 class="mb-1">Himanshu</h4>
        <p class="mb-0" style="font-size: 19px;">Artificial Intelligence & Machine Learning Expert</p>

      </div>

      <div class="col-md col-sm-12 my-2">
        <img src="assets/imgs/pooja.png" class="img-fluid mb-3" alt="">
        <h4 class="mb-1">Pooja Ajmera</h4>
        <p class="mb-0" style="font-size: 19px;">STEM Education Expert</p>

      </div> -->
		</div>
	</div>
</section>

<!-- Blogs -->
<!-- <app-blog id="blog"></app-blog> -->

<!-- About us -->
<section class="about-us" id="about">
	<div class="container">
		<div class="section-title">
			<h2 class="">
				About Us
			</h2>
			<p>We are a bunch of highly motivated and future driven professionals, with a love for teaching and training
				the young population of India. The founding team carries strong academic pedigree across the best
				institutions of the world, including Harvard, MIT, Stanford, IITs, and the IIMs.</p>
			<p>With hands-on experience in training at over 1000 schools across India, and impacting over a million
				students over the last six years, the team has carefully curated the best learning practices to make the
				time spent worthwhile for our students. The intelligent curation of the course flow, curriculum,
				technological interventions make for a productive learning module</p>
		</div>
		<div class="row">
			<div class="col-md-6 mb-3 mb-lg-0">
				<div class="card p-3  h-100">
					<h2>Our Mission</h2>
					<p style="text-align: left">Make education standardised, comprehensive, and a joy to learn for all
						school going students. Impart students with a skill on ‘how to learn’ to enable them pick
						Coding, Robotics, or any discipline in their academic journey.</p>
				</div>
			</div>
			<div class="col-md-6 mb-3 mb-lg-0">
				<div class="card p-3 h-100">
					<h2>Our Services </h2>
					<ul class="services">
						<li>Live 1 to 1 Coding Classes</li>
						<li>Self Paced Coding Courses</li>
						<li>Thematic Robotic Kits</li>
						<li>Online Pre School Immersive Experience Program</li>
						<li>Standard Aligned 3D, AR & VR Modeled K-12 Curricula</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Footer -->
<app-footer id="footer"></app-footer>

<!-- Modal -->
<div class="get-in-touch-mobile-button right" (click)="getIntouch()"> <span>Get In Touch</span> </div>
<div id="overlay" (click)="onCloseModalCouponModal()"></div>
<div class="modal fade" id="getInTouch" tabindex="-1" aria-labelledby="getInTouch" aria-hidden="true"
	data-backdrop="false">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header border-0">
				<!-- <h5 class="modal-title" id="bookFreeClassModalLabel">Modal title</h5> -->
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="onCloseModalCouponModal()"> <span aria-hidden="true">&times;</span> </button>
			</div>
			<div class="modal-body pt-0 pb-5 book-class-modal">
				<div class="row align-items-center">
					<div class="book-demo-form toggle-form-show">
						<div class="card form_cardHome">
							<h2 class="my-0">Get In Touch</h2>
							<form [formGroup]="AddForm" class="custome-form">
								<div class="input-group my-3">
									<div class="input-group-prepend"> <span class="input-group-text" id="basic-sname"
											[ngClass]="{ 'is-invalid': submitted && f.parents_name.errors }"><i
												class="fas fa-user"></i></span> </div>
									<input type="text" class="form-control" placeholder="Enter Parent Name" required
										aria-label="Username" aria-describedby="basic-sname"
										[ngClass]="{ 'is-invalid': submitted && f.parents_name.errors }"
										formControlName="parents_name">
								</div>
								<div class="input-group my-3">
									<div class="input-group-prepend"> <span class="input-group-text" id="basic-sname"
											[ngClass]="{ 'is-invalid': submitted && f.name.errors }"><i
												class="fas fa-user"></i></span> </div>
									<input type="text" class="form-control" placeholder="Enter Child Name" required
										aria-label="Username" aria-describedby="basic-sname"
										[ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name">
								</div>
								<div class="input-group my-3">
									<select class="custome-contory-code" id="exampleFormControlSelect1">
										<option>91</option>
									</select>
									<div class="input-group-prepend"> <span class="input-group-text"
											id="basic-mobile"><i class="fas fa-mobile-alt"></i></span> </div>
									<input type="text" required class="form-control mobile" placeholder="Contact No"
										aria-label="Username" maxlength="10" appNumbersOnly
										aria-describedby="basic-mobile"
										[ngClass]="{ 'is-invalid': submitted && f.phone_no.errors }"
										formControlName="phone_no">
								</div>
								<div class="input-group my-3">
									<div class="input-group-prepend"> <span class="input-group-text" id="basic-email"><i
												class="fas fa-envelope"></i></span> </div>
									<input type="email" class="form-control" placeholder="Email" required
										[ngClass]="{ 'is-invalid': submitted && f.email.errors }"
										formControlName="email" aria-label="Useremail" aria-describedby="basic-email">
								</div>
								<div class="text-center my-3">
									<h6 class="my-2 text-left" *ngIf="!isCoding && !isPreSchool && !isRobotics">I am
										interested in (Select
										One)
									</h6>
									<ng-container *ngFor="let item of classType; let ind= index">
										<button type="button"
											class="custome-selectBtn {{seletedClasstype==item.value?'active-btn':''}}"
											(click)="onSelectClassType(item.value)">{{item.name}}</button>
									</ng-container>
								</div>
								<section *ngIf="!isCoding && !isPreSchool && !isRobotics" class="my-1 text-center">
									<div class="text-center mt-3 mb-2">
										<button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted"
											class="btn btn-form-submit">Contact Us</button>
									</div>
								</section>
								<section *ngIf="isCoding" class="my-1 text-center">
									<h6 class="my-1 text-left">Select Age Group (yrs) <span *ngIf="ageCodingRequired"
											style="color:red;">*(Required)</span></h6>
									<ng-container *ngFor="let item of codingAgeGroup; let ind= index" class="my-3">
										<button type="button"
											class="custome-selectBtn my-3 {{seletedAgeGroup==item.id?'active-btn':''}}"
											(click)="onSelectAgeGroup(item)">{{item.age_from}}-{{item.age_to}}</button>
									</ng-container>
									<div class="row my-0">
										<div class="col-6">
											<h6 class="d-block text-left">Demo Date</h6>
											<input type="date" class="form-control unstyled"
												formControlName="demo_class_date" placeholder="Demo Date" required
												min="{{maxDate}}" (change)="dateSelected($event.target.value)">
										</div>
										<div class="col-6">
											<h6 class="d-block text-left">Demo Time</h6>
											<select class="form-control unstyled" id="time_slot"
												formControlName="time_slot"
												[ngClass]="{ 'is-invalid': submitted && f.time_slot.errors }"
												(change)="onTimeSlot($event.target.value)">
												<option selcted value="" disabled>Select Slot</option>
												<option [value]="time_slot.value"
													*ngFor="let time_slot of Time_Slot_Array">{{time_slot.name}}
												</option>
											</select>
										</div>
									</div>
									<div class="text-center mt-3 mb-2">
										<button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted"
											class="btn btn-form-submit">Book a free Class</button>
									</div>
									<div class="row my-2"> <span class="text-terms">By registering here, you agree to
											our <a href="/page/terms" target="_blank">Terms of use </a> and <a
												href="page/privacy-policy" target="_blank">Privacy
												Policy</a>
										</span> </div>
								</section>
								<section *ngIf="isPreSchool" class="my-3 text-center">
									<h6 class="my-1 text-left">Select Age Group (yrs) <span *ngIf="ageRequired"
											style="color:red;">*(Required)</span></h6>
									<ng-container *ngFor="let item of preSchoolAgeGroup; let ind= index" class="my-3">
										<button type="button"
											class="custome-selectBtn my-3  {{seletedAgeGroup==item.id?'active-btn':''}}"
											(click)="onSelectAgeGroup(item)">{{item.age_from}}-{{item.age_to}}</button>
									</ng-container>
									<div class="text-center">
										<button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted"
											class="btn btn-form-submit">Book your Trial</button>
									</div>
									<div class="row my-2"> <span class="text-terms">By registering here, you agree to
											our <a href="/page/terms" target="_blank">Terms of use </a> and <a
												href="/page/privacy-policy" target="_blank">Privacy
												Policy</a>
										</span> </div>
								</section>
								<section *ngIf="isRobotics" class="my-3 text-center">
									<h6 class="my-1 text-left">Select Age Group (yrs)</h6>
									<ng-container *ngFor="let item of roboticsAgeGroup; let ind= index" class="my-3">
										<button type="button"
											class="custome-selectBtn my-3 {{seletedAgeGroup==item.id?'active-btn':''}}"
											(click)="onSelectAgeGroup(item)">{{item.name}}</button>
									</ng-container>
									<div class="row my-2">
										<h6 class="my-1 text-center">Coming Soon- World's best designed and awarded kits
											in India erweqw
										</h6>
										<!-- <span class="text-terms">Coming Soon- World's best designed and awarded kits in India</span> -->
									</div>
									<div class="text-center">
										<button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted"
											class="btn btn-form-submit">Notify Me</button>
									</div>
									<div class="row my-2"> <span class="text-terms">By registering here, you agree to
											our<a href="/page/terms" target="_blank">Terms of use </a> and <a
												href="/page/privacy-policy" target="_blank">Privacy
												Policy</a>
										</span> </div>
								</section>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>