import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseServiceService, TokenService } from '../../service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
@Component({
  selector: 'app-offer-modal',
  templateUrl: './offer-modal.component.html',
  styleUrls: ['./offer-modal.component.scss']
})
export class OfferModalComponent implements OnInit {
  safeSrcVideoUrl: SafeResourceUrl;
  offerData:any;
  constructor(public _employeeService: BaseServiceService, public token: TokenService, public userService: UserService, private modalService: ModalService, private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this.safeSrcVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/540034347');
    console.log('safeSrcVideoUrl:::::', this.safeSrcVideoUrl);

    this._employeeService.getOffer().subscribe(data => {
      console.log('data::::',data);
      this.offerData=data;
    });

  }
  onCloseModal30Sec() {
    this.modalService.SetOfferModal(false);
  }
}
