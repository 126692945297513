import { CommonService } from '../../service/common.service';
import { environment } from '../../../environments/environment';
import { StarRatingComponent } from 'ng-starrating';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { BaseServiceService, TokenService } from 'src/app/service';
import { ActivatedRoute, Router } from '@angular/router';
declare var Razorpay: any;
declare var $: any;

import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailPatternValidator, MobilePatternValidator } from 'src/app/validate-pattern';
import { UserService } from 'src/app/service/user.service';
import { EnvService } from 'src/app/service/env.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  providers: [DatePipe]
})
export class LandingComponent implements OnInit {

  active = 1;
  subscription: Subscription;
  ProjectsList = [];
  AgeGroupList = [];
  AgeGroupCoursesList = [];
  AddForm: FormGroup;
  submitted = false; btnsubmitted = false;
  isCoding = false;
  isPreSchool = false;
  isRobotics = false;
  seletedClasstype;
  seletedAgeGroup;
  maxDate: any;
  currentDate: any;
  currentTime: any;
  isCurrentTime = false;
  Time_Slot_Array: any = [];

  classType = [
    { name: 'Coding', value: '0' },
    { name: 'Pre School', value: '1' },
    { name: 'Robotics', value: '2' },
  ]
  codingAgeGroup = [
    { name: '5-6', id: '31' },
    { name: '7-8', id: '32' },
    { name: '9-11', id: '33' },
    { name: '12-14', id: '34' },
    { name: '15-18', id: '35' },
  ]
  preSchoolAgeGroup = [
    { name: '1.5-2.5', id: '11' },
    { name: '2.5-3.5', id: '12' },
    { name: '3.5-4.5', id: '13' },
    { name: '4.5-6', id: '14' },
  ]
  roboticsAgeGroup = [
    { title: '2-5', name: '2-5', id: '21' },
    { title: '5-8', name: '5-8', id: '22' },
    { title: '8-12', name: '8-12', id: '23' },
    { title: '12-99', name: '12-99', id: '24' },
  ]
  currentURL='';
  constructor(public fb: FormBuilder, public activatedRoute: ActivatedRoute, public _employeeService: BaseServiceService, public common: CommonService, public token: TokenService, public _router: Router, private _datePipe: DatePipe, public userService: UserService) {
    this.currentURL = window.location.href; }
  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {

    alert(`Old Value:${$event.oldValue},
      New Value: ${$event.newValue},
      Checked Color: ${$event.starRating.checkedcolor},
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }
  ngOnInit(): void {
    var dtToday = new Date();

    var month: any = dtToday.getMonth() + 1;
    var day: any = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10)
      month = '0' + month.toString();
    if (day < 10)
      day = '0' + day.toString();

    this.maxDate = year + '-' + month + '-' + day;

    $('#txtDate').attr('min', this.maxDate);

    this.codingAgeGroupList();
    this.preSchoolAgeGroupList();

    this.AddForm = this.fb.group({

      name: ['', [Validators.required]],
      phone_no: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      // password: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      gender: [''],
      image: [''],
      age_group_id: [''],
      time_zone: [''],
      dob: [''],
      parents_name: [''],
      school_name: [''],
      subscription_type: ['0'],
      demo_class_date: [''],
      time_slot: [''],
      time_from: [''],
      time_to: [''],
      learning: [''],
    })

    //var JSON_DATA = JSON.parse(this.token.getUser())


    // if(JSON_DATA.learning =='1' && JSON_DATA.role_id == '2'){
    //    this._router.navigate(['/pie'])
    // }else if(JSON_DATA.learning =='0' && JSON_DATA.role_id == '2'){
    //   this._router.navigate(['/'])
    // }


    // this.onScroll('achivements')
    this.GetProjectsList();
    this.fetchSlots();
    // this._employeeService.post('age_group_list_dropdown', {}).subscribe((res: any) => {
    //   if (!res.error) {
    //     // console.log('Response::::',res);
    //     this.AgeGroupList= res.data;
    //     this.getCoursesList(this.AgeGroupList[0].id);
    //   }
    // });
    this.common.GetRouteType().subscribe(route => {
      // alert();
      // console.log('route::::', route);
      this.onScroll(route)
    })
  }
  codingAgeGroupList() {
    this._employeeService.post('age_group_list_dropdown', { learning: '0' }).subscribe((res: any) => {
      if (!res.error) {
        // console.log('Response::::',res);
        this.codingAgeGroup = res.data;
      }
    });
  }
  preSchoolAgeGroupList() {
    this._employeeService.post('age_group_list_dropdown', { learning: '1' }).subscribe((res: any) => {
      if (!res.error) {
        // console.log('Response::::',res);
        this.preSchoolAgeGroup = res.data;
      }
    });
  }
  get f() { return this.AddForm.controls; }
  onSelectClassType(classType) {
    this.AddForm.patchValue({
      age_group_id: ''
    })
    this.seletedAgeGroup = ''
    this.AddForm.patchValue({
      learning: classType
    })
    this.seletedAgeGroup = null;
    this.seletedClasstype = classType
    if (classType == '0') {
      this.isCoding = true;
      this.isPreSchool = false;
      this.isRobotics = false;
    }
    else if (classType == '1') {
      this.isCoding = false;
      this.isPreSchool = true;
      this.isRobotics = false;
    }
    else {
      this.isCoding = false;
      this.isPreSchool = false;
      this.isRobotics = true;
    }

  }
  onSelectAgeGroup(item) {
    this.AddForm.patchValue({
      age_group_id: item.id
    })
    this.seletedAgeGroup = item.id

  }
  onPreSchool(value) {
    // console.log('ageid::::',ageid);
    this.common.SetRouteType('course-pie');

    // var FilterData = this.AgeGroupList.Filter(x =>x.id === ageid );
    // console.log('FilterData:::',FilterData)
  }
  getCoursesList(agegroupid) {
    var requestData = {
      age_group_id: String(agegroupid),
      featured: 1
    }
    this._employeeService.post('live_courses_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupCoursesList = res.data;
        // console.log('AgeGroupCoursesList::::', this.AgeGroupCoursesList);
      }
    });
  }
  GetProjectsList() {
    var self = this;
    self._employeeService.post('group_post_list', { featured: 1 }).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.ProjectsList = res.data;
          // console.log('ProjectsList::::',this.ProjectsList);
          // console.log('SelfPaceDataFilter::::',this.SelfPaceDataFilter);
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
  onScroll(type) {
    const itemToScrollTo = document.getElementById(type);
    if (itemToScrollTo) {
      itemToScrollTo.scrollIntoView(true);
    }
  }

  dateSelected(value) {
    let self = this
    this.AddForm.value.time_slot = '';
    self.Time_Slot_Array = []
    let current = new Date();
    let todayDate: any = self._datePipe.transform(new Date());
    let select_date: any = self._datePipe.transform(new Date(value));

    let todayDateNew = new Date(todayDate).getTime();
    let slotDateNew = new Date(select_date).getTime();

    if (todayDateNew == slotDateNew) {

      let time_value = current.getHours();

      if (time_value > 9 && time_value < 20) {
        self.currentTime = `${time_value + 2}:00:00`
        self.fetchSlots();
        self.isCurrentTime = true
      } else {
        self.isCurrentTime = false
        self.fetchSlots();
      }
    }
    else {
      self.isCurrentTime = false
      self.fetchSlots();

    }


  }
  onTimeSlot(time_slot) {
    // console.log('time_slot:::', time_slot);
    if (time_slot != null || time_slot != undefined) {
      var split_time = time_slot.split('-');
      // console.log('split_time:::', split_time);
      this.AddForm.controls['time_from'].setValue(split_time[0]);
      this.AddForm.controls['time_to'].setValue(split_time[1]);
    }
  }
  pad(str, max) {
    str = str.toString();
    return str.length < max ? this.pad("0" + str, max) : str;
  }
  convertHours(mins) {
    var hour = Math.floor(mins / 60);
    mins = mins % 60;
    var converted = this.pad(hour, 2) + ':' + this.pad(mins, 2);
    return converted;
  }
  calculate_time_slot(start_time, end_time, interval = "30") {
    var i, formatted_time;
    var time_slots = new Array();
    for (var i = start_time; i <= end_time; i = i + interval) {
      formatted_time = this.convertHours(i);
      time_slots.push(formatted_time);
    }
    return time_slots;
  }
  parseTime(s) {
    var c = s.split(':');
    return parseInt(c[0]) * 60 + parseInt(c[1]);
  }
  fetchSlots() {
    let self = this
    this._employeeService.post('demo_class_time_slots', {}).subscribe((res: any) => {
      // console.log('res:::',res);

      if (self.isCurrentTime) {
        res.data.time_from = self.currentTime;

      }

      var start_time = this.parseTime(res.data.time_from),
        end_time = this.parseTime(res.data.time_to);
      var interval: any = Number(res.data.class_duration);
      var times_ara = this.calculate_time_slot(start_time, end_time, interval);
      for (var i = 0; i <= times_ara.length - 2; i++) {
        // console.log(times_ara[i]+"-"+times_ara[i+1]);
        this.Time_Slot_Array.push({ value: times_ara[i] + "-" + times_ara[i + 1], name: times_ara[i] + "-" + times_ara[i + 1] });
      }
    });
  }

  ageCodingRequired = false;
  ageRequired = false;




  getIntouch() {
    $('#getInTouch').modal('show');
    document.getElementById("overlay").style.display = "block";

  }
  onCloseModalCouponModal() {

    this.AddForm.reset();
    document.getElementById("overlay").style.display = "none";

    $('#getInTouch').modal('hide');

  }
  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }


  onSubmit() {
    this.ageRequired = false;
    this.ageCodingRequired = false;
    var self = this;
    // console.log(self.AddForm.value);
    self.submitted = true;
    if (!this.isCoding && !this.isPreSchool && !this.isRobotics) {
      if (self.AddForm.invalid) {
        return;
      } else {
        self.btnsubmitted = true;
        self.AddForm.patchValue({
          learning: ''
        })
        var split_string = this.AddForm.value.name.split(' ');
        var senRequestLeeddata = this.AddForm.value;
          senRequestLeeddata.name = split_string[0];
          senRequestLeeddata.first_name = split_string[0];
        if (split_string.length > 2) {
          senRequestLeeddata.last_name = split_string[1] + ' ' + split_string[2];
        } else {
          senRequestLeeddata.last_name = split_string[1] || '';
        }
        senRequestLeeddata.page_url = this.currentURL;
        this.userService.sendFeedback(senRequestLeeddata,this.currentURL).then(data => {
          // console.log('Response::::', data)
          if (!data.error) {
            if (data.replyCode == 'success') {
              self._employeeService.showSuccess(data.replyMsg);
              self.AddForm.reset();
              self.submitted = false;
              self.btnsubmitted = false;
            } else {
              self.btnsubmitted = false;
              self._employeeService.showError(data.replyMsg)
            }
          } else {
            self.btnsubmitted = false;
            self._employeeService.showError(data.error.replyMsg)
          }
        });
      }
    }
    if (this.isCoding) {
      if (self.AddForm.value.age_group_id == undefined || self.AddForm.value.age_group_id == null || self.AddForm.value.age_group_id == '' || self.AddForm.value.age_group_id == 'null') {
        this.ageCodingRequired = true;
        return;
      }
      if (self.AddForm.value.demo_class_date == undefined || self.AddForm.value.demo_class_date == null || self.AddForm.value.demo_class_date == '' || self.AddForm.value.demo_class_date == 'null') {
        return;
      }
      if (self.AddForm.value.time_slot == undefined || self.AddForm.value.time_slot == null || self.AddForm.value.time_slot == '' || self.AddForm.value.time_slot == 'null') {
        return;
      }
      if (self.AddForm.invalid) {
        return;
      } else {
        self.AddForm.patchValue({
          learning: '0'
        })
        self.btnsubmitted = true;
        var sendRequestData = this.AddForm.value
        if (localStorage.getItem('referral_code')) {
          sendRequestData.sponsor_code = localStorage.getItem('referral_code');
        }
        this.userService.userRegister('register_student', sendRequestData).then(data => {
          // console.log('SEnD:::', data);
          if (data.replyCode == "success") {
            self._employeeService.showSuccess(data.replyMsg);
            self.AddForm.reset();
            self.submitted = false;
            self.btnsubmitted = false;
          } else {
            self.btnsubmitted = false;
            self._employeeService.showError(data.replyMsg)
          }
        });
      }
    } else if (this.isPreSchool) {
      if (self.AddForm.value.age_group_id == undefined || self.AddForm.value.age_group_id == null || self.AddForm.value.age_group_id == '' || self.AddForm.value.age_group_id == 'null') {
        this.ageRequired = true;
        return;
      }
      if (self.AddForm.invalid) {
        return;
      } else {
        self.btnsubmitted = true;
        self.AddForm.patchValue({
          learning: '1'
        })
        self.btnsubmitted = true;
        var sendRequestData = this.AddForm.value
        if (localStorage.getItem('referral_code')) {
          sendRequestData.sponsor_code = localStorage.getItem('referral_code');
        }
        this.userService.userRegister('register_student', sendRequestData).then(data => {
          // console.log('SEnD:::', data);
          if (data.replyCode == "success") {
            self._employeeService.showSuccess(data.replyMsg);
            self.AddForm.reset();
            self.submitted = false;
            self.btnsubmitted = false;
          } else {
            self.btnsubmitted = false;
            self._employeeService.showError(data.replyMsg)
          }
        });
      }
    } else if (this.isRobotics) {
      if (self.AddForm.value.age_group_id == undefined || self.AddForm.value.age_group_id == null || self.AddForm.value.age_group_id == '' || self.AddForm.value.age_group_id == 'null') {
        return
      }
      self.btnsubmitted = true;
      self.AddForm.patchValue({
        learning: 2
      })
      self.btnsubmitted = true;
      this.userService.leedFeedbackCapture(this.AddForm.value);
      setTimeout(() => {
        self.AddForm.reset();
        self.submitted = false;
        self.btnsubmitted = false;
      }, 2000);

    }
  }
}
