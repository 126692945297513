<app-header *ngIf="!showHideFlag"></app-header>
<app-pie-header *ngIf="showHideFlag"></app-pie-header>
<section>
    <div class="container">
        <!-- <div class="row my-4">
            <div class="col-12 text-center">
                <h4 class="bold">Contact us</h4>
                <p>We’re devoted to our mission of making this generation creators versus consumers of technology.<br> Reach out to us for any assistance.</p>
            </div>
        </div> -->
        <!-- <div class="row">
            <div class="col-6 text-center" style="margin: 0 auto;">
               <div class="row">
                <div class="col-6">
                    <h5>Call Us @ 8010554400</h5>
                    <p>Waiting time less than 30 secs</p>
                </div>
                <div class="col-6">
                    <h5>Write to us</h5>
                    <p>support@learningbix.com</p>
                </div>
               </div>
            </div>
        </div> -->
        <div class="row mt-5 mb-5">
            <div class="col-12 text-center">
                <h4 class="bold">Get In Touch</h4>
            </div>
            <div class="col-md-12 mt-5">
                <form [formGroup]="AddForm">

                    <div class="row">
                        <div class="col-6">
                            <div class="group">
                                <input type="text" required placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" formControlName="first_name">
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label class="form_active">First Name</label>
                            </div>
                            <div *ngIf="submitted && f.first_name.errors">
                                <small *ngIf="f.first_name.errors.required" class="error_class">
                                    This Field is required
                                </small>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="group">
                                <input type="text" required placeholder="last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" formControlName="last_name">
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label class="form_active">Last Name</label>
                            </div>
                            <div *ngIf="submitted && f.last_name.errors">
                                <small *ngIf="f.last_name.errors.required" class="error_class">
                                    This Field is required
                                </small>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="group">
                                <input type="text" required placeholder="Email Address" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email">
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label class="form_active">Your Email Address</label>
                            </div>
                            <div *ngIf="submitted && f.email.errors">
                                <small *ngIf="f.email.errors.required" class="error_class">
                                    This Field is required
                                </small>
                                <small [hidden]="!f.email.errors.patternInvalid" class="error_class">Please enter valid email.</small>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="group">
                                <input type="text" required placeholder="Contact No." [ngClass]="{ 'is-invalid': submitted && f.contact.errors }" formControlName="contact">
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label class="form_active">Contact No.</label>
                            </div>
                            <div *ngIf="submitted && f.contact.errors">
                                <small *ngIf="f.contact.errors.required" class="error_class">
                                    This Field is required
                                </small>
                                <small [hidden]="!f.contact.errors.patternMobileInvalid" class="error_class">Phone Number should be valid and 10 digit.</small>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="group">
                                <input type="text" required placeholder="Message" [ngClass]="{ 'is-invalid': submitted && f.description.errors }" formControlName="description">
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label class="form_active">Message</label>
                            </div>
                            <div *ngIf="submitted && f.description.errors">
                                <small *ngIf="f.description.errors.required" class="error_class">
                                    This Field is required
                                </small>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row mt-5">
                       
                        <div class="col-3">
                            <div class="group radio-item">
                                <input id="bix" type="radio" value="0" name="learning" formControlName="learning">
                                <label class="learningType" for="bix">Learning Bix</label>
                            </div>

                        </div>
                        <div class="col-3">

                            <div class="group radio-item">
                                <input id="pie" type="radio" value="1" name="learning" formControlName="learning">
                                <label class="learningType" for="pie">Learning Pie</label>
                            </div>
                        </div> 
                    </div> -->
                </form>
                <button class="btn btn-join add_class mt-5" (click)="onSubmit()" [disabled]="btnsubmitted">Submit</button>
            </div>
        </div>
        <!-- <div class="row my-4">
            <div class="col-12 text-center">
                <h4 class="bold">Subscribe for Course Updates</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed.</p>
            </div>
            <div class="col-12 text-right">
                <button class="btn btn-subscribe add_class mt-5">Subscribe</button>
            </div>
        </div> -->

    </div>
</section>
<app-footer *ngIf="!showHideFlag"></app-footer>
<app-pie-footer *ngIf="showHideFlag"></app-pie-footer>