import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseServiceService } from 'src/app/service';
import { CommonService } from 'src/app/service/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from 'src/app/service/modal.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss']
})
export class SchoolComponent implements OnInit {
  @ViewChild('enquirysuccessmodal', { static: false }) public enquirysuccessmodal;

  sendFeedBackForm: FormGroup;
  submitted = false;
  stateData:any;
  currentURL='';

  constructor(private fb: FormBuilder,
    private router: Router,
    private titleService:Title,
    public _employeeService: BaseServiceService,
    private sanitizer: DomSanitizer,
    public common:CommonService,
    private modalService: ModalService) {
      this.currentURL = window.location.href;
      console.log("this.currentURL>>>>>",this.currentURL)
    }

  ngOnInit(): void {
    this.sendFeedBackForm=this.fb.group({
      school_name :['',Validators.required],
      email:['',[Validators.email,Validators.required]],
      address:[''],
      city:[''],
      state:[''],
      zip_code:[''],
      // isagreed:[''],
      contact_person:[''],
      phone:['',[Validators.pattern('^[0-9]{10}$'), Validators.required]]
    })

    this.getStateList()
  }

  get f() { return this.sendFeedBackForm.controls; }

  onProductAddForm(){
    var sendRequestData = this.sendFeedBackForm.value;
    // sendRequestData.title ='Robotics Enquiry';
    // sendRequestData.last_name ='';
    sendRequestData.page_url=this.currentURL;
    // console.log('sendRequestData ::::: ',sendRequestData)
    if (this.sendFeedBackForm.valid) {
        console.log('sendRequestData ::::::',sendRequestData)
        this._employeeService.post('school_enquiry', sendRequestData).subscribe((res: any) => {
          console.log(res)
          if (!res.error) {
            if (res.replyCode == 'success') {
              this._employeeService.showSuccess(res.replyMsg)
              this.enquirysuccessmodal.show();

              this.sendFeedBackForm.reset();
              this.submitted = false;
            }
            else {
              this._employeeService.showError(res.replyMsg)
            }
          }
        }, error => {
          this._employeeService.showError("Oops! Something went wrong!");
        });
      }
    this.submitted = true;

  }

  getStateList(){
    this._employeeService.post('state_list',{country_id:'101'}).subscribe(data=>{
      this.stateData=data
      console.log("state data ::::: ",this.stateData.data)
    })
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
}

}
