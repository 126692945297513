import { Component, OnInit } from '@angular/core';
import { StarRatingComponent } from 'ng-starrating';
import { ModalService } from '../service/modal.service';

@Component({
  selector: 'app-coding',
  templateUrl: './coding.component.html',
  styleUrls: ['./coding.component.scss']
})
export class CodingComponent implements OnInit {
  active = 1;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    alert(`Old Value:${$event.oldValue}, 
      New Value: ${$event.newValue}, 
      Checked Color: ${$event.starRating.checkedcolor}, 
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }

  onOpenDemoPopup(){
    this.modalService.SetModalOnOff(true);
    this.modalService.setBookAFreeModal(true);
    // this.modalService.setbookDemoClassBixModal(true);
  }

}
