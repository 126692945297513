<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-179330351-1"></script>
<script>
    window.dataLayer = window.dataLayer || [];

    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'UA-179330351-1');
</script>

    <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
        <a class="navbar-brand" [routerLink]="['/']"><img src="assets/imgs/logo.png" alt="learningbix logo"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav"  aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="main_nav">
            <ul class="navbar-nav">
                <li class="nav-item dropdown has-megamenu">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0);" data-toggle="dropdown"> Robotics  </a>
                    <div class="dropdown-menu megamenu" role="menu">
                        <div class="row g-3 justify-content-center">
                            <div class="col-lg-2 mb-3 mb-lg-0 text-center">
                                <div class="col-megamenu">
                                    <div class="card mb-2">
                                        <img src="assets/imgs/robotics-header.jpg" class="img-fluid">
                                    </div>
                                    <a class="font-weight-bold" href="\robotics" style="font-size: 18px;color: black;">Explore More</a>
                                </div>
                            </div>
                            <div class="col-lg-2 mb-3 mb-lg-0 text-center">
                                <div class="col-megamenu">
                                    <h5 class="title font-weight-bold">Age 3+</h5>
                                    <ul class="list-unstyled">
                                        <li>
                                            <a href="\robotics\musky" style="font-size: 18px;color: black;"> Musky</a>
                                        </li>
                                        <!-- <li><a href="javascript:void(0)" style="font-size: 18px;color: black;"> Musky</a></li> -->
                                    </ul>
                                </div>  <!-- col-megamenu.// -->
                            </div><!-- end col-3 -->
                            <div class="col-lg-2 mb-3 mb-lg-0 text-center">
                                <div class="col-megamenu">
                                    <h5 class="title font-weight-bold">Age 5+</h5>
                                    <ul class="list-unstyled">
                                        <li><a href="\robotics\hive" style="font-size: 18px;color: black;">LearningBix HIVE</a></li>
                                        <li><a class="underline" (click)="onViewProduct('solar-home')"  style="font-size: 18px;color: black;cursor:pointer">Solar House</a></li>
                                        <li><a class="underline" (click)="onViewProduct('solar-home')" style="font-size: 18px;color: black;cursor:pointer">Paper Circuit</a></li>
                                        <li><a class="underline" (click)="onViewProduct('solar-home')" style="font-size: 18px;color: black;cursor:pointer">Magic Dough</a></li>
                                    </ul>
                                </div>  <!-- col-megamenu.// -->
                            </div><!-- end col-3 -->
                            <div class="col-lg-2 mb-3 mb-lg-0 text-center">
                                <div class="col-megamenu">
                                    <h5 class="title font-weight-bold">Age 8+</h5>
                                    <ul class="list-unstyled">
                                        <li><a class="underline" (click)="onViewProduct('accelero')" style="font-size: 18px;color: black;cursor:pointer">Accelero</a></li>
                                        <li><a class="underline" (click)="onViewProduct('machines-starter')" style="font-size: 18px;color: black;cursor:pointer">Machines Starter</a></li>
                                        <li><a class="underline" (click)="onViewProduct('machines-explorer')" style="font-size: 18px;color: black;cursor:pointer">Machines Explorer</a></li>
                                        <li><a class="underline" (click)="onViewProduct('ultimate-seven')" style="font-size: 18px;color: black;cursor:pointer">Machines Ultimate</a></li>
                                        <li><a class="underline" (click)="onViewProduct('army-tank')" style="font-size: 18px;color: black;cursor:pointer">Army Tank</a></li>
                                        <li><a class="underline" (click)="onViewProduct('space-mission-rover')" style="font-size: 18px;color: black;cursor:pointer">Space Rover</a></li>
                                    </ul>
                                </div>  <!-- col-megamenu.// -->
                            </div>
                            <div class="col-lg-2 mb-3 mb-lg-0 text-center">
                                <div class="col-megamenu">
                                    <h5 class="title font-weight-bold">Age 12+</h5>
                                    <ul class="list-unstyled">
                                        <li><a href="\robotics\intellecto" style="font-size: 18px;color: black;cursor:pointer">Intellecto</a></li>
                                        <li><a href="/robotics/gps-kit" style="font-size: 18px;color: black;cursor:pointer">GPS Wearable Kit</a></li>
                                    </ul>
                                </div>  <!-- col-megamenu.// -->
                            </div><!-- end col-3 -->
                        </div><!-- end row -->
                    </div> <!-- dropdown-mega-menu.// -->
                </li>
                <li class="nav-item dropdown has-megamenu">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownStem" role="button" data-toggle="dropdown" aria-expanded="false"> STEM </a>
                    <div class="dropdown-menu megamenu small" aria-labelledby="navbarDropdownStem">
                        <div class="row g-3 justify-content-center">
                            <div class="col-lg-4 mb-3 mb-lg-0 text-center">
                                <div class="col-megamenu">
                                    <div class="card mb-2">
                                        <img src="assets\imgs\kit-image-8.png" class="img-fluid">
                                    </div>
                                    <a class="font-weight-bold" href="\robotics\stem" style="font-size: 18px;color: black;">Explore More</a>
                                </div>  <!-- col-megamenu.// -->
                            </div>
                            <div class="col-lg-4 mb-3 mb-lg-0 text-center">
                                <div class="col-megamenu">
                                    <h5 class="title font-weight-bold">Age 6+</h5>
                                    <ul class="list-unstyled">
                                        <li> <a class="dropdown-item" (click)="onViewProduct('sketch-machine')" style="font-size: 18px;color: black;cursor:pointer">Sketch Machine</a></li>
                                        <li><a class="dropdown-item" (click)="onViewProduct('crossbow')" style="font-size: 18px;color: black;cursor:pointer">Crossbow</a></li>
                                        <li><a class="dropdown-item" (click)="onViewProduct('guitar')" style="font-size: 18px;color: black;cursor:pointer">Guitar</a></li>
                                        <li><a class="dropdown-item" (click)="onViewProduct('vintage-projector')" style="font-size: 18px;color: black;cursor:pointer">Projector</a></li>
                                    </ul>
                                </div>  <!-- col-megamenu.// -->
                            </div><!-- end col-3 -->
                            <div class="col-lg-4 mb-3 mb-lg-0 text-center">
                                <div class="col-megamenu">
                                    <h5 class="title font-weight-bold">Age 8+</h5>
                                    <ul class="list-unstyled">
                                        <li> <a class="dropdown-item" (click)="onViewProduct('telescope')" style="font-size: 18px;color: black;cursor:pointer">Telescope</a></li>
                                        <li><a class="dropdown-item" (click)="onViewProduct('marine-sailor')" style="font-size: 18px;color: black;cursor:pointer">Sextant</a></li>
                                        <li><a class="dropdown-item" (click)="onViewProduct('Periscope')" style="font-size: 18px;color: black;cursor:pointer">Periscope</a></li>
                                        <li><a class="dropdown-item" (click)="onViewProduct('aliengun')" style="font-size: 18px;color: black;cursor:pointer">Alien Gun</a></li>
                                    </ul>
                                </div>  <!-- col-megamenu.// -->
                            </div>
                        </div>
                        <div class="text-center">
                            <a class="font-weight-bold" href="\robotics\stem" style="font-size: 18px;color: black;cursor:pointer">Check out more</a>
                        </div>

                    </div>
                </li>
                <!-- <li class="nav-item"><a class="nav-link" routerLink="/robotics/home"> Coding with Robotics </a></li> -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdownServices" role="button" data-toggle="dropdown" aria-expanded="false">Services</a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownServices">
                        <a class="dropdown-item" target="_blank" href="https://learningpie.in/">Pre-School</a>
                        <a class="dropdown-item dropdown-toggle" href="#" id="navbarDropdownCoding" role="button" data-toggle="dropdown" aria-expanded="false">Coding</a>
                        <div class="dropdown-menu border-0 rounded-0 py-0 my-0 bg-warning position-relative" aria-labelledby="navbarDropdownCoding">
                            <ul class="list-unstyled">
                                <li><a class="dropdown-item sub-item pl-4" routerLink="/coding"><i class="fa fa-angle-right mr-2" aria-hidden="true"></i>Live</a></li>
                                <li><a class="dropdown-item sub-item pl-4" routerLink="/courses"><i class="fa fa-angle-right mr-2" aria-hidden="true"></i>Self-Paced</a></li>
                            </ul>

                        </div>
                        <a class="dropdown-item" href="javascript:void(0)" routerLink="/robotics/home">Coding with Robotics</a>
                    </div>
                </li>
                <li class="nav-item"><a class="nav-link" routerLink="/school">Schools</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/franchisee">Franchisee</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/learning-center"> Learning Center </a></li>
            </ul>
            <form class="form-inline my-2 my-lg-0 ml-auto">
              <button class="btn btn-outline-primary my-2 my-sm-0 my-lg-0" type="button" style="padding: 5px 0px;font-size: 15px;" routerLink="/robotics">Shop Now</button>&nbsp;
                <button class="btn btn-primary btn-book-class mr-sm-2" type="button" (click)="onOpenDemoPopup()">Book a free class</button>
                <button class="btn btn-outline-primary my-2 my-sm-0 my-lg-0" type="button" (click)="onLoginPanelPopup()" style="padding: 5px 0px;font-size: 15px;" *ngIf="!UserLoginFlag">Login</button>&nbsp;
                <button class="btn btn-outline-primary my-2 my-sm-0 my-lg-0 ml-2" type="button" style="padding: 5px 0px;font-size: 15px;" *ngIf="UserLoginFlag" (click)="onRedirect()">My Profile</button>
            </form>
        </div>
    </div>
    </nav>
