
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand col-2" href="#"><img src="../../../assets/imgs/logo-pie.png" alt="" class="img-fluid"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <span class="navbar-text ml-auto" style="font-weight: 600;">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
      </span>
    </div>
  </nav>

<div class="container">
  <div class="row mt-4"> 
    <div class="col-12 col-md-7">
      <h4 class="mb-4">You'll get upto Rs. 4000 when you join.*</h4>
      <img src="../../../assets/imgs/discount-voucher-coupon-gift-certificate-concept.jpg" width="80%" alt="" class="img-fluid">
    </div>
    
    <div class="col-12 col-md-5">
      <div class="card">

      </div>

      <div class="card form_cardHome" style="padding: 50px 25px;">
        <h2 class="my-0">Get In Touch </h2>
        <form [formGroup]="AddForm" class="custome-form">
          <div class="input-group my-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-sname" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"><i
                  class="fas fa-user"></i></span>
            </div>
            <input type="text" class="form-control" placeholder="Full Name" required aria-label="Username"
              aria-describedby="basic-sname" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
              formControlName="name">
    
          </div>
          <div class="input-group my-3">
            <select class="custome-contory-code" id="exampleFormControlSelect1">
              <option>91</option>
            </select>
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-mobile"><i class="fas fa-mobile-alt"></i></span>
            </div>
            <input type="text" required class="form-control mobile" placeholder="Contact No" aria-label="Username"
              maxlength="10" appNumbersOnly aria-describedby="basic-mobile"
              [ngClass]="{ 'is-invalid': submitted && f.phone_no.errors }" formControlName="phone_no">
    
          </div>
          <div class="input-group my-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-email"><i class="fas fa-envelope"></i></span>
            </div>
            <input type="email" class="form-control" placeholder="Email" required
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" aria-label="Useremail"
              aria-describedby="basic-email">
    
          </div>
          <div class="text-center my-3">
            <h6 class="my-2 text-left" *ngIf="!isCoding && !isPreSchool && !isRobotics">I am interested in (Select One)
            </h6>
            <ng-container *ngFor="let item of classType; let ind= index">
    
              <button type="button" class="custome-selectBtn {{seletedClasstype==item.value?'active-btn':''}}"
                (click)="onSelectClassType(item.value)">{{item.name}}</button>
    
            </ng-container>
    
    
          </div>
          <section *ngIf="!isCoding && !isPreSchool && !isRobotics" class="my-1 text-center">
            <div class="text-center mt-3 mb-2">
              <button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted" class="btn btn-form-submit">Contact
                Us </button>
            </div>
          </section>
          <section *ngIf="isCoding" class="my-1 text-center">
    
            <h6 class="my-1 text-left">Select Age Group (yrs) <span *ngIf="ageCodingRequired"
                style="color:red;">*(Required)</span></h6>
            <ng-container *ngFor="let item of codingAgeGroup; let ind= index" class="my-3">
              <button type="button" class="custome-selectBtn my-3 {{seletedAgeGroup==item.id?'active-btn':''}}"
                (click)="onSelectAgeGroup(item)">{{item.age_from}}-{{item.age_to}}</button>
            </ng-container>
            <div class="row my-0">
              <div class="col-6">
                <h6 class="d-block text-left">Demo Date</h6>
                <input type="date" class="form-control unstyled" formControlName="demo_class_date" placeholder="Demo Date"
                  required min="{{maxDate}}" (change)="dateSelected($event.target.value)">
              </div>
              <div class="col-6">
                <h6 class="d-block text-left">Demo Time</h6>
                <select class="form-control unstyled" id="time_slot" formControlName="time_slot"
                  [ngClass]="{ 'is-invalid': submitted && f.time_slot.errors }"
                  (change)="onTimeSlot($event.target.value)">
                  <option selcted value="" disabled>Select Slot</option>
                  <option [value]="time_slot.value" *ngFor="let time_slot of Time_Slot_Array">{{time_slot.name}}</option>
                </select>
              </div>
            </div>
    
            <div class="text-center mt-3 mb-2">
              <button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted" class="btn btn-form-submit">Book a free
                Class</button>
            </div>
            <div class="row my-2">
              <span class="text-terms">By registering here, you agree to our <a href="/page/terms" target="_blank">Terms
                  of use </a> and <a href="page/privacy-policy" target="_blank">Privacy Policy</a>
              </span>
            </div>
          </section>
          <section *ngIf="isPreSchool" class="my-3 text-center">
            <h6 class="my-1 text-left">Select Age Group (yrs) <span *ngIf="ageRequired"
                style="color:red;">*(Required)</span></h6>
    
    
            <ng-container *ngFor="let item of preSchoolAgeGroup; let ind= index" class="my-3">
    
              <button type="button" class="custome-selectBtn my-3  {{seletedAgeGroup==item.id?'active-btn':''}}"
                (click)="onSelectAgeGroup(item)">{{item.age_from}}-{{item.age_to}}</button>
            </ng-container>
    
    
            <div class="text-center">
              <button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted" class="btn btn-form-submit">Book your 7
                day Trial</button>
            </div>
            <div class="row my-2">
              <span class="text-terms">By registering here, you agree to our <a href="/page/terms" target="_blank">Terms
                  of use </a> and <a href="/page/privacy-policy" target="_blank">Privacy Policy</a>
              </span>
            </div>
          </section>
          <section *ngIf="isRobotics" class="my-3 text-center">
            <h6 class="my-1 text-left">Select Age Group (yrs)</h6>
            <ng-container *ngFor="let item of roboticsAgeGroup; let ind= index" class="my-3">
              <button type="button" class="custome-selectBtn my-3 {{seletedAgeGroup==item.id?'active-btn':''}}"
                (click)="onSelectAgeGroup(item)">{{item.name}}</button>
    
            </ng-container>
    
            <div class="row my-2">
              <h6 class="my-1 text-center">Coming Soon- World's best designed and awarded kits in India.</h6>
            </div>
            <div class="text-center">
              <button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted" class="btn btn-form-submit">Notify
                Me</button>
            </div>
            <div class="row my-2">
              <span class="text-terms">By registering here, you agree to our<a href="/page/terms" target="_blank">Terms of
                  use </a> and <a href="/page/privacy-policy" target="_blank">Privacy Policy</a>
              </span>
            </div>
    
          </section>
  
        </form>
      </div>
    </div>
  </div>
  
</div>

