import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

import { BaseServiceService } from 'src/app/service';
import { CommonService } from 'src/app/service/common.service';
import { ModalService } from 'src/app/service/modal.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ShoppingCartService } from 'src/app/service/shopping-cart.service';

@Component({
  selector: 'app-custom-blog',
  templateUrl: './custom-blog.component.html',
  styleUrls: ['./custom-blog.component.scss']
})
export class CustomBlogComponent implements OnInit {
  id: any;
  isBlogDetails:any={};
  constructor(public _employeeService: BaseServiceService, private Activatedroute: ActivatedRoute) {
      this.id = this.Activatedroute.snapshot.paramMap.get("id");
      console.log('this.id:::::', this.id);
      this.getBlogDetails(this.id);
  }

  ngOnInit(): void {

  }
  getBlogDetails(ids){
    this._employeeService.post('blog_view', {
      id: ids,
    }).subscribe((res: any) => {
      if (!res.error) {
        this.isBlogDetails = res.data;
        console.log("isBlogDetails>>>>",this.isBlogDetails);
        } else {
          this.isBlogDetails = {};
        }
    });
  }

}
