<div class="modal popup" id="loginOTPModal" tabindex="-1" role="dialog" aria-labelledby="loginOTPModalLabel" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 3px 10px;" (click)="onCloseModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2" style="    height: 550px;">
                <div class="row">
                    <div class="col-6">
                        <img src="../../../assets/imgs/loginModalBanner.jpg" class="img-fluid w-100" alt="Login Banner" style="height: 86%;">
                    </div>
                    <div class="col-6">
                        <h1 style="font-size: 30px;">Login</h1>
                        <p class="mt-3 mb-3 mobile-details-for-otp" *ngIf="phoneNo" style="color: #199DE3;">OTP sent on
                            <strong>{{phoneNo}}</strong> &nbsp;&nbsp;<a href="javascript:void(0);" (click)="onEditPhone()" style="color: #ED7F21;">Edit</a>
                        </p>
                        <form [formGroup]="otpModal">
                            <label for="otp1">Enter OTP</label>
                            <div class="row my-4">
                                <div class="col-12 text-center">
                                    <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config">
                                    </ng-otp-input>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-4 text-center">
                                    <a href="javascript:void(0);" class="resend-link" (click)="onResendOtp()">Resend</a>
                                </div>
                                <div class="col-8">
                                    <button class="btn btn-block btn-login" (click)="onSubmitOtp()">Login</button>
                                </div>
                            </div>
                            <p class="mt-3 mb-3 text-center seprator">or</p>
                            <p class="register-link">Not registered yet? <a href="javascript:void(0);" (click)="onRegisterPopup()">Register
                                    Now</a></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
