<app-pie-header></app-pie-header>
<header class="pie-header" id="course-pie">
  <div id="carouselExampleCaptions" class="carousel slide pie-home-banner" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="../../assets/imgs/banner/banner-1.jpg" class="d-block w-100" alt="">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
      <!-- <div class="carousel-item">
        <img src="../../assets/imgs/banner/banner-2.jpg" class="d-block w-100" alt="">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

      <div class="carousel-item">
        <img src="../../assets/imgs/banner/banner-3.jpg" class="d-block w-100" alt="">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div> -->
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

  <div class="book-demo-form toggle-form" id="getTouchFormPie">
    <div class="card form_cardHome pie-card">
      <h3 class="my-0 text-left">Fill In Your Details To Book A FREE Demo Class</h3>
      <form [formGroup]="AddForm" class="custome-form">
        <div class="form-group">
          <!-- <div class="input-group-prepend">
            <span class="input-group-text" id="basic-sname" [ngClass]="{ 'is-invalid': submitted && f.parents_name.errors }">
              <i class="fas fa-user"></i>
            </span>
          </div> -->
          <input type="text" class="form-control" placeholder="Enter Parent Name" required aria-label="Username"
            aria-describedby="basic-sname" [ngClass]="{ 'is-invalid': submitted && f.parents_name.errors }"
            formControlName="parents_name">

        </div>
        <div class="form-group">
          <!-- <div class="input-group-prepend">
            <span class="input-group-text" id="basic-email"><i class="fas fa-envelope"></i></span>
          </div> -->
          <input type="email" class="form-control" placeholder="Email" required
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" aria-label="Useremail"
            aria-describedby="basic-email">

        </div>

        <div class="form-group">
          <!-- <div class="input-group-prepend">
            <span class="input-group-text" id="basic-mobile"><i class="fas fa-mobile-alt"></i></span>
          </div> -->
          <!-- <input type="text" required class="form-control mobile" maxlength="10" appNumbersOnly placeholder="Contact No"
            aria-label="Username" aria-describedby="basic-mobile"
            [ngClass]="{ 'is-invalid': submitted && f.phone_no.errors }" formControlName="phone_no"> -->
            <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="Mobile"
            [ngClass]="{ 'is-invalid': submitted && f.phone_no.errors }" formControlName="phone_no" appNumbersOnly maxlength="10">

        </div>

        <div class="form-group">
          <!-- <div class="input-group-prepend">
            <span class="input-group-text" id="basic-sname" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
              <i  class="fas fa-user"></i>
            </span>
          </div> -->
          <input type="text" class="form-control" placeholder="Enter Child Name" required aria-label="Username"
            aria-describedby="basic-sname" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
            formControlName="name">

        </div>

        <h5>Program</h5>
        <div class="form-row mb-3">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6" *ngFor="let ageCourse of ageCourseList">
            <button class="btn btn-outline-secondary{{ageCourse.class}} w-100" type="button"
              (click)="clickEventAddClass(ageCourse)">{{ageCourse.name}}<br>{{ageCourse.title}}</button>
          </div>
          <div *ngIf="submitted && f.age_group_id.errors">
            <small *ngIf="f.age_group_id.errors.required" class="text-danger">
              This Field is required
            </small>
          </div>
        </div>
        <button class="btn btn-primary btn-book-orange mt-4" type="button" (click)="onOpenPieModal();"  style="margin: 0 auto;display: block;">BOOK A FREE
          DEMO</button>
          <!-- <button class="btn btn-primary btn-book-orange mt-4" type="button" (click)="onOpenPieModal()">BOOK A FREE DEMO</button> -->
      
      </form>
    </div>
  </div>


</header>
<br>
<br>
<div class="connectivity-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-md-12">
        <h2>LearningPie@HOME online preschool program for kids</h2>
        <p>Complete Preschool Program for kids of age group 1.5-6 years</p>
        <div class="embed-responsive embed-responsive-16by9">
        <iframe src="https://www.youtube.com/embed/Xeg7tnqpM98" title="YouTube video player"
          frameborder="0" class="embed-responsive-item"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
          </div>
      </div>
    </div>
  </div>
</div>
<!-- <app-online-pre-school></app-online-pre-school> -->
<!-- <section class="benifits">
  <div class="container">
    <h2>Benefits of LearningPIE@Home?</h2>
  </div>
  <div class="container-fluid Benefits_LearningPIE">
    <div class="row">
      <div class="col-md-4 mb-2 px-1">
        <div class="benifit-1 benifit-item text-white text-center h-100">
          <img src="assets/imgs/Icons/1.png" class="img-fluid benifit-item-img" alt="">
          <div class="w-100"></div>
          <p class="activity-text">Uniquely designed methodology, drawing inspiration from 7 of world’s best curriculum
          </p>
        </div>
      </div>
      <div class="col-md-4 mb-2 px-1">
        <div class="benifit-2 benifit-item text-white text-center h-100">
          <img src="assets/imgs/Icons/2.png" class="img-fluid benifit-item-img" alt="">
          <div class="w-100"></div>
          <p class="activity-text">Mapped with scientifically created all inclusive activity boxes for activity based
            learning</p>
        </div>
      </div>
      <div class="col-md-4 mb-2">
        <div class="row h-100">
          <div class="col-6 px-1">
            <div class="benifit-3 benifit-item text-white text-center h-100">
              <img src="assets/imgs/Icons/3.png" class="img-fluid benifit-item-img" alt="">
              <div class="w-100"></div>
              <p class="activity-text">Expert child counselors to handhold and customize child growth</p>
            </div>
          </div>
          <div class="col-6 px-1">
            <div class="benifit-4 benifit-item text-white text-center h-100">
              <img src="assets/imgs/Icons/4.png" class="img-fluid benifit-item-img" alt="">
              <div class="w-100"></div>
              <p class="activity-text">Pre-recorded sessions- Travel guilt free of not missing your child’s school</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-2 px-1">
        <div class="benifit-5 benifit-item text-white text-center h-100">
          <img src="assets/imgs/Icons/5.png" class="img-fluid benifit-item-img" alt="">
          <div class="w-100"></div>
          <p class="activity-text">Personalised dashboard to track the child’s learning curve and growth</p>
        </div>
      </div>
      <div class="col-md-4 mb-2">
        <div class="row">
          <div class="col-6 mb-1 px-1 pb-1">
            <div class="benifit-6 benifit-item text-white text-center h-100">
              <img src="assets/imgs/Icons/6.png" class="img-fluid benifit-item-img" alt="">
              <div class="w-100"></div>
              <p class="activity-text">Cancel your subscription anytime, no questions asked </p>
            </div>
          </div>
          <div class="col-6 mb-1 px-1 pb-1">
            <div class="benifit-7 benifit-item text-white text-center h-100">
              <img src="assets/imgs/Icons/7.png" class="img-fluid benifit-item-img" alt="">
              <div class="w-100"></div>
              <p class="activity-text">Preferred admission to the best schools because of all round child development
              </p>
            </div>
          </div>
          <div class="col-12 px-1">
            <div class="benifit-8 benifit-item text-white text-center h-100">
              <img src="assets/imgs/Icons/8.png" class="img-fluid benifit-item-img" alt="">
              <div class="w-100"></div>
              <p class="activity-text">Subtle integration of technology and hardware/software to develop both brain
                spheres</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-2 px-1">
        <div class="benifit-9 benifit-item text-white text-center h-100">
          <img src="assets/imgs/Icons/9.png" class="img-fluid benifit-item-img" alt="">
          <div class="w-100"></div>
          <p class="activity-text">Zero preparation needed. All material delivered at your home, on time, every time</p>
        </div>
      </div>



    </div>
  </div>
</section> -->
<!-- <section class="pie-learn-code my-5">
  <div class="container">

    <div class="row">
      <div class="col-12">
        <div id="course11">
          <h3>Select age group</h3>

          <ul class="nav nav-tabs nav-fill" role="tablist">
            <li class="nav-item" *ngFor="let age of AgeGroupList;let i =index;"
              (click)="getCoursesListFirst(i, age.id)">
              <a class="nav-link" [ngClass]="age.id ==firstIndexId ? 'active':''" href="#profile{{i}}" role="tab"
                data-toggle="tab"><img src="{{age.image}}" class="img-fluid age-group-icon" alt="">
                <strong>{{age.age_from}}-{{age.age_to}} Yrs</strong></a>
            </li>

          </ul>

          <div class="tab-content" id="myTabContent">
            <div role="tabpanel" class="tab-pane fade in active show" id="profile0">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="assets/imgs/Skills/Language/1.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Language</h3>
                      <ul class="learning-points">
                        <li>Recognize letters A-Z</li>
                        <li>Finger tracing based learning</li>
                        <li>Develop phonic understanding</li>
                        <li>Develop lingual understanding (English & Hindi)</li>
                        <li>Strengthen fine motor, gripping, and pincer skills for pre-writing ability</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="assets/imgs/Skills/Numeracy/1.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Numeracy</h3>
                      <ul class="learning-points">
                        <li>Identify, recognize, and quantify numbers 1-10</li>
                        <li>Sort and sequence using shape, size, colours etc.</li>
                        <li>Ascending and Descending order learning</li>
                        <li>Early counting games in activity box</li>
                        <li>Pre-math concepts such as big/small, one/many, etc</li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="assets/imgs/Skills/Cognitive/1.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Cognitive</h3>
                      <ul class="learning-points">
                        <li>Development of mental skills</li>
                        <li>Engage in pretend and imaginative play</li>
                        <li>Ability to match shapes and sort colors</li>
                        <li>Identify body parts and functions</li>
                        <li>Identify familiar people and everyday objects</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="assets/imgs/Skills/Socio-emotional/1.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Socio-Emotional</h3>
                      <ul class="learning-points">
                        <li>Recite simple stories</li>
                        <li>Recognizing themselves in mirror</li>
                        <li>Learn basic greeting etiquettes</li>
                        <li>Express basic emotions through gestures & facial expressions</li>
                        <li>Inculcating gratitude and positive values through mindful practices</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane fade in" id="profile1">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="assets/imgs/Skills/Language/2.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Language</h3>
                      <ul class="learning-points">
                        <li>Write & speak English language and letters</li>
                        <li>Enhanced Eye-Hand coordination for emergent writing</li>
                        <li>Learn letters’ sound and pronunciation</li>
                        <li>Ability to answer questions after reading books</li>
                        <li>Development of comprehension skills</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="assets/imgs/Skills/Numeracy/2.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Numeracy</h3>
                      <ul class="learning-points">
                        <li>Identify, recognize, and quantify numbers</li>
                        <li>Sorting and sequencing activities</li>
                        <li>Early math concepts – one/many, more/less, heavy/light, big/small etc</li>
                        <li>Replicate & draw basic geometrical shapes (circles, squares, rectangles etc.)</li>
                        <li>Maths centric games in activity box</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="../../assets/imgs/Skills/Cognitive/2.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Cognitive</h3>
                      <ul class="learning-points">
                        <li>Learns to play with toys having buttons, levers, and moving parts</li>
                        <li>Play with make-believe symbolic play with dolls, animals, and people</li>
                        <li>Learn to build towers of more than 6 blocks</li>
                        <li>Ability to screw and unscrew jar lids, develops gross & fine motor skills</li>
                        <li>Able to solve puzzles with 3 or 4 pieces</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="../../assets/imgs/Skills/Socio-emotional/2.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Socio-Emotional</h3>
                      <ul class="learning-points">
                        <li>Express affection for family and friends without asking to do so</li>
                        <li>Learn to take turns in games</li>
                        <li>Understanding the idea of “mine”, “his” or “hers”</li>
                        <li>Gain ability to express a wide range of emotions</li>
                        <li>Will learn giving gratitude and positive values through mindful practices for kids</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane fade in" id="profile2">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="../../assets/imgs/Skills/Language/1.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Language</h3>
                      <ul class="learning-points">
                        <li>Recognise cursive and capital letters</li>
                        <li>Learn to trace & write - big & small letters</li>
                        <li>Learn about word family - such as am, as, at, an, er, et, ot, og, ug etc</li>
                        <li>Able to identify, read and write CVC words</li>
                        <li>Learn to blend letter sounds (phonics) to pronounce words</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="../../assets/imgs/Skills/Numeracy/3.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Numeracy</h3>
                      <ul class="learning-points">
                        <li>Learn to count & write upto 100</li>
                        <li>Early math concepts – greater than/smaller than, more/less, right/left, big/small,
                          heavy/light, etc</li>
                        <li>Early numeracy concepts</li>
                        <li>Learn and write number names</li>
                        <li>Maths centric games in activity box</li>
                        <li>Work with geometrical shapes</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="../../assets/imgs/Skills/Cognitive/3.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Cognitive</h3>
                      <ul class="learning-points">
                        <li>Child begins to understand the idea of “same” and “different”</li>
                        <li>Begins to understand concept of time and duration</li>
                        <li>Remembers parts of a story and can narrate them</li>
                        <li>Refine fine motor skills and ability to use scissors</li>
                        <li>Develops the ability to plan and prioritise</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="../../assets/imgs/Skills/Socio-emotional/3.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Socio-Emotional</h3>
                      <ul class="learning-points">
                        <li>Child gains enhancement of moral values through storytelling sessions</li>
                        <li>Develops healthy habits by regular exercise and yoga sessions</li>
                        <li>Self-awareness habits through hands on activities</li>
                        <li>Every child is raised as a socially responsible human</li>
                        <li>Social awareness is inculcated in a fun way</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane fade in" id="profile3">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="../../assets/imgs/Skills/Language/2.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Language</h3>
                      <ul class="learning-points">
                        <li>Child learns to spell & write all CVCs 3 letter words</li>
                        <li>Introduction to 4 letter words and consonant</li>
                        <li>Learn to demonstrate adjectives – ‘this’, ‘that’, ‘and’</li>
                        <li>Introduction to prepositions – in , on, under etc.</li>
                        <li>Introduction to singular and plural forms</li>
                        <li>Logically sequencing sentences to form a story and narrate</li>

                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="../../assets/imgs/Skills/Numeracy/4.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Numeracy</h3>
                      <ul class="learning-points">
                        <li>Learn to write number names till 100</li>
                        <li>Ascending/Descending order concepts like small to big, big to small, smallest number etc.
                        </li>
                        <li>Skip counting 2s, 3s, 5s</li>
                        <li>Counting before, after, and in between</li>
                        <li>Maths centric games in activity box</li>
                        <li>Number addition and subtraction</li>

                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="../../assets/imgs/Skills/Cognitive/1.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Cognitive</h3>
                      <ul class="learning-points">
                        <li>Thematic activity box based learning of my home, family, healthy food, trees, plants and
                          flowers etc</li>
                        <li>Hands on learning on animals, birds, gardens, living and non-living things etc</li>
                        <li>Can draw a person with at least 6 body parts</li>
                        <li>Learns to draw shapes like triangle, rectangle & other geometric shapes</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card card-item">
                    <img src="../../assets/imgs/Skills/Socio-emotional/1.png" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3 class="card-title">Socio-Emotional</h3>
                      <ul class="learning-points">
                        <li>Develops empathy through value based stories</li>
                        <li>Establish and maintain positive relationships and friendships</li>
                        <li>Forms a positive self-concept and self-esteem</li>
                        <li>Begins to function independently and starts looking after personal possessions</li>
                        <li>Start making more sense of “who I am” ("Who am I like? Who likes me?)</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          
        </div>
      </div>
    </div>

  </div>
</section> -->

<section class="pie-offerings">
  <div class="container">

    <div class="row">
      <div class="col-12">
        <div id="">
          <h3>Select age group</h3>
          <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
            <li class="nav-item " role="presentation" *ngFor="let age of AgeGroupList;let i =index;">
              <span class="text-ageTitle">{{age.title}}</span>
              <a class="nav-link" id="grade1-tab" [ngClass]="age.id ==IndexId ? 'active':''"
                (click)="getCoursesList(age.id)">
                <img src="{{age.image}}" class="img-fluid age-group-icon" alt="">
                <strong>{{age.age_from}}-{{age.age_to}} Yrs</strong></a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="grade1" role="tabpanel" aria-labelledby="grade1-tab">
              <div class="row">
                <ng-container *ngFor="let agecourses of AgeGroupCoursesList;let i=index">

                  <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                    <div class="card card-item">

                      <div class="card-body">
                        <h3 class="card-title age-title">{{agecourses.course_name}}</h3>
                        <span style="font-size: 15px">{{agecourses.tag}}</span>
                        <div class="decs-pie-data" [innerHtml]="getDescrption(agecourses.description)"
                          style="margin-top: 20px;"> </div>
                        
                        <h4 class="text-center mt-2 mb-4">Rs. {{agecourses.amount}}/- <span
                            style="color:red; ">Rs.<s>{{agecourses.fake_price}}/-</s></span></h4>
                        <h3 class="text-center mt-4 mb-4">Save {{calculateSave(agecourses)}} </h3>
                        <div class="price-box">
                          <ul class="pricing-details">

                            <li><a href="javascript:void(0);" class="text-white" (click)="onBuy(agecourses)">Enroll
                                Now</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="card-border-box"></div>
                    </div>
                  </div>
                  
              </ng-container>
              </div>

            </div>
            </div>

        </div>
      </div>
    </div>

  </div>
</section>

<div class="container-fluid learning-pie-benefits mt-5 py-5">
  <h2 class="text-center">Why LearningPie@HOME?
    <span><img src="../../assets/imgs/logo-pie.png" alt="" class="img-fluid" style="    margin-top: 40px;"></span>
  </h2>
  <p class="text-center pb-3">We are different from others as we are just not raising higher IQ’s , but stronger EQ’s
    and SQ’s too!</p>
  <div class="d-flex flex-row flex-wrap justify-content-center container">
    <div class="card col-12 col-sm-4 col-md-3 col-lg-2 get-card">
      <img src="../../assets/imgs/get-1.png" alt="" class="img-fluid">
      <p class="text-center mt-auto mb-0">Multi-sensory and comprehensive Activity box for high- quality engagement.</p>
    </div>
    <div class="card col-12 col-sm-4 col-md-3 col-lg-2 get-card">
      <img src="../../assets/imgs/get-2.png" alt="" class="img-fluid">
      <p class="text-center mt-auto mb-0">Flexible Recorded sessions held by Early Education Experts</p>
    </div>
    <div class="card col-12 col-sm-4 col-md-3 col-lg-2 get-card">
      <img src="../../assets/imgs/get-3.png" alt="" class="img-fluid">
      <p class="text-center mt-auto mb-0">Dedicated Counsellor To Cater To Your Child’s Unique Needs</p>
    </div>
    <div class="card col-12 col-sm-4 col-md-3 col-lg-2 get-card">
      <img src="../../assets/imgs/get-4.png" alt="" class="img-fluid">
      <p class="text-center mt-auto mb-0">Complete assessment report and certificate for priority admission in formal
        schools.</p>
    </div>
    <div class="card col-12 col-sm-4 col-md-3 col-lg-2 get-card">
      <img src="../../assets/imgs/get-5.png" alt="" class="img-fluid">
      <p class="text-center mt-auto mb-0">Gratitude, Mindfulness, Excursions, Parenting seminars, Robotics and many more
        add ons.</p>
    </div>
  </div>
  <div class="text-center">
    <button class="btn btn-primary btn-book-orange mt-4" type="button" (click)="onOpenPieModal()">ENROL YOUR CHILD
      NOW</button>
  </div>

</div>

<div class="container-fluid learning-pie-benefits py-5" style="background-color: #DCECA0;">
  <h2 class="text-center">What Will Your Child Learn? </h2>
  <p class="text-center">Select Age Group</p>
  <div class="d-flex flex-row flex-wrap justify-content-center container">
    <button class="btn btn-primary {{age.class}} m-2" type="button" *ngFor="let age of AgeGroupList;let i=index"
      (click)="onChangeAgeCourse(age,i)">{{age.age_from}}-{{age.age_to}} years</button>
    <!-- <button class="btn btn-primary btn-book-orange-outline m-2" type="button">2.5-3.5 years</button>
        <button class="btn btn-primary btn-book-orange-outline m-2" type="button">3.5-4.5 years</button>
        <button class="btn btn-primary btn-book-orange-outline m-2" type="button">4.5-6 years</button> -->
  </div>
  <div class="tab-content" id="myTabContent">
    <div role="tabpanel" class="tab-pane fade in active show" id="profile0">
      <div class="d-flex flex-row flex-wrap justify-content-center mt-4">
        <div class="card col-12 col-sm-4 col-md-3 col-lg-3 get-card" *ngFor="let ageData of AgeGroupListData">
          <img src="{{envService.env}}/{{ageData.image}}" alt="" class="img-fluid">
          <h6 class="text-center">{{ageData.title}}</h6>
          <div class="card-body">
            <ul class="learning-points">
              <li>{{ageData.key1}}</li>
              <li>{{ageData.key2}}</li>
              <li>{{ageData.key3}}</li>
              <li>{{ageData.key4}}</li>
              <li>{{ageData.key5}}</li>
            </ul>
          </div>
        </div>
        <!-- <div class="card col-12 col-sm-6 col-md-4 col-lg-3 get-card">
                    <img src="../../assets/imgs/Group38013.png" alt="" class="img-fluid">
                    <h6 class="text-center">Encourage Exploration Into New Subjects</h6>
                    <div class="card-body">
                        <ul class="learning-points">
                            <li>Identify, recognize, and quantify numbers 1-10</li>
                            <li>Sort and sequence using shape, size, colours etc.</li>
                            <li>Ascending and Descending order learning</li>
                            <li>Early counting games in activity box</li>
                            <li>Pre-math concepts such as big/small, one/many, etc</li>
                        </ul>
                    </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 col-lg-3 get-card">
                    <img src="../../assets/imgs/Group38014.png" alt="" class="img-fluid">
                    <h6 class="text-center">Discover A Wide Array Of Themes And Resources</h6>
                    <div class="card-body">
                        <ul class="learning-points">
                            <li>Development of mental skills</li>
                            <li>Engage in pretend and imaginative play</li>
                            <li>Ability to match shapes and sort colors</li>
                            <li>Identify body parts and functions</li>
                            <li>Identify familiar people and everyday objects</li>
                        </ul>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
  <div class="container text-center">
    <button class="btn btn-primary btn-book-orange mt-4" type="button" (click)="onOpenPieModal()">Book a Seat for your
      child</button>
  </div>
</div>

<div class="container-fluid learning-pie-benefits mt-5 py-5">
  <h2 class="text-center">1,25,000+ Parents Trust
      <span><img src="../../assets/imgs/logo-pie.png" alt="" class="img-fluid"  style="    margin-top: 40px;"></span></h2>
  <p class="text-center py-3" style="margin-bottom: 100px;">We are extremely grateful to all the parents for trusting us as their child’s first learning partner!</p>
  
  <div class="container">
    <div class="row justify-content-between">
      <div class="card col-12 col-lg-3 faq-card text-center position-relative" style="background-color: #199de3;">
          <img src="../../../../assets/imgs/img01.png" alt="" class="img-fluid testimonial-img mx-auto faq-img">
          <div class="headline text-center mb-3">
              <h4 class="mb-2 text-white">Nishant Bansal<br>Investment Banker</h4>
              <img src="../../../../assets/imgs/faq-arrow.png" alt="" class="img-fluid">  
          </div>
          
          <div class="d-flex flex-row flex-wrap justify-content-center mb-3">
              <div class="rating-stars">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
              </div>
          </div>
          <p>This pandemic presented itself as a challenge for learning, Suryaveer became very cranky at home.  Learningpie came as a lifesaver, even home is an everyday adventure for him. Thanks to Learningpie for making this happen.</p>
      </div>
      <div class="card col-12 col-lg-3 faq-card text-center position-relative" style="background-color: #EE872E;">
          <img src="../../../../assets/imgs/img02.png" alt="" class="img-fluid testimonial-img mx-auto faq-img">
          <div class="headline text-center mb-3">
              <h4 class="mb-2 text-white">Pooja Mehta<br>Educator</h4>
              <img src="../../../../assets/imgs/faq-arrow.png" alt="" class="img-fluid">  
          </div>
          
          <div class="d-flex flex-row flex-wrap justify-content-center mb-3">
              <div class="rating-stars">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
              </div>
          </div>
          <p>LearningPie Preschool Program was an easy choice. They have included everything. A child could want and a parent would need. Their offerings are vast and comprehensive . Truly, a complete preschool program. Avya cant really take his hands-off the kit.</p>
      </div>
      <div class="card col-12 col-lg-3 faq-card text-center position-relative" style="background-color: #199de3;">
          <img src="../../../../assets/imgs/img03.png" alt="" class="img-fluid testimonial-img mx-auto faq-img">
          <div class="headline text-center mb-3">
              <h4 class="mb-2 text-white">Manish Harodia<br>IIM-K</h4>
              <img src="../../../../assets/imgs/faq-arrow.png" alt="" class="img-fluid">  
          </div>
          
          <div class="d-flex flex-row flex-wrap justify-content-center mb-3">
              <div class="rating-stars">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                  <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
              </div>
          </div>
          <p>During these times, we observed that we need to engage Navya in some or the other way in order to continue her learning process. We came across LearningPie amd it has been an upward spiral of fun since then.</p>
      </div>
  </div>
  </div>
  
</div>

<section class="">
  <div class="container">
    <div class="jumbo-card align-items-center">
      <div class="row align-items-center p-4">
        <div class="col-lg-6 col-md-4 col-sm-12 img-align-auto" style="margin: 0 auto;">
          <h4>Have Questions?</h4>
          <h6 style="color: white;">Please enter your contact details, our Early Childhood Educator will get in touch with you!</h6>
        </div>
        <form class="p-5 text-center">
          <div class="form-group">
            <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Enter Phone No." [(ngModel)]="mobileNo" [ngModelOptions]="{standalone: true}" appNumbersOnly maxlength="10" style="    border-radius: 15px;">
          </div>
          <button class="btn btn-primary btn-book-white mt-1" type="button" (click)="onSubmitQuery()" [disabled]="!(mobileNo.length >=10)">Submit</button>
        </form>
      </div>
    </div>
  </div>

</section>

<section class="">
  <div class="container">
    <div class="jumbo-card align-items-center">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-4 col-sm-12 img-align-auto">
          <img src="../../assets/imgs/jumbo-thumbnail.png" class="img-fluid" alt="">
        </div>
        <div class="col-lg-9  col-md-8 col-sm-12">
          <div id="carouselExampleCaptions1" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleCaptions1" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleCaptions1" data-slide-to="1"></li>
              <li data-target="#carouselExampleCaptions1" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner doyouknow">
              <h4 class="doYouKnowText">Do You Know ?</h4>
              <div class="carousel-item active">
                <div class="d-block w-100">

                  <p class="doyouKnowSlideText">NASA Study says that, <span>"Kids peak in creativity by the age of 6,
                      followed by a gradual decline as they grow up"</span></p>
                </div>

              </div>
              <div class="carousel-item">
                <div class="d-block w-100">
                  <!-- <h4>Do You Know ?</h4> -->
                  <p class="doyouKnowSlideText">As they says,&nbsp;&nbsp;<span>"An aware parent is a better parent than
                      an educated parent"</span> &nbsp;&nbsp;&nbsp;&nbsp;- Anonymous
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                </div>

              </div>

              <div class="carousel-item">
                <div class="d-block w-100">
                  <!-- <h4>Do You Know ?</h4> -->
                  <p class="doyouKnowSlideText">"Education is not the filling of a pail, but the lighting of a fire. And
                    lighting that fire ersly is a huge leg-up for any child.”- <span>Jeff Bezos</span></p>
                </div>

              </div>

            </div>
            <!-- <a class="carousel-control-prev" href="#carouselExampleCaptions1" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleCaptions1" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a> -->
            <!-- <button type="button" class="btn btn-book-class" data-toggle="modal" data-target="#bookFreeClassModal"
              (click)="bookSavenDay()">Book your Trial</button> -->

              <button type="button" class="btn btn-book-class" data-toggle="modal" data-target="#bookFreeClassModal"
              (click)="onOpenPieModal();">Book your Trial</button>

          </div>
        </div>
      </div>

      <!-- <div class="col">
        <h4>Do You Know ?</h4>
        <p style="font-size: 32px;">NASA Study says that, <span style="ffont-size: 32px;font-style: italic;">"Kids peak in creativity by the age of 6, followed by a gradual decline as they grow up"</span></p>
        <button type="button" class="btn btn-book-class" data-toggle="modal" data-target="#bookFreeClassModal">Book a free class</button>

      </div> -->
    </div>
  </div>

</section>
<section class="pie-offerings">
  <div class="container">
    <h2 class="mb-3">Our Methodology</h2>
  </div>
  <img src="assets/imgs/methodology-01.jpg" class="w-100" alt="">
</section>

<section class="learning-process">
  <div class="container text-center section-title">
    <h2>How it Works</h2>

  </div>
  <video src="../../assets/videos/gif.mp4" autoplay muted class="w-100" loop></video>
</section>

<section class="expert-educators" id="educators">
  <div class="">
    <div class="section-title">
      <h2 class="mb-0">
        Our Partner Schools
      </h2>
      <p>We are in collaboration with more than 1000 premium schools of India</p>
    </div>
    <div class="row text-center p-0">

      <div class="col-12" style="background: #fff">
        <div class="row justify-content-md-center">
          <div class="col col-md-8">
            <img src="assets/imgs/schoolspartner.png" alt="" srcset="" class="img-fluid">
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
<section class="pie-testimonials">
  <div class="container">
    <h2 class="text-center">Students and parents love️ us!</h2>
    <div class="col">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let testimonial of TestimonialsList;let i= index">
          <div class="testimonial-item">
            <img src="../../assets/imgs/testimonial-frame-quat-green.png" class="testimonial-quat" alt="">
            <!-- <img src="../../assets/imgs/testimonial-icon-1.png" class="testimo-icon-img" alt=""> -->
            <img class="img-fluid" src="{{this._employeeService.imageBase}}{{testimonial.image}}"
              onError="this.src='assets/imgs/testimonial-icon-1.png'" class="testimo-icon-img">

            <p class="description">{{testimonial.message}}</p>
            <h5>{{testimonial.student_name}}</h5>
            <p class="details">Age {{testimonial.age}}, Class {{testimonial.student_class}}</p>
          </div>
        </ng-template>
        <!-- <ng-template carouselSlide>

      <div class="testimonial-item">
        <img src="../../assets/imgs/testimonial-frame-quat-pink.png" class="testimonial-quat" alt="">
        <img src="../../assets/imgs/testimonial-icon-2.png" class="testimo-icon-img" alt="">
        <p class="description">The activity kit is the best part, makes time fly by, productive screen-time and add-ons are only a massive plus point. There are endless activities.</p>
        <h5>Nandu Gopal Reddy</h5>
        <p class="details">Age 13, Class 7</p>
      </div>
    </ng-template> -->
        <!-- <ng-template carouselSlide>

      <div class="testimonial-item">
        <img src="../../assets/imgs/testimonial-frame-quat-green.png" class="testimonial-quat" alt="">
        <img src="../../assets/imgs/testimonial-icon-1.png" class="testimo-icon-img" alt="">
        <p class="description">LearningPie has evolved our teaching patterns and their standardized curriculum along with kits is easy to implement and the outcome is much more overwhelming, children love it.</p>
        <h5>Nandu Gopal Reddy</h5>
        <p class="details">Age 13, Class 7</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>

      <div class="testimonial-item">
        <img src="../../assets/imgs/testimonial-frame-quat-pink.png" class="testimonial-quat" alt="">
        <img src="../../assets/imgs/testimonial-icon-2.png" class="testimo-icon-img" alt="">
        <p class="description">My child has never been this excited everyday for doing anything, this is like the highlight of the day for her. Parenting seminars are literally full of golden nuggets.</p>
        <h5>Nandu Gopal Reddy</h5>
        <p class="details">Age 13, Class 7</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>

      <div class="testimonial-item">
        <img src="../../assets/imgs/testimonial-frame-quat-green.png" class="testimonial-quat" alt="">
        <img src="../../assets/imgs/testimonial-icon-1.png" class="testimo-icon-img" alt="">
        <p class="description">Making him learn was an everyday challenge, now he waits for the challenge everyday.</p>
        <h5>Nandu Gopal Reddy</h5>
        <p class="details">Age 13, Class 7</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>

      <div class="testimonial-item">
        <img src="../../assets/imgs/testimonial-frame-quat-pink.png" class="testimonial-quat" alt="">
        <img src="../../assets/imgs/testimonial-icon-2.png" class="testimo-icon-img" alt="">
        <p class="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo officiis modi vero tenetur esse quibusdam laboriosam, minus quod, tempora quasi corrupti doloribus eveniet a voluptates. Nulla alias soluta voluptatibus labore!</p>
        <h5>Nandu Gopal Reddy</h5>
        <p class="details">Age 13, Class 7</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>

      <div class="testimonial-item">
        <img src="../../assets/imgs/testimonial-frame-quat-green.png" class="testimonial-quat" alt="">
        <img src="../../assets/imgs/testimonial-icon-1.png" class="testimo-icon-img" alt="">
        <p class="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo officiis modi vero tenetur esse quibusdam laboriosam, minus quod, tempora quasi corrupti doloribus eveniet a voluptates. Nulla alias soluta voluptatibus labore!</p>
        <h5>Nandu Gopal Reddy</h5>
        <p class="details">Age 13, Class 7</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>

      <div class="testimonial-item">
        <img src="../../assets/imgs/testimonial-frame-quat-pink.png" class="testimonial-quat" alt="">
        <img src="../../assets/imgs/testimonial-icon-2.png" class="testimo-icon-img" alt="">
        <p class="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo officiis modi vero tenetur esse quibusdam laboriosam, minus quod, tempora quasi corrupti doloribus eveniet a voluptates. Nulla alias soluta voluptatibus labore!</p>
        <h5>Nandu Gopal Reddy</h5>
        <p class="details">Age 13, Class 7</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>

      <div class="testimonial-item">
        <img src="../../assets/imgs/testimonial-frame-quat-green.png" class="testimonial-quat" alt="">
        <img src="../../assets/imgs/testimonial-icon-1.png" class="testimo-icon-img" alt="">
        <p class="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo officiis modi vero tenetur esse quibusdam laboriosam, minus quod, tempora quasi corrupti doloribus eveniet a voluptates. Nulla alias soluta voluptatibus labore!</p>
        <h5>Nandu Gopal Reddy</h5>
        <p class="details">Age 13, Class 7</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>

      <div class="testimonial-item">
        <img src="../../assets/imgs/testimonial-frame-quat-pink.png" class="testimonial-quat" alt="">
        <img src="../../assets/imgs/testimonial-icon-2.png" class="testimo-icon-img" alt="">
        <p class="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo officiis modi vero tenetur esse quibusdam laboriosam, minus quod, tempora quasi corrupti doloribus eveniet a voluptates. Nulla alias soluta voluptatibus labore!</p>
        <h5>Nandu Gopal Reddy</h5>
        <p class="details">Age 13, Class 7</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>

      <div class="testimonial-item">
        <img src="../../assets/imgs/testimonial-frame-quat-green.png" class="testimonial-quat" alt="">
        <img src="../../assets/imgs/testimonial-icon-1.png" class="testimo-icon-img" alt="">
        <p class="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo officiis modi vero tenetur esse quibusdam laboriosam, minus quod, tempora quasi corrupti doloribus eveniet a voluptates. Nulla alias soluta voluptatibus labore!</p>
        <h5>Nandu Gopal Reddy</h5>
        <p class="details">Age 13, Class 7</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>

      <div class="testimonial-item">
        <img src="../../assets/imgs/testimonial-frame-quat-pink.png" class="testimonial-quat" alt="">
        <img src="../../assets/imgs/testimonial-icon-2.png" class="testimo-icon-img" alt="">
        <p class="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo officiis modi vero tenetur esse quibusdam laboriosam, minus quod, tempora quasi corrupti doloribus eveniet a voluptates. Nulla alias soluta voluptatibus labore!</p>
        <h5>Nandu Gopal Reddy</h5>
        <p class="details">Age 13, Class 7</p>
      </div>
    </ng-template> -->
      </owl-carousel-o>
    </div>
  </div>
</section>
<!-- <section class="testimonials">
  <div class="container">
    <h3 class="text-center text-white">Students and parents love️ us!</h3>
    <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="carousel-caption d-md-block">
            <div class="row align-items-center">
              <div class="col-md-3"><img src="../../assets/imgs/Ellipse 2.png" class="img-fluid" alt=""></div>
              <div class="col-md-9 text-left">
                <div style="text-align:center">
                  <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                </div>
                <p class="desription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et metus a turpis auctor fermentum id at mauris. Quisque mollis ex nunc, vitae gravida lacus ullamcorp</p>
                <span class="date">15 Sep 2019</span>
                <h6 class="name">Nandu Gopal Reddy</h6>
                <span class="age">Age 13, Class 7</span>
              </div>
            </div>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="carousel-caption d-md-block">
            <div class="row align-items-center">
              <div class="col-md-3"><img src="../../assets/imgs/Ellipse 2.png" class="img-fluid" alt=""></div>
              <div class="col-md-9 text-left">
                <div style="text-align:center">
                  <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                </div>
                <p class="desription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et metus a turpis auctor fermentum id at mauris. Quisque mollis ex nunc, vitae gravida lacus ullamcorp</p>
                <span class="date">15 Sep 2019</span>
                <h6 class="name">Nandu Gopal Reddy</h6>
                <span class="age">Age 13, Class 7</span>
              </div>
            </div>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="carousel-caption d-md-block">
            <div class="row align-items-center">
              <div class="col-md-3"><img src="../../assets/imgs/Ellipse 2.png" class="img-fluid" alt=""></div>
              <div class="col-md-9 text-left">
                <div style="text-align:center">
                  <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                </div>
                <p class="desription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et metus a turpis auctor fermentum id at mauris. Quisque mollis ex nunc, vitae gravida lacus ullamcorp</p>
                <span class="date">15 Sep 2019</span>
                <h6 class="name">Nandu Gopal Reddy</h6>
                <span class="age">Age 13, Class 7</span>
              </div>
            </div>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div class="container testimonials-bottom">
      <div class="row">
        <div class="col-md-3 text-center">
          <img src="../../assets/imgs/people-circle.svg" class="img-fluid" alt="">
          <span class="nums">
            75K +
          </span>
          <p class="mb-0">Students</p>
        </div>
        <div class="col-md-3 text-center">
          <img src="../../assets/imgs/star.svg" class="img-fluid" alt="">
          <span class="nums">
            4.7 +
          </span>
          <p class="mb-0">Stars</p>
        </div>
        <div class="col-md-3 text-center">
          <img src="../../assets/imgs/city-alt.svg" class="img-fluid" alt="">
          <span class="nums">
            170 +
          </span>
          <p class="mb-0">Cities</p>
        </div>
        <div class="col-md-3 text-center">
          <img src="../../assets/imgs/time.svg" class="img-fluid" alt="">
          <span class="nums">
            70 Mins
          </span>
          <p class="mb-0">Daily Time Spent</p>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="faq">
  <div class="container">
    <div class="section-title">
      <h2 class="">Frequently asked questions</h2>
    </div>
    <div class="accordion" id="accordionExample">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h3 class="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
            aria-controls="collapseOne">
            What is LearningPie@HOME?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>

        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
          <div class="card-body">
            LearningPie@Home is an online tech enabled experiential learning program designed by experts across the
            globe. It is a combined effort of educators, child psychologists, parenting experts and innovators to bring
            your child an all-round learning experience which aims at making conceptual learning, a child’s play and
            part of their lifelong learning process.
            The program is a combination of online instructor led classes combined with Do-It-Yourself Activity box. You
            will receive an all-inclusive learning kit, packed with activities, rhyme books, storybooks, worksheets and
            much more, providing holistic learning at the comfort of your own home.


          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
            aria-controls="collapseTwo">
            Why LearningPie is doing this?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
          <div class="card-body">
            Pre-schooling or early education in India is a highly fragmented category. Non adherence to a standard
            education curriculum, or lack of training to the teachers, or lack of the right tools and techniques, or the
            absence of the right environment, makes early learning a highly inefficient process today.
            <br>
            LearningPie is conceptualized with a solitary objective of helping young children develop a sense of ‘how to
            learn’ through a very carefully designed curriculum, which borrows the best capabilities of some of the
            world’s most popular curricula- viz. Montessori, Play Way, Bank Street, Waldorf, STEAM etc. <br>
            We want to democratize quality education for children across India by providing them well designed;
            scientifically curated curriculum and state of the art experience to the remotest part of India- Geography
            is History!!

          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
            aria-controls="collapseThree">
            Who has designed the Curriculum?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
          <div class="card-body">
            We continuously engage with leading educators from across the globe to continuously evolve the curriculum
            and the pedagogy so that our children always stay ahead of the curve.
            <br>
            LearningPie@HOME is designed by pedagogy experts, child development professionals, early learning experts,
            psychologists and game designers at our R&D Centre. We assure that all the materials you receive are tested,
            child-friendly, age-specific, safe and best-in-class quality!

          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header" id="heading4">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false"
            aria-controls="collapse4">
            What all will include in my subscription plan?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionExample">
          <div class="card-body">
            In your subscription plan, you will get access to 36 Live Classes, 24 Bonus sessions and an all inclusive
            activity box having playful & sensory kits, craft material, worksheets etc delivered at your doorstep on
            quarterly basis. Your child will also attend special classes on Mindfulness & Gratitude, Augmented
            Reality/360 Excursions and you will get access to counseling sessions from our Early Education & Parenting
            Experts.


          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header" id="heading5">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false"
            aria-controls="collapse5">
            What do I get in a kit?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionExample">
          <div class="card-body">
            You will receive an all-inclusive learning kit, packed with activities, rhyme books, storybooks, worksheets,
            craft material, STEM activities, playful & sensory kits, stationary and much more, providing holistic
            learning at the comfort of your own home. The kits are meticulously planned, in sync with the class
            curriculum, to develop finer skills and the achievement of tasks completion independently/in a guided
            manner.


          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading6">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false"
            aria-controls="collapse6">
            Screen time?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordionExample">
          <div class="card-body">
            While increasing screen time in children has always been a cause of concern, the pandemic has certainly
            added to the woes and multiplied the time kids spend in front of the screens.
            Screens have become a way of life for kids. If the screen can’t be avoided, why not turn it into a playful
            and learning time?
            <br>
            With a lot of role plays, and child centric activities, the two way communication and engagement ensures
            that the child uses his/her time with the device in, an otherwise, productive manner.
            <br>
            We have kept our daily sessions for less than an hour, and here the device (Laptop/pads) acts like a
            Television for kids kept at a distance with more focus on audio rather than visuals.

          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading7">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false"
            aria-controls="collapse7">
            Is parental involvement required?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordionExample">
          <div class="card-body">
            Yes and No. We encourage DIY for most of the activities with an arm distance supervision. However, there are
            certain activities, which may include an additional support for the children.
            But we would love and advice for an active participation by either parent, as research suggests that a
            kindergartner learns more when he/she receives directions from parent, this also helps increase parent –
            child bonding.
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading8">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false"
            aria-controls="collapse8">
            What age should we start formal learning for our child and if we start education at age 2.5, should we
            choose starting age 1.5-2.5 course or 2.5-3.5 course?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse8" class="collapse" aria-labelledby="heading8" data-parent="#accordionExample">
          <div class="card-body">
            Children are a source of almost unlimited energy. If all of it is channelized into a direction, it results
            in massive learning and simulation for the child. Starting early gives an edge to your child as NASA study
            identified that kid’s peak in creativity at age 6, followed by a gradual decline as they grow up. So, you
            should start playful yet simulating learning for your child as early as possible.
            <br>
            We have developed the age and mental capabilities wise curriculum - 1.5-2.5 years, 2.5-3.5 years, 3.5
            years-4.5 years and 4.5-6 years. Each age group requires unique ways of imparting the same skills. So, you
            should select the curricula as per current age of your child, irrespective of starting age of formal
            education for your child.





          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading9">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false"
            aria-controls="collapse9">
            What all do you cover in your curriculum?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse9" class="collapse" aria-labelledby="heading9" data-parent="#accordionExample">
          <div class="card-body">
            We believe in holistic development of a child and has stressed equally on developing Language, Numeracy,
            Cognitive and Socio-Emotional skills.
            Writing, Speaking, Cognitive and all other skills are broken down piecemally through engaging curriculum.
            All concepts are covered in an activity-based format along with animations, rhymes, stories, interactive
            quizzes, etc. The immersion of technology at the right time also facilitates course correction and
            customization, if any, needed for any child.


          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading10">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse10" aria-expanded="false"
            aria-controls="collapse10">
            What are the add-ons that you are providing?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse10" class="collapse" aria-labelledby="heading10" data-parent="#accordionExample">
          <div class="card-body">
            Apart from the thrice a week, online classroom learning, we have included 2 Bonus sessions per week, wherein
            you can give task or engage your little munchkin in various DIY activities. Completing a task gives immense
            sense of satisfaction, all the while building and honing upon skills like reasoning, cognition, familiarity
            etc. <br>
            In addition to Rhymes, Stories, etc, we have included monthly thematic Excursions to virtual Zoos, Fairs,
            Farms, etc to increase awareness in the child. Parents will also get access to live parenting/counseling
            sessions.


          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading11">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse11" aria-expanded="false"
            aria-controls="collapse11">
            How many demos will you provide?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse11" class="collapse" aria-labelledby="heading11" data-parent="#accordionExample">
          <div class="card-body">
            On registration, you will get access to our demo portal, where in you can access the sample recorded
            classroom sessions, animated rhymes & stories, DIY activities videos, virtual zoo animation, a description
            about our Activity kit, and a small video explaining about our teaching pedagogy etc. Our early child
            educator will get in touch with you and help you with questions you may have.

          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading12">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse12" aria-expanded="false"
            aria-controls="collapse12">
            If my child miss any session, will you provide extra class?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse12" class="collapse" aria-labelledby="heading12" data-parent="#accordionExample">
          <div class="card-body">
            We have created an experiential learning program and also recorded them using high quality audio and video
            devices. If your child miss attending any session, the child can attend the recorded session of the same.
            Now you can travel guilt free!


          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading13">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse13" aria-expanded="false"
            aria-controls="collapse13">
            Why should we trust you for our child's education?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse13" class="collapse" aria-labelledby="heading13" data-parent="#accordionExample">
          <div class="card-body">
            We have over 6 years of experience in teaching all these courses in schools to children. With over 1000
            schools spread across India, and having physically channeled interventions for about a million kids, we
            bring the best of the acquired knowledge to carefully designed curriculum.


          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading14">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse14" aria-expanded="false"
            aria-controls="collapse14">
            Does my child get admission into schools after completing your programs?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse14" class="collapse" aria-labelledby="heading14" data-parent="#accordionExample">
          <div class="card-body">
            Our Early learning program is an absolute equivalent of the traditional Nursery and Kindergarten concept in
            India. Similar to graduating from Pre-Schools, our courses are recognized across India and a student will
            find it easy to get into a school of their choice.
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading15">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse15" aria-expanded="false"
            aria-controls="collapse15">
            What is your refund policy?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse15" class="collapse" aria-labelledby="heading15" data-parent="#accordionExample">
          <div class="card-body">
            Though unsatisfied student is an absolute rarity with us, in the unlikely situation of your child/you not
            being satisfied with our program, we do facilitate a refund. Once cancelled, the refund amount for the
            unshipped terms will be credited to your account -- to the same mode of payment chosen during enrollment, in
            5-7 business days.
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading16">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse16" aria-expanded="false"
            aria-controls="collapse16">
            I already have a preschool, do you have an offering that I can use?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse16" class="collapse" aria-labelledby="heading16" data-parent="#accordionExample">
          <div class="card-body">
            We have created an omnichannel mode of training module for the thousands of entrepreneurs who have reached
            out to us during the last year. Your physical infrastructure can be blended with our curriculum, where you
            focus on the student’s learning, while we take care of everything else.
            <br>
            The teachers are also trained on how to effectively manage the curriculum and students at their respective
            physical pre-schools and education centres.
            <br>
            We offer a curated curriculum with DIY kits. You get access to the same engaging content available on our
            website.
            <br>
            During this COVID period, with preschools being shut for physical classes, our offering enables you to run
            classes and enable parents to effectively manage their child’s learning with regular counseling sessions and
            assessments.
            <br>
            For more details, Contact us at contact@learningbix.com
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-blog></app-blog>

<app-pie-footer></app-pie-footer>




<div class="get-in-touch-mobile-button right" (click)="getIntouch()">
  <span>Get In Touch</span>
</div>
<div id="overlay" (click)="onCloseModalTouchModal()"></div>
<div class="modal fade" id="getInTouch" tabindex="-1" aria-labelledby="getInTouch" aria-hidden="true"
  data-backdrop="false">

  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header border-0">
        <!-- <h5 class="modal-title" id="bookFreeClassModalLabel">Modal title</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseModalTouchModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0 pb-5 book-class-modal">
        <div class="row align-items-center">

          <div class="book-demo-form toggle-form-show">
            <div class="card form_cardHome pie-card">
              <h2 class="my-0">Get In Touch</h2>
              <form [formGroup]="AddForm" class="custome-form">
                <div class="input-group my-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-sname" [ngClass]="{ 'is-invalid': submitted && f.parents_name.errors }"><i
                        class="fas fa-user"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Enter Parent Name" required aria-label="Username"
                    aria-describedby="basic-sname" [ngClass]="{ 'is-invalid': submitted && f.parents_name.errors }"
                    formControlName="parents_name">
        
                </div>
                <div class="input-group my-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-sname" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"><i
                        class="fas fa-user"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Enter Child Name" required aria-label="Username"
                    aria-describedby="basic-sname" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                    formControlName="name">
        
                </div>
                <!-- <div *ngIf="submitted && f.name.errors">
                      <small *ngIf="f.name.errors.required" class="error_class">
                        Full Name is required
                      </small>
                    </div> -->
                <div class="input-group my-3">
                  <select class="custome-contory-code" id="exampleFormControlSelect1">
                    <option>91</option>
                    <!-- <option>92</option>
                        <option>93</option>
                        <option>94</option>
                        <option>95</option> -->
                  </select>
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-mobile"><i class="fas fa-mobile-alt"></i></span>
                  </div>
                  <input type="text" required class="form-control mobile" maxlength="10" appNumbersOnly
                    placeholder="Contact No" aria-label="Username" aria-describedby="basic-mobile"
                    [ngClass]="{ 'is-invalid': submitted && f.phone_no.errors }" formControlName="phone_no">

                </div>
                <!-- <div *ngIf="submitted && f.phone_no.errors">
                      <small *ngIf="f.phone_no.errors.required" class="error_class">
                        Contact no. is required
                      </small>
                      <small [hidden]="!f.phone_no.errors.patternMobileInvalid" class="error_class">Phone Number should be valid
                        and 10 digit.</small>
                    </div> -->
                <div class="input-group my-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-email"><i class="fas fa-envelope"></i></span>
                  </div>
                  <input type="email" class="form-control" placeholder="Email" required
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email"
                    aria-label="Useremail" aria-describedby="basic-email">

                </div>
                <!-- <div *ngIf="submitted && f.email.errors">
                      <small *ngIf="f.email.errors.required" class="error_class">
                        Email is required
                      </small>
                      <small [hidden]="!f.email.errors.patternInvalid" class="error_class">Please enter valid email.</small>
                    </div> -->

                <section class="my-3 text-center">
                  <h6 class="my-1 text-left">Select Age Group (yrs) <span *ngIf="ageRequired"
                      style="color:red;">*(Required)</span></h6>

                  <ng-container *ngFor="let item of preSchoolAgeGroup; let ind= index" class="my-3">

                    <button type="button" class="custome-selectBtn my-3  {{seletedAgeGroup==item.id?'active-btn':''}}"
                      (click)="onSelectAgeGroup(item)">{{item.age_from}}-{{item.age_to}}</button>
                  </ng-container>


                  <div class="text-center">
                    <!-- <button type="submit" (click)="onSubmit()" [disabled]="btnsubmitted"
                      class="btn btn-form-submit">Book your Trial</button> -->
                  </div>
                  <div class="row my-2">
                    <span class="text-terms">By registering here, you agree to our <a href="/page/terms"
                        target="_blank">Terms of use </a> and <a href="/page/privacy-policy" target="_blank">Privacy
                        Policy</a>
                    </span>
                  </div>
                </section>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>