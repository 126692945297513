<app-header></app-header>

<!-- Main Banner -->
<section class="main-banner py-5">
    <div class="container text-center" style="padding-top: 260px;">
        <h1 class="text-white font-weight-bold my-4" style="font-size: 42px;">LearningBIX Innovation Space (LBIS)</h1>
        <h3 class="text-white font-weight-bold mb-4">One-Stop Solution For Setting Up The Perfect Lab</h3>
        <button class="btn btn-lg btn-primary font-weight-bold" (click)="scroll(sendFeedBackForms)">Book a Demo</button>
    </div>
</section>

<!-- Section Four Wrapper -->
<div class="container py-5">
    <div class="section-header text-center my-5">
        <h2 class="font-weight-bold mb-5" style="font-size: 35px;">What is LearningBIX Innovation Space (LBIS)</h2>
        <div class="p-3 rounded border-blue-2 mb-5">
            <p class="font-weight-normal mb-0 text-center" style="font-size: 23px;"><strong>LBIS</strong> is a work
                space where young minds can give shape to their ideas through hands
                on do-it-yourself mode; and learn innovation skills. It's a space where teachers and
                students can learn and apply <strong>21<sup>st</sup> century skills</strong>. <br><br>
                At LBIS, Students get a chance to work with latest technological tools &amp; equipments for
                understanding of STEM-related topics (Science, Technology, Engineering &amp; Mathematics). LBIS
                consists of Educational DIY Kits &amp; equipments related to :</p>
            <div class="d-flex flex-wrap justify-content-between mt-3">
                <div class="col-sm-3 col-4 text-center mb-4">
                    <div class="icon-img mb-2">
                        <img src="assets\imgs\artificial-intelligence.png" class="w-25">
                    </div>
                    <h6 class="font-weight-bold">Artificial Intelligence</h6>
                </div>
                <div class="col-sm-3 col-4 text-center mb-4">
                    <div class="icon-img mb-2">
                        <img src="assets\imgs\machine-learning.png" class="w-25">
                    </div>
                    <h6 class="font-weight-bold">Machine Learning</h6>
                </div>
                <div class="col-sm-3 col-4 text-center mb-4">
                    <div class="icon-img mb-2">
                        <img src="assets\imgs\electronics.png" class="w-25">
                    </div>
                    <h6 class="font-weight-bold">Basic &amp; Advanced Electronics</h6>
                </div>
                <div class="col-sm-3 col-4 text-center mb-4">
                    <div class="icon-img mb-2">
                        <img src="assets\imgs\automations.png" class="w-25">
                    </div>
                    <h6 class="font-weight-bold">Automations</h6>
                </div>
                <div class="col-sm-3 col-4 text-center mb-4">
                    <div class="icon-img mb-2">
                        <img src="assets\imgs\3d-printing.png" class="w-25">
                    </div>
                    <h6 class="font-weight-bold">3D Printing</h6>
                </div>
                <div class="col-sm-3 col-4 text-center mb-4">
                    <div class="icon-img mb-2">
                        <img src="assets\imgs\drone.png" class="w-25">
                    </div>
                    <h6 class="font-weight-bold">Drone Tech</h6>
                </div>
                <div class="col-sm-3 col-4 text-center mb-4">
                    <div class="icon-img mb-2">
                        <img src="assets\imgs\internet-of-things.png" class="w-25">
                    </div>
                    <h6 class="font-weight-bold">Internet of Things</h6>
                </div>
                <div class="col-sm-3 col-4 text-center mb-4">
                    <div class="icon-img mb-2">
                        <img src="assets\imgs\stem.png" class="w-25">
                    </div>
                    <h6 class="font-weight-bold">STEAM</h6>
                </div>
            </div>
        </div>
        <section class="experience rounded" id="experience">
            <div class="section-header text-center mb-4">
                <h2 class="font-weight-bold text-white" style="font-size: 35px;">The LearningBix Experience</h2>
            </div>
            <div class="row align-items-center px-4">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="card mb-3 pl-3 pr-3 mb-3">
                        <div class="row no-gutters justify-content-center">
                            <div class="col-md-4 col-sm-12 card-img-box"> <img
                                    src="assets/imgs/experience/Facilitate.jpg" class="card-img circular--landscape"
                                    alt="..."> </div>
                            <div class="col-md-8 card-experience-box">
                                <div class="card-body">
                                    <h5 class="card-title">Facilitate</h5>
                                    <p class="card-text">Children have a higher learning curve than adults and they
                                        learn futuristic technologies much faster.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 pl-3 pr-3 mb-3">
                        <div class="row no-gutters justify-content-center">
                            <div class="col-md-4 col-sm-12 card-img-box"> <img src="assets/imgs/experience/logical.jpg"
                                    class="card-img circular--landscape" alt="..."> </div>
                            <div class="col-md-8 card-experience-box">
                                <div class="card-body">
                                    <h5 class="card-title">Logic-ify</h5>
                                    <p class="card-text">Help your students form logic patterns and engage them in
                                        technology which boosts their reasoning abilities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 pl-3 pr-3 mb-3">
                        <div class="row no-gutters justify-content-center">
                            <div class="col-md-4 col-sm-12 card-img-box"> <img
                                    src="assets/imgs/experience/creativity.jpg" class="card-img circular--landscape"
                                    alt="..."> </div>
                            <div class="col-md-8 card-experience-box">
                                <div class="card-body">
                                    <h5 class="card-title">Creativity</h5>
                                    <p class="card-text">Flexible & customized curriculum to boost imagination &
                                        critical thinking to create meaningful form of ideas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 text-center"> <img src="assets/imgs/Group-9169.png"
                        class="img-fluid" alt=""> </div>
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="card mb-3 pl-3 pr-3 mb-3">
                        <div class="row no-gutters justify-content-center">
                            <div class="col-md-4 col-sm-12 card-img-box"> <img
                                    src="assets/imgs/experience/gamification.jpg" class="card-img circular--landscape"
                                    alt="..."> </div>
                            <div class="col-md-8 card-experience-box">
                                <div class="card-body">
                                    <h5 class="card-title">Gamify</h5>
                                    <p class="card-text">The learning and courses at LearningBIX are gamified to leave
                                        subtle impressions that last a lifetime.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 pl-3 pr-3 mb-3">
                        <div class="row no-gutters justify-content-center">
                            <div class="col-md-4 col-sm-12 card-img-box"> <img
                                    src="assets/imgs/experience/extrapolate.jpg" class="card-img circular--landscape"
                                    alt="..."> </div>
                            <div class="col-md-8 card-experience-box">
                                <div class="card-body">
                                    <h5 class="card-title">Extrapolate</h5>
                                    <p class="card-text">Students start to code, design models, and form circuits
                                        boosting their ability to predict patterns and form scientific extrapolation.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 pl-3 pr-3 mb-3">
                        <div class="row no-gutters justify-content-center">
                            <div class="col-md-4 col-sm-12 card-img-box"> <img
                                    src="assets/imgs/experience/problem_solving.jpg"
                                    class="card-img circular--landscape" alt="..."> </div>
                            <div class="col-md-8 card-experience-box">
                                <div class="card-body">
                                    <h5 class="card-title">Problem Solving</h5>
                                    <p class="card-text">100% DIY. Applying innovative ideas to reach goal brings a
                                        sense of achievement & boost the confidence.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

    <div class="section-header text-center mb-5">
        <h2 class="font-weight-bold" style="font-size: 35px;">Our Software Technology Platforms</h2>
    </div>

    <div class="d-flex flex-wrap align-items-center justify-content-between">
        <div class="col-md-6 col-12">
            <img src="assets\imgs\software-1.png" alt="" class="img-fluid">
        </div>
        <div class="col-md-6 col-12">
            <h2 class="font-weight-bold" style="font-size: 35px;">
                LearningBix Play App</h2>
            <ul class="ml-4">
                <li>Students can instantly learn to
                    operate Robots & Machines without any coding.</li>
                <li>Scripts/Block based
                    Coding Curriculum</li>
            </ul>
        </div>
    </div>

    <div class="row mx-0 align-items-center mb-5 flex-row-reverse justify-content-between">
        <div class="col-md-6 col-12">
            <img src="assets\imgs\software-2.png" alt="" class="img-fluid">
        </div>
        <div class="col-md-6 col-12">
            <h2 class="font-weight-bold" style="font-size: 35px;">School Panel</h2>
            <ul class="ml-4">
                <li>School can assign
                    Coding/Robotics courses to
                    students - Personalised
                    Learning.</li>
                <li>Track individual teachers' & students'
                    progress</li>
                <li>Generate Progression Reports & Certificates</li>
                <li>Learning & Developement
                    Resources for Teachers</li>
            </ul>
        </div>
    </div>

    <div class="row mx-0 align-items-center mb-5 justify-content-between">
        <div class="col-md-6 col-12">
            <img src="assets\imgs\software-3.png" alt="" class="img-fluid">
        </div>
        <div class="col-md-6 col-12">
            <h2 class="font-weight-bold" style="font-size: 35px;">LearningBIX App</h2>
            <ul class="ml-4">
                <li>Students can access the
                    assigned courses via
                    Android App.</li>
                <li>Weekly Live Classes by
                    Experts to resolve doubts/
                    queries</li>
                <li>Multi-Lingual Video
                    Tutorials</li>
                <li>Puzzles, Quizzes and Real
                    Life Projects</li>
                <li>Community Interaction</li>
            </ul>
        </div>
    </div>

    <div class="row mx-0 align-items-center mb-5 flex-row-reverse justify-content-between">
        <div class="col-md-6 col-12">
            <img src="assets\imgs\software-4.png" alt="" class="img-fluid">
        </div>
        <div class="col-md-6 col-12">
            <h2 class="font-weight-bold" style="font-size: 35px;">LearningBIX Web Portal</h2>
            <ul class="ml-4">
                <li>Students can access the
                    assigned courses via
                    Laptop/Computer/Tab.</li>
                <li>Puzzles, Quizzes and Real
                    Life Projects</li>
                <li>Monthly performance
                    Reports</li>
                <li>Certificates</li>
            </ul>
        </div>
    </div>
</div>

<!-- What students Learn -->
<div class="container-fluid bg-light py-5">
    <div class="container">
        <div class="section-header text-center mb-5">
            <h2 class="font-weight-bold" style="font-size: 35px;">Why Teach With LBIS?</h2>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-1.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">21<sup>st</sup> Century Skills</h4>
                <p>Help kids inculcate the necessary STEM skills and make them ready for a tech-driven world of future
                </p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-2.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Intuitive Technology</h4>
                <p>World-class user-friendly STEM & Robotics resources comprising of hardware and software for creating
                    the perfect STEM & Robotics classroom</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-3.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Experiential Learning</h4>
                <p>In-depth understanding of concepts with learning through creative Hands-on STEM educational
                    activities</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-4.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Package Customization</h4>
                <p>Tailor-made bundles of STEM & Robotics classroom kits according to class strength, student's age, and
                    duration of lectures</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-5.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Gratuitous Training</h4>
                <p>Free of charge training & support on hardware and software from our expert educators</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-6.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Structured Curriculum</h4>
                <p>Designed by STEM & Robotics Experts covering the fundamental essentials of Electronics, Programming,
                    and Robotics</p>
            </div>
        </div>
    </div>
</div>

<!-- New Beginning Section -->
<div class="container py-5">
    <div class="section-header text-center mb-5">
        <h2 class="font-weight-bold" style="font-size: 35px;">A New Beginning</h2>
        <h6>Journey for children! From Beginner to Advanced</h6>
    </div>
    <div class="d-flex flex-wrap align-items-center">
        <div class="col-md-6 col-12">
            <h4 class="font-weight-bold mb-3">Other Benefits Include -</h4>
            <ul class="ml-3">
                <li class="mb-2"><strong>Lesson Progression Model</strong> - Next
                    lesson is unlocked, when current lesson’s
                    exercises & projects completed and
                    uploaded.</li>
                <li class="mb-2"><strong>Progression Tracking</strong> - Based on
                    time-taken to complete assignments,
                    complete lessons and answer questions.</li>
                <li class="mb-2"><strong>Certification of Excellence</strong> - Students receive official credible
                    certificates signifying course
                    completion</li>
            </ul>
        </div>
        <div class="col-md-6 col-12">
            <img src="assets\imgs\journey-laptop.png" class="img-fluid">
        </div>
    </div>
</div>

<!--Our Services -->
<div class="container-fluid bg-light py-5">
    <div class="container">
        <div class="section-header text-center mb-5">
            <h2 class="font-weight-bold" style="font-size: 35px;">Our Services Include</h2>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\stem.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">STEAM</h6>
            </div>
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\video-marketing.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">Video Tutorials</h6>
            </div>
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\analysis.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">Training Programs</h6>
            </div>
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\manual.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">Instruction Manual</h6>
            </div>
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\competition.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">Competitions</h6>
            </div>
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\workshop.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">Workshops</h6>
            </div>
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\customer-service.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">Teacher Support</h6>
            </div>
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\interview.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">Interaction With Mentors</h6>
            </div>
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\exhibition.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">Exhibitions</h6>
            </div>
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\ai.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">Quality Products</h6>
            </div>
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\crowdfunding.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">Crowd Funding</h6>
            </div>
            <div class="col-sm-3 col-4 text-center mb-4">
                <div class="icon-img mb-2">
                    <img src="assets\imgs\compliant.png" class="w-25">
                </div>
                <h6 class="font-weight-bold">Patent/IPR Support</h6>
            </div>
        </div>
    </div>
</div>

<!-- Partner Schools -->
<div class="container-fluid py-5">
    <div class="container">
        <div class="section-header text-center mb-5">
            <h2 class="font-weight-bold" style="font-size: 35px;">Our Partner Schools</h2>
        </div>
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-1.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-2.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-3.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-4.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-5.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-6.png" class="mb-3">
                        </div>
                    </div>
                </div>
                <div class="carousel-item py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-7.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-8.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-9.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-10.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-11.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-12.png" class="mb-3">
                        </div>
                    </div>
                </div>
                <div class="carousel-item py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-13.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-14.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-15.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-16.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-17.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-18.png" class="mb-3">
                        </div>
                    </div>
                </div>
                <div class="carousel-item py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-19.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-20.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-21.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-22.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-23.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-24.png" class="mb-3">
                        </div>
                    </div>
                </div>
                <div class="carousel-item py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-25.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-26.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-27.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-28.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-29.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-30.png" class="mb-3">
                        </div>
                    </div>
                </div>
                <div class="carousel-item py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-31.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-32.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-33.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-34.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-35.png" class="mb-3">
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-29.png" class="mb-3">
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span style="background-color: black;" class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span style="background-color: black;" class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</div>

<!-- Collaborate Banner -->
<div #sendFeedBackForms data-offset="200"></div>
<div class="container-fluid py-4" style="background-color: #2a3791;">
    <div class="container">
        <div class="section-header text-center">
            <h2 class="font-weight-bold text-white mb-0" style="font-size: 35px;">Collaborate with Us</h2>
        </div>
    </div>
</div>

<!-- Form -->
<div class="container py-5" >
    <form [formGroup]="sendFeedBackForm">
        <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputName4">School Name</label>
              <input type="text" formControlName="school_name" class="form-control" id="inputName4" placeholder="Name">
            </div>
            <div class="form-group col-md-6">
                <label for="inputEmail4">School Email</label>
                <input type="email" formControlName="email" class="form-control" id="inputEmail4" placeholder="Email">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
              <label for="contact_person">Contact Person</label>
              <input type="text" formControlName="contact_person" class="form-control" id="contact_person" placeholder="Contact Person Name">
            </div>
            <div class="form-group col-md-6">
                <label for="phone">Contact Number</label>
                <input type="number" formControlName="phone" maxlength="10" class="form-control" id="phone" placeholder="Contact Number">
                <div *ngIf="submitted && f.phone.errors">
                    <small *ngIf="f.phone.errors.required" class="text-danger">
                        This Field is required</small>
                    <small *ngIf="f.phone.errors.pattern" class="text-danger">
                        Enter 10 digit number</small>
                </div>
            </div>
        </div>
        <div class="form-group">
          <label for="inputAddress">Address</label>
          <input type="text" formControlName="address" class="form-control" id="inputAddress" placeholder="1234 Main St">
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputCity">City</label>
            <input type="text" formControlName="city" class="form-control" id="inputCity" placeholder="City">
          </div>
          <div class="form-group col-md-6">
            <label for="inputState">State</label>
            <select id="inputState" class="form-control" formControlName="state">
              <option selected>Select State</option>
              <option *ngFor="let item of stateData?.data">{{item.name}}</option>
            </select>
          </div>
        </div>
        <!-- <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="gridCheck" formControlName="isagreed">
            <label class="form-check-label" for="gridCheck">
              I agree to all Terms & Conditions
            </label>
          </div>
        </div> -->
        <button type="submit" class="btn btn-primary" (click)="onProductAddForm()">Submit</button>
    </form>
</div>

<!-- Modal -->
<div mdbModal #enquirysuccessmodal="mdb-modal" class="modal fade" id="centralModalSuccess" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto;">
    <div class="modal-dialog modal-notify modal-danger" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Enquiry</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="enquirysuccessmodal.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <!-- <i class="fa fa-close fa-4x mb-3 animated rotateIn"></i> -->
                    <p>Enquiry Submitted Successfully</p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>