import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../../validate-pattern';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-login-password-modal',
  templateUrl: './login-password.component.html',
  styleUrls: ['./login-password.component.scss']
})
export class LoginPasswordBixModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public userService: UserService, private modalService: ModalService,public commonService:CommonService) { }
  get f() { return this.Form.controls; }
  ngOnInit(): void {
    this.Form = this.fb.group({
      role_id: ['2'],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }
  onProceed() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      this.btnsubmitted = true;
      // console.log('LOGIN::::FORM', this.Form.value);
      this.userService.userPhoneLogin('login', this.Form.value).then(data => {
        if (data.replyCode == "success") {
          console.log('EMail with Password')
          this.token.saveUser(JSON.stringify(data.data));
          this.token.saveSid(JSON.stringify(data.sid));
          this.commonService.setLoggedInStatus(true);
          this.modalService.SetloginemailPasswordBixModal(true);
          this.userService.checkStudentSubscription(data.sid);
          // this.modalService.setloginphoneBixModal(false);
          // this.modalService.setloginphoneOtpBixModal(true);
          // this.commonService.SettypeOtp('phone');
          // this.commonService.SetPhoneNoOtp(this.Form.value.phone);
          this.Form.reset();
          this.submitted = false;
          this.btnsubmitted = false;
        } else {
          this.btnsubmitted = false;
          this._employeeService.showError(data.replyMsg)
        }
      });
    }
  }
  onLoginEmail() {
    this.modalService.SetloginemailPasswordBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }
  onCloseModal() {
    this.modalService.SetloginemailPasswordBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }
  onRegisterPopup() {
    this.modalService.setloginphoneBixModal(false);
    this.modalService.setregisterBixModal(true);

  }
}
