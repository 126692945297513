import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
declare const $: any;
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../validate-pattern';
import { BaseServiceService, TokenService } from '../../service';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  AddForm: FormGroup;
  subscription: Subscription;
  submitted = false; btnsubmitted = false;
  showHideFlag: boolean = false;
  currentURL='';
  constructor(public common: CommonService, public fb: FormBuilder, public _employeeService: BaseServiceService, public router: Router, public token: TokenService, public route: Router, public _leadsquaredService: LeadsquaredService,) {
    this.currentURL = window.location.href;
    this.common.GetshowhideFlag().subscribe(data => {
      if (data) {
        this.showHideFlag = data;
        console.log('showHideFlag::::::', this.showHideFlag);
      }
    })
  }

  ngOnInit(): void {
    this.AddForm = this.fb.group({
      first_name: [''],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      contact: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,10}$/)]],
      description: ['', [Validators.required]],
      learning: ['0']
    })
  }
  get f() { return this.AddForm.controls; }
  onSubmit() {
    var self = this;
    self.submitted = true;
    if (self.AddForm.invalid) {
      return;
    } else {
      let relatedId = '';
      const hostname = window && window.location && window.location.hostname;
      if (/^learningbix.com/.test(hostname)) {
        this.subscription = this._leadsquaredService.AddLeadCaptureContact(this.AddForm.value).subscribe(message => {
          if (message) {
            relatedId = message.Message.RelatedId
          } else {
            // clear messages when empty message received
          }
        });
      }


      self.btnsubmitted = true;
      var sendRequestData = self.AddForm.value;
      sendRequestData.page_url = this.currentURL;
      self._employeeService.post('send_feedback', sendRequestData).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            // this.subscription = this._leadsquaredService.AddCourseEnquiry('', relatedId || '').subscribe(message => {

            // })
            self._employeeService.showSuccess(res.replyMsg);
            self.AddForm.reset();
            self.submitted = false;
            self.btnsubmitted = false;
          } else {
            self.btnsubmitted = false;
            self._employeeService.showError(res.replyMsg)
          }
        } else {
          self.btnsubmitted = false;
          self._employeeService.showError(res.error.replyMsg)
        }
      }, error => {
        self.btnsubmitted = false;
        self._employeeService.showError("Oops! Something went wrong!");
      });
    }
  }
}
