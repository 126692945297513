import { HttpClient } from '@angular/common/http';
import { CommonService } from '../../service/common.service';
import { environment } from '../../../environments/environment';
import { StarRatingComponent } from 'ng-starrating';
import { Component, OnInit } from '@angular/core';
 
import { BaseServiceService } from 'src/app/service';
import { ActivatedRoute } from '@angular/router';
declare var Razorpay: any;
declare var $ : any;


@Component({
  selector: 'app-pie-blog',
  templateUrl: './pie-blog.component.html',
  styleUrls: ['./pie-blog.component.scss']
})
export class PieBlogComponent implements OnInit {
  active = 1;
  SlefPaceId:any;
  SelfPaceData:any;
  SelfPaceDataFilter:any;
  learnData:any=[];
  benefitsData:any=[];
  kidsData:any=[];
  options: any =environment.razorPayoptions;
  Amount:number=0;
  Course_Id:any;

  avengers = []; 
  BlogList = []; 
  constructor(public activatedRoute:ActivatedRoute,public _employeeService: BaseServiceService,public common:CommonService,public http:HttpClient) {
    this.SlefPaceId = this.activatedRoute.snapshot.paramMap.get("id");
    // console.log('SlefPaceId:::;',this.SlefPaceId);
    this.GetBlogList();
   }

  ngOnInit(): void {
    this.avengers = 
        [{ id: 1, naming: 'Captain America', city:'US' }, 
        { id: 2, naming: 'Thor' , city:'Asgard'}, 
        { id: 3, naming: 'Iron Man' , city:'New York'}, 
        { id: 4, naming: 'Spider Man' , city:'Spiderverse'}, 
        { id: 5, naming: 'Doctor Strange', city:'Nepal' }, 
        { id: 6, naming: 'Black Panther' , city:'Wakanda'}, 
        { id: 7, naming: 'Hulk' , city:'US'}]; 
  }
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    alert(`Old Value:${$event.oldValue}, 
      New Value: ${$event.newValue}, 
      Checked Color: ${$event.starRating.checkedcolor}, 
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }
  GetBlogList() {
    var self = this;
    let requestData = {
      id: this.SlefPaceId
    }
    self.http.get('https://learningpie.in/blogs/wp-json/wp/v2/posts?_embed').subscribe((res: any) => {
        // console.log('res::::',res)
        if(res.length > 0){
            this.BlogList=res;
        }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
  onBlogRedirect(){
    window.location.href = "https://learningpie.in/blogs";   
  }
  onBlogRedirectLink(link){
    var a = document.createElement('a');
    a.target="_blank";
    a.href=link;
    a.click();
  }
}
