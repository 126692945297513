import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/service/env.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  constructor(private http: HttpClient, private envService: EnvService) {}
  ngOnInit(): void {
   this.http.post<any>(`${this.envService.env}/payment-success`, { title: 'Angular POST Request Example' }).subscribe(data => {
    //   // console.log('URL:::::RESPONSE::::::',data)
     })
  }
  gotoHome() {
    window.close();
  }
}
