import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StarRatingComponent } from 'ng-starrating';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BaseServiceService, TokenService } from '../service';
import { CommonService } from '../service/common.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailPatternValidator } from './../validate-pattern/email-pattern-validator';
import { MobilePatternValidator } from './../validate-pattern/mobile-pattern-validator';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { UserService } from '../service/user.service';
import { EnvService } from '../service/env.service';
import { ModalService } from '../service/modal.service';
import { LeadsquaredService } from '../service/leadsquared.service';

declare var Razorpay: any;
declare var $: any;



@Component({
  selector: 'app-pie-home',
  templateUrl: './pie-home.component.html',
  styleUrls: ['./pie-home.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class PieHomeComponent implements OnInit {
  safeSrcVideoUrl: SafeResourceUrl;
  subscription: Subscription;
  active = 1;
  TestimonialsList = [];
  ProjectsList = [];
  AgeGroupList = [];
  AgeGroupCoursesList = [];
  options: any = environment.razorPayoptions;
  Amount: number = 0;
  Course_Id: any;
  openOfferModal = false;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    slideBy: 2,
    navText: ['&#60;', '&#62;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 2
      }
    },
    nav: true
  }
  IndexId: any;
  firstIndexId: any;

  AgeGroupId: any;
  AddForm: FormGroup;
  submitted = false; btnsubmitted = false;
  isCoding = false;
  isPreSchool = false;
  isRobotics = false;
  seletedClasstype;
  seletedAgeGroup;
  maxDate: any;
  currentDate: any;
  currentTime: any;
  isCurrentTime = false;
  Time_Slot_Array: any = [];
  preSchoolAgeGroup = [];
  classSetting: any;
  open30SecModal;
  ageCourseList: any = [];
  AgeGroupListData: any;
  mobileNo: any;

  constructor(public leedService:LeadsquaredService,private modalService: ModalService,public fb: FormBuilder, public activatedRoute: ActivatedRoute, public _employeeService: BaseServiceService, public common: CommonService, public token: TokenService, public _router: Router, private sanitizer: DomSanitizer, private _datePipe: DatePipe, public userService: UserService, public envService: EnvService) {

    console.log('${this.envService.env}::::::', `${ this.envService.env }`);






    localStorage.removeItem('checkout');
    this.common.SetRouteType('course-pie');

    this.GetTestimonialsList()
    this.common.getShowStartModalStatus().subscribe(data => {
      // console.log('hideModal', data);
      if (!data) {
        clearTimeout(this.open30SecModal);

      }
    })
  }
  ageWiseImageIcon = [
    { image: 'assets/imgs/ageGroupIcon1.png' },
    { image: 'assets/imgs/ageGroupIcon2.png' },
    { image: 'assets/imgs/ageGroupIcon3.png' },
    { image: 'assets/imgs/ageGroupIcon4.png' },
    { image: 'assets/imgs/ageGroupIcon5.png' },
    { image: 'assets/imgs/ageGroupIcon6.png' },
    { image: 'assets/imgs/ageGroupIcon7.png' },
  ]
  ngOnInit(): void {
    // this.AddLeadCapture();
    // this.AddCoursePurchase();
    // this.AddCourseEnquiry();
    // this.AddDemoClassBook();
    this._employeeService.getpieAgeCourseJSON().subscribe(data => {
      if (data) {
        this.ageCourseList = data.data;
      }
    })
    this.safeSrcVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/540034347');
    let openModel = JSON.parse(localStorage.getItem('opentrialDemoPopoup'))

    if (openModel != false) {
      if (localStorage.getItem('checkout') == undefined) {
        this.open30SecModal = setTimeout(() => {

          if (localStorage.getItem('checkout') == undefined) {
            $('#basicModel30Sec').modal('show');
          }
          if (openModel == false) {
            $('#basicModel30Sec').modal('hide');
          }
          else {
            $('#basicModel30Sec').modal('hide');

          }
        }, 10000);
      }
      else {
        $('#basicModel30Sec').modal('hide');
      }
    } else {
      $('#basicModel30Sec').modal('hide');
    }
    var dtToday = new Date();

    var month: any = dtToday.getMonth() + 1;
    var day: any = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10)
      month = '0' + month.toString();
    if (day < 10)
      day = '0' + day.toString();

    this.maxDate = year + '-' + month + '-' + day;

    $('#txtDate').attr('min', this.maxDate);
    this.preSchoolAgeGroupList();

    this.AddForm = this.fb.group({
      name: ['', [Validators.required]],
      phone_no: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      // password: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      gender: [''],
      image: [''],
      age_group_id: [''],
      time_zone: [''],
      dob: [''],
      parents_name: ['', [Validators.required]],
      school_name: [''],
      subscription_type: ['0'],
      demo_class_date: [''],
      time_slot: [''],
      time_from: [''],
      time_to: [''],
      learning: ['1'],
    })





    this.GetProjectsList();
    this.fetchSlots();
    // }


    this._employeeService.post('info_demo_class_settings', { id: '1' }).subscribe((res: any) => {
      if (!res.error) {
        // console.log('Response::::',res);
        this.classSetting = res.data;
        let subscribeDay = +this.classSetting.shipping_days
        let today_Date = new Date();
        let courseStartDate = new Date(new Date(today_Date).getTime() + subscribeDay * 24 * 60 * 60 * 1000);
        this.closestMonday(courseStartDate)
      }
    });


    this._employeeService.post('age_group_list_dropdown', { learning: '1' }).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupList = res.data;
        this.AgeGroupList = this.AgeGroupList.map((item, i) => Object.assign({}, item, this.ageWiseImageIcon[i]));

        this.getCoursesList(this.AgeGroupList[0].id);
        this.getCoursesListFirst('0', this.AgeGroupList[0].id)

        if (res.data.length > 0) {
          this.common.GetAgeCourseType().subscribe(data => {
            // console.log('Group::ID::', data);
            this.IndexId = data;
            this.firstIndexId = data;
            const isLargeNumber = this.AgeGroupList.findIndex(item => item.id == this.firstIndexId)
            // this.getCoursesList(data);
            if (data == '') {
              this.getCoursesList(this.AgeGroupId);
              this.getCoursesListFirst(isLargeNumber, this.AgeGroupId)
            } else {
              this.getCoursesList(data);
              this.getCoursesListFirst(isLargeNumber, data)

            }
          })
        }
      }
    });
    this.common.GetRouteType().subscribe(route => {
      //  alert(route);
      // console.log('route::::', route);
      this.onScroll(route)
    })
  }
  onSubmitQuery() {
    console.log('this.mobileNo:::::', this.mobileNo);
    if (this.mobileNo != '') {
      this.leedService.questionQuery(this.mobileNo).subscribe(data => 
        { localStorage.setItem('LEED', JSON.stringify(data)); return data }
      );
      this.mobileNo = '';
      this._employeeService.showSuccess('Successfully Done.');
    } else {
      this._employeeService.showError('Please Enter Your Number.')
      return
    }
    // 
  }
  onChangeAgeCourse(ageObject,index) {
    this._employeeService.getJSON().subscribe(data => {
      this.AgeGroupListData= data.data.filter(x=>x.index==index)
    })
    this.AgeGroupList.forEach(element => element.class="btn-book-orange-outline");
    ageObject.class = "btn-book-orange";
  }
  onBookFreeClass() {
    this.submitted = true;
    console.log('FORM:::::', this.AddForm.value);
    if (this.AddForm.invalid) {
      return
    } else {
      var split_string = this.AddForm.value.name.split(' ');
      var senRequestLeeddata = this.AddForm.value;
        senRequestLeeddata.name = split_string[0];
        senRequestLeeddata.first_name = split_string[0];
      if (split_string.length > 2) {
        senRequestLeeddata.last_name = split_string[1] + ' ' + split_string[2];
      } else {
        senRequestLeeddata.last_name = split_string[1] || '';
      }
      console.log('senRequestLeeddata:::::', senRequestLeeddata);
      this.userService.userRegister('register_student',senRequestLeeddata).then(data => {
        if (!data.error) {
          if (data.replyCode == 'success') {
            this._employeeService.showSuccess(data.replyMsg);
            this.AddForm.reset();
            this.submitted = false;
            this.btnsubmitted = false;
          } else {
            this.btnsubmitted = false;
            this._employeeService.showError(data.replyMsg)
          }
        } else {
          this.btnsubmitted = false;
          this._employeeService.showError(data.error.replyMsg)
        }
      });
    }
  }

  clickEventAddClass(ageCourseDataObject) {
    this.ageCourseList.forEach(element => element.class = '');
    ageCourseDataObject.class = '_active';
    this.AddForm.controls['age_group_id'].setValue(ageCourseDataObject.value)
  }

  getCoursesList(agegroupid) {
    this.IndexId = agegroupid;
    console.log('IndexId::::', this.IndexId);
    var requestData = {
      age_group_id: String(agegroupid),
      featured: '1',
      learning: '1'
    }
    this._employeeService.post('live_courses_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupCoursesList = res.data;
        
        // console.log(this.AgeGroupCoursesList[0].tag.replace(/[^a-zA-Z0-9]/g, ''));
        console.log('First:::',this.AgeGroupCoursesList[0].tag)
      }
    });
  }

  next_Sunday;
  courseStartDate;
  closestMonday = (date) => {
    let toDay_date = new Date(); // current date
    var curr_date = new Date(date); // current date
    //  console.log('date', curr_date);
    var day_info = 8.64e+7; // milliseconds per day
    var days_to_monday = 7 - curr_date.getDay(); // days left to closest Monday
    var monday_in_sec = curr_date.getTime() + days_to_monday * day_info; // aleary Monday in seconds from 1970 x
    this.next_Sunday = new Date(monday_in_sec); // Monday in date object
    this.next_Sunday.setHours(0, 0, 0);
    // console.log('next_Sunday', this.next_Sunday)
    this.courseStartDate = this.next_Sunday;
    return this.next_Sunday;
  }
  onCloseModal30Sec() {
    this.safeSrcVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    $('#basicModel30Sec').modal('hide');

  }
  bookSavenDay() {
    this.common.setShowStartModalStatus(false)
  }
  onBook7DayTrial() {
    this.safeSrcVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');

    $('#basicModel30Sec').modal('hide');

  }
  getDescrption(HtmlDescrption) {
    return this.sanitizer.bypassSecurityTrustHtml(HtmlDescrption);
  }
  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    alert(`Old Value:${$event.oldValue}, 
      New Value: ${$event.newValue}, 
      Checked Color: ${$event.starRating.checkedcolor}, 
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }

  onScroll(type) {
    const itemToScrollTo = document.getElementById(type);
    if (itemToScrollTo) {
      itemToScrollTo.scrollIntoView(true);
    }
  }
  onOpenPieModal() {
    this.modalService.SetModalOnOff(true);
    this.modalService.setBookAFreeModal(true);
  }
  getCoursesListFirst(index, agegroupid) {
    // console.log('====================================');
    // console.log(index, agegroupid);
    // console.log('====================================');


    if (index == '0') {
      $('#profile0').addClass('active show');
      $('#profile1').removeClass('active show');
      $('#profile2').removeClass('active show');
      $('#profile3').removeClass('active show');
      this.firstIndexId = agegroupid;
    }
    if (index == '1') {
      $('#profile0').removeClass('active show');
      $('#profile1').addClass('active show');
      $('#profile2').removeClass('active show');
      $('#profile3').removeClass('active show');
      this.firstIndexId = agegroupid;
    }
    if (index == '2') {
      $('#profile0').removeClass('active show');
      $('#profile1').removeClass('active show');
      $('#profile2').addClass('active show');
      $('#profile3').removeClass('active show');
      this.firstIndexId = agegroupid;
    }
    if (index == '3') {
      $('#profile0').removeClass('active show');
      $('#profile1').removeClass('active show');
      $('#profile2').removeClass('active show');
      $('#profile3').addClass('active show');
      this.firstIndexId = agegroupid;
    }
    // $(profileid).addClass('active show');
    // $(profileid).removeClass('active show');
  }
  // getCoursesList(agegroupid) {

  //   this.IndexId = agegroupid;
  //   var requestData = {
  //     age_group_id: String(agegroupid),

  //     featured: '1',
  //     learning: '1'
  //   }
  //   this._employeeService.post('live_courses_list', requestData).subscribe((res: any) => {
  //     if (!res.error) {
  //       this.AgeGroupCoursesList = res.data;
  //       // console.log('AgeGroupCoursesList::::', this.AgeGroupCoursesList);
  //     }
  //   });
  // }
  calculateSave(item) {
    let fackPrice = parseInt(item.fake_price);
    let actualPrice = parseInt(item.amount);
    let discountAmount = fackPrice - actualPrice
    let percent = (discountAmount * 100 / fackPrice);
    return `${percent.toFixed(2)}%`

  }
  GetProjectsList() {
    var self = this;
    self._employeeService.post('group_post_list', { featured: 1 }).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.ProjectsList = res.data;
          // console.log('ProjectsList::::',this.ProjectsList);
          // console.log('SelfPaceDataFilter::::',this.SelfPaceDataFilter);
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
  public couponCodeValue = '';
  public couponValue = 0;
  public TotalPayAmount;
  public isApply = false;
  public TotalDiscountValue = 0;
  public isInvalid = false;
  courseDetailsData: any;;
  onBuy(SelfPaceObject) {
    localStorage.setItem('checkout', JSON.stringify(SelfPaceObject));
    window.location.href = `${this.envService.env}/checkout`;
    // this._router.navigate(['/checkout']);
  }
  onChangeCouponCode(value) {
    this.isInvalid = false

    if (value.length > 3) {
      this.isApply = false;
    }
  }
  GetTestimonialsList() {
    var self = this;
    let requestData = {
      learning: '1'
    }
    self._employeeService.post('web_student_testimonials_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.TestimonialsList = res.data;
          // console.log('TestimonialsList::::',this.TestimonialsList);
          // console.log('SelfPaceDataFilter::::',this.SelfPaceDataFilter);
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
  preSchoolAgeGroupList() {
    this._employeeService.post('age_group_list_dropdown', { learning: '1' }).subscribe((res: any) => {
      if (!res.error) {
        // console.log('Response::::',res);
        this.preSchoolAgeGroup = res.data;
      }
    });
  }
  get f() { return this.AddForm.controls; }

  dateSelected(value) {
    let self = this
    this.AddForm.value.time_slot = '';
    self.Time_Slot_Array = []
    let current = new Date();
    let todayDate: any = self._datePipe.transform(new Date());
    let select_date: any = self._datePipe.transform(new Date(value));

    let todayDateNew = new Date(todayDate).getTime();
    let slotDateNew = new Date(select_date).getTime();

    if (todayDateNew == slotDateNew) {

      let time_value = current.getHours();

      if (time_value > 9 && time_value < 20) {
        self.currentTime = `${time_value + 2}:00:00`
        self.fetchSlots();
        self.isCurrentTime = true
      } else {
        self.isCurrentTime = false
        self.fetchSlots();
      }
    }
    else {
      self.isCurrentTime = false
      self.fetchSlots();

    }


  }
  onTimeSlot(time_slot) {
    // console.log('time_slot:::', time_slot);
    if (time_slot != null || time_slot != undefined) {
      var split_time = time_slot.split('-');
      // console.log('split_time:::', split_time);
      this.AddForm.controls['time_from'].setValue(split_time[0]);
      this.AddForm.controls['time_to'].setValue(split_time[1]);
    }
  }
  pad(str, max) {
    str = str.toString();
    return str.length < max ? this.pad("0" + str, max) : str;
  }
  convertHours(mins) {
    var hour = Math.floor(mins / 60);
    mins = mins % 60;
    var converted = this.pad(hour, 2) + ':' + this.pad(mins, 2);
    return converted;
  }
  calculate_time_slot(start_time, end_time, interval = "30") {
    var i, formatted_time;
    var time_slots = new Array();
    for (var i = start_time; i <= end_time; i = i + interval) {
      formatted_time = this.convertHours(i);
      time_slots.push(formatted_time);
    }
    return time_slots;
  }
  parseTime(s) {
    var c = s.split(':');
    return parseInt(c[0]) * 60 + parseInt(c[1]);
  }
  fetchSlots() {
    let self = this
    this._employeeService.post('demo_class_time_slots', {}).subscribe((res: any) => {
      // console.log('res:::',res);
      // console.log('====================================');
      // console.log('value', self.isCurrentTime);
      // console.log('====================================');
      if (self.isCurrentTime) {
        res.data.time_from = self.currentTime;
        // console.log('====================================');
        // console.log('value', res.data.time_from);
        // console.log('value', self.currentTime);
        // console.log('====================================');
      }

      var start_time = this.parseTime(res.data.time_from),
        end_time = this.parseTime(res.data.time_to);
      var interval: any = Number(res.data.class_duration);
      var times_ara = this.calculate_time_slot(start_time, end_time, interval);
      for (var i = 0; i <= times_ara.length - 2; i++) {
        // console.log(times_ara[i]+"-"+times_ara[i+1]);
        this.Time_Slot_Array.push({ value: times_ara[i] + "-" + times_ara[i + 1], name: times_ara[i] + "-" + times_ara[i + 1] });
      }
    });
  }
  onShowForm() {
    $("#getTouchBtn").addClass("hide-touch-btn");
    $("#getTouchForm").removeClass("toggle-form");
    $("#getTouchForm").addClass("toggle-form-show");
  }
  onCloseTouchForm() {
    $("#getTouchBtn").removeClass("hide-touch-btn");
    $("#getTouchForm").addClass("toggle-form");
    $("#getTouchForm").removeClass("toggle-form-show");
    this.AddForm.reset();
  }
  onSelectAgeGroup(item) {
    this.AddForm.patchValue({
      age_group_id: item.id
    })
    this.seletedAgeGroup = item.id
  }
  getIntouch() {
    $('#getInTouch').modal('show');
    document.getElementById("overlay").style.display = "block";

  }
  onCloseModalTouchModal() {

    this.AddForm.reset();
    document.getElementById("overlay").style.display = "none";

    $('#getInTouch').modal('hide');

  }
  ageRequired = false;


  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  onSubmit() {

    this.ageRequired = false;
    var self = this;
    // console.log(self.AddForm.value);
    self.submitted = true;
    if (self.AddForm.value.age_group_id == undefined || self.AddForm.value.age_group_id == null || self.AddForm.value.age_group_id == '' || self.AddForm.value.age_group_id == 'null') {
      // this._employeeService.showError('Please Select Age');
      this.ageRequired = true;
      return;
    }
    if (self.AddForm.invalid) {
      return;
    } else {
      let relatedId = '';
      self.btnsubmitted = true;
      var random_pwd =this.randomString(8, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
      var sendRequestData = this.AddForm.value
      if (localStorage.getItem('referral_code')) {
        sendRequestData.sponsor_code = localStorage.getItem('referral_code');
      }
      var split_string = sendRequestData.parents_name.split(' ');
          sendRequestData.first_name = split_string[0];
      if (split_string.length > 2) {
          sendRequestData.last_name = split_string[1] + ' ' + split_string[2];
      } else {
          sendRequestData.last_name = split_string[1] || '';
      }
      console.log('sendRequestData:::::', sendRequestData);
      this.userService.userRegister('register_student', sendRequestData).then(data => {
        // console.log('SEnD:::', data);
        if (data.replyCode == "success") {
          self._employeeService.showSuccess(data.replyMsg);
          self.AddForm.reset();
          self.submitted = false;
          self.btnsubmitted = false;
        } else {
          self.btnsubmitted = false;
          self._employeeService.showError(data.replyMsg)
        }
      });

      

    }
  }

}
