<div class="modal popup" id="bookPieRegistrationModal" tabindex="-1" aria-labelledby="bookPieRegistrationModalLabel"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <!-- <h5 class="modal-title" id="bookFreeClassModalLabel">Modal title</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0 pb-5 pl-5 pr-5 book-class-modal">
        <div class="row align-items-center">
          <div class="col-md-12">
            <h1>Register Enroll</h1>

            <form [formGroup]="Form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="fullname">Full name</label>
                    <input type="text" placeholder="Enter Name" appAphpaOnly class="form-control pl-0" id="fullname"
                      formControlName="name">
                    <div *ngIf="submitted && f.name.errors" class="error_input">
                      <div *ngIf="f.name.errors.required">This field is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="Email">Email</label>
                    <input type="Email" placeholder="Enter Email" class="form-control pl-0" id="Email"
                      formControlName="email">
                    <div *ngIf="submitted && f.email.errors">
                      <small *ngIf="f.email.errors.required" class="error_input">
                        This Field is required
                      </small>
                      <small [hidden]="!f.email.errors.patternInvalid" class="error_input">Please enter valid
                        email.</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="mobile">Mobile No.</label>
                    <input type="text" placeholder="Enter Mobile" class="form-control pl-0" id="mobile"
                      formControlName="phone_no" appNumbersOnly maxlength="10">
                    <div *ngIf="submitted && f.phone_no.errors">
                      <small *ngIf="f.phone_no.errors.required" class="error_input">
                        This Field is required
                      </small>
                      <small [hidden]="!f.phone_no.errors.patternMobileInvalid" class="error_input">Phone
                        Number should be valid and 10 digit.</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="Password">Password</label>
                    <input type="password" placeholder="Enter Password" class="form-control pl-0 font_size-15"
                      id="mobile" formControlName="password">
                    <div *ngIf="submitted && f.password.errors">
                      <small *ngIf="f.password.errors.required" class="error_input">
                        This Field is required
                      </small>
                      <small *ngIf="f.password.errors.minlength || f.password.errors.maxlength"
                        class="error_input">Password
                        length should be atleast 4 and maximum 20</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="age">Age group</label>
                    <select name="age" class="form-control pl-0" id="age" formControlName="age">
                      <option value="" selected disabled>Select Age Group</option>
                      <option [value]="ages.id" *ngFor="let ages of pieAgeGroupList">{{ages.age_from}} - {{ages.age_to}}
                        Yrs</option>

                    </select>
                    <div *ngIf="submitted && f.age.errors" class="error_input">
                      <div *ngIf="f.age.errors.required">This field is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="gst">GST Number</label>
                    <input type="text" placeholder="Enter GST" class="form-control pl-0" id="Email"
                      formControlName="gst">

                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="state">State</label>
                    <select name="state" class="form-control pl-0" id="state" formControlName="state">
                      <option value="" selected disabled>Select State</option>
                      <option [value]="state.id" *ngFor="let state of stateList">{{state.state}}</option>

                    </select>
                    <div *ngIf="submitted && f.state.errors" class="error_input">
                      <div *ngIf="f.state.errors.required">State is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="age">Address</label>
                    <textarea placeholder="Ex. 141- Ram Nagar, Jaipur , Rajasthan, Pincode:303101"
                      class="form-control pl-0" id="mobile" formControlName="address" rows="4"></textarea>
                    <div *ngIf="submitted && f.address.errors" class="error_input">
                      <div *ngIf="f.address.errors.required">Address is required</div>
                    </div>
                  </div>
                </div>

              </div>

              <button type="submit" class="btn btn-book-class" (click)="OnRegisterEnroll()"
                [disabled]="btnsubmitted">Register</button>
              <p *ngIf="!UserData" class="mt-3 mb-3 text-center seprator">or</p>
              <p *ngIf="!UserData" class="register-link">Already have account? <a href="javascript:void(0);"
                  (click)="onLoginEmail()">Login Now</a></p>

            </form>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>