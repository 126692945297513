import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  data1 = {
    "status": true, "data": [
      {
        "status": true,
        "_id": "60dd61f815e3f1cfd87d32bc",
        "category": {
          "status": true,
          "isDeleted": false,
          "_id": "60d7002aa8fee20c1b3011c6",
          "name": "Health and wellness",
          "slug": "health-and-wellness",
          "attachment": "60eab2350cf60d9d869cdfe7",
          "attributes": [],
          "createdAt": "2021-06-26T10:23:38.625Z",
          "updatedAt": "2021-07-11T08:56:21.931Z",
          "v": 0,
          "commissionPercentage": 2
        },
        "name": "Size",
        "values": [
          { "status": true, "isDeleted": false, "_id": "60dd61f815e3f1cfd87d32bd", "name": "50 gm" },
          { "status": true, "isDeleted": false, "_id": "60dd61f815e3f1cfd87d32be", "name": "100 gm" }, { "status": true, "isDeleted": false, "_id": "60dd61f815e3f1cfd87d32bf", "name": "200 gm" }, { "status": true, "isDeleted": false, "_id": "60dd61f815e3f1cfd87d32c0", "name": "500 gm" }
        ]
      }, { "status": true, "_id": "60dd61f815e3f1cfd87d32c1", "category": { "status": true, "isDeleted": false, "_id": "60d7002aa8fee20c1b3011c6", "name": "Health and wellness", "slug": "health-and-wellness", "attachment": "60eab2350cf60d9d869cdfe7", "attributes": [], "createdAt": "2021-06-26T10:23:38.625Z", "updatedAt": "2021-07-11T08:56:21.931Z", "_v": 0, "commissionPercentage": 2 }, "name": "Type", "values": [{ "status": true, "isDeleted": false, "_id": "60dd61f815e3f1cfd87d32c2", "name": "mixed" }, { "status": true, "isDeleted": false, "_id": "60dd61f815e3f1cfd87d32c3", "name": "kaju katri" }, { "status": true, "isDeleted": false, "_id": "60dd61f815e3f1cfd87d32c4", "name": "aalu bhujiya" }] }], "message": "Attribute and its value list"
  }
  
  data2 = {
    "status": true, "data": {
      "addedBy": {
        "userId": { "_id": "610e6c42ac09111614684272", "firstName": "bex-vendor", "lastName": "bex-vendor" },
        "role": "609ba9d370ce59948dacca5d"
      },
      "typeOfProduct": [
        { "status": "Active", "_id": "60dc6d8f642b0cb98ab88b45", "name": "Recent" },
        { "status": "Active", "_id": "60dc6e54642b0cb98ab88b46", "name": "Recommended" },
        { "status": "Active", "_id": "60dc6e5c642b0cb98ab88b47", "name": "Popular" }
      ],
      "commission": 0,
      "commissionType": "percentage",
      "productImages": [
        { "status": true, "isDeleted": false, "_id": "61239067b314bf41eb003f0c", "filePath": "https://bex-products.s3.ap-south-1.amazonaws.com/1629720678788-GTI18046.jpg", "createdAt": "2021-08-23T12:11:19.071Z", "updatedAt": "2021-08-23T12:11:19.071Z", "__v": 0 }, { "status": true, "isDeleted": false, "_id": "61239067b314bf41eb003f0b", "filePath": "https://bex-products.s3.ap-south-1.amazonaws.com/1629720678790-Himalaya-doubles-down-on-Dubai-R-D-centre-to-drive-Ayurveda-advances.jpg", "createdAt": "2021-08-23T12:11:19.065Z", "updatedAt": "2021-08-23T12:11:19.065Z", "__v": 0 }, { "status": true, "isDeleted": false, "_id": "61239067b314bf41eb003f0d", "filePath": "https://bex-products.s3.ap-south-1.amazonaws.com/1629720678798-products_455063_image_B483_104154048%20-%20Copy.jpg", "createdAt": "2021-08-23T12:11:19.073Z", "updatedAt": "2021-08-23T12:11:19.073Z", "__v": 0 }],
      "status": "Inactive",
      "isDeleted": false,
      "_id": "61239067b314bf41eb003f10",
      "name": "Vendor Product",
      "slug": "vendor-product",
      "category": { "status": true, "_id": "60d7002aa8fee20c1b3011c6", "name": "Health and wellness", "slug": "health-and-wellness" },
      "subCategory": { "status": true, "_id": "60d702aca8fee20c1b3011c9", "name": "Weight Management", "slug": "weight-management" },
      "shortDescription": "test",
      "description": "test one",
      "gstPercentage": "3",
      "HSNCode": "FDHDFH346",
      "skuId": "VNDR446",
      "weight": "1",
      "createdAt": "2021-08-23T12:11:18.872Z",
      "brand": { "status": true, "isDeleted": false, "createdAt": "2021-08-11T10:59:44.470Z", "_id": "6113ada0fa1fde892a8e33fb", "title": "Ranson Coffee", "image": "6113ada0fa1fde892a8e33fa", "slug": "ranson-coffee", "user": "609a3fbca716673990873c16", "updatedAt": "2021-08-11T10:59:44.470Z", "__v": 0 },
      "productType": "attribute",
      "quantityBasedDiscounts": [
        { "discountType": "percentage", "_id": "61239067b314bf41eb003f11", "minQuantity": 5, "maxQuantity": 15, "discount": 2 },
        { "discountType": "percentage", "_id": "61239067b314bf41eb003f12", "minQuantity": 20, "maxQuantity": 50, "discount": 5 }
      ],
      "unit": "KG",
      "variant": [{ "_id": "61239067b314bf41eb003f13", "attributes": [{ "name": "Size", "value": "S" }, { "name": "Type", "value": "Flax" }, { "name": "Brand", "value": "Top" }], "price": 100, "offerPrice": 80, "stock": 10, "offerPercentage": 20 }, { "_id": "61239067b314bf41eb003f14", "attributes": [{ "name": "Size", "value": "M" }, { "name": "Type", "value": "Troll" }, { "name": "Brand", "value": "Thermal" }], "price": 500, "offerPrice": 300, "stock": 50, "offerPercentage": 40 }],
      "thumbnailImage": { "status": true, "isDeleted": false, "_id": "61239067b314bf41eb003f0e", "filePath": "https://bex-products.s3.ap-south-1.amazonaws.com/1629720678799-SOPFGWC5XHFDHYFG_3_1024x1024.jpg", "createdAt": "2021-08-23T12:11:19.176Z", "updatedAt": "2021-08-23T12:11:19.176Z", "__v": 0 }
      ,
      "productVideo": {
        "status": true, "isDeleted": false, "_id": "61239067b314bf41eb003f0f", "filePath": "https://bex-products.s3.ap-south-1.amazonaws.com/1629720678800-file_example_MP4_640_3MG.mp4",
        "createdAt": "2021-08-23T12:11:19.394Z",
        "updatedAt": "2021-08-23T12:11:19.394Z", "__v": 0
      }, "updatedAt": "2021-08-23T12:11:18.872Z", "__v": 0
    }
  }

  arrayForm: any = [];
  survey: FormGroup;
  NewArray: any = [];
  arrayObject: any = {
    'index':0,
    'offerPrice': '',
    'stock': '',
    'price': '',
    'validate':false
  }
  constructor() {
  }

  ngOnInit(): void {
    this.NewArray.push(this.arrayObject);
    console.log('NewArray::::',this.NewArray)
    console.log(this.data1);
    console.log(this.data2);
    this.survey = new FormGroup({
      surveyName: new FormControl(''),
      logoUrl: new FormControl(''),
      headerUrl: new FormControl(''),
      headerColor: new FormControl(''),
      footerUrl: new FormControl(''),
      footerColor: new FormControl(''),
      sections: new FormArray([
        this.initSection(),
      ]),
    });
    const control = (<FormArray>this.survey.controls['sections']);
    // control.push(this.initQuestion());
    // console.log('control::::::', control.value);
    // this.NewArray = control.value.attributes;
    // console.log('this.NewArray::::::', this.NewArray);
  }
  initSection() {
    return new FormGroup({
      offerPrice: new FormControl(''),
      price: new FormControl(''),
      stock: new FormControl(''),
      attributes: new FormArray([])
    });
  }
  initQuestion(name,value) {
    return new FormGroup({
      name: new FormControl(''),
      value: new FormControl('')
    });
  }
  getSections(form) {
    // console.log('form:::::1111', form.controls.sections.controls);
    return form.controls.sections.controls;
  }
  getQuestions(form) {
    // console.log('form:::::', form.controls.attributes.controls);
    return form.controls.attributes.controls;
  }
  addSection(index) {
    this.NewArray.push({
      'index':index,
      'offerPrice': '',
      'stock': '',
      'price': '',
      'validate':false
    });
    const control = <FormArray>this.survey.get('sections');
    control.push(this.initSection());
  }
  removeSection(i){
    const control = <FormArray>this.survey.get('sections');
    control.removeAt(i);
    this.NewArray.splice(i, 1)
  }
  onSubmit(form){
    // console.log('form:::::', form.value);
    console.log('NewArray::::', this.NewArray);
    if (this.checkValidation) {
      console.log('VALID');
    } else {
      console.log('INVALID');
    }
  }
  addQuestion() {
    
    
  }
  attributeLists: any = [];
  onClickCheckbox(value,attr,j) {
    if (value == true) {
      this.attributeLists.push({ _id: attr._id, name: attr.name,value:'' });
    } else {
      for (let i = 0; i < this.attributeLists.length; i++) {
        if (this.attributeLists[i]._id == attr._id) { this.attributeLists.splice(i, 1) };
      }
    }
    this.NewArray.forEach(element => {
      var obj = element;
      Object.assign(obj,{attribute:this.attributeLists})
    });
    console.log(this.attributeLists);
  }
  onAddValue(object_data, value, key) {
    if (key == 'offer') {
      object_data.offerPrice = value;
    }else if (key == 'stock') {
      object_data.stock = value;
    }else if (key == 'price') {
      object_data.price = value;
    }else if (key == 'array') {
      object_data.value = value;
    } else {
      return;
    }
    console.log('NewArray:::::',this.NewArray);
  }
  checkValidation(object_data) {
    if (object_data.validate) {
      return true;
    } else {
      return false;
    }
  }
}


