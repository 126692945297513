import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../../validate-pattern';
import { Subscription } from 'rxjs';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { UserService } from 'src/app/service/user.service';
import * as commonFunction from '../../../function/common-function';
import { DatePipe } from '@angular/common';
import { ModalService } from 'src/app/service/modal.service';
import { CommonService } from 'src/app/service/common.service';
declare var $: any;
@Component({
  selector: 'app-book-demo-class-modal',
  templateUrl: './book-demo-class.component.html',
  styleUrls: ['./book-demo-class.component.scss'],
  providers: [DatePipe]
})
export class BookDemoClassBixModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  isPreSchool = false;
  AgeGroupList: any = [];
  UserData = JSON.parse(localStorage.getItem('user'));
  stateList = [];
  subscription: Subscription;
  Time_Slot_Array: any = [];
  isCurrentTime = false;
  currentTime: any;
  maxDate: any;
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public _leadsquaredService: LeadsquaredService, public userService: UserService, private _datePipe: DatePipe,private modalService: ModalService,public commonService:CommonService) { }
  ngOnInit(): void {
    
    var dtToday = new Date(),month: any = dtToday.getMonth() + 1,day: any = dtToday.getDate(),year =    dtToday.getFullYear();
    if (month < 10)
      month = '0' + month.toString();
    if (day < 10)
      day = '0' + day.toString();
    this.maxDate = year + '-' + month + '-' + day;
    $('#txtDate').attr('min', this.maxDate);
    this.Form = this.fb.group({
      name: ['', [Validators.required]],
      phone_no: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      gender: [''],
      image: [''],
      age_group_id: ['', [Validators.required]],
      learning: ['', [Validators.required]],
      time_zone: [''],
      dob: [''],
      parents_name: ['', [Validators.required]],
      school_name: [''],
      subscription_type: ['0'],
      demo_class_date: [''],
      time_slot: [''],
      time_from: [''],
      time_to: [''],
    });
    this.commonService.GetPhoneNoOtp().subscribe(data => {
      if (data) {
        this.Form.controls['phone_no'].setValue(data);
      }
      console.log('data::::::', data);
      console.log('data::::::', this.Form.value);
    })
  }
  get f() { return this.Form.controls; }

  chageLearningType(type) {
    this.isPreSchool = commonFunction.chageLearningType(type);
    this.Form.patchValue({
      demo_class_date: '',
      time_slot: '',
    });
    this._employeeService.post('age_group_list_dropdown', { learning: type }).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupList = res.data;
      }
    });
  }
  
  parseTime(s) {
    var c = s.split(':');
    return parseInt(c[0]) * 60 + parseInt(c[1]);
  }
  pad(str, max) {
    str = str.toString();
    return str.length < max ? this.pad("0" + str, max) : str;
  }
  convertHours(mins) {
    var hour = Math.floor(mins / 60);
    mins = mins % 60;
    var converted = this.pad(hour, 2) + ':' + this.pad(mins, 2);
    return converted;
  }
  fetchSlots() {
    let self = this
    this._employeeService.post('demo_class_time_slots', {}).subscribe((res: any) => {
      if (self.isCurrentTime) {
        res.data.time_from = self.currentTime;
      }
      var start_time = this.parseTime(res.data.time_from),
          end_time = this.parseTime(res.data.time_to);
      var interval: any = Number(res.data.class_duration);
      var times_ara = this.calculate_time_slot(start_time, end_time, interval);
      for (var i = 0; i <= times_ara.length - 2; i++) {
        // console.log(times_ara[i]+"-"+times_ara[i+1]);
        this.Time_Slot_Array.push({ value: times_ara[i] + "-" + times_ara[i + 1], name: times_ara[i] + "-" + times_ara[i + 1] });
      }
    });
  }
  calculate_time_slot(start_time, end_time, interval = "30") {
    var i, formatted_time;
    var time_slots = new Array();
    for (var i = start_time; i <= end_time; i = i + interval) {
      formatted_time = this.convertHours(i);
      time_slots.push(formatted_time);
    }
    return time_slots;
  }
  dateSelected(value) {
    let self = this
    this.Form.value.time_slot = '';
    self.Time_Slot_Array = []
    let current = new Date();
    let todayDate: any = self._datePipe.transform(new Date());
    let select_date: any = self._datePipe.transform(new Date(value));

    let todayDateNew = new Date(todayDate).getTime();
    let slotDateNew = new Date(select_date).getTime();

    if (todayDateNew == slotDateNew) {

      let time_value = current.getHours();

      if (time_value > 9 && time_value < 20) {
        self.currentTime = `${time_value + 2}:00:00`
        self.fetchSlots();
        self.isCurrentTime = true
      } else {
        self.isCurrentTime = false
        self.fetchSlots();
      }
    }
    else {
      self.isCurrentTime = false
      self.fetchSlots();

    }


  }
  onTimeSlot(time_slot) {
    if (time_slot != null || time_slot != undefined) {
      var split_time = time_slot.split('-');
      this.Form.controls['time_from'].setValue(split_time[0]);
      this.Form.controls['time_to'].setValue(split_time[1]);
    }
  }
  onCloseModal() {
    this.modalService.setbookDemoClassBixModal(false);
  }
  // Book Demo 
  onLogin() {
    // console.log('Data::::::', this.Form.value);
    let courseType = 'Preschool';
    this.submitted = true;
    if (this.Form.invalid) {
      if (!this.isPreSchool) {
        courseType = 'Coding';
        if (this.Form.value.demo_class_date == undefined || this.Form.value.demo_class_date == null || this.Form.value.demo_class_date == '') {
          this._employeeService.showError("Please Select a Date");
          return;
        }
        if (this.Form.value.time_slot == undefined || this.Form.value.time_slot == null || this.Form.value.time_slot == '') {
          this._employeeService.showError("Please Select a time slot");
          return;
        }
      }
      return;
    } else {
      var sendRequestData = this.Form.value;
      if (localStorage.getItem('referral_code')) {
        sendRequestData.sponsor_code = localStorage.getItem('referral_code');
      }
      this.userService.userRegister('register_student', sendRequestData)
    }
  }
}
