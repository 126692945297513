<div class="container-fluid learning-pie-benefits mt-5 py-5">
    <h2 class="text-center">1,25,000+ Parents Trust
        <span><img src="../../assets/imgs/logo-pie.png" alt="" class="img-fluid"  style="    margin-top: 40px;"></span></h2>
    <p class="text-center py-3" style="margin-bottom: 100px;">We are extremely grateful to all the parents for trusting us as their child’s first learning partner!</p>
    
    <div class="d-flex flex-row flex-wrap justify-content-center">
        <div class="card col-12 col-lg-3 faq-card">
            <img src="../../../../assets/imgs/img01.png" alt="" class="img-fluid faq-img">
            <div class="headline text-center mb-3">
                <h4 class="mb-2">Nishant Bansal<br>Investment Banker</h4>
                <img src="../../../../assets/imgs/faq-arrow.png" alt="" class="img-fluid">  
            </div>
            
            <div class="d-flex flex-row flex-wrap justify-content-center mb-3">
                <div class="rating-stars">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                </div>
            </div>
            <p>This pandemic presented itself as a challenge for learning, Suryaveer became very cranky at home.  Learningpie came as a lifesaver, even home is an everyday adventure for him. Thanks to Learningpie for making this happen.</p>
        </div>
        <div class="card col-12 col-lg-3 faq-card" style="background-color: #EE872E;">
            <img src="../../../../assets/imgs/img02.png" alt="" class="img-fluid faq-img">
            <div class="headline text-center mb-3">
                <h4 class="mb-2">Pooja Mehta<br>Educator</h4>
                <img src="../../../../assets/imgs/faq-arrow.png" alt="" class="img-fluid">  
            </div>
            
            <div class="d-flex flex-row flex-wrap justify-content-center mb-3">
                <div class="rating-stars">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                </div>
            </div>
            <p>LearningPie Preschool Program was an easy choice. They have included everything. A child could want and a parent would need. Their offerings are vast and comprehensive . Truly, a complete preschool program. Avya cant really take his hands-off the kit.</p>
        </div>
        <div class="card col-12 col-lg-3 faq-card">
            <img src="../../../../assets/imgs/img03.png" alt="" class="img-fluid faq-img">
            <div class="headline text-center mb-3">
                <h4 class="mb-2">Manish Harodia<br>IIM-K</h4>
                <img src="../../../../assets/imgs/faq-arrow.png" alt="" class="img-fluid">  
            </div>
            
            <div class="d-flex flex-row flex-wrap justify-content-center mb-3">
                <div class="rating-stars">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                    <img src="../../../../assets/imgs/star-yellow.png" alt="" class="img-fluid star">
                </div>
            </div>
            <p>During these times, we observed that we need to engage Navya in some or the other way in order to continue her learning process. We came across LearningPie amd it has been an upward spiral of fun since then.</p>
        </div>
    </div>
</div>