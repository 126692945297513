import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../../validate-pattern';
import { Subscription } from 'rxjs';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';

import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-guest-login-modal',
  templateUrl: './guest-login.component.html',
  styleUrls: ['./guest-login.component.scss']
}) 
export class LoginEmailGuestUserComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public _leadsquaredService: LeadsquaredService, public userService: UserService, private modalService: ModalService,public common : CommonService) { }
  
  ngOnInit(): void {
    this.Form = this.fb.group({
      role_id: ['2'],
      email: ['', [Validators.required]],
    })
  }
  get f() { return this.Form.controls; }
  
  onCloseModal() {
    this.modalService.SetGuestLogin(false);
  }
  onLogin() {
    console.log('validateEmail:::::',this.validateEmail(this.Form.value.email))
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      this.btnsubmitted = true;
      if (this.validateEmail(this.Form.value.email)) {
        this.userService.userEmailOtpGenerateLogin(this.Form.value).then(data => {
          if (data.replyCode == "success") {
            this._employeeService.showSuccess(data.replyMsg);
            this.modalService.SetGuestLogin(false);
            this.modalService.setloginphoneOtpBixModal(true);
            this.common.SetPhoneNoOtp(this.Form.value.email);
            this.common.SettypeOtp('email');
            // this.Form.reset();
            this.submitted = false;
            this.btnsubmitted = false;
          }else{
            this.btnsubmitted = false;
            this._employeeService.showError(data.replyMsg)
          }
        });
      } else {
        var sendRequestData:any = {};
            sendRequestData.role_id = '2';
            sendRequestData.phone = this.Form.value.email;
        this.userService.userPhoneLogin('otp_generate', sendRequestData).then(data => {
          if (data.replyCode == "success") {
            this._employeeService.showSuccess(data.replyMsg);
            this.modalService.SetGuestLogin(false);
            this.modalService.setloginphoneOtpBixModal(true);
            this.common.SetPhoneNoOtp(this.Form.value.email);
            this.Form.reset();
            this.submitted = false;
            this.btnsubmitted = false;
          } else {
            this.btnsubmitted = false;
            this._employeeService.showError(data.replyMsg)
          }
        });
      }
      
    }
  }
 
  onLoginMobile() {
    this.modalService.setloginemailBixModal(false);
    this.modalService.setloginphoneBixModal(true);
  }
  forgotPassword() {
    this.modalService.setloginemailBixModal(false);
    this.modalService.setforgotBixModal(true);
  }
  onRegisterPopup() {
    this.modalService.setloginemailBixModal(false);
    this.modalService.setregisterBixModal(true);
    
  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
}
