
<app-header></app-header>
<div class="row m-0 pt-5 pb-5">

    <div class="col-lg-6 center alert alert-success justify-content-center">
        <p class="text-center mb-4 py-3" style="border-bottom: 1px solid#f99090 !important;">
            <i class="far fa-question-circle" style="font-size: 70px;"></i>
        </p>
        <h3 class="text-center">
            Ohh! Your transaction failed.
        </h3>
        <!-- <h4 class="text-center">You Booking id : ghfgh</h4> -->
        <!-- <h4 class="text-center">You Reference id : fghfgh</h4> -->
        <!-- <h4 class="text-center">You Transaction id : ghfgh</h4> -->
        <a class="btn btn-sm btn-primary mt-2" href="javascript: window.close();">Retry</a>
        <!-- <button class="btn btn-sm btn-primary mt-2" onclick="javascript:close_window();">Go Back</button> -->
        <!-- <button class="btn btn-sm btn-primary mt-2" (click)="paynow()">paynow</button> -->
    </div>
</div>
<app-footer></app-footer>
