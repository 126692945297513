<section>
    <div class="container">
        <div class="row my-4">
            <div class="col-12 text-center">
                <h4>Test Here</h4>

                <div class="row">
                    <div class="form-check" *ngFor="let checked of data1.data;let i=index">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (click)="onClickCheckbox($event.target.checked,checked,i)">
                        <label class="form-check-label" for="flexCheckDefault">
                            {{checked.name}}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <form [formGroup]="survey" novalidate>
                        <!---Survey Section -->
                        <div formArrayName="sections">
                            <div class="section-container" *ngFor="let X of NewArray; let ix=index">
                                <div class="row">
                                    <ng-container *ngIf="X.attribute">
                                        <div class="col" *ngFor="let data of X.attribute">
                                            <input type="text" placeholder="{{data.name}}" (keyup)="onAddValue(data,$event.target.value,'array')">
                                        </div>
                                    </ng-container>
                                    <div class="col">
                                        <input type="text" placeholder="Enter offerPrice" (keyup)="onAddValue(X,$event.target.value,'offer')">
                                    </div>
                                    <div class="col">
                                        <input type="text" placeholder="Enter stock" (keyup)="onAddValue(X,$event.target.value,'stock')">
                                    </div>
                                    <div class="col">
                                        <input type="text" placeholder="Enter price" (keyup)="onAddValue(X,$event.target.value,'price')">
                                    </div>
                                    <button (click)="addSection(NewArray.length)" class="point">Add</button>
                                    <span (click)="removeSection(ix)">Remove</span>
                                </div>
                            </div>
                        </div>
                        <!-- End Section -->
                        <button (click)="onSubmit(survey)" >Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>