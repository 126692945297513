<app-header></app-header>

<!-- Main Banner -->
<div class="container-fluid main-banner py-5">
    <div class="container">
        <div class="d-flex flex-wrap mb-5 align-items-center">
            <div class="col-sm-5 col-12 pt-5" style="margin-bottom: 6.25rem">
                <h1 class="font-weight-bold text-white mb-3">
                    Inspiring NextGen Innovators
                </h1>
                <h5 class="font-weight-bold text-white">Build | Play | Innovate</h5>
            </div>
        </div>

        <div class="about-section" style="position: relative">
            <h3 class="text-center">About Us</h3>
            <div class="content text-center">
                <p class="mb-4">
                    Inspiring the creators of tomorrow, Learning Bix strive to provide
                    hands-on learning solutions to the students so that they can give
                    shape to their innovative ideas. Learning Bix has come a long way in
                    enhancing the learning curve of the younger generation by leveraging
                    technology tools and learning resources in STEM Education.
                </p>
                <div class="d-flex flex-wrap justify-content-between">
                    <div class="col-md-5 col-12 text-center p-2 border border-dark rounded mb-5 mb-md-2"
                        style="position: relative">
                        <p>
                            Learning Bix aims to inspire the creators of tomorrow and help
                            them in giving shape to their innovative ideas by providing them
                            project based, hands-on learning solutions.
                        </p>
                        <button class="font-weight-bold btn btn-sm text-white bg-primary vision">
                            Our Vision
                        </button>
                    </div>
                    <div class="col-md-5 col-12 text-center p-2 border border-dark rounded mb-5 mb-md-2"
                        style="position: relative">
                        <p>
                            We aim to foster 21<sup>st</sup> century skills in children by
                            enabling them to dive into the world of innovation and learn the
                            basics of Electronics, Programming, Robotics, IOT and more.
                        </p>
                        <button class="font-weight-bold btn btn-sm text-white bg-primary vision">
                            Our Mission
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- What We Offer -->
<section class="container" style="padding: 80px 0">
    <div class="text-center mb-5">
        <h2 class="font-weight-bold">What We Offer ?</h2>
    </div>
    <div class="d-flex flex-wrap">
        <div class="col d-flex flex-column align-content-center mb-3">
            <div class="rounded-circle px-3 py-4 border border-dark hover-card hover-card-orange">
                <h6 class="font-weight-bold text-center mb-0">
                    Robotics and STEM Education
                </h6>
            </div>
        </div>
        <div class="col d-flex flex-column align-content-center mb-3">
            <div class="rounded-circle px-3 py-4 border border-dark hover-card hover-card-green">
                <h6 class="font-weight-bold text-center mb-0">
                    School Innovation Programs
                </h6>
            </div>
        </div>
        <div class="col d-flex flex-column align-content-center mb-3">
            <div class="rounded-circle px-3 py-4 border border-dark hover-card hover-card-blue">
                <h6 class="font-weight-bold text-center mb-0">
                    Open Source Learning Programs
                </h6>
            </div>
        </div>
        <div class="col d-flex flex-column align-content-center mb-3">
            <div class="rounded-circle px-3 py-4 border border-dark hover-card hover-card-yellow">
                <h6 class="font-weight-bold text-center mb-0">
                    Online Training Programs
                </h6>
            </div>
        </div>
        <div class="col d-flex flex-column align-content-center mb-3">
            <div class="rounded-circle px-3 py-4 border border-dark hover-card hover-card-pink">
                <h6 class="font-weight-bold text-center mb-0">
                    International Robotics Championship
                </h6>
            </div>
        </div>
        <div class="col d-flex flex-column align-content-center mb-3">
            <div class="rounded-circle px-3 py-4 border border-dark hover-card hover-card-purple">
                <h6 class="font-weight-bold text-center mb-0">
                    Teaching Manuals & Handbooks
                </h6>
            </div>
        </div>
        <div class="col d-flex flex-column align-content-center mb-3">
            <div class="rounded-circle px-3 py-4 border border-dark hover-card hover-card-grey">
                <h6 class="font-weight-bold text-center mb-0">
                    Hardware Resources For All
                </h6>
            </div>
        </div>
    </div>
</section>

<!-- Team Members -->
<section class="container-fluid team-banner py-5">
    <div class="container">
        <div class="text-center mb-5">
            <h2 class="font-weight-bold">Meet Our Professional Team</h2>
        </div>
        <div class="d-flex flex-wrap align-items-center mb-3">
            <div class="col-md-5 col-12">
                <p class="font-weight-light">
                    "LearningBix, a leading STEM Education service provider, offers a wide
                    range of technology to make the young generation future-ready with
                    21<sup>st</sup> century skills by offering age-appropriate robotics
                    kits to make technology a kids play. Over the course of years, we have
                    set-up training labs and organized intervention programs for over a
                    million students in 1000+ educational institutes spread across length
                    and breadth of the country.
                </p>
                <p class="font-weight-light">
                    Focused on leveraging technology in education, we provide
                    project-based hands-on learning solutions for kids aged 2 to 18. The
                    objective of LearningBix is to embed curiosity, creativity and
                    imagination in young minds and inculcate computational thinking,
                    adaptive learning, physical computing skills in them."
                </p>
            </div>
            <div class="col-md-7 col-12">
                <div class="d-flex flex-wrap">
                    <div class="col-sm-4 col-6 text-center mb-3">
                        <div class="text-center">
                            <img src="assets\imgs\nikhil-sir-icon.png" class="mb-3 team-member rounded-circle" />
                        </div>
                        <h6 class="font-weight-bold mb-0">Nikhil Agrawal</h6>
                        <!-- <p class="">Founder / CEO</p> -->
                    </div>
                    <div class="col-sm-4 col-6 text-center mb-3">
                        <div class="text-center">
                            <img src="assets\imgs\neha-maam-icon.png" class="mb-3 team-member rounded-circle" />
                        </div>
                        <h6 class="font-weight-bold mb-0">Neha Agrawal</h6>
                        <!-- <p class="">Co-Founder / Director – LearningPie</p> -->
                    </div>
                    <div class="col-sm-4 col-6 text-center mb-3">
                        <div class="text-center">
                            <img src="assets\imgs\harshit-sir-icon.png" class="mb-3 team-member rounded-circle" />
                        </div>
                        <h6 class="font-weight-bold mb-0">Harshit Nagar</h6>
                        <!-- <p class="">Co-Founder / COO</p> -->
                    </div>
                    <div class="col-sm-4 col-6 text-center mb-3">
                        <div class="text-center">
                            <img src="assets\imgs\kushal-sir-icon.png" class="mb-3 team-member rounded-circle" />
                        </div>
                        <h6 class="font-weight-bold mb-0">Kushal Jain</h6>
                        <!-- <p class="">Head of Sales</p> -->
                    </div>
                    <div class="col-sm-4 col-6 text-center mb-3">
                        <div class="text-center">
                            <img src="assets\imgs\amanpreet-sir-icon.png" class="mb-3 team-member rounded-circle" />
                        </div>
                        <h6 class="font-weight-bold mb-0">Amanpreet Singh</h6>
                        <!-- <p class="">Head of R&D</p> -->
                    </div>
                    <div class="col-sm-4 col-6 text-center mb-3">
                        <div class="text-center">
                            <img src="assets\imgs\aviral-sir-icon.png" class="mb-3 team-member rounded-circle" />
                        </div>
                        <h6 class="font-weight-bold mb-0">Aviral Chaudari</h6>
                        <!-- <p class="">Product Designer</p> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-2 border border-dark rounded mb-5 mb-md-2" style="position: relative;">
            <div class="d-flex flex-wrap justify-content-between">
                <div class="col-md-10 col-12 mb-3">
                    <p>
                        Vikash is a seasoned Investment Banker, and is currently working as
                            Zonal Head & Senior Vice President - Corporate Banking at IndusInd
                            Bank leading team of Professionals who are into Origination and
                            Relationship Management for Corporate Banking (Mid Market Clients).
                            Working on various solutions involving Structured Finance, Working
                            Capital Finance, Transaction Banking, Project and Corporate Finance.
                            Vikas has also done an Advance Programme in Fintech and Financial
                            Blockchain from IIM-C. Vikas has passion towards newer technologies
                            and its mass implementation at School level. He is advising
                            LearningBix since its inception.
                    </p>
                </div>
                
                <div class="col-md-2 col-12 mb-3">
                    <div class="text-center">
                        <img src="assets\imgs\vikas.jpeg" class="mb-3 team-member rounded-circle" />
                        <h6 class="font-weight-bold mb-0">Vikash Ranjan</h6>
                    </div>
                </div>
            </div>
            
            <button class="font-weight-bold btn btn-sm text-white bg-primary vision">
                Our Advisor
            </button>
        </div>
    </div>
</section>


<!-- Founder Message -->
<section class="container-fluid py-5 bg-primary">
    <div class="container">
        <div class="d-flex flex-wrap">
            <div class="col-12 col-md-6 mb-3">
                <div class="mb-3">
                    <h2 class="font-weight-bold text-white">From Founder's Pen</h2>
                </div>
                <p class="text-white font-weight-light font-italic">
                    "LearningBix, a leading STEM Education service provider, offers a wide
                    range of technology to make the young generation future-ready with
                    21<sup>st</sup> century skills by offering age-appropriate robotics
                    kits to make technology a kids play. Over the course of years, we have
                    set-up training labs and organized intervention programs for over a
                    million students in 1000+ educational institutes spread across length
                    and breadth of the country.
                </p>
                <p class="text-white font-weight-light font-italic">
                    Focused on leveraging technology in education, we provide
                    project-based hands-on learning solutions for kids aged 2 to 18. The
                    objective of LearningBix is to embed curiosity, creativity and
                    imagination in young minds and inculcate computational thinking,
                    adaptive learning, physical computing skills in them."
                </p>
                <div class="row">
                    <div class="col-1 mb-3 mr-3">
                        <img class="rounded-circle" src="assets\imgs\nikhil-sir.jpeg" width="50px" height="50px" />
                    </div>
                    <div class="col mb-3">
                        <h4 class="text-white font-weight-bold mb-0">Nikhil Agrawal</h4>
                        <p class="text-white font-weight-bold">CEO & Founder</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-3">
                <div class="text-center mb-3">
                    <h2 class="font-weight-bold text-white">Skills Of Future</h2>
                </div>
                <img src="assets\imgs\IMAGE_3.png" class="img-fluid" />
            </div>
        </div>
    </div>
</section>

<!-- Numbers -->
<section class="container" style="padding: 80px 0">
    <!-- <div class="text-center mb-5">
        <h2 class="font-weight-bold">Numbers of LearningBIX</h2>
    </div> -->

    <div class="d-flex flex-wrap justify-content-center">
        <div class="col-md-3 col-6 text-center mb-2 d-flex flex-column align-content-center mb-3">
            <div>
                <h3 class="font-weight-bold mb-0 count1" style="display: inline" data-target="50000">
                    0
                </h3>
                <p style="display: inline; font-weight: bolder; font-size: 20px">+</p>
            </div>
            <p class="mb-2">Kits Delivered</p>
            <div class="text-center">
                <img src="assets\imgs\aid-kit.png" width="70px" height="70px" />
            </div>
        </div>
        <div class="col-md-3 col-6 text-center mb-2 d-flex flex-column align-content-center mb-3">
            <div>
                <h3 class="font-weight-bold mb-0 count" style="display: inline" data-target="1000">
                    0
                </h3>
                <p style="display: inline; font-weight: bolder; font-size: 20px">+</p>
            </div>
            <!-- <h3 class="font-weight-bold mb-0 count" data-target="1000">0</h3> -->
            <p class="mb-2">Schools</p>
            <div class="text-center">
                <img src="assets\imgs\school.png" width="70px" height="70px" />
            </div>
        </div>
        <div class="col-md-3 col-6 text-center mb-2 d-flex flex-column align-content-center mb-3">
            <div>
                <h3 class="font-weight-bold mb-0 count2" style="display: inline" data-target="1000000">
                    0
                </h3>
                <p style="display: inline; font-weight: bolder; font-size: 20px">+</p>
            </div>
            <!-- <h3 class="font-weight-bold mb-0 count2" data-target="1000000">0</h3> -->
            <p class="mb-2">Students</p>
            <div class="text-center">
                <img src="assets\imgs\student.png" width="70px" height="70px" />
            </div>
        </div>
        <div class="col-md-3 col-6 text-center mb-2 d-flex flex-column align-content-center mb-3">
            <div>
                <h3 class="font-weight-bold mb-0 count3" style="display: inline" data-target="20000">
                    0
                </h3>
                <p style="display: inline; font-weight: bolder; font-size: 20px">+</p>
            </div>
            <!-- <h3 class="font-weight-bold mb-0 count3" data-target="20000">0</h3> -->
            <p class="mb-2">Teachers Trained</p>
            <div class="text-center">
                <img src="assets\imgs\female.png" width="70px" height="70px" />
            </div>
        </div>
    </div>
</section>

<!-- Timeline -->
<section id="timeline" class="d-lg-flex d-none">
    <div class="tl-item">
        <div class="tl-bg" style="background-image: url(assets/imgs/2014-17.png)"></div>

        <div class="tl-year">
            <h4 class="font-weight-bold text-white my-2 custom-border">2014-2017</h4>
            <h4 class="font-weight-bold text-white my-2">Inception</h4>
        </div>

        <div class="tl-content">
            <ul style="list-style-type: none">
                <li>Partnership with 100+ Engineering Colleges</li>
                <li>Trained 1,00,000+ Engineering Students in Coding & Robotics</li>
            </ul>
        </div>
    </div>

    <div class="tl-item">
        <div class="tl-bg" style="background-image: url(assets/imgs/2017-18.png)"></div>

        <div class="tl-year">
            <h4 class="font-weight-bold text-white my-2 custom-border">2017-2018</h4>
            <h4 class="font-weight-bold text-white my-2 text-center">Genesis</h4>
        </div>

        <div class="tl-content">
            <ul style="list-style-type: none">
                <li>Introduced STEM Curriculum(Robotics) in schools</li>
                <li>
                    The group got featured in the approved vendors list for Atal Tinkering
                    Labs by NITI Aayog, Govt of India.
                </li>
            </ul>
        </div>
    </div>

    <div class="tl-item">
        <div class="tl-bg" style="background-image: url(assets/imgs/2018-19.png)"></div>

        <div class="tl-year">
            <h4 class="font-weight-bold text-white my-2 custom-border">2018-2019</h4>
            <h4 class="font-weight-bold text-white my-2 text-center">Flow</h4>
        </div>

        <div class="tl-content">
            <ul style="list-style-type: none">
                <li>Established R&D Facility for development of Robotics/Stem Kits</li>
            </ul>
        </div>
    </div>

    <div class="tl-item">
        <div class="tl-bg" style="background-image: url(assets/imgs/2019-20.png)"></div>

        <div class="tl-year">
            <h4 class="font-weight-bold text-white my-2 custom-border">2019-2020</h4>
            <h4 class="font-weight-bold text-white my-2 text-center">Growth</h4>
        </div>

        <div class="tl-content">
            <ul style="list-style-type: none">
                <li>
                    Established Robotics Lab & Intervention Programs in 1000+ Schools
                </li>
            </ul>
        </div>
    </div>

    <div class="tl-item">
        <div class="tl-bg" style="background-image: url(assets/imgs/2020-21.png)"></div>

        <div class="tl-year">
            <h4 class="font-weight-bold text-white my-2 custom-border">2020-2021</h4>
            <h4 class="font-weight-bold text-white my-2 text-center">Evolution</h4>
        </div>

        <div class="tl-content">
            <ul style="list-style-type: none">
                <li>
                    Introduced Online 1 to 1, Self-paced Coding Courses for Children Aged
                    6-18.
                </li>
                <li>Successfully Launched Propriety Robotics & STEM Kits</li>
            </ul>
        </div>
    </div>
</section>
<div class="container-fluid py-5 d-lg-none bg-primary">
    <div class="container">
        <div class="text-center mb-4">
            <h2 class="font-weight-bold text-white">How We Started ?</h2>
        </div>
        <div class="text-center">
            <img src="assets\imgs\timeline-img.png" class="img-fluid rounded" />
        </div>
    </div>
</div>

<!-- Our Partnerships -->
<section class="container py-5">
    <div class="text-center mb-4">
        <h2 class="font-weight-bold">Our Collaborators</h2>
        <p>
            LearningBix is honored to be recognized for its accomplishments as a
            global STEM education service provider known for its contribution to the
            education industry.
        </p>
    </div>
    <div class="d-flex flex-wrap justify-content-center align-items-center mb-3">
        <div class="col-6 col-md-3 col-lg-2 mb-3">
            <img src="assets\imgs\startup-india-logo.png" class="img-fluid bg-dark p-2" />
        </div>
        <div class="col-6 col-md-3 col-lg-2 mb-3">
            <img src="assets\imgs\acic-logo.png" class="img-fluid" />
        </div>
        <div class="col-6 col-md-3 col-lg-2 mb-3">
            <img src="assets\imgs\aim-logo.jpg" class="img-fluid" />
        </div>
        <div class="col-6 col-md-3 col-lg-2 mb-3">
            <img src="assets\imgs\dst-logo.jpg" class="img-fluid" />
        </div>
        <div class="col-6 col-md-3 col-lg-2 mb-3">
            <img src="assets\imgs\toycathon-final-logo.jpg" class="img-fluid" />
        </div>
        <div class="col-6 col-md-3 col-lg-2 mb-3">
            <img src="assets\imgs\miic-logo.png" class="img-fluid" />
        </div>
    </div>
</section>

<!-- Partner Schools -->
<div class="container-fluid pb-5">
    <div class="container">
        <div class="section-header text-center mb-5">
            <h2 class="font-weight-bold" style="font-size: 35px">
                Our Partner Schools
            </h2>
        </div>
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-1.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-2.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-3.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-4.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-5.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-6.png" class="mb-3 img-fluid" />
                        </div>
                    </div>
                </div>
                <div class="carousel-item py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-7.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-8.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-9.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-10.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-11.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-12.png" class="mb-3 img-fluid" />
                        </div>
                    </div>
                </div>
                <div class="carousel-item py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-13.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-14.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-15.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-16.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-17.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-18.png" class="mb-3 img-fluid" />
                        </div>
                    </div>
                </div>
                <div class="carousel-item py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-19.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-20.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-21.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-22.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-23.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-24.png" class="mb-3 img-fluid" />
                        </div>
                    </div>
                </div>
                <div class="carousel-item py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-25.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-26.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-27.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-28.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-29.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-30.png" class="mb-3 img-fluid" />
                        </div>
                    </div>
                </div>
                <div class="carousel-item py-4">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-31.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-32.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-33.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-34.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-35.png" class="mb-3 img-fluid" />
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4 text-center">
                            <img src="assets\imgs\school-logo\school-29.png" class="mb-3 img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span style="background-color: black" class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span style="background-color: black" class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</div>

<app-footer></app-footer>