import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../../validate-pattern';
import { Subscription } from 'rxjs';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pre-school-enroll-now-modal',
  templateUrl: './pre-school-enroll-now.component.html',
  styleUrls: ['./pre-school-enroll-now.component.scss']
})
export class PreSchoolEnrollNowBixModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  UserData = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription; stateList: any = [];
  pieAgeGroupList: any = [];
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public userService: UserService, private modalService: ModalService, public commonService: CommonService, public router: Router) { }
  get f() { return this.Form.controls; }
  ngOnInit(): void {
    this._employeeService.post('age_group_list_dropdown', { learning: '1' }).subscribe((res: any) => {
      if (!res.error) {
        this.pieAgeGroupList = res.data;
      }
    });
    this.Form = this.fb.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      state: ['', [Validators.required]],
      age: ['', [Validators.required]],
      password: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      gst: [''],
      phone_no: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    })
  }
  OnRegisterEnroll() {
    if (this.UserData) {
      this.router.navigate(['/checkout'])
      // this.modalService.setAppyCouponModal(true);
    } else {
      // this.modalService.setenrollnowBixModal(false);
      // this.modalService.setAppyCouponModal(true);
      // return
      this.submitted = true;
      if (this.Form.invalid) {
        return;
      } else {
        this.btnsubmitted = true;
        var sendRequestData: any;
        sendRequestData = this.Form.value;
        sendRequestData.learning = '1';
        // console.log('Enroll::::', this.Form.value);
        var sendRequestData = this.Form.value;
        if (localStorage.getItem('referral_code')) {
          sendRequestData.sponsor_code = localStorage.getItem('referral_code');
        }
        this.userService.userEnroll('register_student', sendRequestData).then(data => {
          // console.log('SEnD:::', data);
          if (data.replyCode == "success") {
            this.modalService.setenrollnowBixModal(false);
            // this.modalService.setAppyCouponModal(true);
            this.router.navigate(['/checkout'])
            var split_string = data.name.split(' ');
            var senRequestLeeddata: any = data;
            senRequestLeeddata.first_name = split_string[0];
            if (split_string.length > 2) {
              senRequestLeeddata.last_name = split_string[1] + ' ' + split_string[2];
            } else {
              senRequestLeeddata.last_name = split_string[1] || '';
            }
            // console.log('senRequestLeeddata:::::', senRequestLeeddata);
            // this.subscription = this._leadsquaredService.AddLeadCapture(senRequestLeeddata, this.pieAgeGroupList).subscribe(message => {
            //   // console.log(message)
            //   if (message) { message.Message.RelatedId }
            // })
          }
        });
      }
    }

  }
  onCloseModal() {
    this.modalService.setenrollnowBixModal(false);
  }
  onLoginEmail() {
    this.modalService.setloginemailBixModal(true);
  }
}
