import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd,ActivatedRoute, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BaseServiceService } from './service';
import { CommonService } from './service/common.service';
import { ModalService } from './service/modal.service';
import { Title, Meta } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'learningBixDesign';
  openPopup = false;
  openLoginPopup = false;
  openForgotPopup = false;
  openRegisterPopup = false;
  openPhonePopup = false;
  openPhoneOtpPopup = false;
  openBixPiePopup = false;
  openApplyCouponPopup = false;
  openEnrollPopup = false;
  openLoginEmailPasswordPopup = false;

  
  openPiePopup = false;
  openBasicModal = false;
  openOfferModal = false;
  openBookaFreeModal = false;
  openBookaFreeStep2Modal = false;
  selectPanelOption = false;
  loader_payment = false;
  openGuestLoginModal = false;
  panel = '';
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private gtmService: GoogleTagManagerService,private modalService: ModalService,
    public _employeeService: BaseServiceService,public activatedRoute: ActivatedRoute, public common: CommonService) {
    
    // Local Data
    if (localStorage.getItem('user') == undefined) {
      localStorage.removeItem('user')
    }
    if (localStorage.getItem('sid') == undefined) {
      localStorage.removeItem('sid')
    }
    // Local Data
    this.activatedRoute.queryParams.forEach((urlParams) => {
      if (urlParams['refferal']) {
        localStorage.setItem('referral_code', urlParams['refferal']);
        // console.log('referral_code:::::',localStorage.setItem('referral_code', urlParams['refferal']))
      } else {
        // localStorage.removeItem('referral_code');
      }
  });
  }
  customEvent() {
    const gtmTag = {
      event: 'button-click',
      data: 'my-custom-event',
    };
    this.gtmService.pushTag(gtmTag);

  }

  ngOnInit() {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {
 
        var rt = this.getChild(this.activatedRoute)
 
        rt.data.subscribe(data => {
          console.log(data);
          this.titleService.setTitle(data.title)
 
          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }
 
          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }
 
          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }
 
          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }
 
          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }
 
          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }
 
 
        })
 
      })


    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
    // console.log('Referral:::Code::::')
    this.modalService.getbookDemoClassBixModal().subscribe(response => this.openPopup = response);
    this.modalService.getloginemailBixModal().subscribe(response => this.openLoginPopup = response);
    this.modalService.getforgotBixModal().subscribe(response => this.openForgotPopup = response);
    this.modalService.getregisterBixModal().subscribe(response => this.openRegisterPopup = response);
    this.modalService.getloginphoneBixModal().subscribe(response => this.openPhonePopup = response);
    this.modalService.getloginphoneOtpBixModal().subscribe(response => this.openPhoneOtpPopup = response);
    this.modalService.getBixPieModal().subscribe(response => this.openBixPiePopup = response);
    this.modalService.getAppyCouponModal().subscribe(response => this.openApplyCouponPopup = response);
    this.modalService.getenrollnowBixModal().subscribe(response => this.openEnrollPopup = response);
    this.modalService.getbookDemoClassPieModal().subscribe(response => {
      // console.log('response::::Pie', response);
      return this.openPiePopup = response
    });
    this.modalService.getBookAFreeStep2Modal().subscribe(response => {
      // console.log('response::::Pie', response);
      return this.openBookaFreeStep2Modal = response
    });

    this.modalService.GetBasicModal().subscribe(response => this.openBasicModal = response);
    // this.modalService.GetOfferModal().subscribe(response => this.openOfferModal = response);
    this.modalService.getBookAFreeModal().subscribe(response => this.openBookaFreeModal = response);

    this.modalService.GetloginemailPasswordBixModal().subscribe(response => this.openLoginEmailPasswordPopup = response);



    this.common.GetPanel().subscribe(response => {
      console.log('response::::Panel', response);
      // return this.openPiePopup = response
    });

    this._employeeService.getOffer().subscribe(data => {
      // console.log('data::::',data);
      // console.log('data::::::',window.location.pathname)
      var pathName= window.location.pathname;
      console.log("pathname ::::::: ",window.location.pathname)


      var splitPath= pathName.split('/');
      console.log('splitPath::::::',splitPath);
      setInterval(()=>{
        if(window.location.pathname=='/'){
          this.openOfferModal=true
        }
        else{
          this.openOfferModal=false
        }
        if(splitPath[1]==''){
          this.modalService.GetOfferModal().subscribe(response => {
            console.log('response::::openOfferModal', response);
            return this.openOfferModal = response
          });
        }
      },5000)
  

      if(data.flag == 1){
        if(splitPath[1] !='robotics'){
          this.modalService.SetOfferModal(true);
        }
        
      }else{
        this.modalService.SetOfferModal(false);
      }
    });
    this.modalService.GetLoader().subscribe(response => this.loader_payment = response);
    this.modalService.GetGuestLogin().subscribe(response => {
      console.log('response::::openGuestLoginModal', response);
      return this.openGuestLoginModal = response
    });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }

}
 