import { Component, OnInit } from '@angular/core';
import { StarRatingComponent } from 'ng-starrating';
import { DomSanitizer} from '@angular/platform-browser';
import { BaseServiceService } from 'src/app/service';
import { ActivatedRoute, Router } from '@angular/router';

import { DatePipe } from '@angular/common';

import { CommonService } from '../../service/common.service';
import { environment } from '../../../environments/environment';

import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/service/modal.service';
import { EnvService } from 'src/app/service/env.service';
declare var Razorpay: any;
declare var $ : any;
@Component({
  selector: 'app-early-learning',
  templateUrl: './early-learning.component.html',
  styleUrls: ['./early-learning.component.scss'],
  providers: [DatePipe],

})
export class EarlyLearningComponent implements OnInit {
  active = 1;
  ProjectsList = []; 
  AgeGroupList = [];
  AgeGroupCoursesList = [];
  options: any =environment.razorPayoptions;
  Amount:number=0;
  Course_Id:any;
    IndexId:any;
    AgeGroupId:any;
    htmlContent:any;
  classSetting: any;
  
  subscription: Subscription;
  constructor(public activatedRoute:ActivatedRoute,public _employeeService: BaseServiceService,public common:CommonService, private sanitizer: DomSanitizer, private _datePipe: DatePipe,  private modalService: ModalService, public router: Router, private envService: EnvService) { }
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    alert(`Old Value:${$event.oldValue}, 
      New Value: ${$event.newValue}, 
      Checked Color: ${$event.starRating.checkedcolor}, 
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }
  calculateSave(item){
    let fackPrice=parseInt(item.fake_price);
    let actualPrice= parseInt(item.amount);
     let discountAmount= fackPrice-actualPrice
    let percent= (discountAmount*100/fackPrice);
    return `${percent.toFixed(2)}%`
  }
  ngOnInit(): void {
    this.GetProjectsList();
    this._employeeService.post('age_group_list_dropdown', {learning:'1'}).subscribe((res: any) => {
      if (!res.error) {
        // console.log('Response::::',res);
        this.AgeGroupList= res.data;
        this.AgeGroupId=this.AgeGroupList[0].id;
        if(res.data.length> 0){
          this.common.GetAgeCourseType().subscribe(data =>{
            // console.log('Group::ID::',data);
            this.IndexId= data;
            // this.getCoursesList(data);
            if(data == ''){
              this.getCoursesList(this.AgeGroupId);
            }else{
              this.getCoursesList(data);
            }
          })
        }
        // this.getCoursesList(this.AgeGroupList[0].id);
      }
    });
    this._employeeService.post('info_demo_class_settings', { id: '1' }).subscribe((res: any) => {
      if (!res.error) {
        // console.log('Response::::',res);
        this.classSetting = res.data;
        let subscribeDay= +this.classSetting.shipping_days
        let today_Date= new Date();
        let courseStartDate = new Date(new Date(today_Date).getTime() + subscribeDay*24*60*60*1000);
        this.closestMonday(courseStartDate)
      }
    });
    
  }
  next_Sunday;
  courseStartDate;
  closestMonday = (date) => {
    let toDay_date = new Date(); // current date
    var curr_date = new Date(date); // current date
  //  console.log('date', curr_date);
    var day_info = 8.64e+7; // milliseconds per day
    var days_to_monday = 7 - curr_date.getDay(); // days left to closest Monday
    var monday_in_sec = curr_date.getTime() + days_to_monday * day_info; // aleary Monday in seconds from 1970 x
    this.next_Sunday = new Date(monday_in_sec); // Monday in date object
    this.next_Sunday.setHours(0,0,0);
    // console.log('next_Sunday', this.next_Sunday)
    this.courseStartDate=this.next_Sunday;
    return this.next_Sunday;
}
  getDescrption(HtmlDescrption){
    return this.sanitizer.bypassSecurityTrustHtml(HtmlDescrption);
  }

  getCoursesList(agegroupid){
    this.IndexId= agegroupid;
    var requestData={
      age_group_id:String(agegroupid),
      featured:1,
      learning: '1'
    }
    this._employeeService.post('live_courses_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupCoursesList= res.data;
        // console.log('AgeGroupCoursesList::::',this.AgeGroupCoursesList);
      }
    });
  }
  GetProjectsList() {
    var self = this;
    self._employeeService.post('group_post_list', {featured:1}).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.ProjectsList = res.data;
          // console.log('ProjectsList::::',this.ProjectsList);
          // console.log('SelfPaceDataFilter::::',this.SelfPaceDataFilter);
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
  public couponCodeValue = '';
  public couponValue = 0;
  public TotalPayAmount;
  public isApply=false;
  public TotalDiscountValue=0;
  public isInvalid=false;


  onBuy(SelfPaceObject) {
    localStorage.setItem('checkout', JSON.stringify(SelfPaceObject));
    window.location.href = `${this.envService.env}/checkout`;
    // this.router.navigate(['/checkout']);
  }
}
