import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAphpaOnly]'
})
export class AphpaOnlyDirective {


  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    //A-Za-z0-9? ,_-
     this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z ,_-]*/g, '');
    if ( initalValue !== this._el.nativeElement.value) { 
      
      event.stopPropagation();
    }
  }

}