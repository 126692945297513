<app-pie-header></app-pie-header>
<section class="pie-offerings">
    <div class="container">
      <h2>Our Offerings</h2>
  
      <div class="row">
        <div class="col-12">
          <div id="">
            <h3>Select age group</h3>
            <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
              <li class="nav-item " role="presentation" *ngFor="let age of AgeGroupList;let i =index;">
                <span class="text-ageTitle">{{age.title}}</span>
                <a class="nav-link" id="grade1-tab" [ngClass]="age.id ==IndexId ? 'active':''"
                  (click)="getCoursesList(age.id)">
                  <img src="{{age.image}}" class="img-fluid age-group-icon" alt="">
                  <strong>{{age.age_from}}-{{age.age_to}} Yrs</strong></a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="grade1" role="tabpanel" aria-labelledby="grade1-tab">
                <div class="row">
                  <ng-container *ngFor="let agecourses of AgeGroupCoursesList;let i=index">
  
                    <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                      <div class="card card-item">
  
                        <div class="card-body">
                          <h3 class="card-title age-title">{{agecourses.course_name}}</h3>
                          <span style="font-size: 15px">{{agecourses.tag}}</span>
                          <div class="decs-pie-data" [innerHtml]="getDescrption(agecourses.description)"
                            style="margin-top: 20px;"> </div>
                          <h4 class="text-center mt-2 mb-4">Rs. {{agecourses.amount}}/- <span
                              style="color:red; ">Rs.<s>{{agecourses.fake_price}}/-</s></span></h4>
                          <h3 class="text-center mt-4 mb-4">Save {{calculateSave(agecourses)}} </h3>
                          <div class="price-box">
                            <ul class="pricing-details">
  
                              <li><a href="javascript:void(0);" class="text-white" (click)="onBuy(agecourses)">Enroll
                                  Now</a></li>
                            </ul>
                          </div>
                        </div>
                        <div class="card-border-box"></div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
             </div>
  
          </div>
        </div>
      </div>
  
    </div>
  </section>
<app-pie-footer></app-pie-footer>