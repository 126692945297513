import { CommonService } from './../../service/common.service';
import { environment } from './../../../environments/environment';
import { StarRatingComponent } from 'ng-starrating';
import { Component, OnInit } from '@angular/core';
 
import { BaseServiceService } from 'src/app/service';
import { ActivatedRoute } from '@angular/router';
declare var Razorpay: any;
declare var $ : any;

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  active = 1;
  SlefPaceId:any;
  SelfPaceData:any;
  SelfPaceDataFilter:any;
  learnData:any=[];
  benefitsData:any=[];
  kidsData:any=[];
  options: any =environment.razorPayoptions;
  Amount:number=0;
  Course_Id:any;

  avengers = []; 
  TestimonialsList = []; 
  subscription: Subscription;
  constructor(public activatedRoute:ActivatedRoute,public _employeeService: BaseServiceService,public common:CommonService) {
    this.SlefPaceId = this.activatedRoute.snapshot.paramMap.get("id");
    // console.log('SlefPaceId:::;',this.SlefPaceId);
    this.GetTestimonialsList();
   }

  ngOnInit(): void {
    this.avengers = 
        [{ id: 1, naming: 'Captain America', city:'US' }, 
        { id: 2, naming: 'Thor' , city:'Asgard'}, 
        { id: 3, naming: 'Iron Man' , city:'New York'}, 
        { id: 4, naming: 'Spider Man' , city:'Spiderverse'}, 
        { id: 5, naming: 'Doctor Strange', city:'Nepal' }, 
        { id: 6, naming: 'Black Panther' , city:'Wakanda'}, 
        { id: 7, naming: 'Hulk' , city:'US'}]; 
  }
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    alert(`Old Value:${$event.oldValue}, 
      New Value: ${$event.newValue}, 
      Checked Color: ${$event.starRating.checkedcolor}, 
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }
  SelfPaceDetailsData(id) {
    var self = this;
    let requestData = {
      course_id: id
    }
    self._employeeService.post('self_page_courses_details', requestData).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.SelfPaceData = res.data[0];
          // console.log('SelfPaceData::::',this.SelfPaceData);
          if(this.SelfPaceData.info.length > 0){
            this.learnData= this.SelfPaceData.info.filter(x => x.type == 1);
            this.benefitsData= this.SelfPaceData.info.filter(x => x.type == 2);
            this.kidsData= this.SelfPaceData.info.filter(x => x.type == 3);
            // console.log('learnData::::',this.learnData);
            // console.log('benefitsData::::',this.benefitsData);
            // console.log('kidsData::::',this.kidsData);
          }
         
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
  GetTestimonialsList() {
    var self = this;
    let requestData = {
      id: this.SlefPaceId
    }
    self._employeeService.post('web_student_testimonials_list', {}).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.TestimonialsList = res.data;
          // console.log('TestimonialsList::::',this.TestimonialsList);
          // console.log('SelfPaceDataFilter::::',this.SelfPaceDataFilter);
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
  onBuy(SelfPaceObject){
    // console.log('SelfPaceObject::::',SelfPaceObject);
    this.Course_Id= SelfPaceObject.id;
    this.Amount=SelfPaceObject.amount;
    if(localStorage.getItem('user')){
      // this.payNow(event)
    }else{
      this.common.SetLoginType('email');
      // $('#loginModal').modal('show');
      // this._employeeService.showError('Please Login');
    }
   
  }
  
}
