import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { BaseServiceService } from 'src/app/service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
  SelfPaceData: any;
  ageGroupOneData: any;
  ageGroupTwoData: any;
  ageGroupThreeData: any;
  ageGroupFourData: any;
  ageGroupFiveData: any;
  constructor(public _employeeService: BaseServiceService,public route:Router) {
    this.GetSelfPaceList();
  }

  ngOnInit(): void {
  }
  GetSelfPaceList() {
    var self = this;
    let requestData = {
      keyword: '',
      featured:1
    }
    self._employeeService.post('self_page_courses_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.SelfPaceData = res.data;
          this.ageGroupOneData = this.SelfPaceData.filter(data=>data.age_group_title==='Foundation');
          this.ageGroupTwoData =this.SelfPaceData.filter(data=>data.age_group_title==='Beginner');
          this.ageGroupThreeData =this.SelfPaceData.filter(data=>data.age_group_title==='Intermediate');
          this.ageGroupFourData =this.SelfPaceData.filter(data=>data.age_group_title==='Advance');
          this.ageGroupFiveData =this.SelfPaceData.filter(data=>data.age_group_title==='Professional');
          
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
  onDetails(id){
    this.route.navigate(['description/'+id]);
  }
  viewAll(){
    this.route.navigate(['all-courses']);

  }
}


