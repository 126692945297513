<!-- Facebook Pixel Code -->
<script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '767965727313084');
  fbq('track', 'PageView');
  </script>
  <noscript><img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=767965727313084&ev=PageView&noscript=1"
  /></noscript>
  <!-- End Facebook Pixel Code -->

<nav class="navbar navbar-expand-lg navbar-light bg-white sticky-top">
  <div class="container-fluid">
    <a class="navbar-brand" href="/"><img src="assets/imgs/logo-pie.png" alt=""></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto ml-auto">
        <li class="nav-item active">
          <a class="nav-link" href="javascript:void(0);" style="padding: 10px 0px;">Age Group <span
              class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item dropdown">
          <select name="" id="" class="nav-link dropdown-toggle" (change)="onChangeAgeCourse($event.target.value,'2')">
            <option selected value="" disabled>Select Age Group</option>
            <option *ngFor="let age_group of AgeGroupList" [value]="age_group.id">
              {{age_group.age_from}}-{{age_group.age_to}} yrs</option>
          </select>
        </li>
        <li class="nav-item pl-4 pr-4"></li>
      </ul>
      <button class="btn btn-primary btn-book-class mr-3" type="button" (click)="onOpenPieModal()" *ngIf="learningStatus">Book your Trial</button>
      <button class="btn btn-outline-primary my-2 my-sm-0 my-lg-0" type="button" (click)="onLoginPanelPopup()"
        style="padding: 5px 0px;font-size: 15px;" *ngIf="!UserLoginFlag">Login</button>&nbsp;
      <button class="btn btn-outline-primary my-2 my-sm-0 my-lg-0 ml-2" type="button"
        style="padding: 5px 0px;font-size: 15px;" *ngIf="UserLoginFlag" (click)="onRedirect()">My Profile</button>
    </div>
  </div>
</nav>