import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../validate-pattern';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
import { EnvService } from 'src/app/service/env.service';
@Component({
  selector: 'app-bix-pie-modal',
  templateUrl: './bix-pie-modal.component.html',
  styleUrls: ['./bix-pie-modal.component.scss']
})
export class BixPieModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public userService: UserService, private modalService: ModalService, private envService: EnvService) { }
  ngOnInit(): void {
    this.Form = this.fb.group({
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    })
  }
  get f() { return this.Form.controls; }  
  gotoProfile(value) {
    if (value === 'Pie') {
      window.location.href = `${this.envService.env}/student/dashboard-learning`;
    }
    else {
      window.location.href = `${this.envService.env}/student/dashboard`;
    }
  }
  onCloseModal() {
    this.modalService.setforgotBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }
}
