<footer>
    <section class="footer-top py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <img src="assets/imgs/footerlogo.png" class="img-fluid mb-3" alt="">
                    <p>
                        Inculcating curiosity in every child, designed for the unique child requirements. Learning through the carefully designed scientific and sequential learning modules, unleash the creativity of your child.
                    </p>
                    <div class="row">
                        <div class="col-md-6 col-sm-12 h-100">
                            <a href="mailto:contact@learningbix.com" class="email">contact@learningbix.com</a>
                            <address>Plot No. 4,  GST Lane, Near<br>
                                 OTS Crossing, JLN Marg, Jaipur</address>
                            <h3 class="follow-title">Follow Us</h3>
                            <ul class="social-links alignment justify-content-center justify-content-md-start">
                                <li>
                                    <a href="https://www.facebook.com/learningbixCoding" target="_blank"><img src="../../../assets/imgs/facebook-f.svg" class="img-fluid" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/82140181/" target="_blank"><img src="../../../assets/imgs/linkedin-in.svg" class="img-fluid" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/learningbix" target="_blank"><img src="../../../assets/imgs/twitter.svg" class="img-fluid" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCRR3fLQ9GUbcF6-pptJb_tg" target="_blank"><img src="../../../assets/imgs/youtube2.png" class="img-fluid" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/learningbix/" target="_blank"><img src="../../../assets/imgs/insta2.png" class="img-fluid" alt=""></a>
                                </li>

                            </ul>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="row foo-links">
                                <div class="col">
                                    <h3>Company</h3>
                                    <ul class="nav flex-column">
                                        <li class="nav-item"><a class="nav-link pl-0" routerLink="/">Home</a></li>
                                        <li class="nav-item"><a class="nav-link pl-0" routerLink="/about" href="javascript:void(0);">About Us</a></li>
                                        <!-- <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link pl-0" (click)="onBlogRedirect()">Blog</a>
                                        </li> -->
                                        <li class="nav-item">
                                            <a href="https://blog.learningbix.com/" class="nav-link pl-0">Blog</a>
                                        </li>
                                        <li class="nav-item"><a class="nav-link pl-0" routerLink="/page/refund-policy">Refund policy</a></li>
                                        <li class="nav-item"><a class="nav-link pl-0" routerLink="/contact-us">Contact Us</a></li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <h3>Offering</h3>
                                    <ul class="nav flex-column">
                                        <!-- <li class="nav-item" *ngFor="let agegroup of AgeGroupList"><a class="nav-link pl-0" href="javascript:void(0);">Age {{agegroup.age_from}}-{{agegroup.age_to}}</a></li> -->
                                        <!-- <li class="nav-item"><a class="nav-link pl-0" href="javascript:void(0);" (click)="onPreSchool('course-pie')" routerLink="/pie">Pre-School </a></li> -->
                                        <li class="nav-item"><a class="nav-link pl-0" href="https://learningpie.in/">Pre-School </a></li>
                                        <li class="nav-item"><a class="nav-link pl-0" href="javascript:void(0);" routerLink="/coding"> Coding</a></li>
                                        <!-- <li class="nav-item"><a class="nav-link pl-0" href="javascript:void(0);" routerLink="/coding    `">Robotics</a></li> -->
                                        <li class="nav-item"><a class="nav-link pl-0" href="javascript:void(0);" routerLink="/courses"> Self Paced</a></li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <h3>Resources</h3>

                                    <ul style="list-style-type:none;">
                                        <li>
                                            <a style="color: white;cursor: pointer;" (click)="goToLink(isLinkSetting?.stem_lab_link)"> STEM Lab Brochure</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a style="color: white;cursor: pointer;"  (click)="goToLink(isLinkSetting?.robotics_lab_link)"> Robotics Lab Brochure</a>
                                        </li>
                                        <br>
                                        <li> <a style="color: white;cursor: pointer;"  (click)="goToLink(isLinkSetting?.coding_lab_link)">Coding Lab Curriculum</a></li>
                                        <br>
                                        <li> <a style="color: white;cursor: pointer;"  (click)="goToLink(isLinkSetting?.robotics_curr_lab_link)"> Robotics Lab Curriculum </a></li>
                                        <!-- <li> <a href="\robotics\shop"> Shop </a></li> -->
                                        <!-- <li> <a href="\robotics\stem" target="_blank"> Stem </a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 get-in-touch">
                    <h2>Get In Touch</h2>
                    <form action="javascript:void(0);" [formGroup]="AddFormContact">
                        <div class="form-group">
                            <input type="text" placeholder="Your Name" class="form-control" [ngClass]="{ 'is-invalid': submittedContact && fContact.first_name.errors }" formControlName="first_name">
                            <!-- <div *ngIf="submittedContact && fContact.first_name.errors">
                                <small *ngIf="fContact.first_name.errors.required" class="error_class">
                                    This Field is required
                                </small>
                            </div> -->
                        </div>
                        <div class="form-group">
                            <input type="email" placeholder="Email ID" class="form-control" [ngClass]="{ 'is-invalid': submittedContact && fContact.email.errors }" formControlName="email">
                            <!-- <div *ngIf="submittedContact && fContact.email.errors">
                                <small *ngIf="fContact.email.errors.required" class="error_class">
                                    This Field is required
                                </small>
                                <small [hidden]="!fContact.email.errors.patternInvalid" class="error_class">Please enter valid email.</small>
                            </div> -->
                        </div>
                        <div class="form-group">
                            <input type="text" placeholder="Contact No" class="form-control" appNumbersOnly [ngClass]="{ 'is-invalid': submittedContact && fContact.contact.errors }" formControlName="contact" maxlength="10">
                            <!-- <div *ngIf="submittedContact && fContact.contact.errors">
                                <small *ngIf="fContact.contact.errors.required" class="error_class">
                                    This Field is required
                                </small>
                                <small [hidden]="!fContact.contact.errors.patternMobileInvalid" class="error_class">Phone Number should be valid and minimum 8 to maximum 12 digit.</small>
                            </div> -->
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" rows="4" placeholder="Type your question" [ngClass]="{ 'is-invalid': submittedContact && fContact.description.errors }" formControlName="description"></textarea>
                            <!-- <div *ngIf="submittedContact && fContact.description.errors">
                                <small *ngIf="fContact.description.errors.required" class="error_class">
                                    This Field is required
                                </small>
                            </div> -->
                        </div>

                        <div class="row">
                            <button class="btn btn-send float-right col-6 col-sm-6 offset-3 offset-sm-3" type="submit" (click)="onSubmitContact()" [disabled]="btnsubmittedContact">Send</button>

                        </div>
                        <div class="clearfix"></div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <section class="footer-bottom">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col mr-md-auto mt-3">
                    <h6 class="footerheading">XAP EDUBOTS TECHNOLOGIES PRIVATE LIMITED | GST NO: 08AAACX2488E1Z7</h6>
                </div>
                <nav class="nav ml-md-auto">
                    <a class="nav-link footertext" routerLink="/page/privacy-policy">Privacy Policy</a>
                    <a class="nav-link footertext" routerLink="/page/terms">Terms of use</a>
                </nav>
            </div>
        </div>
    </section>
</footer>



<!-- Student Login And Register -->

<!--Login Modal -->
<!-- <div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: none;">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 3px 10px;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2" style="    height: 550px;">
          <div class="row" *ngIf="Email_Flag">
              <div class="col-6">
                  <img src="../../../assets/imgs/loginModalBanner.jpg" class="img-fluid w-100" alt="Login Banner" style="
                  height: 86%;
              ">
              </div>
              <div class="col-6">
                <h1 style="font-size: 30px;">Login</h1>

                <form [formGroup]="LoginForm">
                    <div class="form-group mb-0">
                        <label for="Email">Email</label>
                        <input type="email" placeholder="Enter Email" class="form-control pl-0" id="Email" formControlName="email" [ngClass]="{ 'is-invalid': Loginsubmitted && fLogin.email.errors }">
                        <div *ngIf="Loginsubmitted && fLogin.email.errors">
                            <small *ngIf="fLogin.email.errors.required" class="text-danger">
                                This Field is required
                            </small>
                            <small [hidden]="!fLogin.email.errors.patternInvalid" class="error_input">Please enter valid email.</small>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <label for="Password">Password</label>
                        <input type="password" placeholder="Enter Password" class="form-control pl-0" id="Password" formControlName="password" [ngClass]="{ 'is-invalid': Loginsubmitted && fLogin.password.errors }">
                        <div *ngIf="Loginsubmitted && fLogin.password.errors">
                            <small *ngIf="fLogin.password.errors.required" class="text-danger">
                                This Field is required
                            </small>
                            <small *ngIf="fLogin.password.errors.minlength || fLogin.password.errors.maxlength" class="error_input">Password
                                length should be atleast 4 and maximum 20</small>
                        </div>
                    </div>
                    <button class="btn btn-block btn-login mt-3" (click)="onLoginPanel()" [disabled]="Loginbtnsubmitted">Login</button>

                    <p class="mt-1 mb-1 text-center seprator">or</p>
                    <button type="button" class="btn btn-block btn-login-with-phone mb-4" (click)="onLoginType('mobile')">Login with Phone</button>
                    <button type="button" class="btn btn-block btn-login-with-fb mb-4"><i class="fab fa-facebook"></i> Login with Facebook</button>
                    <button type="button" class="btn btn-block btn-login-with-gp mb-4"><img src="../../../assets/imgs/google-icon.png" class="img-fluid" alt=""> Login with Google</button>
                    <p class="register-link">Not registered yet? <a href="javascript:void(0);" data-dismiss="modal" data-toggle="modal" data-target="#RegisterModal">Register Now</a></p>
                </form>
              </div>
          </div>
        </div>
      </div>
    </div>
</div> -->
