<app-header></app-header>
<section>
    <div class="container">
        <div class="row my-4">
            <div class="col-12 text-center">
                <h4 class="bold">Featured Projects</h4>
                <p>We’re devoted to our mission of making this generation creators versus consumers of technology.<br> Reach out to us for any assistance.</p>
            </div>
        </div>
        <div class="row">
            <ng-container *ngFor="let project_list of ProjectsList">
              <div class="col-lg-4 mt-2 mb-2">
                <div class="card pt-4 pb-4 pl-3 pr-3">
                  <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{project_list.post_image}}" class="img-fluid mb-3" alt=""
                    style="width: 100%;
                    max-height: 200px;
                    min-height: 200px;">
                  <h3>{{project_list.post_title}}</h3>
                  <p class="mb-0">{{project_list.post_description}}</p>
                </div>
              </div>
            </ng-container>
          </div>
        
    </div>
</section>


<app-footer></app-footer>