<footer class="footer">

    <section class="footer-about">
        <div class="container">
            <div class="section-title">
                <h2 class="mb-0">
                    About Us
                </h2>
                <p>We are a leading education services provider, after having set up training labs and intervention programs for over a million students, spread over 1000+ schools, spread across the length and breadth of India, and after hundreds of hours
                    of research with the best early age educators in India and abroad, is proud to present LearningPie.</p>
                <p>
                    LearningPie is a combined effort of educators, child psychologists, parenting experts and innovators to bring your child an all-round learning experience which aims at making conceptual learning, a child’s play and part of their lifelong learning process.
                </p>
                <p>
                    <a *ngIf="!isReadMore" href="javascript:void(0);" (click)="onReadMore()">Read More</a>

                </p>
                <ng-container *ngIf="isReadMore">
                    <p>LearningPie is conceptualized with a solitary objective of helping young children develop a sense of ‘how to learn’, through a very carefully designed curriculum, which borrows the best capabilities of some of the world’s most popular
                        curricula- viz. Montessori, Play Way, Bank Street, Waldorf, STEAM etc.
                    </p>
                    <p>This has helped us create a modular 50 month curriculum, which starts when a child is 1.5 years old, till the time a kid attains an age of 6 years. The unique modular nature eliminates the timing and minimizes interferences in a child’s
                        upbringing. Continual R&D by the best educators around the Globe ensures that our models are always updated and the child makes the best use of their time. Studies indicate that a child’s mental development and curiosity levels
                        peak in these early years. When a child is trained and exposed to 50 months of fun-filled, scientifically calibrated activities, not only are they able to get sensitized to their environment and society in general, but also to
                        the finer nuances- like writing, speaking, mathematics etc- that help them get into a school of their choice easily. </p>
                    <a *ngIf="isReadMore" href="javascript:void(0);" (click)="onReadMore()">Less</a>
                </ng-container>
            </div>
        </div>
        <img src="../../../assets/imgs/pie-footer-wave.jpg" class="w-100" alt="">
    </section>
    <section class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <h3 class="foo-link-title">Our Specialites</h3>

                    <ul class="foo-links Our_Specialites">
                        <li><label class="foo-nav-link">Online Preschool for Nursery</label></li>
                        <li><label class="foo-nav-link">UKG Online Class</label></li>
                        <li><label class="foo-nav-link">Montessori Education</label></li>
                        <li><label class="foo-nav-link">Early Childhood Care and Education</label>
                        </li>
                    </ul>
                    <ul class="social-links my-2">
                        <li>
                            <a href="https://www.facebook.com/learningpiepreschool" target="_blank"><img src="assets/imgs/facebook-f.svg" class="img-fluid" alt=""></a>
                        </li>

                        <li>
                            <a href="https://twitter.com/learningpie1" target="_blank"><img src="assets/imgs/twitter.svg" class="img-fluid" alt=""></a>
                        </li>

                        <li>
                            <a href="https://www.linkedin.com/company/learningpie/" target="_blank"><img src="assets/imgs/linkedin-in.svg" class="img-fluid" alt=""></a>
                        </li>

                        <li>
                            <a href="https://www.youtube.com/channel/UCA7Wdl0Nk5bDNSHtSsd1qVQ" target="_blank"><img src="assets/imgs/Icons/white-youtube-logo.png" class="img-fluid" alt="" style="width:40px"></a>
                        </li>

                        <li>
                            <a href="https://www.instagram.com/learningpie_/" target="_blank"><img src="assets/imgs/Icons/insta.png" class="img-fluid" alt="" style="width:40px"></a>
                        </li>
                    </ul>

                </div>
                <div class="col-md-4">
                    <h3 class="foo-link-title text-center">We Accept</h3>
                    <div class="w-100 text-center">
                        <img class="my-2 img-fluid" src="assets/imgs/Icons/weacceptpart1.png" alt="">
                        <img class="my-2 img-fluid" src="assets/imgs/Icons/weacceptpart2.png" alt="">
                    </div>


                </div>

                <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-12 right-footer-section">
                            <h3 class="foo-link-title">Important Links</h3>
                            <ul class="foo-links">
                                <li><a href="/contact-us" class="foo-nav-link">Contact us</a> </li>
                                <li><a href="/page/privacy-policy" class="foo-nav-link">Privacy Policy</a> </li>
                                <li><a href="/page/terms" class="foo-nav-link">Terms & Conditions</a> </li>
                                <li><a href="https://blog.learningpie.in/" class="foo-nav-link">Blogs</a></li>
                                <!-- <li><a href="#" class="foo-nav-link">FAQs</a></li> -->
                            </ul>
                        </div>

                    </div>
                </div>

                <div class="container text-center copyright mt-3">
                    <h5>XAP EDUBOTS TECHNOLOGIES PRIVATE LIMITED</h5>
                    <p class="mt-2">Plot No. 4, Bhaskar Enclave, Near OTS Chaurahan, JLN Marg, Jaipur, Rajasthan, 302017 | GST NO: 08AAACX2488E1Z7</p>
                </div>
            </div>
        </div>
    </section>


</footer>



<!-- Student Login And Register -->

<!--Login Modal -->
<!-- <div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: none;">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 3px 10px;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2" style="    height: 550px;">
          <div class="row" *ngIf="Email_Flag">
              <div class="col-6">
                  <img src="../../../assets/imgs/loginModalBanner.jpg" class="img-fluid w-100" alt="Login Banner" style="
                  height: 86%;
              "> 
              </div>
              <div class="col-6">
                <h1 style="font-size: 30px;">Login</h1>
                
                <form [formGroup]="LoginForm">
                    <div class="form-group mb-0">
                        <label for="Email">Email</label>
                        <input type="email" placeholder="Enter Email" class="form-control pl-0" id="Email" formControlName="email" [ngClass]="{ 'is-invalid': Loginsubmitted && fLogin.email.errors }">
                        <div *ngIf="Loginsubmitted && fLogin.email.errors">
                            <small *ngIf="fLogin.email.errors.required" class="text-danger">
                                This Field is required
                            </small>
                            <small [hidden]="!fLogin.email.errors.patternInvalid" class="error_input">Please enter valid email.</small>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <label for="Password">Password</label>
                        <input type="password" placeholder="Enter Password" class="form-control pl-0" id="Password" formControlName="password" [ngClass]="{ 'is-invalid': Loginsubmitted && fLogin.password.errors }">
                        <div *ngIf="Loginsubmitted && fLogin.password.errors">
                            <small *ngIf="fLogin.password.errors.required" class="text-danger">
                                This Field is required
                            </small>
                            <small *ngIf="fLogin.password.errors.minlength || fLogin.password.errors.maxlength" class="error_input">Password
                                length should be atleast 4 and maximum 20</small>
                        </div>
                    </div>
                    <button class="btn btn-block btn-login mt-3" (click)="onLoginPanel()" [disabled]="Loginbtnsubmitted">Login</button>

                    <p class="mt-1 mb-1 text-center seprator">or</p>
                    <button type="button" class="btn btn-block btn-login-with-phone mb-4" (click)="onLoginType('mobile')">Login with Phone</button>
                    <button type="button" class="btn btn-block btn-login-with-fb mb-4"><i class="fab fa-facebook"></i> Login with Facebook</button>
                    <button type="button" class="btn btn-block btn-login-with-gp mb-4"><img src="../../../assets/imgs/google-icon.png" class="img-fluid" alt=""> Login with Google</button>
                    <p class="register-link">Not registered yet? <a href="javascript:void(0);" data-dismiss="modal" data-toggle="modal" data-target="#RegisterModal">Register Now</a></p>
                </form>
              </div>
          </div>
        </div>
      </div>
    </div>
</div> -->