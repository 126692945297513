<div class="modal popup" id="forgotPasswordModal" tabindex="-1" role="dialog" aria-labelledby="forgotPasswordModal"
    aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 3px 10px;"
                    (click)="onCloseModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2" style="height: auto;">
                <div class="row mt-1 mb-4">
                    <div class="col-12">
                        <h1 style="font-size: 20px; text-align: center;" class="my-1">Forgot Password</h1>

                        <form [formGroup]="Form">
                            <label for="otp1">Enter Email</label>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="email" class="form-control" id="email" formControlName="email" style="font-size: 15px;" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                        <div *ngIf="submitted && f.email.errors">
                                            <small *ngIf="f.email.errors.required" class="text-danger">
                                                This Field is required
                                            </small>
                                            <small [hidden]="!f.email.errors.patternInvalid" class="error_input">Please enter valid email.</small>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row align-items-center">

                                <div class="col-8">
                                    <button class="btn btn-sm btn-forgot" (click)="onSubmitForgot()" [disabled]="btnsubmitted">Submit</button>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>