<app-header></app-header>

<div class="container py-5">
  <div class="text-center mb-5">
    <h1>STEM Project Hub</h1>
    <p>Enhance your STEM learning skills with exciting DIY electronics, programming, IoT and robotics based projects
      for kids & hobbyists of all levels under one roof.</p>
  </div>



</div>
<div class="row">
  <div class="col-md-12">
    <ul class="nav nav-pills justify-content-center mx-auto mb-5 px-3" id="pills-level1-tab" role="tablist">
      <li class="nav-item" role="presentation" *ngFor="let blogCat of isParentBlogCategoryList;let i=index" style="margin: 10px 2px;" (click)="onChangeSubCategory(blogCat)">
        <button class="btn btn-sm btn-primary nav-link font-weight-bold" [ngClass]="isSelectMainCategory == blogCat.id?'active':''" id="pills-level1-{{blogCat.title}}-tab" data-toggle="pill" [attr.data-target]="'#pills-level1-' + blogCat.title" type="button" role="tab" attr.aria-controls="pills-level1-{{blogCat.title}}" aria-selected="false">{{blogCat.name}}</button>
      </li>
    </ul>
  </div>
  <div class="col-md-12" *ngIf="isParentBlogCategoryList2.length>0">
    <ul class="nav nav-pills justify-content-center mx-auto mb-5 px-3" id="pills-level2-tab" role="tablist">
      <li class="nav-item" role="presentation" *ngFor="let blogCat1 of isParentBlogCategoryList2;let i=index" style="margin: 10px 2px;" (click)="onChangeSubSubCategory(blogCat1)">
        <button class="btn btn-sm btn-primary nav-link font-weight-bold" [ngClass]="isSelectSubCategory == blogCat1.id?'active':''" id="pills-level2-{{blogCat1.title}}-tab" data-toggle="pill" [attr.data-target]="'#pills-level2-' + blogCat1.title" type="button" role="tab" attr.aria-controls="pills-level2-{{blogCat1.title}}" aria-selected="false">{{blogCat1.name}}</button>
      </li>
    </ul>
  </div>
  <div class="col-md-12" *ngIf="isParentBlogCategoryList3.length>0">
    <ul class="nav nav-pills justify-content-center mx-auto mb-5 px-3" id="pills-level3-tab" role="tablist">
      <li class="nav-item" role="presentation" *ngFor="let blogCat2 of isParentBlogCategoryList3;let i=index" style="margin: 10px 2px;" (click)="getBlogList(blogCat2.id)">
        <button class="btn btn-sm btn-primary nav-link font-weight-bold" id="pills-level3-{{blogCat2.title}}-tab" data-toggle="pill" [attr.data-target]="'#pills-level3-' + blogCat2.title" type="button" role="tab" attr.aria-controls="pills-level3-{{blogCat2.title}}" aria-selected="false">{{blogCat2.name}}</button>
      </li>
    </ul>
  </div>




  <!-- blog section -->
  <div class="container py-5" *ngIf="(isBlogFlag) && (isBlogList.length>0)">
    <div class="col-md-12 row blog-content">
      <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" *ngFor="let bblog of isBlogList">
        <div class="card rounded shadow">
          <div class="card-header p-0">
            <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{bblog.image}}" alt="" class="img-fluid">
          </div>
          <div class="card-body text-center">
            <h6 class="text-primary">{{bblog.title}}</h6>
            <a class="btn btn-sm btn-warning" href="{{bblog.slug}}" *ngIf="bblog.slug">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
