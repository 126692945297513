<div class="mt-5 my-4">
    <section class="testimonials">
        <div class="container">
          <h3 class="text-center text-white">Students and parents love️ us!</h3>
          <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              
              <div class="carousel-item" [ngClass]="i == '0' ? 'active':''" *ngFor="let data of TestimonialsList;let i=index">
                <div class="carousel-caption d-md-block">
                  <div class="row align-items-center">
                    <div class="col-md-3 col-sm-12"><img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{data.image}}" class="img-fluid testimonials_img" alt="" onError="this.src='../../assets/imgs/Ellipse 2.png'"></div>
                    <div class="col-md-9 col-sm-12 text-center">
                      <div style="text-align:center">
                        <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                      </div>
                      <p class="desription">{{data.message}}</p>
                      <!-- <span class="date">{{data.created |date}}</span> -->
                      <h6 class="name">{{data.student_name}}</h6>
                      <span class="age">Age {{data.age}}, Class {{data.student_class}}</span>
                    </div>
                  </div>
                  <!-- <h5>First slide label</h5> -->
                  <!-- <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> -->
                </div>
              </div>
              <!-- <div class="carousel-item">
                <div class="carousel-caption d-md-block">
                  <div class="row align-items-center">
                    <div class="col-md-3"><img src="../../assets/imgs/Ellipse 2.png" class="img-fluid" alt=""></div>
                    <div class="col-md-9 text-left">
                      <div style="text-align:center">
                        <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                      </div>
                      <p class="desription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et metus a turpis auctor fermentum id at mauris. Quisque mollis ex nunc, vitae gravida lacus ullamcorp</p>
                      <span class="date">15 Sep 2019</span>
                      <h6 class="name">Nandu Gopal Reddy</h6>
                      <span class="age">Age 13, Class 7</span>
                    </div>
                  </div>
                  <h5>First slide label</h5>
                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </div>
              </div>
              <div class="carousel-item">
                <div class="carousel-caption d-md-block">
                  <div class="row align-items-center">
                    <div class="col-md-3"><img src="../../assets/imgs/Ellipse 2.png" class="img-fluid" alt=""></div>
                    <div class="col-md-9 text-left">
                      <div style="text-align:center">
                        <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                      </div>
                      <p class="desription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et metus a turpis auctor fermentum id at mauris. Quisque mollis ex nunc, vitae gravida lacus ullamcorp</p>
                      <span class="date">15 Sep 2019</span>
                      <h6 class="name">Nandu Gopal Reddy</h6>
                      <span class="age">Age 13, Class 7</span>
                    </div>
                  </div>
                  <h5>First slide label</h5>
                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </div>
              </div> -->

            </div>
            <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
          <div class="container testimonials-bottom">
            <div class="row">
              <div class="col-md-3 col-sm-6 col-xs-12 text-center mb-3">
                <img src="../../assets/imgs/people-circle.svg" class="img-fluid" alt="">
                <span class="nums">
                6500 + 
                </span>
                <p class="mb-0">Students Enrolled</p> 
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12 text-center mb-3">
                <i class="fas fa-graduation-cap" style="font-size: 58px;
                color: #f08723;"></i>
                <!-- <img src="../../assets/imgs/star.svg" class="img-fluid" alt=""> -->
                <span class="nums">
                  5000 + 
                </span>
                <p class="mb-0">Student Certified</p> 
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12 text-center mb-3">
                <i class="fa fa-book" style="font-size: 58px;
                color: #f08723;"></i>
                <span class="nums">
                  170 + 
                </span>
                <p class="mb-0">Projects Completed</p> 
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12 text-center mb-3">
                <i class="fas fa-chalkboard-teacher"style="font-size: 58px;
                color: #f08723;"></i>
                <span class="nums">
                  900 + 
                </span>
                <p class="mb-0">Teachers</p>
              </div>
            </div>
          </div>
        </div>
      </section>
</div>