<div class="modal popup d-none" id="basicModel30Sec" tabindex="-1" aria-labelledby="basicModel30Sec" aria-hidden="true"
  data-backdrop="false">
  <div class="modal-dialog modal-lg">
    <div class="">
      <div class="modal-content modal-header border-0" style="    background-color: transparent;">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseModal30Sec()" style="    position: absolute;
        top: 41%;
        left: 86%;
        opacity: 1;">
          <span aria-hidden="true" style="font-size: 36px;
          color: #fff;">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0 pb-5 book-class-modal">
        <div class="row align-items-center">
          <div class="col-md-12" style="text-align: center;">
            <img [src]="offerData?.img" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>