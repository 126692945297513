import { CommonService } from './../../service/common.service';
import { environment } from './../../../environments/environment';
import { StarRatingComponent } from 'ng-starrating';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
 
import { BaseServiceService } from 'src/app/service';
import { ActivatedRoute } from '@angular/router';
declare var Razorpay: any;
declare var $ : any;


import { Subscription } from 'rxjs';


@Component({
  selector: 'app-desc',
  templateUrl: './desc.component.html',
  styleUrls: ['./desc.component.scss'],
  providers: [DatePipe],
})
export class DescComponent implements OnInit {
  active = 1;
  selectedIndex=null;
  chaptersdata:any=[];
  SlefPaceId:any;
  SelfPaceData:any;
  SelfPaceDataFilter:any;
  learnData:any=[];
  benefitsData:any=[];
  safeSrcVideoUrl: SafeResourceUrl;

  kidsData:any=[];
  options: any =environment.razorPayoptions;
  Amount:number=0;
  Course_Id:any;
videourl:any="https://player.vimeo.com/video/545344372"
urlSafe: SafeResourceUrl;
  avengers = []; 
  subscription: Subscription;
  newArr1: any;
  constructor(public activatedRoute:ActivatedRoute,public _employeeService: BaseServiceService,public common:CommonService,public sanitizer: DomSanitizer,private _datePipe: DatePipe) {
    this.SlefPaceId = this.activatedRoute.snapshot.paramMap.get("id");
    // console.log('SlefPaceId:::;',this.SlefPaceId);
    this.GetSelfPaceList();
    this.SelfPaceDetailsData(this.SlefPaceId);
   }

  ngOnInit(): void {
    // this.videourl = this.sanitizer.bypassSecurityTrustHtml(this.videourl);
    // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.videourl);
    this.avengers = 
        [{ id: 1, naming: 'Captain America', city:'US' }, 
        { id: 2, naming: 'Thor' , city:'Asgard'}, 
        { id: 3, naming: 'Iron Man' , city:'New York'}, 
        { id: 4, naming: 'Spider Man' , city:'Spiderverse'}, 
        { id: 5, naming: 'Doctor Strange', city:'Nepal' }, 
        { id: 6, naming: 'Black Panther' , city:'Wakanda'}, 
        { id: 7, naming: 'Hulk' , city:'US'}]; 
  }
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    alert(`Old Value:${$event.oldValue}, 
      New Value: ${$event.newValue}, 
      Checked Color: ${$event.starRating.checkedcolor}, 
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }
  SelfPaceDetailsData(id) {
    var self = this;
    let requestData = {
      course_id: id
    }
    self._employeeService.post('self_page_courses_details', requestData).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.SelfPaceData = res.data[0];
          if(this.SelfPaceData.info.length > 0){
            this.learnData= this.SelfPaceData.info.filter(x => x.type == 1);
            this.benefitsData= this.SelfPaceData.info.filter(x => x.type == 2);
            this.kidsData= this.SelfPaceData.info.filter(x => x.type == 3);
      
          
            this.urlSafe=this.sanitizer.bypassSecurityTrustResourceUrl(this.SelfPaceData.video_url)


            // console.log('learnData::::',this.learnData);
            // console.log('benefitsData::::',this.benefitsData);
            // console.log('kidsData::::',this.kidsData);
          }
         
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });

  }
  allowurl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  GetSelfPaceList() {
    var self = this;
    let requestData = {
      id: this.SlefPaceId
    }
    self._employeeService.post('student_view_course_info', requestData).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.SelfPaceDataFilter = res.data[0].chapters;
          // console.log('SelfPaceDataFilter::::',this.SelfPaceDataFilter);
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
  onBuy(SelfPaceObject){
    // console.log('SelfPaceObject::::',SelfPaceObject);
    this.Course_Id= SelfPaceObject.id;
    this.Amount=SelfPaceObject.amount;
    if(localStorage.getItem('user')){
      // this.payNow(event)
    }else{
      this.common.SetLoginType('email');
      // $('#loginModal').modal('show');
      // this._employeeService.showError('Please Login');
    }
   
  }
  expendLectureChapters(index){
    if(this.selectedIndex==index){
      this.selectedIndex +=100;
      return;
    }
    var self = this;
    let requestData = {
      course_chapter_id: this.SelfPaceDataFilter[index].id,
      keyword: ""
    }
    self._employeeService.post('chapter_lessons_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          self.chaptersdata=res.data;
          self.selectedIndex=index;
          // console.log('SelfPaceDataFilter::::',this.SelfPaceDataFilter);
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });

  }
}
