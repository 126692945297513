import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseServiceService } from 'src/app/service';
import { CommonService } from 'src/app/service/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvService } from 'src/app/service/env.service';
import { ModalService } from 'src/app/service/modal.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-redeem-code',
  templateUrl: './redeem-code.component.html',
  styleUrls: ['./redeem-code.component.scss']
})
export class RedeemCodeComponent implements OnInit {
  public CodeForm: FormGroup |any;public submitted: boolean = false;public btnsubmitted: boolean = false;
  constructor(
    public _employeeService: BaseServiceService,
    private sanitizer: DomSanitizer,public modalService:ModalService,public envService:EnvService, public common: CommonService,private formBuilder: FormBuilder
  ) { }
  ngOnInit(): void {
    this.CodeForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
    });
  }
  get formControlErrors() { return this.CodeForm.controls }
  onSubmit(){
    this.submitted=true;
    if(this.CodeForm.invalid){  return }
    else{
      this.btnsubmitted=true;
      this._employeeService.post('redeem_course_code',this.CodeForm.value).subscribe((res:any)=>{
        if(res.replyCode == "success"){
          this._employeeService.showSuccess('Code redeem Successfully.')
          this.submitted=false;this.btnsubmitted=false;
          this.CodeForm.reset();
        }else {
          this.btnsubmitted = false;
          this._employeeService.showError(res.replyMsg)
        }
      });
    }
  }
}
