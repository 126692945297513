import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../../validate-pattern';
import { Subscription } from 'rxjs';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-login-phone-modal',
  templateUrl: './login-phone.component.html',
  styleUrls: ['./login-phone.component.scss']
})
export class LoginPhoneBixModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public _leadsquaredService: LeadsquaredService, public userService: UserService, private modalService: ModalService,public commonService:CommonService) { }
  get f() { return this.Form.controls; }
  ngOnInit(): void {
    this.Form = this.fb.group({
      role_id: ['2'],
      phone: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
    });
  }
  onProceed() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      this.btnsubmitted = true;
      // console.log('LOGIN::::FORM', this.Form.value);
      this.userService.userPhoneLogin('otp_generate', this.Form.value).then(data => {
        // console.log('SEnD:::', data);
        if (data.replyCode == "success") {
          this.modalService.setloginphoneBixModal(false);
          this.modalService.setloginphoneOtpBixModal(true);
          this.commonService.SettypeOtp('phone');
          this.commonService.SetPhoneNoOtp(this.Form.value.phone);
          this.Form.reset();
          this.submitted = false;
          this.btnsubmitted = false;
        } else {
          this.btnsubmitted = false;
          this._employeeService.showError(data.replyMsg)
        }
      });
    }
  }
  onLoginEmail() {
    this.modalService.setloginphoneBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }
  onCloseModal() {
    this.modalService.setloginphoneBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }
  onRegisterPopup() {
    this.modalService.setloginphoneBixModal(false);
    this.modalService.setregisterBixModal(true);

  }
}
