import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BaseServiceService } from '.';
import { CommonService } from './common.service';
import { EnvService } from './env.service';
import { Injectable } from '@angular/core';
import { LeadsquaredService } from './leadsquared.service';
import { ModalService } from './modal.service';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';
import { Subscription } from 'rxjs';
import { ToastService } from 'ng-uikit-pro-standard';
import { TokenService } from './tokenservice.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  subscription: Subscription;
  public user: any = JSON.parse(localStorage.getItem("user"));
  public imageBase: any = environment.imageBase_api_url;
  public serviceBase = `${this.envService.apiUrl}`;
  public headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  ageGroupAllData: any = [];
  isLoggedInRobotics: boolean = false;
  constructor(private router: Router, private http: HttpClient, private toastrService: ToastService, public spinnerService: SpinnerService, private token: TokenService, private envService: EnvService, public common: CommonService, public _leadsquaredService: LeadsquaredService, public _employeeService: BaseServiceService, public modalService: ModalService) {
    this.common.GetageGroupData().subscribe(data => {
      this.ageGroupAllData = data;
    })
    this.common.GetLoginRobotics().subscribe(response => {
      return this.isLoggedInRobotics = response
    });
  }

  public errorHandler(error: any) {
    if (error) {
      // console.log('Error:::::::',error);
      // return this.testData;
      if (error == 'Error: Session expired') {
        // console.log('Error: Session expired');
        //401 Unauthorized
      }
      else if (error.status == 404) {
        // console.log('Error: 404');
        this.router.navigate(['/', '404']);
      }
      else if (error.status == 500) {
        // console.log('Error: 500');
        this.router.navigate(['/', '500']);
      }
      else if (error.status == 401) {
        // console.log('Error: 401');
        //  LoginPage
      }
    }
  }

  public randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  public leedFeedbackCapture(leadObject: any) {
    let relatedId = '';
    console.log('leadObject::::', leadObject);
    var senRequestLeeddata = leadObject;
    if (leadObject.parents_name) {
      var split_string = leadObject.name.split(' ');
      senRequestLeeddata.first_name = split_string[0];
      if (split_string.length > 2) {
        senRequestLeeddata.last_name = split_string[1] + ' ' + split_string[2];
      } else {
        senRequestLeeddata.last_name = split_string[1] || '';
      }
    }
    console.log('senRequestLeeddata', leadObject)
    localStorage.setItem('LEEDDATA', JSON.stringify(leadObject));
    this.subscription = this._leadsquaredService.AddLeadCapture(leadObject, this.ageGroupAllData).subscribe(message => { localStorage.setItem('LEED', JSON.stringify(message)); return message.Message.RelatedId });
  }

  public checkStudentSubscription(sid: any): any {
    this.spinnerService.setSpinner(true);
    // console.log('sid::::::', sid);
    return this.http.post(this.serviceBase + 'check_student_subscription', { sid: sid }, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        this.spinnerService.setSpinner(false);
        this.token.saveLogin_Teacher(JSON.stringify(data.data));
        let subData = data.data;
        let bixData = subData.filter(item => item.learning === 0);
        let pieData = subData.filter(item => item.learning === 1);
        var pieSubscription = pieData
        var bixSubscription = bixData;
        var UserData = JSON.parse(localStorage.getItem('user'));
        // console.log('pieSubscription::::::', pieSubscription);
        // console.log('pieSubscription::::::', pieSubscription);
        if (pieSubscription.length > 0 && bixSubscription.length > 0) {
          this.modalService.setBixPieModal(true);
        }
        if (pieSubscription.length > 0 && bixSubscription.length == 0) {
          window.location.href = `${this.envService.env}/student/dashboard-learning`;
        }
        if (pieSubscription.length == 0 && bixSubscription.length > 0) {
          window.location.href = `https://student.learningbix.com/dashboard`;
        }
        if (pieSubscription.length == 0 && bixSubscription.length == 0) {
          if (UserData != undefined) {
            if (UserData.learning == '0') {
              window.location.href = `https://student.learningbix.com/dashboard`;
            }
            else {
              window.location.href = `${this.envService.env}/student/dashboard-learning`;
            }
          }
        }
        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        this.token.removeLogin_Teacher();
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
    });
  }

  public userRegister(url: any, bodyData: any): any {
    var random_pwd = this.randomString(8, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    if (!bodyData.password) {
      bodyData.password = random_pwd;
    }
    this.spinnerService.setSpinner(true);
    console.log('URL::::::', url, 'bodyData:::::', bodyData);
    return this.http.post(this.serviceBase + url, bodyData, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        if (data.replyCode == "success") {
          this._employeeService.showSuccess(data.replyMsg);
          this.leedFeedbackCapture(bodyData);
          this.spinnerService.setSpinner(false);
          this.token.saveUser(JSON.stringify(data.data));
          this.token.saveSid(JSON.stringify(data.sid));
          this.checkStudentSubscription(data.sid);
          this.common.setLoggedInStatus(true);
        } else {
          this._employeeService.showError(data.replyMsg);
        }
        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }

  public userEmailLogin(url: any, bodyData: any): any {
    this.spinnerService.setSpinner(true);
    // console.log('URL::::::', url, 'bodyData:::::', bodyData);
    return this.http.post(this.serviceBase + url, bodyData, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        if (data.replyCode == "success") {
          console.log('API::::::RESPONSE', data);
          this.spinnerService.setSpinner(false);
          this.token.saveUser(JSON.stringify(data.data));
          this.token.saveSid(JSON.stringify(data.sid));
          this.checkStudentSubscription(data.sid);
          this.common.setLoggedInStatus(true);
        }
        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }

  public userSocialLogin(url: any, bodyData: any): any {
    this.spinnerService.setSpinner(true);
    // console.log('URL::::::', url, 'bodyData:::::', bodyData);
    return this.http.post(this.serviceBase + url, bodyData, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        if (data.replyCode == "success") {
          this.spinnerService.setSpinner(false);
          this.token.saveUser(JSON.stringify(data.data));
          this.token.saveSid(JSON.stringify(data.sid));
          this.common.setLoggedInStatus(true);
          this.checkStudentSubscription(data.sid);
        }
        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }

  public userPhoneLogin(url: any, bodyData: any): any {
    this.spinnerService.setSpinner(true);
    // console.log('URL::::::', url, 'bodyData:::::', bodyData);
    return this.http.post(this.serviceBase + url, bodyData, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        if (data.replyCode == "success") {
          this.spinnerService.setSpinner(false);
        }
        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }

  public userPhoneOtpVerifyLogin(url: any, bodyData: any): any {
    this.spinnerService.setSpinner(true);
    // console.log('URL::::::', url, 'bodyData:::::', bodyData);
    return this.http.post(this.serviceBase + url, bodyData, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        if (data.replyCode == "success") {
          this.spinnerService.setSpinner(false);
          this.token.saveUser(JSON.stringify(data.data));
          this.token.saveSid(JSON.stringify(data.sid));
          this.common.setLoggedInStatus(true);
          if(!this.isLoggedInRobotics){
            this.checkStudentSubscription(data.sid);
          }else{
            this.modalService.setloginphoneOtpBixModal(false);
          }
        }

        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }

  public userPhoneOtpVerifyBookFreeClass(url: any, bodyData: any): any {
    this.spinnerService.setSpinner(true);
    // console.log('URL::::::', url, 'bodyData:::::', bodyData);
    return this.http.post(this.serviceBase + url, bodyData, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        this.spinnerService.setSpinner(false);
        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }

  public userEnroll(url: any, bodyData: any): any {
    var random_pwd = this.randomString(8, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    if (!bodyData.password) {
      bodyData.password = random_pwd;
    }
    this.spinnerService.setSpinner(true);
    // console.log('URL::::::', url, 'bodyData:::::', bodyData);
    // return
    return this.http.post(this.serviceBase + url, bodyData, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        if (data.replyCode == "success") {
          this.spinnerService.setSpinner(false);
          this.token.saveUser(JSON.stringify(data.data));
          this.token.saveSid(JSON.stringify(data.sid));
          this.common.setLoggedInStatus(true);
        }

        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }

  public sendFeedback(bodyData: any,pageUrl:any): any {
    this.spinnerService.setSpinner(true);
    console.log('bodyData:::::', bodyData);
    var sendInputParameter:any={};
    sendInputParameter=bodyData;
    sendInputParameter.page_url=pageUrl;
    return this.http.post(`${this.serviceBase}send_feedback`, sendInputParameter, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        this.spinnerService.setSpinner(false);
        this.leedFeedbackCapture(sendInputParameter);
        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }

  public userEmailOtpGenerateLogin(bodyData: any): any {
    this.spinnerService.setSpinner(true);
    // console.log('URL::::::', url, 'bodyData:::::', bodyData);
    return this.http.post(this.serviceBase + 'otp_generate_email', bodyData, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        if (data.replyCode == "success") {
          console.log('API::::::RESPONSE', data);
          // this.spinnerService.setSpinner(false);
          // this.token.saveUser(JSON.stringify(data.data));
          // this.token.saveSid(JSON.stringify(data.sid));
          // this.checkStudentSubscription(data.sid);
          // this.common.setLoggedInStatus(true);
        }
        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }

  public userEmailOtpVerifyLogin(bodyData: any): any {
    this.spinnerService.setSpinner(true);
    var sendRequestObject: any = {};
    sendRequestObject = bodyData;
    sendRequestObject.email = bodyData.phone
    // console.log('URL::::::', url, 'bodyData:::::', bodyData);
    return this.http.post(this.serviceBase + 'otp_verify_email', sendRequestObject, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        if (data.replyCode == "success") {
          console.log('API::::::RESPONSE', data);
          this.spinnerService.setSpinner(false);
          this.token.saveUser(JSON.stringify(data.data));
          this.token.saveSid(JSON.stringify(data.sid));
          this.checkStudentSubscription(data.sid);
          this.common.setLoggedInStatus(true);
        }
        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }

  public ageGroupList(): any {
    this.spinnerService.setSpinner(true);
    return this.http.post(this.serviceBase + 'age_group_list_dropdown', { "learning": "1" }, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        this.spinnerService.setSpinner(false);
        console.log('ageGroup:::::', data);
        this.common.SetageGroupData(data.data);
        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        this.token.removeLogin_Teacher();
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }


  public productDataApi(bodyData: any): any {
    this.spinnerService.setSpinner(true);
    console.log('bodyData:::::', bodyData);
    var objectData = {
      keyword: bodyData.keyword,
      category_id: bodyData.category_id,
      age_group_id: bodyData.keyword,
      start: bodyData.start,
      limit: bodyData.limit
    }
    return this.http.post(this.serviceBase + 'product_list', objectData, {
      headers: this.headers
    }).toPromise().then(
      (data: any) => {
        if (data.replyCode == "success") {
          this.spinnerService.setSpinner(false);
          this.common.SetProductData(data.data);
        }
        return data;
      },
      error => {
        this.spinnerService.setSpinner(false);
        if (error.response) {
          var err = error.response['error']['message'];
          // console.log('Error : err', err);
        }
        this.errorHandler(error);
        return error;
      });
  }
}
