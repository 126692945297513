import { CommonService } from '../../service/common.service';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BaseServiceService, TokenService } from 'src/app/service';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import Player from '@vimeo/player';

@Component({
  selector: 'app-vimeo',
  templateUrl: './vimeo.component.html',
  styleUrls: ['./vimeo.component.scss'],
  providers: [DatePipe]
})
export class VimeoComponent implements OnInit {
  vimeoID: any;
  constructor(public fb: FormBuilder, public activatedRoute: ActivatedRoute, public _employeeService: BaseServiceService, public common: CommonService, public token: TokenService, public _router: Router, private _datePipe: DatePipe, public userService: UserService) {
    this.vimeoID = this.activatedRoute.snapshot.paramMap.get('id')
  }
  private player: Player;
  @HostListener('window:blur', ['$event'])
  onWindowBlur(event: any): void {
    var iframe = document.getElementById("myFrame");
    this.player = new Player(iframe);
    console.log('this.player::::::', this.player);
    this.player.on('play', (response: { duration: number; }) => {
      console.log('response:::::::', response);

    });
  }
  ngOnInit(): void {
  }
}
