import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { TokenService } from './tokenservice.service';

@Injectable({
	providedIn: 'root'
  })
export class TokenGuard implements CanActivate {
	public title:any;
	constructor(
		private tokenService: TokenService, 
		private router: Router){}
	canActivate(): boolean {
		if(this.tokenService.getUser()){
			// console.log('DashboardGuard-return::::',true);
			return true;
        }
        this.router.navigate(['/login']);
		// console.log('DashboardGuard-return::::',false);
		return false;
	  }
}

@Injectable({
	providedIn: 'root'
  })
export class ForgotPasswordGuard implements CanActivate {
	public title:any;
	constructor(
		private tokenService: TokenService, 
		private router: Router){}
	canActivate(): boolean {
		if(this.tokenService.getSid()){
			// console.log('DashboardGuard-return::::',true);
			return true;
        }
        this.router.navigate(['/forgot-password']);
		// console.log('DashboardGuard-return::::',false);
		return false;
	  }
}

@Injectable({
	providedIn: 'root'
  })
export class DemoClassGuard implements CanActivate {
	public title:any;
	constructor(
		private tokenService: TokenService, 
		private router: Router){}
	canActivate(): boolean {
		if(this.tokenService.getDemoClass()){
			// console.log('DashboardGuard-return::::',true);
			return true;
        }
        this.router.navigate(['/dashboard']);
		// console.log('DashboardGuard-return::::',false);
		return false;
	  }
}