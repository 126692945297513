export * from './coding/book-demo-class/book-demo-class.component';
export * from './coding/pre-school-enroll-now/pre-school-enroll-now.component';
export * from './coding/forgot/forgot.component';
export * from './coding/login-email/login-email.component';
export * from './coding/login-phone/login-phone.component';
export * from './coding/register/register.component';
export * from './coding/login-otp/login-otp.component';
export * from './apply-coupon-modal/apply-coupon-modal.component';
export * from './bix-pie-modal/bix-pie-modal.component';
export * from './coding/book-demo-pie-class/book-demo-pie-class.component';
export * from './offer-modal/offer-modal.component';
export * from './coding/guest-login/guest-login.component';
export * from './coding/login-password/login-password.component';