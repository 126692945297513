import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { BaseServiceService } from 'src/app/service';

@Component({
  selector: 'app-all-list',
  templateUrl: './all-list.component.html',
  styleUrls: ['./all-list.component.scss']
})
export class AllListComponent implements OnInit {
  SelfPaceData: any;
  AgeGroupList:any=[]
  selectedAgeId='';
  constructor(public _employeeService: BaseServiceService,public route:Router) {
    this.GetSelfPaceList();
  }

  ngOnInit(): void {
    this._employeeService.post('age_group_list_dropdown', {learning:'0'}).subscribe((res: any) => {
      if (!res.error) {
        // console.log('Response::::',res);
        this.AgeGroupList= res.data;
        
      }
    });
  }
  GetSelfPaceList() {
    var self = this;
    let requestData = {
      keyword: '',
      featured:1,
      age_group_id:self.selectedAgeId
    }
    self._employeeService.post('self_page_courses_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.SelfPaceData = res.data;
          // console.log('SelfPaceData::::',this.SelfPaceData);
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
  onDetails(id){
    this.route.navigate(['description/'+id]);
  }
  ageGroup=false;
  showAgeGroup(){
    this.ageGroup= !this.ageGroup
  }
  changeAge(value){
    // console.log('====================================');
    // console.log(value);
    // console.log('====================================');
    // if(value=='clear'){
    //   this.selectedAgeId=''
    // }
    this.GetSelfPaceList();
  }
  onClear(){
     this.selectedAgeId=''
    this.GetSelfPaceList();


  }
}



