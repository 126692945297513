import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../../validate-pattern';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-login-otp-modal',
  templateUrl: './login-otp.component.html',
  styleUrls: ['./login-otp.component.scss']
})
export class LoginOtpBixModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  phoneNo: any;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '60px',
      'height': '50px',
      'margin-right': '20px',
      'border-radius': '10px',
      'border': 'solid 2px #000000',
    }
  };
  otpModal: FormGroup;
  redirectPath: boolean = false;
  isLoggedInRobotics: boolean = false;
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public userService: UserService, private modalService: ModalService,public commonService:CommonService) { }
  get f() { return this.Form.controls; }
  ngOnInit(): void {
    this.commonService.GetPhoneNoOtp().subscribe(response => {
      return this.phoneNo = response
    });
    this.commonService.GetLoginRobotics().subscribe(response => {
      return this.isLoggedInRobotics = response
    });
    this.Form = this.fb.group({
      role_id: ['2'],
      phone: [this.phoneNo, [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
    });
    this.otpModal = this.fb.group({
      otp1: ['', [Validators.minLength(1)]],
      otp2: ['', [Validators.minLength(1)]],
      otp3: ['', [Validators.minLength(1)]],
      otp4: ['', [Validators.minLength(1)]]
    });
  }
  
  otpValue = ''
  onOtpChange(otp) {
    this.otpValue = otp;
  }
  onSubmitOtp() {
    this.submitted = true;
    if (this.otpValue == undefined || this.otpValue== '' || this.otpValue == null) {
      this._employeeService.showError('Please enter valid otp...')
    } else {
      this.btnsubmitted = true;
      var requestData = this.Form.value;
      requestData.otp = this.otpValue
      this.commonService.GettypeOtp().subscribe(data => {
        if (data == 'email') {
          this.userService.userEmailOtpVerifyLogin(this.Form.value).then(data => {
            if (data.replyCode == "success") {
              this.Form.reset();
              this.submitted = false;
              this.btnsubmitted = false;
            } else {
              this.btnsubmitted = false;
              this._employeeService.showError(data.replyMsg)
            }
          });
        } else {
          if(this.isLoggedInRobotics){
            this.userService.userPhoneOtpVerifyLogin('otp_verify_signup', this.Form.value).then(data => {
              if (data.replyCode == "success") {
                this.Form.reset();
                this.submitted = false;
                this.btnsubmitted = false;
              } else {
                this.btnsubmitted = false;
                this._employeeService.showError(data.replyMsg)
              }
            });
          }else{
            this.userService.userPhoneOtpVerifyLogin('otp_verify', this.Form.value).then(data => {
              if (data.replyCode == "success") {
                this.Form.reset();
                this.submitted = false;
                this.btnsubmitted = false;
              } else {
                this.btnsubmitted = false;
                this._employeeService.showError(data.replyMsg)
              }
            });
          }
          
        }
      })
    }
  }
  onEditPhone() {
    this.commonService.GettypeOtp().subscribe(data => {
      if (data == 'email') {
        this.modalService.setloginphoneOtpBixModal(false);
        this.modalService.setloginemailBixModal(true);
      } else {
        this.modalService.setloginphoneOtpBixModal(false);
        this.modalService.setloginphoneBixModal(true);
      }
    });

  
  }
  onLoginEmail() {
    this.modalService.setloginphoneBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }
  onCloseModal() {
    this.modalService.setloginphoneBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }
  onRegisterPopup() {
    this.modalService.setloginphoneOtpBixModal(false);
    this.modalService.setregisterBixModal(true);
  }
  onResendOtp() {
    this._employeeService.post('otp_generate', this.Form.value).subscribe((data:any) => {
      if (!data.error) {
        if (data.replyCode == 'success') {
          this._employeeService.showSuccess(data.replyMsg);
        } else {
          this._employeeService.showError(data.replyMsg)
        }
      }else {
        this._employeeService.showError(data.error.replyMsg)
      }
    });
  }
}
