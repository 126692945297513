import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
    export class CommonService {
    isLoggedIn = new BehaviorSubject<boolean>(false);
    isShowStartModal = new BehaviorSubject<boolean>(true);
    isLoggedInPie = new BehaviorSubject<boolean>(false);
    showHideFlag = new BehaviorSubject<boolean>(false);
    isLoginType = new BehaviorSubject<string>('');
    isLoginTypePie = new BehaviorSubject<string>('');
    RouteType = new BehaviorSubject<string>('');
    AgeCourseType = new BehaviorSubject<string>('');
    AgeCourseTypeId = new BehaviorSubject<string>('');
    LookingForType = new BehaviorSubject<string>('');
    PhoneNoOtp = new BehaviorSubject<string>('');
    otpType = new BehaviorSubject<string>('');
    Panel = new BehaviorSubject<string>('');
    CountryData = new BehaviorSubject<any>([]);
    StateData = new BehaviorSubject<any>([]);
    CityData = new BehaviorSubject<any>([]);
    ageGroupData = new BehaviorSubject<any>([]);
    isCartQuantity = new BehaviorSubject<number>(0);
    isProductData = new BehaviorSubject<any>([]);
    isUserCartList = new BehaviorSubject<any>([]);
    isUserSid = new BehaviorSubject<string>('');
    isCartUpdated = new BehaviorSubject<boolean>(false);
    isLoggedInRobotics = new BehaviorSubject<boolean>(false);
    isRoboticsTestimonialID = new BehaviorSubject<string>('');
    isRoboticsPageName = new BehaviorSubject<string>('');
    isRoboticsPageID = new BehaviorSubject<string>('');
    isRoboticsHivePageID = new BehaviorSubject<string>('');
    isRoboticsKoddlePageID = new BehaviorSubject<string>('');
    isRoboticsIntellectoPageID = new BehaviorSubject<string>('');
    isRoboticsModularPageID = new BehaviorSubject<string>('');
    isRoboticsShopPageID = new BehaviorSubject<string>('');
    isRoboticsStemPageID = new BehaviorSubject<string>('');
    
    GetRoboticsPageName(){return this.isRoboticsPageName.asObservable()}
    SetRoboticsPageName(data){this.isRoboticsPageName.next(data)}

    GetRoboticsHivePageID(){return this.isRoboticsHivePageID.asObservable()}
    SetRoboticsHivePageID(data){this.isRoboticsHivePageID.next(data)}

    GetRoboticsKoddlePageID(){return this.isRoboticsKoddlePageID.asObservable()}
    SetRoboticsKoddlePageID(data){this.isRoboticsKoddlePageID.next(data)}

    GetRoboticsIntellectoPageID(){return this.isRoboticsIntellectoPageID.asObservable()}
    SetRoboticsIntellectoPageID(data){this.isRoboticsIntellectoPageID.next(data)}

    GetRoboticsModularPageID(){return this.isRoboticsModularPageID.asObservable()}
    SetRoboticsModularPageID(data){this.isRoboticsModularPageID.next(data)}

    GetRoboticsShopPageID(){return this.isRoboticsShopPageID.asObservable()}
    SetRoboticsShopPageID(data){this.isRoboticsShopPageID.next(data)}

    GetRoboticsStemPageID(){return this.isRoboticsStemPageID.asObservable()}
    SetRoboticsStemPageID(data){this.isRoboticsStemPageID.next(data)}



    GetRoboticsPageID(){
        return this.isRoboticsPageID.asObservable();
    }
    SetRoboticsPageID(data){
        this.isRoboticsPageID.next(data);
    }
    GetRoboticsTestimonialID(){
        return this.isRoboticsTestimonialID.asObservable();
    }
    SetRoboticsTestimonialID(data){
        this.isRoboticsTestimonialID.next(data);
    }
    GetLoginRobotics(){
        return this.isLoggedInRobotics.asObservable();
    }
    SetLoginRobotics(data){
        this.isLoggedInRobotics.next(data);
    }

    GetCartUpdated(){
        return this.isCartUpdated.asObservable();
    }
    SetCartUpdated(data){
        this.isCartUpdated.next(data);
    }

    GetSID(){
        return this.isUserSid.asObservable();
    }
    SetSID(data){
        this.isUserSid.next(data);
    }
    GetCartList(){
        return this.isUserCartList.asObservable();
    }
    SetCartList(data){
        this.isUserCartList.next(data);
    }
    GetProductData(){
        return this.isProductData.asObservable();
    }
    SetProductData(data){
        this.isProductData.next(data);
    }
    GetCartQuantity(){
        return this.isCartQuantity.asObservable();
    }
    SetCartQuantity(data){
        this.isCartQuantity.next(data);
    }
    GetageGroupData(){
        return this.ageGroupData.asObservable();
    }
    SetageGroupData(data){
        this.ageGroupData.next(data);
    }
    GetCountryData(){
        return this.CountryData.asObservable();
    }
    SetCountryData(data){
        this.CountryData.next(data);
    }
    GetStateData(){
        return this.StateData.asObservable();
    }
    SetStateData(data){
        this.StateData.next(data);
    }
    GetCityData(){
        return this.CityData.asObservable();
    }
    SetCityData(data){
        this.CityData.next(data);
    }
    GetshowhideFlag(){
        return this.showHideFlag.asObservable();
    }
    SetshowhideFlag(data){
        this.showHideFlag.next(data);
    }
    getLoggedInStatus() {
        return this.isLoggedIn.asObservable();
    }
    getShowStartModalStatus() {
        return this.isShowStartModal.asObservable();
    }
    
    setLoggedInStatus(value) {
        this.isLoggedIn.next(value)
    }
    setShowStartModalStatus(value) {
        this.isShowStartModal.next(value)
    }
    getLoggedInPieStatus() {
        return this.isLoggedIn.asObservable();
    }
    
    setLoggedInPieStatus(value) {
        this.isLoggedIn.next(value)
    }

    GetLoginType(){
        return this.isLoginType.asObservable();
    }
    SetLoginType(data){
        this.isLoginType.next(data);
    }
    GetLoginTypePie(){
        return this.isLoginTypePie.asObservable();
    }
    SetLoginTypePie(data){
        this.isLoginTypePie.next(data);
    }

    // Section Route Type
    GetRouteType(){
        return this.RouteType.asObservable();
    }
    SetRouteType(data){
        this.RouteType.next(data);
    }

     // Age Course Type
    GetAgeCourseType(){
        return this.AgeCourseType.asObservable();
    }
    SetAgeCourseType(data){
        this.AgeCourseType.next(data);
    }
    // Age Course Type
    GetAgeCourseTypeId(){
        return this.AgeCourseTypeId.asObservable();
    }
    SetAgeCourseTypeId(data){
        this.AgeCourseTypeId.next(data);
    }
    // Age Course Type
    GetLookingForType(){
        return this.LookingForType.asObservable();
    }
    SetLookingForType(data){
        this.LookingForType.next(data);
    }
    GetPhoneNoOtp(){
        return this.PhoneNoOtp.asObservable();
    }
    SetPhoneNoOtp(data){
        this.PhoneNoOtp.next(data);
    }
    GettypeOtp(){
        return this.otpType.asObservable();
    }
    SettypeOtp(data){
        this.otpType.next(data);
    }
    // Panel
    GetPanel(){
        return this.Panel.asObservable();
    }
    SetPanel(data){
        this.Panel.next(data);
    }
}

