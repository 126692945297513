<router-outlet></router-outlet>
<app-book-demo-class-modal *ngIf="openPopup"></app-book-demo-class-modal>
<app-login-email-modal *ngIf="openLoginPopup"></app-login-email-modal>
<app-forgot-modal *ngIf="openForgotPopup"></app-forgot-modal>
<app-register-modal *ngIf="openRegisterPopup"></app-register-modal>
<app-login-phone-modal *ngIf="openPhonePopup"></app-login-phone-modal>
<app-login-otp-modal *ngIf="openPhoneOtpPopup"></app-login-otp-modal>
<app-apply-coupon-modal *ngIf="openApplyCouponPopup"></app-apply-coupon-modal>
<app-pre-school-enroll-now-modal *ngIf="openEnrollPopup"></app-pre-school-enroll-now-modal>
<app-login-password-modal *ngIf="openLoginEmailPasswordPopup"></app-login-password-modal>


<app-book-demo-pie-class-modal *ngIf="openPiePopup"></app-book-demo-pie-class-modal>

<app-basic-modal *ngIf="openBasicModal"></app-basic-modal>
<app-offer-modal *ngIf="openOfferModal"></app-offer-modal>

<app-book-a-free-modal *ngIf="openBookaFreeModal"></app-book-a-free-modal>
<app-book-a-free-step-2-modal *ngIf="openBookaFreeStep2Modal"></app-book-a-free-step-2-modal>



<app-bix-pie-modal *ngIf="openBixPiePopup"></app-bix-pie-modal>
<app-loader-modal *ngIf="loader_payment"></app-loader-modal>
<app-guest-login-modal *ngIf="openGuestLoginModal"></app-guest-login-modal>

<!-- <div class="main_loader"></div> -->