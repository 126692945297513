<div class="modal popup" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel"
    aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 3px 10px;"
                    (click)="onCloseModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2" style="height: 550px;">
                <div class="row">
                    <div class="col-6">
                        <img src="../../../assets/imgs/loginModalBanner.jpg" class="img-fluid w-100" alt="Login Banner" style="height: 86%;">
                    </div>
                    <div class="col-6">
                        <h1 style="font-size: 30px;">Login</h1>
                        <form [formGroup]="Form">
                            <div class="form-group mb-0">
                                <label for="Email">Email/Phone</label>
                                <input type="email" placeholder="Enter Email/Phone" class="form-control pl-0" id="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                <div *ngIf="submitted && f.email.errors">
                                    <small *ngIf="f.email.errors.required" class="text-danger">
                                        This Field is required
                                    </small>
                                    <small [hidden]="!f.email.errors.patternInvalid" class="error_input">Please enter valid email.</small>
                                </div>
                            </div>
                            <button class="btn btn-block btn-login mt-3" (click)="onLogin()" [disabled]="btnsubmitted">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>