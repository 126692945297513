<app-header></app-header>
<section class="desc-back">
    <div class="SelfPace_course_Details">
        <div class="container" *ngIf="SelfPaceData">
            <div class="row">
                <div class="col-md-9 col-sm-12">
                    <h4 class="align_left bold">{{SelfPaceData.course_name}}
                    </h4>
                    <p class="desc mt-2 text-left">{{SelfPaceData.sub_title}}</p>
                    <div class="rating text-left">
                        <span>
                            <strong>{{SelfPaceData.dummy_rating}} / 5 </strong>
                            <span>
                                <i class="fas fa-star star-fill"></i>
                                <i class="fas fa-star star-fill"></i>
                                <i class="fas fa-star star-fill"></i>
                                <i class="fas fa-star star-fill"></i>
                                <i class="fas fa-star star-fill"></i>
                            </span>
                            <!-- &nbsp;&nbsp; -->
                            <!-- (6,397 ratings) 24,480 students -->
                        </span>
                    </div>
                    <div class="contant mt-3">
                        <h6 class="bold">By - {{SelfPaceData.by_teacher_name}}</h6>
                        <div class="button mt-4 mb-3">
                            <div class="d-flex flex-wrap justify-content-start">
                                <button class="theme-blue-btn" style="width: 120px;"><strong><i
                                            class="far fa-heart"></i>&nbsp;Wishlist</strong></button>
                                <button class="theme-blue-btn ml-3"  style="width: 120px;"><strong><i
                                            class="fas fa-share"></i>&nbsp;Share</strong></button>
                            </div>
                        </div>
                    </div>
                    <!-- <p class="mt-4">Last updated {{SelfPaceData.created}}</p> -->
                </div>
                <div class="col-md-3 col-sm-12">
                    <h4 class="align_left bold orange text-left"><i class="fas fa-rupee-sign"></i>&nbsp;{{SelfPaceData.amount}}</h4>
                    <button class="theme-orange-btn" (click)="onBuy(SelfPaceData)"><strong>Enroll now</strong></button>
                    <!-- <p class="mt-4">30-Day Money-Back Guarantee</p> -->
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="SelfPaceData">
      
        <div class="mt-5 container" style="padding: 0;">
            <div class="col">
                <div class="row">
                    <div class="col-md-7 col-sm-12 mb-4">
                        <h4 class="bold">What you’ll learn</h4>
                       <div class="card card_whatWill_lern">
                       
                        <div class="learn-contant mt-2">
                            <div class="row" *ngFor="let learn_data of learnData">
                                <div class="col-2" style="max-width: 7%;">
                                    <img src="assets/imgs/right_sign.png" width="30">
                                </div>
                                <div class="col-10">
                                    <p>{{learn_data.title}}</p>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-2" style="max-width: 7%;">
                                    <img src="assets/imgs/right_sign.png" width="30">
                                </div>
                                <div class="col-10">
                                    <p>Draw colorful spirals and shapes with Turtle graphics in Python</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2" style="max-width: 7%;">
                                    <img src="assets/imgs/right_sign.png" width="30">
                                </div>
                                <div class="col-10">
                                    <p>Draw colorful spirals and shapes with Turtle graphics in Python</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2" style="max-width: 7%;">
                                    <img src="assets/imgs/right_sign.png" width="30">
                                </div>
                                <div class="col-10">
                                    <p>Build interactive apps that respond to user input and mouse clicks</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2" style="max-width: 7%;">
                                    <img src="assets/imgs/right_sign.png" width="30">
                                </div>
                                <div class="col-10">
                                    <p>Master variables, loops and functions to prepare for more advanced programming
                                        courses (and a brighter future)!</p>
                                </div>
                            </div> -->
                        </div>
                       </div>
                    </div>
                    <div class="col-md-5 col-sm-12 w-100">
                        <h4 class="bold">Preview</h4>
                        <div class="learn-contant">
                            <div class="d-flex" *ngIf="SelfPaceData.video_url !=undefined && SelfPaceData.video_url !=null && SelfPaceData.video_url !='null' && SelfPaceData.video_url !=''">
                                <!-- <img src="assets/imgs/learn-img.png"> -->

                                  <!-- <video *ngIf="SelfPaceData.video_url ==undefined || SelfPaceData.video_url == null ||  SelfPaceData.video_url =='null'|| SelfPaceData.video_url ==''" src="{{SelfPaceData.video_url}}" controls autoplay style="width: 100%; border-radius: 10px;"></video>   -->
                                
                                  <iframe [id]="SelfPaceData.id"  class="embed-responsive-item" [src]="urlSafe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                <!-- <iframe src="https://player.vimeo.com/video/545344372?h=28a8b79ab7&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div [innerHtml]="tnCData?.description"></div> -->

            </div>
        </div>
        <div class="mt-5 container" style="padding: 0;">
            <div class="col">
                <div class="row">
                    <div class="col-12">
                        <h4 class="bold">Benefits of online course with LearningBIX</h4>
                        <div class="learn-contant card_whatWill_lern">
                            <div class="row">
                                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 text-center" *ngFor="let card of benefitsData">
                                    <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{card.image}}" onError="this.src='assets/imgs/box-learn.png'" width="200">
                                    <div class="description">
                                        <h4 class="bold text-center">{{card.title}}</h4>
                                        <div class="mt-2">
                                            <p style="line-height: 20px;">{{card.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Course Contant -->
        <div class="mt-5 my-4 container" style="padding: 0;">
            <div class="col">
                <div class="row">
                    <div class="col-lg-7 col-md-6 col-sm-12 mb-4">
                        <h4 class="bold">Course content</h4>
                        <div class="learn-contant">
                            <ng-container *ngFor="let course_contant of SelfPaceDataFilter;let i=index;">
                                <div class="d-flex card_CourseContent mt-3">
                                    <div class="col-7">
                                        <div class="course-heading">
                                            <h6>{{course_contant.chapter_title}}</h6>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="course-time">
                                            <span>{{course_contant.total_lessons}} lecture</span>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="course-dropdown btn btn-link" (click)="expendLectureChapters(i)">
                                            <span><i class="fas fa-chevron-down"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row card_CourseContentList {{i==selectedIndex?'d-block':'d-none'}}">
                                    <ol type="A" class="p-0 m-0">
                                        <li *ngFor="let item of chaptersdata;">{{item.lesson_title}}</li>
                                    </ol>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-12">
                        <h4 class="bold">Course Summary</h4>
                        <div class="requirements">
                            <p>
                              {{SelfPaceData.requirement_description}}
                            </p>
                            <!-- <span class="mt-2">
                                If you can use a text editor (Notepad, Word, etc.), you can program in Python!
                            </span>
                            <span class="mt-2">
                                No prior programming experience needed
                            </span> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="mt-5 my-4 container" style="padding: 0;" *ngIf="SelfPaceData.recommended_course">
            <div class="col">
                        <h4 class="bold">Enroll in the Course!</h4>
                        <div class="learn-contant">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <div class="card card_recommended_course mb-3">
                                    <div class="card-header class_couse_header_orange">
                                        <h6 class="m-0">{{SelfPaceData.course_name}}</h6>
                                    </div>
                                    <div class="card-body">
                                        <div class="learn-contant">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-12 col-sm-12">
                                                    <div class="session" *ngFor="let learn_data of learnData">
                                                        <span class="bold learn_datatitle"><i
                                                                class="fas fa-check right_check"></i>&nbsp;<span
                                                                class="ml-2">{{learn_data.title}}</span></span>
                                                    </div>
                                                    <!-- <div class="session">
                                                        <span class="bold"><i
                                                                class="fas fa-check right_check"></i>&nbsp;<span
                                                                class="ml-2">Quizzes & Assignments</span></span>
                                                    </div>
                                                    <div class="session">
                                                        <span class="bold"><i
                                                                class="fas fa-check right_check"></i>&nbsp;<span
                                                                class="ml-2">Certificate of completions</span></span>
                                                    </div>
                                                    <div class="session">
                                                        <span class="bold"><i
                                                                class="fas fa-check right_check"></i>&nbsp;<span
                                                                class="ml-2">Certificate of completions</span></span>
                                                    </div> -->
                                                </div>
                                                <div class="col-lg-4 col-md-12 col-sm-12">
                                                    <h4 class="align_left bold orange ml-5"><i
                                                            class="fas fa-rupee-sign"></i>&nbsp;{{SelfPaceData.amount}}</h4>
                                                            <!-- <p class="text-center">
                                                                <del>₹1000</del>
                                                                <span class="ml-1"><strong class="right_check">30% off</strong></span></p> -->
                                                    <button class="theme-orange-btn" (click)="onBuy(SelfPaceData)"><strong>Enroll
                                                            now</strong></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="card card_recommended_course">
                                    <div class="card-header class_couse_header_blue">
                                        <h6 class="m-0">{{SelfPaceData.recommended_course.course_name}}</h6>
                                    </div>
                                    <div class="card-body">
                                        <div class="learn-contant">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-12 col-sm-12">
                                                    <div class="session" *ngFor="let learn_data of learnData">
                                                        <span class="bold learn_datatitle"><i
                                                                class="fas fa-check right_check"></i>&nbsp;<span
                                                                class="ml-2">{{learn_data.title}}</span></span>
                                                    </div>
                                                    <!-- <div class="session">
                                                        <span class="bold"><i
                                                                class="fas fa-check right_check"></i>&nbsp;<span
                                                                class="ml-2">Quizzes & Assignments</span></span>
                                                    </div>
                                                    <div class="session">
                                                        <span class="bold"><i
                                                                class="fas fa-check right_check"></i>&nbsp;<span
                                                                class="ml-2">Certificate of completions</span></span>
                                                    </div>
                                                    <div class="session">
                                                        <span class="bold"><i
                                                                class="fas fa-check right_check"></i>&nbsp;<span
                                                                class="ml-2">Certificate of completions</span></span>
                                                    </div> -->
                                                </div>
                                                <div class="col-lg-4 col-md-12 col-sm-12">
                                                    <h4 class="align_left bold blue ml-5"><i
                                                            class="fas fa-rupee-sign"></i>&nbsp;{{SelfPaceData.recommended_course.amount}}</h4>
                                                            <!-- <p class="text-center">
                                                                <del>₹1000</del>
                                                                <span class="ml-1"><strong class="right_check">30% off</strong></span></p> -->
                                                    <button class="theme-blue-btn" (click)="onBuy(SelfPaceData.recommended_course)"><strong>Enroll
                                                            now</strong></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
        </div>


        <div class="mt-5 my-4 container" style="padding: 0;">
            <div class="col">
                        <h4 class="bold text-center">What Kids Will Learn</h4>
                        <div class="learn-contant">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-12 text-center mt-4 mb-4" *ngFor="let kidsDatacard of kidsData">
                                    <div class="card card-kidsLern" style="padding:20px">
                                        <div class="row">
                                          
                                        </div>
                                        <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{kidsDatacard.image}}" onError="this.src='assets/imgs/kid-learn.png'" class="img-fluid mb-3" alt="" style="max-width: 100%;">
                                        <h3 style="height:40px">{{kidsDatacard.title.slice(0,32)}}</h3>
                                        <p class="mb-0 descrption-kidslern">{{kidsDatacard.description.slice(0,100)}}</p>
                                      </div>
                                </div>
                                <!--<div class="col-lg-3 col-md-4 col-sm-12 mr-4 ml-5 text-center mt-4 mb-4" *ngFor="let kidsDatacard of kidsData">
                                    
                                   <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{kidsDatacard.image}}" onError="this.src='assets/imgs/kid-learn.png'" width="250">
                                    
                                    <div class="description mt-3">
                                        <h4 class="bold text-center">{{kidsDatacard.title}}</h4>
                                        <div class="mt-2">
                                            <p style="line-height: 20px;">{{kidsDatacard.description}}</p>
                                        </div>
                                    </div> 
                                    
                                </div>-->
                            </div>

                        </div>
            </div>
        </div>


        <!-- <div class="row mt-5 my-4">
            <section class="testimonials">
                <div class="container">
                  <h3 class="text-center text-white">Students and parents love️ us!</h3>
                  <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">

                      <div class="carousel-item active">
                        <div class="carousel-caption d-md-block">
                          <div class="row align-items-center">
                            <div class="col-md-3"><img src="../../assets/imgs/Ellipse 2.png" class="img-fluid" alt=""></div>
                            <div class="col-md-9 text-left">
                              <div style="text-align:center">
                                <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                              </div>
                              <p class="desription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et metus a turpis auctor fermentum id at mauris. Quisque mollis ex nunc, vitae gravida lacus ullamcorp</p>
                              <span class="date">15 Sep 2019</span>
                              <h6 class="name">Nandu Gopal Reddy</h6>
                              <span class="age">Age 13, Class 7</span>
                            </div>
                          </div>
                          <h5>First slide label</h5>
                          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="carousel-caption d-md-block">
                          <div class="row align-items-center">
                            <div class="col-md-3"><img src="../../assets/imgs/Ellipse 2.png" class="img-fluid" alt=""></div>
                            <div class="col-md-9 text-left">
                              <div style="text-align:center">
                                <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                              </div>
                              <p class="desription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et metus a turpis auctor fermentum id at mauris. Quisque mollis ex nunc, vitae gravida lacus ullamcorp</p>
                              <span class="date">15 Sep 2019</span>
                              <h6 class="name">Nandu Gopal Reddy</h6>
                              <span class="age">Age 13, Class 7</span>
                            </div>
                          </div>
                          <h5>First slide label</h5>
                          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="carousel-caption d-md-block">
                          <div class="row align-items-center">
                            <div class="col-md-3"><img src="../../assets/imgs/Ellipse 2.png" class="img-fluid" alt=""></div>
                            <div class="col-md-9 text-left">
                              <div style="text-align:center">
                                <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                              </div>
                              <p class="desription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et metus a turpis auctor fermentum id at mauris. Quisque mollis ex nunc, vitae gravida lacus ullamcorp</p>
                              <span class="date">15 Sep 2019</span>
                              <h6 class="name">Nandu Gopal Reddy</h6>
                              <span class="age">Age 13, Class 7</span>
                            </div>
                          </div>
                          <h5>First slide label</h5>
                          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </div>
                      </div>

                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                  <div class="container testimonials-bottom">
                    <div class="row">
                      <div class="col-md-3 text-center">
                        <img src="../../assets/imgs/people-circle.svg" class="img-fluid" alt="">
                        <span class="nums">
                          75K + 
                        </span>
                        <p class="mb-0">Students</p> 
                      </div>
                      <div class="col-md-3 text-center">
                        <img src="../../assets/imgs/star.svg" class="img-fluid" alt="">
                        <span class="nums">
                          4.7 + 
                        </span>
                        <p class="mb-0">Stars</p> 
                      </div>
                      <div class="col-md-3 text-center">
                        <img src="../../assets/imgs/city-alt.svg" class="img-fluid" alt="">
                        <span class="nums">
                          170 + 
                        </span>
                        <p class="mb-0">Cities</p> 
                      </div>
                      <div class="col-md-3 text-center">
                        <img src="../../assets/imgs/time.svg" class="img-fluid" alt="">
                        <span class="nums">
                          70 Mins 
                        </span>
                        <p class="mb-0">Daily Time Spent</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
        </div> -->

        <app-testimonials></app-testimonials>

        <div class="mt-5 my-4 container" style="padding: 0;">
            <section class="faq" style="padding: 15px;">
                <div class="container" *ngIf="SelfPaceData.faq.length > 0">
                    <div class="faq_title">
                        <h4 class="bold">FAQ’s</h4>
                    </div>
                
                    <mat-accordion>

                      <mat-expansion-panel *ngFor="let questions of SelfPaceData.faq;let i =index" class="box_layout">
                        <mat-expansion-panel-header class="faq_heading">
                          {{questions.question}} 
                          
                        </mat-expansion-panel-header>
                        <div class="card-body faq_subheading">
                          {{questions.answer}}
                        </div>
                        
                      </mat-expansion-panel>
                    </mat-accordion> 
                </div>
            </section>
        </div>


        <app-blog></app-blog>



        
    </div>
</section>


<app-footer></app-footer>