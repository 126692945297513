import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../../validate-pattern';
import { Subscription } from 'rxjs';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { UserService } from 'src/app/service/user.service';
import * as commonFunction from '../../../function/common-function';
import { DatePipe } from '@angular/common';
import { ModalService } from 'src/app/service/modal.service';
import { CommonService } from 'src/app/service/common.service';
declare var $: any;
@Component({
  selector: 'app-book-demo-pie-class-modal',
  templateUrl: './book-demo-pie-class.component.html',
  styleUrls: ['./book-demo-pie-class.component.scss'],
  providers: [DatePipe]
})
export class BookDemoClassPieModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  isPreSchool = false;
  AgeGroupList: any = [];
  UserData = JSON.parse(localStorage.getItem('user'));
  stateList = [];
  subscription: Subscription;
  phoneNo: any = '';
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public _leadsquaredService: LeadsquaredService, public userService: UserService, private _datePipe: DatePipe,private modalService: ModalService,public commonService:CommonService) { }
  ngOnInit(): void {
    this.commonService.GetPhoneNoOtp().subscribe(response => {
      // console.log('response::::', response);
      return this.phoneNo = response
    });
    this._employeeService.post('age_group_list_dropdown', { learning: 1 }).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupList = res.data;
      }
    });
    this.subscription = this._leadsquaredService.stateList().subscribe(res => {
      if (res) { this.stateList = res.data; }
    });
    this.Form = this.fb.group({
      name: ['', [Validators.required]],
      phone_no: [this.phoneNo],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      gender: [''],
      image: [''],
      age_group_id: ['', [Validators.required]],
      learning: ['1'],
      time_zone: [''],
      dob: [''],
      parents_name: ['', [Validators.required]],
      school_name: [''],
      subscription_type: ['0'],
      demo_class_date: [''],
      time_slot: [''],
      time_from: [''],
      time_to: [''],
    })
  }
  get f() { return this.Form.controls; }

  onCloseModal() {
    this.modalService.setbookDemoClassPieModal(false);
  }
  // Book Demo 
  onLogin() {
    // console.log('Data::::::', this.Form.value);
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      var sendRequestData = this.Form.value;
      if (localStorage.getItem('referral_code')) {
        sendRequestData.sponsor_code = localStorage.getItem('referral_code');
      }
      this.userService.userRegister('register_student', sendRequestData)
    }
  }
}
