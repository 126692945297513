<div class="modal popup" id="bookFreeClassModal" tabindex="-1" aria-labelledby="bookFreeClassModalLabel" aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <!-- Coding Header -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0 pb-5 pl-5 pr-5 book-class-modal">
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-12">
            <h1>Book your Trial</h1>
            <p>Only few spots left!</p>
            <form [formGroup]="Form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="parents_name">Parent name</label>
                    <input type="text" placeholder="Enter Parent Name" appAphpaOnly class="form-control pl-0" id="parents_name"
                      formControlName="parents_name">
                    <div *ngIf="submitted && f.parents_name.errors" class="error_input">
                      <div *ngIf="f.parents_name.errors.required">This field is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="fullname">Child name</label>
                    <input type="text" placeholder="Enter Child Name" appAphpaOnly class="form-control pl-0" id="fullname"
                      formControlName="name">
                    <div *ngIf="submitted && f.name.errors" class="error_input">
                      <div *ngIf="f.name.errors.required">This field is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="Email">Email</label>
                    <input type="Email" placeholder="Enter Email" class="form-control pl-0" id="Email"
                      formControlName="email">
                    <div *ngIf="submitted && f.email.errors">
                      <small *ngIf="f.email.errors.required" class="error_input">
                        This Field is required
                      </small>
                      <small [hidden]="!f.email.errors.patternInvalid" class="error_input">Please enter valid
                        email.</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="mobile">Mobile No.</label>
                    <input type="text" placeholder="Enter Mobile" class="form-control pl-0" id="mobile"
                      formControlName="phone_no" appNumbersOnly maxlength="10" readonly>
                    <div *ngIf="submitted && f.phone_no.errors">
                      <small *ngIf="f.phone_no.errors.required" class="error_input">
                        This Field is required
                      </small>
                      <small [hidden]="!f.phone_no.errors.patternMobileInvalid" class="error_input">Phone Number should
                        be valid and 10 digit.</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="age">Age group</label>
                    <select name="age" class="form-control pl-0" id="age_group_id" formControlName="age_group_id">
                      <option value="" selected disabled>Select Age Group</option>
                      <option [value]="ages.id" *ngFor="let ages of AgeGroupList">{{ages.age_from}} - {{ages.age_to}}
                        Yrs</option>

                    </select>
                    <div *ngIf="submitted && f.age_group_id.errors" class="error_input">
                      <div *ngIf="f.age_group_id.errors.required">This field is required</div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-book-class" (click)="onLogin()" [disabled]="btnsubmitted">Book your 7
                day Trial</button>
            </form>
          </div>
          <div class=" col-lg-5 col-md-12 free_Classmobile_view">
            <img src="assets/imgs/modalimg/bookclass1.png" class="img-fluid" alt="" style="border-radius: 50%;
                  transform: scale(1.1);">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>