import { Component, OnInit } from '@angular/core';
import { StarRatingComponent } from 'ng-starrating';

import { BaseServiceService } from 'src/app/service';
import { ActivatedRoute } from '@angular/router';
declare var Razorpay: any;
declare var $ : any;
import { CommonService } from '../../service/common.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-learning-path',
  templateUrl: './learning-path.component.html',
  styleUrls: ['./learning-path.component.scss']
})
export class LearningPathComponent implements OnInit {
  constructor(public activatedRoute:ActivatedRoute,public _employeeService: BaseServiceService,public common:CommonService) { }
   
  ngOnInit(): void {
     
    
  }
   
  
   

   
}
