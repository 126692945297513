import { Component, OnInit,AfterViewInit} from '@angular/core';
import { Router } from '@angular/router';
import { BaseServiceService } from 'src/app/service';
import { CommonService } from 'src/app/service/common.service';
import { EnvService } from 'src/app/service/env.service';
import { ModalService } from 'src/app/service/modal.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $: any;
declare var require: any;
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit,AfterViewInit {
  TestimonialData: any = [];
  UserLoginFlag: boolean = false;
  constructor(public _employeeService: BaseServiceService,private sanitizer: DomSanitizer, public common: CommonService, public envService: EnvService, private modalService: ModalService,private router: Router) { }
  arrayLength = 10;
  images: any = [];
  currentImage: any = 0;
  responsiveOptions1: any = {};
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    autoplay:true,
    dots: false,
    navSpeed: 700,
    navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false
  }
  safeSrc1: SafeResourceUrl;
  safeSrc2: SafeResourceUrl;
  safeSrc3: SafeResourceUrl;
  safeSrc4: SafeResourceUrl;
  safeSrc5: SafeResourceUrl;
  safeSrc6: SafeResourceUrl;
  safeSrc7: SafeResourceUrl;
  safeSrc8: SafeResourceUrl;
  onPrevious(){
    if(this.currentImage>0)
    this.currentImage--;
  }
  interval;
  testimonialData: any = [];
  onNext(){
    if(this.currentImage<this.images.length-1)
    this.currentImage++;
  }
  ngAfterViewInit() {
   
  }
  ngOnInit(): void {
    this.responsiveOptions1 = {
      dragging: true,
      loop: true,
      navSpeed: 700,
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      970: {
        items: 4
      }
    };
    this.images = [
      { path: 'assets/imgs/review/1.png' },
      { path: 'assets/imgs/review/2.png' },
      { path: 'assets/imgs/review/3.png' },
      { path: 'assets/imgs/review/4.png' },
      { path: 'assets/imgs/review/5.png' },
      { path: 'assets/imgs/review/6.png' },
      { path: 'assets/imgs/review/7.png' },
      { path: 'assets/imgs/review/8.png' },
      { path: 'assets/imgs/review/9.png' }
    ]
    this._employeeService.gettestimonial().subscribe(data => {
      if (data) {
        this.TestimonialData = data.data;
        this.TestimonialData.forEach(element => {
          Object.assign(element,{class_view:false})
        });
        console.log('data:::::', data.data);
        this.safeSrc1 =  this.sanitizer.bypassSecurityTrustResourceUrl(data.data[0].url);
        this.safeSrc2 =  this.sanitizer.bypassSecurityTrustResourceUrl(data.data[1].url);
        this.safeSrc3 =  this.sanitizer.bypassSecurityTrustResourceUrl(data.data[2].url);
        this.safeSrc4 =  this.sanitizer.bypassSecurityTrustResourceUrl(data.data[3].url);
        this.safeSrc5 =  this.sanitizer.bypassSecurityTrustResourceUrl(data.data[4].url);
        this.safeSrc6 =  this.sanitizer.bypassSecurityTrustResourceUrl(data.data[5].url);
        this.safeSrc7 =  this.sanitizer.bypassSecurityTrustResourceUrl(data.data[6].url);
        this.safeSrc8 =  this.sanitizer.bypassSecurityTrustResourceUrl(data.data[7].url);
      }
    });
    this.common.getLoggedInStatus().subscribe(data => {
      if (data == true) {
        this.UserLoginFlag = true;
      } else {
        this.UserLoginFlag = false;
      }
    })
  }
  getUrl(url) {
    // console.log(url);
    
    // console.log(this.safeSrc);
    // return this.safeSrc;
  }
  redirect(redirectId) {
    this.router.navigateByUrl('/');
    this.common.SetRouteType(redirectId);
  }
  onRedirect() {
    if (localStorage.getItem('user')) {
      var userData = JSON.parse(localStorage.getItem('user'))
      if (userData.learning == 1) {
        window.location.href = `${this.envService.env}/student/dashboard-learning`;
      } else {
        window.location.href = `https://student.learningbix.com/dashboard`;
      }
    }
  }
  onOpenPieModal() {
    this.modalService.SetModalOnOff(true);
    this.modalService.setBookAFreeModal(true);
  }
  onLoginPanelPopup() {
    this.modalService.SetModalOnOff(true);
    this.modalService.setloginemailBixModal(true);
  }
  onChangeDescriptionView(reviewObject) {
    if (reviewObject.class_view) {
      reviewObject.class_view = false;
      this.TestimonialData.forEach(element => Object.assign(element,{class_view:false}));
    } else {
      this.TestimonialData.forEach(element => Object.assign(element,{class_view:false}));
      reviewObject.class_view = true;
    }
  }
}
