import { ApplyCouponModalComponent, BixPieModalComponent, BookDemoClassBixModalComponent, BookDemoClassPieModalComponent, ForgotBixModalComponent, LoginEmailBixModalComponent, LoginEmailGuestUserComponent, LoginOtpBixModalComponent, LoginPasswordBixModalComponent, LoginPhoneBixModalComponent, OfferModalComponent, PreSchoolEnrollNowBixModalComponent, RegisterBixModalComponent } from './modal-box';
import { BlogComponent, CheckoutComponent, CourseCodingComponent, CourseComponent, EarlyLearningComponent, LearningPathComponent, PieBlogComponent, TestimonialsComponent } from './common';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModulesPro, MDBSpinningPreloader, ToastModule } from 'ng-uikit-pro-standard';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule
} from '@angular/material';
import { SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';

import { APP_INITIALIZER } from '@angular/core';
import { AboutComponent } from './pages/about/about.component';
import { AllListComponent } from './courses/all-list/all-list.component';
import { AphpaOnlyDirective } from './directive/aphpa-only.directive';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BasicModalComponent } from './modal-box/basic-modal/basic-modal.component';
import { BookAFreeModalComponent } from './modal-box/coding/book-a-free/book-a-free.component';
import { BookAFreeStep2ModalComponent } from './modal-box/coding/book-a-free-step-2/book-a-free-step-2.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BuyNowComponent } from './common/buy-now/buy-now.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CodingComponent } from './coding/coding.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { CustomBlogComponent } from './pages/custom-blog/custom-blog.component';
import { DescComponent } from './pages/desc/desc.component';
import { DetailsComponent } from './courses/details/details.component';
import { EnvService } from './service/env.service';
import { FaqNewComponent } from './components/robotics/faq-new/faq-new.component';
import { FooterComponent } from './imcludes/footer/footer.component';
import { FranchiseComponent } from './pages/franchise/franchise.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { HeaderComponent } from './imcludes/header/header.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { KitsComponent } from './pages/kits/kits.component';
import { LandingComponent } from './common/landing/landing.component';
import { LazyPayloadModalComponent } from './modal-box/lazy-payload/lazy-payload.component';
import { LearningPieContactsComponent } from './pages/in-contacts/in-contacts.component';
import { ListingComponent } from './courses/listing/listing.component';
import { LoaderModalComponent } from './modal-box/loader/loader.component';
import { NgModule } from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxSlickJsModule } from 'ngx-slickjs'
import { NumbersOnlyDirective } from './directive/numbers-only.directive';
import { OnlinePreSchoolComponent } from './online-pre-school/online-pre-school.component';
import { OwlModule } from 'ngx-owl-carousel';
import { PaymentFailureComponent } from './common/payment/payment-failure/payment-failure.component';
import { PaymentSuccessComponent } from './common/payment/payment-success/payment-success.component';
import { PieAboutComponent } from './pie-about/pie-about.component';
import { PieFooterComponent } from './imcludes/pie-footer/pie-footer.component';
import { PieHeaderComponent } from './imcludes/pie-header/pie-header.component';
import { PieHomeComponent } from './pie-home/pie-home.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { ProjectComponent } from './pages/project/project.component';
import { RatingModule } from 'ng-starrating';
import { RedeemCodeComponent } from './pages/redeem-code/redeem-code.component';
import { SchoolComponent } from './pages/school/school.component';
import { ShopNewComponent } from './components/robotics/shop-new/shop-new.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TestComponent } from './pages/test/test.component';
import { TestimonialComponent } from './pages/testimonial/testimonial.component';
import { TncComponent } from './pages/tnc/tnc.component';
import { VimeoComponent } from './common/vimeo/vimeo.component';
import { environment } from '../environments/environment';

// Material Module






































// Modal Box Declare




























@NgModule({
  declarations: [
    AppComponent, HomeComponent, HeaderComponent, FooterComponent, PieHeaderComponent, PieFooterComponent, CodingComponent, ListingComponent, DetailsComponent, AboutComponent, TncComponent, PolicyComponent, DescComponent, TestimonialsComponent, BlogComponent, ContactsComponent, CourseComponent, ProjectComponent, AllListComponent, PieHomeComponent, NumbersOnlyDirective, AphpaOnlyDirective, EarlyLearningComponent, CourseCodingComponent, LearningPathComponent, CheckoutComponent,
    PaymentSuccessComponent, PaymentFailureComponent, PieAboutComponent,
    BookDemoClassBixModalComponent,SchoolComponent, PreSchoolEnrollNowBixModalComponent, ForgotBixModalComponent, LoginEmailBixModalComponent, LoginPhoneBixModalComponent, RegisterBixModalComponent, LoginOtpBixModalComponent, ApplyCouponModalComponent, BixPieModalComponent, BookDemoClassPieModalComponent, BuyNowComponent,TestComponent,BasicModalComponent,OfferModalComponent,BookAFreeModalComponent,BookAFreeStep2ModalComponent,LandingComponent,VimeoComponent, OnlinePreSchoolComponent, FaqNewComponent,LearningPieContactsComponent,TestimonialComponent,PieBlogComponent,LoaderModalComponent,LazyPayloadModalComponent,LoginEmailGuestUserComponent,LoginPasswordBixModalComponent, SchoolComponent,FranchiseComponent,KitsComponent,CustomBlogComponent,RedeemCodeComponent
  ],
  imports: [

    BrowserModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    RatingModule,
    CarouselModule,
    NgOtpInputModule,
    AppRoutingModule, FormsModule, ReactiveFormsModule, HttpClientModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    MatToolbarModule, MatAutocompleteModule, FormsModule, SocialLoginModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule, BrowserAnimationsModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatExpansionModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,
    MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule,
    MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-W8DFQN7',
    }),NgxSlickJsModule.forRoot({
      links: {
        jquery: "https://code.jquery.com/jquery-3.4.0.min.js",
        slickJs: "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js",
        slickCss: "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css",
        slickThemeCss: "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
      }
  }),OwlModule
  ],
  providers: [
    { provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' },{
    provide: APP_INITIALIZER,
    useFactory: (envService: EnvService) => () => envService.init(),
    deps: [EnvService],
    multi: true
  }, MDBSpinningPreloader, {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [{
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider('811885995946-eh5jkj56e5888cn7k75ibuqvncaja94m.apps.googleusercontent.com')
      }, {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('2971458876471193')
      }]
    } as SocialAuthServiceConfig,
  },Meta],
  bootstrap: [AppComponent]
})
export class AppModule { }
