<app-header></app-header>
<section>
    <div class="container my-4">
        <div class="row my-3">
            <div class="col-12">
                <h3 class="coading-text">Coding For Kids Courses</h3>
                <p class="coading-text-desc">Learning Bix offers a range of courses for you and your child to choose from. You can opt for courses
                    focused on a specific language (like HTML, CSS, or Python), or you can choose more game-based
                    introductions algorithmic logic.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-12">
               <div class="card filter-card">
                    
                    <h3 class="filter_title" (click)="showAgeGroup()">Age Group<i class="fas fa-chevron-down float-right"></i></h3>
                         <div *ngIf="selectedAgeId !=''">
                            <a href="javascript:void(0)" (click)="onClear()">Clear All</a>
                             
                        </div>  
                    <ng-container *ngIf="ageGroup">
                        <hr>
                     
                        <div *ngFor="let age of AgeGroupList">
                            <input type="radio" name="selectedAgeId"  id="{{age.id}}"  (change)="changeAge($event)" [(ngModel)]="selectedAgeId" [value]="age.id">
                            <label class="filter_label" for="{{age.id}}">{{age.title}}</label>
                        </div>
                    </ng-container>

               </div>
                               
                 
            </div>
            <div class="col-md-9 col-sm-6 col-xs-12">
                <div class="card course-card">
                    <div class="row">
                        <!-- <div class="col-md-3">ajsdghfs</div> -->
                        <div class="col">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row custome-list" *ngFor="let selfpace of SelfPaceData">
                                        <div class="col-lg-3 col-md-5 col-sm-12">
                                            <div>
                                                <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{selfpace.image}}" onError="this.src='assets/imgs/Rectangle 71.png'"  alt="" class="img-fluid course-image">
                                            </div>
                                        </div>
                                        <div class="col-lg-9 col-md-12 col-sm-12">
                                            <div class="row" style="align-items: center;">
                                                <div class="col-lg-12 col-md-12 col-sm-12 course-details">
                                                    <span class="text-heading course-details-iner">{{selfpace.course_name}}</span>
                                                    <span class="text-descrption course-details-iner" *ngIf="selfpace.sub_title != null && selfpace.sub_title != 'null'">{{selfpace.sub_title | slice:0:250}}</span>
                                                    <span class="text-author course-details-iner" *ngIf="selfpace.by_teacher_name != null">By - {{selfpace.by_teacher_name}}</span>
                                                    <span class="text-rating course-details-iner">{{selfpace.dummy_rating}} / 5 
                                                        <span *ngIf="selfpace.dummy_rating=='1'">
                                                            <i class="fas fa-star"></i>
                                                        </span>
                                                        <span *ngIf="selfpace.dummy_rating >'1' && selfpace.dummy_rating <'2'"> <i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                                        <span *ngIf="selfpace.dummy_rating=='2'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i></span>
                                                        <span *ngIf="selfpace.dummy_rating >'2' && selfpace.dummy_rating <'3'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                                        <span *ngIf="selfpace.dummy_rating=='3'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                                        <span *ngIf="selfpace.dummy_rating >'3' && selfpace.dummy_rating <'4'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                
                                                        <span *ngIf="selfpace.dummy_rating=='4'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                                        <span *ngIf="selfpace.dummy_rating >'4' && selfpace.dummy_rating <'5'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                
                                                        <span *ngIf="selfpace.dummy_rating=='5'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                                    </span>
                                                    <span class="text-time-level course-details-iner">{{selfpace.course_hours}} hrs | {{selfpace.lectures}} Lectures | All levels</span>
                                                </div>
                                                <div class="col-lg-4 col-md-12 col-sm-12 course-details-action">
                                                    <span class="text-author course-details-iner">₹{{selfpace.amount}}</span>
                                                    <div class="course-details-iner"><button class="btn btn-light btn-accent" routerLink="/description" (click)="onDetails(selfpace.id)">View Details</button></div>
                                                    <!-- <span class="text-time-level course-details-iner">5 Slots left</span> -->
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <!-- <div class="row custome-list">
                                        <div class="col-md-3 col-sm-12">
                                            <div>
                                                <img src="assets/imgs/Rectangle 71.png" alt="" class="img-fluid course-image">
                                            </div>
                                        </div>
                                        <div class="col-md-9 col-sm-12">
                                            <div class="row" style="align-items: center;">
                                                <div class="col-md-9 col-sm-12 course-details">
                                                    <span class="text-heading course-details-iner">Teach Your Kids to Code: Learn Python Programming at Any Age</span>
                                                    <span class="text-descrption course-details-iner">Lorem ipsum dolor sit amet, id altera persequeris vim, mea an appareat prodesset. Cu mea error eripuit delicata. Nec soluta aliquid similique eu.</span>
                                                    <span class="text-author course-details-iner">By - Trisha Musziri</span>
                                                    <span class="text-rating course-details-iner">4.7 / 5</span>
                                                    <span class="text-time-level course-details-iner">7.5 hrs | 14 Lectures | All levels</span>
                                                </div>
                                                <div class="col-md-3 col-sm-12 course-details-action">
                                                    <span class="text-author course-details-iner">₹238.00</span>
                                                    <div class="course-details-iner"><button class="btn btn-light btn-accent">View Details</button></div>
                                                    <span class="text-time-level course-details-iner">5 Slots left</span>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div class="row custome-list">
                                        <div class="col-md-3 col-sm-12">
                                            <div>
                                                <img src="assets/imgs/Rectangle 71.png" alt="" class="img-fluid course-image">
                                            </div>
                                        </div>
                                        <div class="col-md-9 col-sm-12">
                                            <div class="row" style="align-items: center;">
                                                <div class="col-md-9 col-sm-12 course-details">
                                                    <span class="text-heading course-details-iner">Teach Your Kids to Code: Learn Python Programming at Any Age</span>
                                                    <span class="text-descrption course-details-iner">Lorem ipsum dolor sit amet, id altera persequeris vim, mea an appareat prodesset. Cu mea error eripuit delicata. Nec soluta aliquid similique eu.</span>
                                                    <span class="text-author course-details-iner">By - Trisha Musziri</span>
                                                    <span class="text-rating course-details-iner">4.7 / 5</span>
                                                    <span class="text-time-level course-details-iner">7.5 hrs | 14 Lectures | All levels</span>
                                                </div>
                                                <div class="col-md-3 col-sm-12 course-details-action">
                                                    <span class="text-author course-details-iner">₹238.00</span>
                                                    <div class="course-details-iner"><button class="btn btn-light btn-accent">View Details</button></div>
                                                    <span class="text-time-level course-details-iner">5 Slots left</span>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</section>

<app-footer></app-footer>