import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
@Injectable({
	providedIn: 'root'
})
export class TokenService {
  constructor(private router: Router,public common:CommonService){}

  getUser(): string { return window.localStorage['user']};
  saveUser(user: string) { window.localStorage['user'] = user};

  getSid(): string { return window.localStorage['sid']};
  saveSid(sid: string) { window.localStorage['sid'] = sid};

  getpwdflg(): string { return window.localStorage['cngpwd']};
  savepwdflg(cngpwd: string) { window.localStorage['cngpwd'] = cngpwd};

  getDemoClass(): string { return window.localStorage['flag']};
  saveDemoClass(flag: string) { window.localStorage['flag'] = flag};
  removeDemoClass() {window.localStorage.removeItem('flag')};


  getLogin_Teacher(): string { return window.localStorage['login_teacher']};
  saveLogin_Teacher(login_teacher: string) { window.localStorage['login_teacher'] = login_teacher};
  removeLogin_Teacher() {window.localStorage.removeItem('login_teacher')};

  destroyToken() { 
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('cngpwd');
    window.localStorage.removeItem('sid');
    window.localStorage.removeItem('flag');
    window.localStorage.removeItem('login_teacher');
  }

  onLogout(){
    this.destroyToken();
    localStorage.clear();
    this.common.SetCartUpdated(true);
    this.common.setLoggedInStatus(false);
    this.common.SetCartUpdated(false)
    this.router.navigateByUrl('/robotics');
  }
}