import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './pages/about/about.component';
import { AllListComponent } from './courses/all-list/all-list.component';
import { BuyNowComponent } from './common/buy-now/buy-now.component';
import { CheckoutComponent } from './common';
import { CodingComponent } from './coding/coding.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { CustomBlogComponent } from './pages/custom-blog/custom-blog.component';
import { DescComponent } from './pages/desc/desc.component';
import { FaqNewComponent } from './components/robotics/faq-new/faq-new.component';
import { FranchiseComponent } from './pages/franchise/franchise.component';
import { HomeComponent } from './home/home.component';
import { KitsComponent } from './pages/kits/kits.component';
import { LandingComponent } from './common/landing/landing.component';
import { LearningPieContactsComponent } from './pages/in-contacts/in-contacts.component';
import { ListingComponent } from './courses/listing/listing.component';
import { NgModule } from '@angular/core';
import { OnlinePreSchoolComponent } from './online-pre-school/online-pre-school.component';
import { PaymentFailureComponent } from './common/payment/payment-failure/payment-failure.component';
import { PaymentSuccessComponent } from './common/payment/payment-success/payment-success.component';
import { PieAboutComponent } from './pie-about/pie-about.component';
import { PieHomeComponent } from './pie-home/pie-home.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { ProjectComponent } from './pages/project/project.component';
import { RedeemCodeComponent } from './pages/redeem-code/redeem-code.component';
import { SchoolComponent } from './pages/school/school.component';
import { TestComponent } from './pages/test/test.component';
import { TestimonialComponent } from './pages/testimonial/testimonial.component';
import { TncComponent } from './pages/tnc/tnc.component';
import { VimeoComponent } from './common/vimeo/vimeo.component';

let routes: Routes = [];
const hostname = window && window.location && window.location.hostname;
console.log('hostname>>>>>>>', hostname);
if (hostname == 'www.learningpie.in' || hostname == 'learningpie.in') {
  routes = [
    {
      path: '', component: OnlinePreSchoolComponent, data: { title: 'Learningpie@Home- Best Virtual Play School in India | Preschool for Kids', description: 'Learningpie@Home is one of the best virtual preschools for kids that offers International Standard Education for holistic development of kids of age 1.5 - 6 yrs' },
    }, {
      path: 'pie/buy-now', component: BuyNowComponent, data: { title: 'Learningpie@Home- Best Virtual Play School in India | Preschool for Kids', description: 'Learningpie@Home is one of the best virtual preschools for kids that offers International Standard Education for holistic development of kids of age 1.5 - 6 yrs' },
    }, {
      path: 'pie-about', component: PieAboutComponent, data: { title: 'Learningpie@Home- Best Virtual Play School in India | Preschool for Kids', description: 'Learningpie@Home is one of the best virtual preschools for kids that offers International Standard Education for holistic development of kids of age 1.5 - 6 yrs' },
    }, {
      path: 'page/:slug', component: TncComponent, data: { title: 'Learningpie@Home- Best Virtual Play School in India | Preschool for Kids', description: 'Learningpie@Home is one of the best virtual preschools for kids that offers International Standard Education for holistic development of kids of age 1.5 - 6 yrs' },
    }, {
      path: 'description/:id', component: DescComponent, data: { title: 'Learningpie@Home- Best Virtual Play School in India | Preschool for Kids', description: 'Learningpie@Home is one of the best virtual preschools for kids that offers International Standard Education for holistic development of kids of age 1.5 - 6 yrs' },
    }, {
      path: 'contact-us', component: ContactsComponent, data: { title: 'Learningpie@Home- Best Virtual Play School in India | Preschool for Kids', description: 'Learningpie@Home is one of the best virtual preschools for kids that offers International Standard Education for holistic development of kids of age 1.5 - 6 yrs' },
    }, {
      path: 'contacts-us', component: LearningPieContactsComponent, data: { title: 'Learningpie@Home- Best Virtual Play School in India | Preschool for Kids', description: 'Learningpie@Home is one of the best virtual preschools for kids that offers International Standard Education for holistic development of kids of age 1.5 - 6 yrs' },
    }, {
      path: 'online-preschool-reviews', component: TestimonialComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    },{
      path: 'redeem-code', component: RedeemCodeComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    }
  ]
} else if (hostname == 'www.learningbix' || hostname == 'learningbix') {
  routes = [
    {
      path: '', component: HomeComponent, data: { title: 'Robotics | Coding & skill development activity For Kids | Learning Bix', description: 'Best Stem Robotics Kits, Coding Games & Skill Development online Courses At Affordable Prices. Learn Coding, Programming, Robotics, IoT & Ai At Learning Bix.' },
    }, {
      path: 'coding', component: CodingComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'courses', component: ListingComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'all-courses', component: AllListComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'about', component: AboutComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'page/:slug', component: TncComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'description/:id', component: DescComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'contact-us', component: ContactsComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'projects', component: ProjectComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'school', component: SchoolComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    }, {
      path: 'robotics',
      loadChildren: () => import('./robotics/robotics.module').then(m => m.RoboticsModule)
    }, {
      path: 'checkout', component: CheckoutComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'payment-success', component: PaymentSuccessComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'payment-failure', component: PaymentFailureComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'test', component: TestComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'landing-page', component: LandingComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'vimeo/:id', component: VimeoComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    },{
      path: 'redeem-code', component: RedeemCodeComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    }
  ]
}else if (hostname == 'www.dev.learningbix' || hostname == 'dev.learningbix') {
  routes = [
    {
      path: '', component: HomeComponent, data: { title: 'Robotics | Coding & skill development activity For Kids | Learning Bix', description: 'Best Stem Robotics Kits, Coding Games & Skill Development online Courses At Affordable Prices. Learn Coding, Programming, Robotics, IoT & Ai At Learning Bix.' },
    }, {
      path: 'coding', component: CodingComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'courses', component: ListingComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'all-courses', component: AllListComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'about', component: AboutComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'page/:slug', component: TncComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'description/:id', component: DescComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'contact-us', component: ContactsComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'projects', component: ProjectComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'school', component: SchoolComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    },{
      path: 'franchisee', component: FranchiseComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    },{
      path: 'learning-center', component: KitsComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    },  {
      path: 'robotics',
      loadChildren: () => import('./robotics/robotics.module').then(m => m.RoboticsModule)
    }, {
      path: 'checkout', component: CheckoutComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'payment-success', component: PaymentSuccessComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'payment-failure', component: PaymentFailureComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'test', component: TestComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'landing-page', component: LandingComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'vimeo/:id', component: VimeoComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    },{
      path: 'redeem-code', component: RedeemCodeComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    }
  ]
} else {
  routes = [
    {
      path: '', component: HomeComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'coding', component: CodingComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'courses', component: ListingComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'all-courses', component: AllListComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'about', component: AboutComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'page/:slug', component: TncComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'description/:id', component: DescComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'contact-us', component: ContactsComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'projects', component: ProjectComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'school', component: SchoolComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    },{
      path: 'franchisee', component: FranchiseComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    }, {
      path: 'learning-center', component: KitsComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    }, {
      path: 'blogs/:id', component: CustomBlogComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    }, {
      path: 'pie', component: PieHomeComponent,
    }, {
      path: 'pie/buy-now', component: BuyNowComponent,
    }, {
      path: 'pie-about', component: PieAboutComponent,
    }, {
      path: 'page/:slug', component: TncComponent,
    }, {
      path: 'description/:id', component: DescComponent,
    }, {
      path: 'contact-us', component: ContactsComponent,
    }, {
      path: 'robotics',
      loadChildren: () => import('./robotics/robotics.module').then(m => m.RoboticsModule)
    }, {
      path: 'checkout', component: CheckoutComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'payment-success', component: PaymentSuccessComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'payment-failure', component: PaymentFailureComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'test', component: TestComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'landing-page', component: LandingComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'vimeo/:id', component: VimeoComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'online-preschool-reviews', component: TestimonialComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' },
    }, {
      path: 'online-preschool', component: OnlinePreSchoolComponent,
    }, {
      path: 'faq-new', component: FaqNewComponent,
    },{
      path: 'redeem-code', component: RedeemCodeComponent, data: { title: 'Online Coding Classes For Kids - Learningbix', description: 'LearningBIX offers LIVE 1 on 1 online coding classes for kids to make them learn block-based coding, games and web development. The courses include AI & ML for kids.' }
    }
  ]
}
@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],

  exports: [RouterModule]
})
export class AppRoutingModule { }
