import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseServiceService } from '../service';
import { CommonService } from '../service/common.service';
import { EnvService } from '../service/env.service';
import { LeadsquaredService } from '../service/leadsquared.service';
import { ModalService } from '../service/modal.service';
import { UserService } from '../service/user.service';
import { EmailPatternValidator, MobilePatternValidator } from '../validate-pattern';
declare var $: any;
@Component({
  selector: 'app-online-pre-school',
  templateUrl: './online-pre-school.component.html',
  styleUrls: ['./online-pre-school.component.scss']
})
export class OnlinePreSchoolComponent implements OnInit {
  ageCourseList: any = [];
  AgeGroupList: any = [];
  AgeGroupListData: any = [];
  UserLoginFlag: boolean = false;
  AddForm: FormGroup; submitted = false; btnsubmitted = false;
  mobileNo: any='';
  AgeGroupListPricing = [];
  AgeGroupCoursesList = [];
  IndexId: any;
  firstIndexId: any;
  AgeGroupId: any;
  ageWiseImageIcon = [
    { image: 'assets/imgs/ageGroupIcon1.png' },
    { image: 'assets/imgs/ageGroupIcon2.png' },
    { image: 'assets/imgs/ageGroupIcon3.png' },
    { image: 'assets/imgs/ageGroupIcon4.png' },
    { image: 'assets/imgs/ageGroupIcon5.png' },
    { image: 'assets/imgs/ageGroupIcon6.png' },
    { image: 'assets/imgs/ageGroupIcon7.png' },
  ]
  constructor(public _employeeService: BaseServiceService, public envService: EnvService, private modalService: ModalService, public common: CommonService,public fb: FormBuilder, public userService: UserService,public leedService:LeadsquaredService, private sanitizer: DomSanitizer,) { 
    this.common.SetPanel('pie');
  }
  get f() { return this.AddForm.controls; }
  ngOnInit(): void {
    // $('#basicModel30Sec').modal('show');
    this.userService.ageGroupList();
    this.common.GetRouteType().subscribe(route => {
      this.onScroll(route)
    })
    this.AddForm = this.fb.group({
      name: ['', [Validators.required]],
      phone_no: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      gender: [''],
      image: [''],
      age_group_id: ['', [Validators.required]],
      time_zone: [''],
      dob: [''],
      parents_name: ['', [Validators.required]],
      school_name: [''],
      subscription_type: ['0'],
      demo_class_date: [''],
      time_slot: [''],
      time_from: [''],
      time_to: [''],
      learning: ['1'],
    })
    this._employeeService.getpieAgeCourseJSON().subscribe(data => {
      if (data) {
        this.ageCourseList = data.data;
      }
    })
    this.common.getLoggedInStatus().subscribe(data => {
      if (data == true) {
        this.UserLoginFlag = true;
      } else {
        this.UserLoginFlag = false;
      }
    })
    this._employeeService.post('age_group_list_dropdown', { learning: '1' }).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupList = res.data;
        this.AgeGroupList.forEach((element,index) => {
          if (index == 0) {
            element.class="btn-book-orange"
          } else {
            element.class="btn-book-orange-outline" 
          }
        });
        this.onChangeAgeCourse(this.AgeGroupList[0],0)
      }
    });
    this._employeeService.post('age_group_list_dropdown', { learning: '1' }).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupListPricing = res.data;
        this.AgeGroupListPricing = this.AgeGroupListPricing.map((item, i) => Object.assign({}, item, this.ageWiseImageIcon[i]));

        this.getCoursesList(this.AgeGroupListPricing[0].id);
        this.getCoursesListFirst('0', this.AgeGroupListPricing[0].id)

        // if (res.data.length > 0) {
        //   this.common.GetAgeCourseType().subscribe(data => {
        //     // console.log('Group::ID::', data);
        //     this.IndexId = data;
        //     this.firstIndexId = data;
        //     const isLargeNumber = this.AgeGroupListPricing.findIndex(item => item.id == this.firstIndexId)
        //     // this.getCoursesList(data);
        //     if (data == '') {
        //       this.getCoursesList(this.AgeGroupId);
        //       this.getCoursesListFirst(isLargeNumber, this.AgeGroupId)
        //     } else {
        //       this.getCoursesList(data);
        //       this.getCoursesListFirst(isLargeNumber, data)

        //     }
        //   })
        // }
      }
    });
  }
  onChangeAgeCourse(ageObject,index) {
    this._employeeService.getJSON().subscribe(data => {
      this.AgeGroupListData= data.data.filter(x=>x.index==index)
    })
    this.AgeGroupList.forEach(element => element.class="btn-book-orange-outline");
    ageObject.class = "btn-book-orange";
  }
  onOpenPieModal() {
    this.modalService.SetModalOnOff(true);
    this.modalService.setBookAFreeModal(true);
  }
  onLoginPanelPopup() {
    this.modalService.SetModalOnOff(true);
    this.modalService.setloginemailBixModal(true);
  }
  onRedirect() {
    if (localStorage.getItem('user')) {
      var userData = JSON.parse(localStorage.getItem('user'))
      if (userData.learning == 1) {
        window.location.href = `${this.envService.env}/student/dashboard-learning`;
      } else {
        window.location.href = `${this.envService.env}/student/dashboard`;
      }
    }
  }
  clickEventAddClass(ageCourseDataObject) {
    this.ageCourseList.forEach(element => element.class = '');
    ageCourseDataObject.class = '_active';
    this.AddForm.controls['age_group_id'].setValue(ageCourseDataObject.value)
  }
  onBookFreeClass() {
    this.submitted = true;
    console.log('FORM:::::', this.AddForm.value);
    if (this.AddForm.invalid) {
      return
    } else {
      var split_string = this.AddForm.value.name.split(' ');
      var senRequestLeeddata = this.AddForm.value;
        senRequestLeeddata.name = split_string[0];
        senRequestLeeddata.first_name = split_string[0];
      if (split_string.length > 2) {
        senRequestLeeddata.last_name = split_string[1] + ' ' + split_string[2];
      } else {
        senRequestLeeddata.last_name = split_string[1] || '';
      }
      console.log('senRequestLeeddata:::::', senRequestLeeddata);
      this.userService.userRegister('register_student',senRequestLeeddata).then(data => {
        if (!data.error) {
          if (data.replyCode == 'success') {
            this._employeeService.showSuccess(data.replyMsg);
            this.AddForm.reset();
            this.submitted = false;
            this.btnsubmitted = false;
          } else {
            this.btnsubmitted = false;
            this._employeeService.showError(data.replyMsg)
          }
        } else {
          this.btnsubmitted = false;
          this._employeeService.showError(data.error.replyMsg)
        }
      });
    }
  }
  onSubmitQuery() {
    console.log('this.mobileNo:::::', this.mobileNo);
    if (this.mobileNo != '') {
      this.leedService.questionQuery(this.mobileNo).subscribe(data => 
        { localStorage.setItem('LEED', JSON.stringify(data)); return data }
      );
      this.mobileNo = '';
      this._employeeService.showSuccess('Successfully Done.');
    } else {
      this._employeeService.showError('Please Enter Your Number.')
      return
    }
    // 
  }
  
  getCoursesListFirst(index, agegroupid) {
    // console.log('====================================');
    // console.log(index, agegroupid);
    // console.log('====================================');


    if (index == '0') {
      $('#profile0').addClass('active show');
      $('#profile1').removeClass('active show');
      $('#profile2').removeClass('active show');
      $('#profile3').removeClass('active show');
      this.firstIndexId = agegroupid;
    }
    if (index == '1') {
      $('#profile0').removeClass('active show');
      $('#profile1').addClass('active show');
      $('#profile2').removeClass('active show');
      $('#profile3').removeClass('active show');
      this.firstIndexId = agegroupid;
    }
    if (index == '2') {
      $('#profile0').removeClass('active show');
      $('#profile1').removeClass('active show');
      $('#profile2').addClass('active show');
      $('#profile3').removeClass('active show');
      this.firstIndexId = agegroupid;
    }
    if (index == '3') {
      $('#profile0').removeClass('active show');
      $('#profile1').removeClass('active show');
      $('#profile2').removeClass('active show');
      $('#profile3').addClass('active show');
      this.firstIndexId = agegroupid;
    }
    // $(profileid).addClass('active show');
    // $(profileid).removeClass('active show');
  }
  getCoursesList(agegroupid) {
    this.IndexId = agegroupid;
    console.log('IndexId::::', this.IndexId);
    var requestData = {
      age_group_id: String(agegroupid),
      featured: '1',
      learning: '1'
    }
    this._employeeService.post('live_courses_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupCoursesList = res.data;
        
        // console.log(this.AgeGroupCoursesList[0].tag.replace(/[^a-zA-Z0-9]/g, ''));
        console.log('First:::',this.AgeGroupCoursesList[0].tag)
      }
    });
  }
  getPerMonth(amount,totalPrice) {
    var removecharc= totalPrice.replace(/[^a-zA-Z0-9]/g, '')
    var split = removecharc.split('months');
    var calculteAmount = amount / split[0]
    var returnValue = Math.round(calculteAmount)
    return returnValue
  }
  getPerMonthFake(amount,totalPrice) {
    var removecharc= totalPrice.replace(/[^a-zA-Z0-9]/g, '')
    var split = removecharc.split('months');
    var calculteAmount = amount/split[0]
    var returnValue = Math.round(calculteAmount)
    return returnValue;
  }
  calculateSave(item) {
    let fackPrice = parseInt(item.fake_price);
    let actualPrice = parseInt(item.amount);
    let discountAmount = fackPrice - actualPrice
    let percent = (discountAmount * 100 / fackPrice);
    var returnValue = Math.round(percent)
    return `${returnValue}%`
  }
  
  getDescrption(HtmlDescrption) {
    return this.sanitizer.bypassSecurityTrustHtml(HtmlDescrption);
  }
  onBuy(SelfPaceObject) {
    localStorage.setItem('checkout', JSON.stringify(SelfPaceObject));
    window.location.href = `${this.envService.env}/checkout`;
    // this._router.navigate(['/checkout']);
  }
  onScroll(type) {
    const itemToScrollTo = document.getElementById(type);
    if (itemToScrollTo) {
      itemToScrollTo.scrollIntoView(true);
    }
  }
  redirect(redirectId) {
    this.common.SetRouteType(redirectId);
  }
}
