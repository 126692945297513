<nav class="navbar navbar-expand-lg navbar-light bg-white sticky-top">
    <div class="container-fluid">
        <a class="navbar-brand" href="/"><img src="assets/imgs/logo-pie.png" alt=""></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav m-auto">
                <li class="nav-item mr-4">
                    <a class="nav-link" href="#">Blog</a>
                </li>
                <li class="nav-item mr-4">
                    <a class="nav-link" href="#">Programs</a>
                </li>
                <li class="nav-item mr-4">
                    <a class="nav-link" href="#">Pricing</a>
                </li>
                <li class="nav-item mr-4">
                    <a class="nav-link" href="tel:80 10 55 44 00" style="display: flex; align-items: center;"><img
                            src="assets/imgs/phone-icon.png">&nbsp;91-8010554400</a>
                </li>
            </ul>



            <button class="btn btn-primary btn-book mr-3" type="button" (click)="onOpenPieModal()">Book a free
                class</button>

            <button class="btn btn-outline-primary btn-login" type="button" (click)="onLoginPanelPopup()"
                *ngIf="!UserLoginFlag">Login</button>&nbsp;

            <button class="btn btn-outline-primary btn-login" type="button" (click)="onRedirect()"
                *ngIf="UserLoginFlag">My
                Profile</button>&nbsp;
        </div>
    </div>
</nav>
<section>
    <div class="container">
        <div class="row my-4">
            <div class="col-12 text-center">
                <h4 class="bold">Contact us</h4>
                <p>We’re devoted to our mission of making this generation creators versus consumers of technology.<br>
                    Reach out to us for any assistance.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-12 text-center mx-auto">
                <div class="row">
                    <div class="col-6">
                        <h5>Call Us @ 8010554400</h5>
                        <p>Waiting time less than 30 secs</p>
                    </div>
                    <div class="col-6">
                        <h5>Write to us</h5>
                        <p style="word-wrap: break-word;">support@learningbix.com</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5 mb-5">
            <div class="col-12 text-center">
                <h4 class="bold">Get In Touch</h4>
            </div>
            <div class="col-md-12 mt-5">
                <form [formGroup]="AddForm">

                    <div class="row">
                        <div class="col-6">
                            <div class="group">
                                <input type="text" required placeholder="Your First Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
                                    formControlName="first_name">
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label class="form_active">First Name</label>
                            </div>
                            <div *ngIf="submitted && f.first_name.errors">
                                <small *ngIf="f.first_name.errors.required" class="error_class">
                                    This Field is required
                                </small>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="group">
                                <input type="text" required placeholder="Your last Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }"
                                    formControlName="last_name">
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label class="form_active">Last Name</label>
                            </div>
                            <div *ngIf="submitted && f.last_name.errors">
                                <small *ngIf="f.last_name.errors.required" class="error_class">
                                    This Field is required
                                </small>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="group">
                                <input type="text" required placeholder="Your Email Address"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email">
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label class="form_active">Your Email Address</label>
                            </div>
                            <div *ngIf="submitted && f.email.errors">
                                <small *ngIf="f.email.errors.required" class="error_class">
                                    This Field is required
                                </small>
                                <small [hidden]="!f.email.errors.patternInvalid" class="error_class">Please enter valid
                                    email.</small>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="group">
                                <input type="text" required placeholder="Contact No."
                                    [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"
                                    formControlName="contact">
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label class="form_active">Contact No.</label>
                            </div>
                            <div *ngIf="submitted && f.contact.errors">
                                <small *ngIf="f.contact.errors.required" class="error_class">
                                    This Field is required
                                </small>
                                <small [hidden]="!f.contact.errors.patternMobileInvalid" class="error_class">Phone
                                    Number should be valid and 10 digit.</small>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="group">
                                <input type="text" required placeholder="Addition Details Here..."
                                    [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                                    formControlName="description">
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label class="form_active">Addition Details Here</label>
                            </div>
                            <div *ngIf="submitted && f.description.errors">
                                <small *ngIf="f.description.errors.required" class="error_class">
                                    This Field is required
                                </small>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row mt-5">
                       
                        <div class="col-3">
                            <div class="group radio-item">
                                <input id="bix" type="radio" value="0" name="learning" formControlName="learning">
                                <label class="learningType" for="bix">Learning Bix</label>
                            </div>

                        </div>
                        <div class="col-3">

                            <div class="group radio-item">
                                <input id="pie" type="radio" value="1" name="learning" formControlName="learning">
                                <label class="learningType" for="pie">Learning Pie</label>
                            </div>
                        </div> 
                    </div> -->
                </form>
                <button class="btn btn-join add_class mt-5" (click)="onSubmit()"
                    [disabled]="btnsubmitted">Submit</button>
            </div>
        </div>
        <!-- <div class="row my-4">
            <div class="col-12 text-center">
                <h4 class="bold">Subscribe for Course Updates</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed.</p>
            </div>
            <div class="col-12 text-right">
                <button class="btn btn-subscribe add_class mt-5">Subscribe</button>
            </div>
        </div> -->

    </div>
</section>
<section class="footer-about">
    <img src="../../../assets/imgs/pie-footer-wave.jpg" class="w-100" alt="">
</section>
<section class="footer-top">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h3 class="foo-link-title">Our Specialites</h3>

                <ul class="foo-links Our_Specialites">
                    <li><label class="foo-nav-link" style="position: relative;">360 degree excursions.</label></li>
                    <li><label class="foo-nav-link" style="position: relative;">Robotics, STEM & DIY sessions.</label></li>
                    <li><label class="foo-nav-link" style="position: relative;">Parenting Seminars & Counselling Sessions.</label></li>
                    <li><label class="foo-nav-link" style="position: relative;">Gratitude & Mindfulness Sessions for Kids.</label>
                    </li>
                </ul>
                <ul class="social-links my-2">
                    <li><a href="https://www.facebook.com/learningpiepreschool" target="_blank"><img
                                src="assets/imgs/facebook-f.svg" class="img-fluid" alt=""></a></li>

                    <li><a href="https://twitter.com/learningpie1" target="_blank"><img src="assets/imgs/twitter.svg"
                                class="img-fluid" alt=""></a></li>

                    <li><a href="https://www.linkedin.com/in/learning-pie-20a34720b/" target="_blank"><img
                                src="assets/imgs/linkedin-in.svg" class="img-fluid" alt=""></a></li>

                    <li><a href="https://www.youtube.com/channel/UCA7Wdl0Nk5bDNSHtSsd1qVQ" target="_blank"><img
                                src="assets/imgs/Icons/white-youtube-logo.png" class="img-fluid" alt=""
                                style="width:40px"></a></li>

                    <li><a href="https://www.instagram.com/learningpie_/" target="_blank"><img
                                src="assets/imgs/Icons/insta.png" class="img-fluid" alt="" style="width:40px"></a>
                    </li>
                </ul>

            </div>
            <div class="col-md-4">
                <h3 class="foo-link-title text-center">We Accept</h3>
                <div class="w-100 text-center">
                    <img class="my-2 img-fluid" src="assets/imgs/Icons/weacceptpart1.png" alt="">
                    <img class="my-2 img-fluid" src="assets/imgs/Icons/weacceptpart2.png" alt="">
                </div>


            </div>

            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12 right-footer-section">
                        <h3 class="foo-link-title">Important Links</h3>
                        <ul class="foo-links">
                            <li><a href="/contacts-us" class="foo-nav-link">Contact us</a> </li>
                            <li><a href="/page/privacy-policy" class="foo-nav-link">Privacy Policy</a> </li>
                            <li><a href="/page/terms" class="foo-nav-link">Terms & Conditions</a> </li>
                            <li><a href="/blogs" class="foo-nav-link">Blogs</a></li>
                            <!-- <li><a href="#" class="foo-nav-link">FAQs</a></li> -->
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>