<app-header *ngIf="!showHideFlag"></app-header>
<app-pie-header *ngIf="showHideFlag"></app-pie-header>
<section>
    <div class="container">
        <div class="row my-4">
            <div class="col-12 text-center">
                <h4>{{tnCData?.title}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">

              <!-- <div  [innerHtml]="tnCData?.description"></div> -->
              <div  [innerHtml]="getDescrption(tnCData?.description)"></div>

            </div>
        </div>
        </div>
</section>

<app-footer *ngIf="!showHideFlag"></app-footer>
<app-pie-footer *ngIf="showHideFlag"></app-pie-footer>