<app-header></app-header>
<header class="header">
  <div class="banner mb-3">
    <img src="assets/imgs/banner/codingBanner.jpg" alt="" class="img-fluid">
  </div>
  <div class="text-center position-absolute-btn mb-md-5">
    <button class="btn btn-lg banner-btn text-white" data-toggle="modal" data-target="#bookFreeClassModal" (click)="onOpenDemoPopup()">Book a free
      class</button>
  </div>
</header>
<section class="learn-code pt-5">
  <div class="container">
    <h2>Why should your kid learn to code?</h2>
    <p>Teach your kid the skills of the future !Design a module for your child !!.</p>
    <div class="row align-items-center feature-list">
      <div class="col-md-5">
        <div class="border-dotted-bottom">
          <div class="row align-items-center">
            <div class="col-md-2">
              <img src="../../assets/imgs/learn-code-icon-1.png" class="img-fluid" alt="Icon 1">
            </div>
            <div class="col-md-10">
              <h3>Boosts Intelligence</h3>
              <!-- <p class="mb-0">Improve logical thinking and boost intelligence</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="border-dotted-bottom">
          <div class="row align-items-center">
            <div class="col-md-2">
              <img src="../../assets/imgs/learn-code-icon-2.png" class="img-fluid" alt="Icon 1">
            </div>
            <div class="col-md-10">
              <h3>Builds sequential learning</h3>
              <!-- <p class="mb-0">Improve logical thinking and boost intelligence</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="border-dotted-bottom">
          <div class="row align-items-center">
            <div class="col-md-2">
              <img src="../../assets/imgs/learn-code-icon-3.png" class="img-fluid" alt="Icon 1">
            </div>
            <div class="col-md-10">
              <h3>Develops critical thinking</h3>
              <!-- <p class="mb-0">Improve logical thinking and boost intelligence</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="border-dotted-bottom">
          <div class="row align-items-center">
            <div class="col-md-2">
              <img src="../../assets/imgs/learn-code-icon-4.png" class="img-fluid" alt="Icon 1">
            </div>
            <div class="col-md-10">
              <h3>Enhances Problem Solving</h3>
              <!-- <p class="mb-0">Improve logical thinking and boost intelligence</p> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6">
                <div class="border-dotted-bottom border-0">
                    <div class="row align-items-center">
                        <div class="col-md-2">
                            <img src="../../assets/imgs/learn-code-icon-5.png" class="img-fluid" alt="Icon 1">
                        </div>
                        <div class="col-md-10">
                            <h3>Boosts intelligence</h3>
                            <p class="mb-0">Improve logical thinking and boost intelligence</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="border-dotted-bottom border-0">
                    <div class="row align-items-center">
                        <div class="col-md-2">
                            <img src="../../assets/imgs/learn-code-icon-6.png" class="img-fluid" alt="Icon 1">
                        </div>
                        <div class="col-md-10">
                            <h3>Boosts intelligence</h3>
                            <p class="mb-0">Improve logical thinking and boost intelligence</p>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
</section>
<section class="code-features">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-3 col-md-12 title">
        <h2>What your kid will get!</h2>
        <p>Teach your kid the skills of the future !</p>
      </div>
      <div class="col-lg-9 col-md-12">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="card">
              <img src="assets/imgs/experience/Facilitate.jpg" class="img-fluid circular--landscape" alt="">
              <h2>Facilitate</h2>
              <p>Children have a higher learning curve than adults and they learn futuristic technologies much faster.
                We enable their future by teaching them fundamentals of Coding, 3D designing, Circuit Designs, Web & App
                development, AR/VR development</p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="card">
              <img src="assets/imgs/experience/logical.jpg" class="img-fluid circular--landscape" alt="">
              <h2>Logic-ify</h2>
              <p>Logic will remain the most sought after skill in future. Help your child form logic patterns and engage
                them in technology which boosts their reasoning abilities by creating a structure of code, design,
                circuits, animations, etc</p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="card">
              <img src="assets/imgs/experience/gamification.jpg" class="img-fluid circular--landscape" alt="">
              <h2>Gamify</h2>
              <p>Children love games, don’t they? The learning and courses at LearningBIX are gamified to leave subtle
                impressions that last a lifetime</p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="card">
              <img src="assets/imgs/experience/extrapolate.jpg" class="img-fluid circular--landscape" alt="">
              <h2>Extrapolate</h2>
              <p>When the kids start to code, design models, and form circuits, the dynamic output scenarios boost their
                ability to predict patterns and form scientific extrapolation of their own theories to solve seemingly
                complex problems</p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</section>

<app-course-coding></app-course-coding>

<section class="book-free-class">
  <div class="container">
    <div class="section-title">
      <h2 class="">How to get started with your free class!</h2>
      <p>Helps improve kids’ logic, concentration and abstract thinking</p>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-4 col-12">
        <div class="hexagon-box">
          <div class="circle-icon">
            <img src="../../assets/imgs/mobile-phone-solid.svg" class="img-fluid" alt="">
          </div>
          <h1>Step 1</h1>
          <p class="mb-0">Sign up with your <br/>basic details</p>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="hexagon-box ">
          <div class="circle-icon">
            <img src="../../assets/imgs/details-line.svg" class="img-fluid" alt="">
          </div>
          <h1>Step 2</h1>
          <p class="mb-0">Personalized counseling by Experts to select the right course</p>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="hexagon-box">
          <div class="circle-icon">
            <img src="../../assets/imgs/Group 9172.svg" class="img-fluid" alt="">
          </div>
          <h1>Step 3</h1>
          <p class="mb-0">Start learning <br/>for FREE</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <button type="button" class="btn btn-book-class" data-toggle="modal" data-target="#bookFreeClassModal" (click)="onOpenDemoPopup()">Book a
          free class</button>
      </div>
    </div>
    <div class="container">
      <div class="jumbo-card align-items-center p-3">
        <div class="row align-items-center">
          <div class="col-lg-3 col-md-4 col-sm-12 text-center"><img src="../../assets/imgs/jumbo-thumbnail.png"
              class="img-fluid" alt=""></div>
          <div class="col-lg-9 col-md-8 col-sm-12 text-center">
            <h4>Still Confused? Know what suits you well. Personalised coaching solutions in Coding and Robotics.</h4>
            <button type="button" class="btn btn-book-class" data-toggle="modal" data-target="#bookFreeClassModal" (click)="onOpenDemoPopup()">Book a
              free class</button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</section>
<!-- <section class="learning-path">
  <div class="container">
    <div class="section-title">
      <h2 class="">Learning path for your kids!</h2>
      <p>Helps improve kids’ logic, concentration and abstract thinking</p>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-6 text-center">
        <img src="../../assets/imgs/Group 9238.svg" class="img-fluid" alt="">
        <p class="mb-0">Your learning journey</p>
        <div class="start-btn ml-auto mr-auto">
          Start
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="item">
          <img src="../../assets/imgs/Group 9240.svg" class="img-fluid" alt="">
          <p class="mb-0">Game development</p>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="item">
          <img src="../../assets/imgs/Group 9241.svg" class="img-fluid" alt="">
          <p class="mb-0">AI and game development</p>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="item">
          <img src="../../assets/imgs/Group 9246.svg" class="img-fluid" alt="">
          <p class="mb-0">Mobile app development</p>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 pl-0 pr-0">
        <div class="item">
          <img src="../../assets/imgs/Group 9242.svg" class="img-fluid" alt="">
          <p class="mb-0">3D Games and virtual reality</p>
        </div>
      </div>
    </div>
  </div>
</section> -->
<app-testimonials></app-testimonials>
<!-- <section class="testimonials">
  <div class="container">
    <h3 class="text-center text-white">Students and parents love️ us!</h3>
    <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="carousel-caption d-md-block">
            <div class="row align-items-center">
              <div class="col-md-3"><img src="../../assets/imgs/Ellipse 2.png" class="img-fluid" alt=""></div>
              <div class="col-md-9 text-left">
                <div style="text-align:center">
                  <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                </div>
                <p class="desription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et metus a turpis auctor fermentum id at mauris. Quisque mollis ex nunc, vitae gravida lacus ullamcorp</p>
                <span class="date">15 Sep 2019</span>
                <h6 class="name">Nandu Gopal Reddy</h6>
                <span class="age">Age 13, Class 7</span>
              </div>
            </div>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="carousel-caption d-md-block">
            <div class="row align-items-center">
              <div class="col-md-3"><img src="../../assets/imgs/Ellipse 2.png" class="img-fluid" alt=""></div>
              <div class="col-md-9 text-left">
                <div style="text-align:center">
                  <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                </div>
                <p class="desription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et metus a turpis auctor fermentum id at mauris. Quisque mollis ex nunc, vitae gravida lacus ullamcorp</p>
                <span class="date">15 Sep 2019</span>
                <h6 class="name">Nandu Gopal Reddy</h6>
                <span class="age">Age 13, Class 7</span>
              </div>
            </div>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="carousel-caption d-md-block">
            <div class="row align-items-center">
              <div class="col-md-3"><img src="../../assets/imgs/Ellipse 2.png" class="img-fluid" alt=""></div>
              <div class="col-md-9 text-left">
                <div style="text-align:center">
                  <star-rating value="4" totalstars="5" checkedcolor="orange" uncheckedcolor="white" size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                </div>
                <p class="desription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et metus a turpis auctor fermentum id at mauris. Quisque mollis ex nunc, vitae gravida lacus ullamcorp</p>
                <span class="date">15 Sep 2019</span>
                <h6 class="name">Nandu Gopal Reddy</h6>
                <span class="age">Age 13, Class 7</span>
              </div>
            </div>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div class="container testimonials-bottom">
      <div class="row">
        <div class="col-md-3 text-center">
          <img src="../../assets/imgs/people-circle.svg" class="img-fluid" alt="">
          <span class="nums">
            75K + 
          </span>
          <p class="mb-0">Students</p> 
        </div>
        <div class="col-md-3 text-center">
          <img src="../../assets/imgs/star.svg" class="img-fluid" alt="">
          <span class="nums">
            4.7 + 
          </span>
          <p class="mb-0">Stars</p> 
        </div>
        <div class="col-md-3 text-center">
          <img src="../../assets/imgs/city-alt.svg" class="img-fluid" alt="">
          <span class="nums">
            170 + 
          </span>
          <p class="mb-0">Cities</p> 
        </div>
        <div class="col-md-3 text-center">
          <img src="../../assets/imgs/time.svg" class="img-fluid" alt="">
          <span class="nums">
            70 Mins 
          </span>
          <p class="mb-0">Daily Time Spent</p>
        </div>
      </div>
    </div>
  </div>
</section> -->
<section class="expert-educators" id="educators">
  <div class="container">
    <div class="section-title">
      <h2 class="mb-0">
        Our Expert Educators
      </h2>
      <p>Top percentile Coding experts with Early childhood teaching experience</p>
    </div>
    <div class="row text-center">
      <div class="col-md">
        <img src="../../assets/imgs/sourabh.png" class="img-fluid mb-3" alt="">
        <h4 class="mb-1">Sourabh Goyal</h4>
        <p class="mb-0" style="font-size: 19px;">Coding Expert</p>
        <p class="mb-0" style="font-size: 19px;">B.Tech MNIT, Jaipur</p>
      </div>


      <div class="col-md">
        <img src="../../assets/imgs/amanpreet.png" class="img-fluid mb-3" alt="">
        <h4 class="mb-1">Amanpreet Singh</h4>
        <p class="mb-0" style="font-size: 19px;">Robotics Expert</p>
        <p class="mb-0" style="font-size: 19px;">B.Tech BITS</p>
      </div>
      <div class="col-md">
        <img src="../../assets/imgs/anshita.png" class="img-fluid mb-3" alt="">
        <h4 class="mb-1">Anshita Agarwal</h4>
        <p class="mb-0" style="font-size: 19px;">Early Childhood Education, West Yorkshire, UK</p>
      </div>
      <div class="col-md">
        <img src="../../assets/imgs/himanshu.png" class="img-fluid mb-3" alt="">
        <h4 class="mb-1">Himanshu</h4>
        <p class="mb-0" style="font-size: 19px;">Artificial Intelligence & Machine Learning Expert</p>

      </div>

      <div class="col-md">
        <img src="../../assets/imgs/pooja.png" class="img-fluid mb-3" alt="">
        <h4 class="mb-1">Pooja Ajmera</h4>
        <p class="mb-0" style="font-size: 19px;">STEM Education Expert</p>

      </div>


    </div>
  </div>
</section>
<section class="faq">
  <div class="container">
    <div class="section-title">
      <h2 class="">Frequently asked questions</h2>
    </div>
    <div class="accordion" id="accordionExample">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h3 class="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
            aria-controls="collapseOne">
            Why does my kid need to learn about the latest technologies?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>

        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
          <div class="card-body">
            Currently, acquaintance with the latest technologies is important for children, especially when they start
            their academic journeys. The child’s mind is most receptive and curious in early years. An early exposure to
            things beyond the normal school curriculum helps them sharpen multiple skills.
            The mostly binary nature of any technology based subject prepares them for mental and creative simulations,
            which in turn enables them to see their surroundings with a different lens altogether. Some of the best
            known examples, who have benefitted from early learning interventions include, Elon Musk, Mark Zuckerberg
            etc.

          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
            aria-controls="collapseTwo">
            Will small kids be able to understand the topics?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
          <div class="card-body">
            Short answer, Yes! Our students have been challenging our teachers across the age groups, forcing us to
            believe we may have been conservative in estimating the kid’s intelligence and potential.
            After running intervention programs across 1000+ plus schools in India, impacting over a million students,
            our curriculum is designed based on the extensive data points we generated over the years. We have mapped
            the age and concept in a seamless manner, where the children ( 6 years - 18 years) do not feel burdened or
            lost. They, infact enjoy coding which resonates with their acquired knowledge for a particular age group.
            <br>
            We are an online learning platform which provides multiple options to the students.We train students using a
            1-to-1 teacher ratio on their coding modules. The teachers are well trained and equipped to handle the
            heterogeneity of the questions that a child may pose. <br>
            For a child who already has had an exposure to coding, there are also self-learning modules created. These
            are pre-recorded sessions, where the students find immense value to revise/recreate their learning on
            specific modules.

          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
            aria-controls="collapseThree">
            What is the difference between LIVE and 1-on-1 courses?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
          <div class="card-body">
            LIVE classes- An interactive learning environment, in which learners are given an expert coach, who guides
            them through their journey of learning through the lessons, activities and sessions and making true sense of
            learning possible through two-way communication and solving their issues hand-to-hand, and reviewing their
            work done with them, then-and-there. Think of this as a personal trainer to your child. The teacher exhibits
            complete ownership of the content thus taught and learnt by the student.
            <br>
            Self Paced - Pre recorded, identical in curriculum to the 1-on-1 modules. Limited feedback mechanism and the
            onus of learning is on the student himself.

          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header" id="heading4">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false"
            aria-controls="collapse4">
            What career streams open up when learning latest tech?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionExample">
          <div class="card-body">
            The latest technological advances help in creating and opening up the career streams of the future as well.
            Your child can choose to be a software Developer, an Advocate, a Web Developer, a Software Engineer, a
            Designer, a DevOps Engineer, a Product Manager, a Product Architect, a Chief Product Officer, or aChief
            Executive Officer. <br>

            And when it comes to innovations, programming has played a vital role, Bill Gates is a programmer, Elon Musk
            is a programmer, Mark Zuckerberg is a programmer. They are some of the key people who have created shifts
            using programming and latest technologies.


          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header" id="heading5">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false"
            aria-controls="collapse5">
            What all do you teach?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionExample">
          <div class="card-body">
            We teach, block-based coding, 3D designing, AR/VR Scene Development, Animation creation, Android app
            development, game development, electronics circuit designing, web development, AI & ML as of now. We are
            constantly working to add on to our repertoire and bring the most relevant courses for your child.


          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading6">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false"
            aria-controls="collapse6">
            Why teach coding to kids?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordionExample">
          <div class="card-body">
            Children develop a persistent way of coming to a conclusion along with an extensive thought process, a new
            way of thinking. The right module on coding would involve the following- learning how to program and and
            then apply the coding techniques/programs to robotics.
            <br>
            Programming and robotics balances their left and right brain hemispheres respectively, logical and
            analytical thought processes along with improving on pre-existing and generating patterns of ideas for
            thinking, to enable peak creative expression. Children also learn to use logic and structure to command
            technological tools and generate relevant outcomes like apps, games, 3D models and animations. Programming
            and robotics also teaches our children to become creators rather than consumers of the current and upcoming
            technologies.



          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading7">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false"
            aria-controls="collapse7">
            Why should I learn from you?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordionExample">
          <div class="card-body">
            We have over 6 years of experience in teaching all these courses in schools to children. With over 1000
            schools spread across India, and having physically channeled interventions for about a million kids, we
            bring the best of the acquired knowledge to carefully designed coding and robotics curriculum. <br>
            We also host yearly competitions which are fiercely competed by students all across India.
            Do you need more reasons? <br>
            Alright, we are the only platform offering a wide range of courses to opt from- along with self-learn and
            LIVE courses options to choose from.




          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading8">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false"
            aria-controls="collapse8">
            What makes your offering a complete solution?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse8" class="collapse" aria-labelledby="heading8" data-parent="#accordionExample">
          <div class="card-body">
            We offer in-depth self-learning as well as LIVE 1-to-1 courses with certification, exercises and projects
            which will help you in complete learning experience along with a variety of courses to choose from.<br>
            The natural progression of applying coding techniques and modules to build on robotics, which involved
            amalgamation of software and hardware, completes the circle of being a real programmer.





          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading9">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false"
            aria-controls="collapse9">
            How is your offering different?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse9" class="collapse" aria-labelledby="heading9" data-parent="#accordionExample">
          <div class="card-body">
            The detailed structure of the course with the different level of offerings like LIVE 1-to-1 and
            Self-learning course patterns with expanded variety of courses and an experienced faculty makes the whole
            solution a completely different kind of learning experience.

          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading10">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse10" aria-expanded="false"
            aria-controls="collapse10">
            How to opt for LIVE classes?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse10" class="collapse" aria-labelledby="heading10" data-parent="#accordionExample">
          <div class="card-body">
            You can either start by getting a Free Class and subsequently when you opt to continue, our team will enroll
            you for the course of your choice. <br>
            Alternatively, you can directly enroll for the course of your choice, by selecting the course of you want to
            get into, on course page select "Enroll for LIVE"


          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading11">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse11" aria-expanded="false"
            aria-controls="collapse11">
            How to enrol for a self-learn course?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse11" class="collapse" aria-labelledby="heading11" data-parent="#accordionExample">
          <div class="card-body">
            You can click on View all courses from the Browse Top Courses section or click on Courses on Navigation Menu
            on the Homepage. Once you select the desired course, go to the course page and click on Enroll Now. Complete
            the payment process and start the course right away.


          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading12">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse12" aria-expanded="false"
            aria-controls="collapse12">
            Do I need any skill or knowledge in advance before starting the course?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse12" class="collapse" aria-labelledby="heading12" data-parent="#accordionExample">
          <div class="card-body">
            You only need to know about how to operate a computer and start your course from our home page.


          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading13">
          <h3 class="collapsed" data-toggle="collapse" data-target="#collapse13" aria-expanded="false"
            aria-controls="collapse13">
            What hardware or other setup do I need to take a class?
            <i class="fas fa-chevron-down float-right"></i>
            <!-- <i class="fas fa-chevron-up float-right"></i> -->
            <div class="clearfix"></div>
          </h3>
        </div>
        <div id="collapse13" class="collapse" aria-labelledby="heading13" data-parent="#accordionExample">
          <div class="card-body">
            You only need a desktop/laptop, a webcam (most laptops already have a webcam) and a stable internet
            connection to enjoy your classes and learn.


          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="page-explore">
  <div class="container">
  <div class="row">
    <div class="col-12">
      <h2 class="looking-text">Looking for Self Paced courses?  <a  class="btn_Explorehere" routerLink="/courses">Explore here</a> 
      
      
        <!-- <button type="button" class="btn btn-book-class" data-toggle="modal" data-target="#bookFreeClassModal">Book a
          free class</button> -->
      </h2>
    </div>
  </div>
</div>
</section>
<app-blog></app-blog>
<app-footer></app-footer>