import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const counters = document.querySelectorAll('.count');
    const counter1=document.querySelectorAll('.count1')
    const counter2=document.querySelectorAll('.count2')
    const counter3=document.querySelectorAll('.count3')


const speed = 1000;

counters.forEach((counter) => {
  const updateCount = () => {
    const target = parseInt(counter.getAttribute('data-target'));
    const count = parseInt(counter.innerHTML);
    const increment = Math.trunc(target / speed);

    if (count < target) {
      counter.innerHTML = String(count + increment);
      setTimeout(updateCount, 1);
    } else {
      counter.innerHTML = String(target);
    }
  };
  updateCount();
});

counter1.forEach((counter) => {
  const updateCount = () => {
    const target = parseInt(counter.getAttribute('data-target'));
    const count = parseInt(counter.innerHTML);
    const increment = Math.trunc(target / speed);

    if (count < target) {
      counter.innerHTML = String(count + increment);
      setTimeout(updateCount, 1);
    } else {
      counter.innerHTML = String(target);
    }
  };
  updateCount();
});

counter2.forEach((counter) => {
  const updateCount = () => {
    const target = parseInt(counter.getAttribute('data-target'));
    const count = parseInt(counter.innerHTML);
    const increment = Math.trunc(target / speed);

    if (count < target) {
      counter.innerHTML = String(count + increment);
      setTimeout(updateCount, 1);
    } else {
      counter.innerHTML = String(target);
    }
  };
  updateCount();
});

counter3.forEach((counter) => {
  const updateCount = () => {
    const target = parseInt(counter.getAttribute('data-target'));
    const count = parseInt(counter.innerHTML);
    const increment = Math.trunc(target / speed);

    if (count < target) {
      counter.innerHTML = String(count + increment);
      setTimeout(updateCount, 1);
    } else {
      counter.innerHTML = String(target);
    }
  };
  updateCount();
});


  }

}
