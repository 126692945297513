import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServiceService, TokenService } from '../../../service';
import { PasswordMatchPattern, EmailPatternValidator, MobilePatternValidator, NamePatternValidator } from '../../../validate-pattern';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
@Component({
  selector: 'app-forgot-modal',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotBixModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public userService: UserService, private modalService: ModalService) { }
  ngOnInit(): void {
    this.Form = this.fb.group({
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    })
  }
  get f() { return this.Form.controls; }  
  onSubmitForgot() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      this.btnsubmitted = true;
      // console.log('LOGIN::::FORM', this.Form.value);
      this.userService.userEmailLogin('user_forgot_password', this.Form.value).then(data => {
        // console.log('SEnD:::', data);
        if (data.replyCode == "success") {
          this._employeeService.showSuccess(data.replyMsg)
          this.Form.reset();
          this.submitted = false;
          this.btnsubmitted = false;
        }else{
          this.btnsubmitted = false;
          this._employeeService.showError(data.replyMsg)
        }
      });
    }
  }
  onCloseModal() {
    this.modalService.setforgotBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }
}
