<!-- <app-pie-header></app-pie-header> -->

<nav class="navbar navbar-expand-lg navbar-light bg-white sticky-top">
    <div class="container-fluid">
        <a class="navbar-brand" href="/"><img src="assets/imgs/logo-pie.png" alt=""></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav m-auto">
                <li class="nav-item mr-4">
                    <a class="nav-link" href="javascript:void(0);" (click)="redirect('program');">Programs</a>
                </li>
                <li class="nav-item mr-4">
                    <a class="nav-link" href="javascript:void(0);" (click)="redirect('pricing');">Pricing</a>
                </li>
                <li class="nav-item mr-4">
                    <a class="nav-link" href="javascript:void(0);" routerLink="/online-preschool-reviews">Review</a>
                </li>
                <li class="nav-item mr-4">
                    <a class="nav-link" href="https://blog.learningpie.in/">Blogs</a>
                </li>
                <li class="nav-item mr-4">
                    <a class="nav-link" href="tel:80 10 55 44 00" style="display: flex; align-items: center;"><img src="assets/imgs/phone-icon.png">&nbsp;91-8010554400</a>
                </li>
            </ul>

            <button class="btn btn-primary btn-book mr-3" type="button" (click)="onOpenPieModal()">Book a free class</button>

            <button class="btn btn-outline-primary btn-login" type="button" (click)="onLoginPanelPopup()" *ngIf="!UserLoginFlag">Login</button>&nbsp;

            <button class="btn btn-outline-primary btn-login" type="button" (click)="onRedirect()" *ngIf="UserLoginFlag">My
        Profile</button>&nbsp;
        </div>
    </div>
</nav>

<div class="main-banner container-fluid" style="margin-bottom: 65px;">
    <div class="d-flex flex-row flex-wrap justify-content-between align-items-center form-flex pl-md-5" style="padding-top: 0px;">
        <div class="col-12 col-sm-8 col-md-5 col-lg-4 card ml-auto mt-sm-5">
            <form class="p-4" [formGroup]="AddForm">
                <h3>Fill In Your Details To Book A FREE Demo Class</h3>
                <div class="form-group">
                    <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Parent's Name" [ngClass]="{ 'is-invalid': submitted && f.parents_name.errors }" formControlName="parents_name">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="Mobile" [ngClass]="{ 'is-invalid': submitted && f.phone_no.errors }" formControlName="phone_no" appNumbersOnly maxlength="10">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="Child's Name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name">
                </div>

                <h5>Program</h5>
                <div class="form-row mb-3">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6" *ngFor="let ageCourse of ageCourseList">
                        <button class="btn btn-outline-secondary{{ageCourse.class}} w-100" type="button" (click)="clickEventAddClass(ageCourse)">{{ageCourse.name}}<br>{{ageCourse.title}}</button>
                    </div>
                    <div *ngIf="submitted && f.age_group_id.errors">
                        <small *ngIf="f.age_group_id.errors.required" class="text-danger">
              This Field is required
            </small>
                    </div>
                </div>
                <button class="btn btn-primary btn-book-orange mt-4" type="button" (click)="onBookFreeClass();" [disabled]="this.AddForm.invalid" style="margin: 0 auto;display: block;">BOOK A FREE
          DEMO</button>
            </form>
        </div>
    </div>
</div>

<!-- Video Section -->

<div class="connectivity-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-12">
                <h2>LearningPie@HOME online preschool program for kids</h2>
                <p>Complete Preschool Program for kids of age group 1.5-6 years</p>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe src="https://www.youtube.com/embed/Xeg7tnqpM98" title="YouTube video player" frameborder="0" class="embed-responsive-item" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<section id="pricing"></section>
<section class="pie-offerings">
    <div class="container">
        <h2>Our Offerings</h2>

        <div class="row">
            <div class="col-12">
                <div id="">
                    <h3>Select age group</h3>
                    <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
                        <li class="nav-item " role="presentation" *ngFor="let age of AgeGroupListPricing;let i =index;">
                            <span class="text-ageTitle">{{age.title}}</span>
                            <a class="nav-link" id="grade1-tab" [ngClass]="age.id ==IndexId ? 'active':''" (click)="getCoursesList(age.id)">
                                <img src="{{age.image}}" class="img-fluid age-group-icon" alt="">
                                <strong>{{age.age_from}}-{{age.age_to}} Yrs</strong></a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="grade1" role="tabpanel" aria-labelledby="grade1-tab">
                            <div class="row">
                                <ng-container *ngFor="let agecourses of AgeGroupCoursesList;let i=index">

                                    <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                        <div class="card card-item">

                                            <div class="card-body">
                                                <h3 class="card-title age-title">{{agecourses.course_name}}</h3>
                                                <span style="font-size: 15px">{{agecourses.tag}}</span>
                                                <div class="decs-pie-data" [innerHtml]="getDescrption(agecourses.description)" style="margin-top: 20px;"> </div>

                                                <h4 class="text-center mt-2 mb-4" style="    white-space: nowrap;
                        font-size: 19px;">You Pay Rs. {{agecourses.amount}}/- <span style="color:red; ">
                            Rs.<s>{{agecourses.fake_price}}/-</s></span></h4>
                                                <h4 class="text-center mt-2 mb-4" style="    white-space: nowrap;
                        font-size: 19px;">Per Month Rs. {{getPerMonth(agecourses.amount,agecourses.tag)}}/- <span style="color:red; ">
                            Rs.<s>{{getPerMonth(agecourses.fake_price,agecourses.tag)}}/-</s></span></h4>
                                                <h3 class="text-center mt-4 mb-4">Save {{calculateSave(agecourses)}} </h3>
                                                <div class="price-box">
                                                    <ul class="pricing-details">

                                                        <li><a href="javascript:void(0);" class="text-white" (click)="onBuy(agecourses)">Enroll
                                Now</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="card-border-box"></div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- What You Will Get -->

<div class="container-fluid learning-pie-benefits mt-5 py-5">
    <h2 class="text-center">Why LearningPie@HOME?
        <span><img src="../../assets/imgs/logo-pie.png" alt="" class="img-fluid" style="    margin-top: 40px;"></span>
    </h2>
    <p class="text-center pb-3">We are different from others as we are just not raising higher IQ’s , but stronger EQ’s and SQ’s too!</p>
    <div class="d-flex flex-row flex-wrap justify-content-center container">
        <div class="card col-12 col-sm-4 col-md-3 col-lg-2 get-card">
            <img src="../../assets/imgs/get-1.png" alt="" class="img-fluid">
            <p class="text-center mt-auto mb-0">Multi-sensory and comprehensive Activity box for high- quality engagement.</p>
        </div>
        <div class="card col-12 col-sm-4 col-md-3 col-lg-2 get-card">
            <img src="../../assets/imgs/get-2.png" alt="" class="img-fluid">
            <p class="text-center mt-auto mb-0">Flexible Recorded sessions held by Early Education Experts</p>
        </div>
        <div class="card col-12 col-sm-4 col-md-3 col-lg-2 get-card">
            <img src="../../assets/imgs/get-3.png" alt="" class="img-fluid">
            <p class="text-center mt-auto mb-0">Dedicated Counsellor To Cater To Your Child’s Unique Needs</p>
        </div>
        <div class="card col-12 col-sm-4 col-md-3 col-lg-2 get-card">
            <img src="../../assets/imgs/get-4.png" alt="" class="img-fluid">
            <p class="text-center mt-auto mb-0">Complete assessment report and certificate for priority admission in formal schools.
            </p>
        </div>
        <div class="card col-12 col-sm-4 col-md-3 col-lg-2 get-card">
            <img src="../../assets/imgs/get-5.png" alt="" class="img-fluid">
            <p class="text-center mt-auto mb-0">Gratitude, Mindfulness, Excursions, Parenting seminars, Robotics and many more add ons.</p>
        </div>
    </div>
    <div class="text-center">
        <button class="btn btn-primary btn-book-orange mt-4" type="button" (click)="onOpenPieModal()">ENROL YOUR CHILD
      NOW</button>
    </div>

</div>

<!-- What will your child learn -->
<section id="program"> </section>
<div class="container-fluid learning-pie-benefits py-5" style="background-color: #DCECA0;">
    <h2 class="text-center">What Will Your Child Learn? </h2>
    <p class="text-center">Select Age Group</p>
    <div class="d-flex flex-row flex-wrap justify-content-center container">
        <button class="btn btn-primary {{age.class}} m-2" type="button" *ngFor="let age of AgeGroupList;let i=index" (click)="onChangeAgeCourse(age,i)">{{age.age_from}}-{{age.age_to}} years</button>
        <!-- <button class="btn btn-primary btn-book-orange-outline m-2" type="button">2.5-3.5 years</button>
        <button class="btn btn-primary btn-book-orange-outline m-2" type="button">3.5-4.5 years</button>
        <button class="btn btn-primary btn-book-orange-outline m-2" type="button">4.5-6 years</button> -->
    </div>
    <div class="tab-content" id="myTabContent">
        <div role="tabpanel" class="tab-pane fade in active show" id="profile0">
            <div class="d-flex flex-row flex-wrap justify-content-center mt-4">
                <div class="card col-12 col-sm-4 col-md-3 col-lg-3 get-card" *ngFor="let ageData of AgeGroupListData">
                    <img src="{{this.envService.env}}/{{ageData.image}}" alt="" class="img-fluid">
                    <h6 class="text-center">{{ageData.title}}</h6>
                    <div class="card-body">
                        <ul class="learning-points">
                            <li>{{ageData.key1}}</li>
                            <li>{{ageData.key2}}</li>
                            <li>{{ageData.key3}}</li>
                            <li>{{ageData.key4}}</li>
                            <li>{{ageData.key5}}</li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="card col-12 col-sm-6 col-md-4 col-lg-3 get-card">
                    <img src="../../assets/imgs/Group38013.png" alt="" class="img-fluid">
                    <h6 class="text-center">Encourage Exploration Into New Subjects</h6>
                    <div class="card-body">
                        <ul class="learning-points">
                            <li>Identify, recognize, and quantify numbers 1-10</li>
                            <li>Sort and sequence using shape, size, colours etc.</li>
                            <li>Ascending and Descending order learning</li>
                            <li>Early counting games in activity box</li>
                            <li>Pre-math concepts such as big/small, one/many, etc</li>
                        </ul>
                    </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 col-lg-3 get-card">
                    <img src="../../assets/imgs/Group38014.png" alt="" class="img-fluid">
                    <h6 class="text-center">Discover A Wide Array Of Themes And Resources</h6>
                    <div class="card-body">
                        <ul class="learning-points">
                            <li>Development of mental skills</li>
                            <li>Engage in pretend and imaginative play</li>
                            <li>Ability to match shapes and sort colors</li>
                            <li>Identify body parts and functions</li>
                            <li>Identify familiar people and everyday objects</li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="container text-center">
        <button class="btn btn-primary btn-book-orange mt-4" type="button" (click)="onOpenPieModal()">Book a Seat for your
      child</button>
    </div>
</div>

<app-faq-new></app-faq-new>

<!-- Benefits -->

<div class="container-fluid learning-pie-benefits py-5 text-center">
    <img src="../../assets/images/web_banner.png" alt="" class="img-fluid">
    <!-- <div class="container">
    <div class="card green-card">
      <div class="d-flex flex-row flex-wrap justify-content-between align-content-center">
        <div class="col-12 col-md-3" style="margin: 5px;">
          <h5>BENEFITS OF<br><img src="../../assets/imgs/logo-outline.png" alt="" class="img-fluid"></h5>
          <ul class="" style="list-style-type: none; color: white; font-size: 20px;">
            <li class="list-item-white">PACE</li>
            <hr class="list-line">
            <li class="list-item-white">FLEXIBILITY</li>
            <hr class="list-line">
            <li class="list-item-white">PRACTICE STYLE</li>
            <hr class="list-line">
            <li class="list-item-white">STUDENT PARTICIPATION</li>
            <hr class="list-line">
            <li class="list-item-white">LEARNING OUTCOME</li>
          </ul>
        </div>
        <div class="col-12 col-md-4" style="margin: 5px;">
          <h4 style="color: white;">ONLINE CLASSES</h4>
          <div class="card inner-card">
            <ul class="" style="list-style-type: none;">
              <li class="list-item-green">Lessons are delivered according <br>
                to the pace of the teacher</li>
              <hr class="list-line-green">
              <li class="list-item-green">Fixed timing of the classes & no flexibility.<br>
                If the child misses the class due to<br> unavoidable circumstances, he/she misses on the lessons</li>
              <hr class="list-line-green">
              <li class="list-item-green">Abstract & traditional way of teaching</li>
              <hr class="list-line-green">
              <li class="list-item-green">Child learns passively by watching</li>
              <hr class="list-line-green">
              <li class="list-item-green">Superficial completion of course</li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-4" style="margin: 5px;">
          <h4 style="color: white;">LEARNINGPIE @ HOME</h4>
          <div class="card inner-card">
            <ul class="" style="list-style-type: none;">
              <li class="list-item-green">Parent can choose the pace according to the child</li>
              <hr class="list-line-green">
              <li class="list-item-green">Both environments, live & recorded help <br>
                in complete flexibility with timings</li>
              <hr class="list-line-green">
              <li class="list-item-green">Through hybrid method for much <br>
                impactful learning</li>
              <hr class="list-line-green">
              <li class="list-item-green">Child learns actively by doing</li>
              <hr class="list-line-green">
              <li class="list-item-green">Child understands & masters the concepts</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>

<!-- Card -->

<section class="">
    <div class="container">
        <div class="jumbo-card align-items-center">
            <div class="row align-items-center p-4">
                <div class="col-lg-6 col-md-4 col-sm-12 img-align-auto" style="margin: 0 auto;">
                    <h4>Have Questions?</h4>
                    <h6 style="color: white;">Please enter your contact details, our Early Childhood Educator will get in touch with you!</h6>
                </div>
                <form class="p-5 text-center">
                    <div class="form-group">
                        <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Enter Phone No." [(ngModel)]="mobileNo" [ngModelOptions]="{standalone: true}" appNumbersOnly maxlength="10" style="    border-radius: 15px;">
                    </div>
                    <button class="btn btn-primary btn-book-white mt-1" type="button" (click)="onSubmitQuery()" [disabled]="!(mobileNo.length >=10)">Submit</button>
                </form>
            </div>
        </div>
    </div>

</section>

<!-- Faq -->

<section class="faq">
    <div class="container">
        <div class="section-title">
            <h2 class="">Frequently asked questions</h2>
        </div>
        <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        What is LearningPie@HOME?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>

                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        LearningPie@Home is an online tech enabled experiential learning program designed by experts across the globe. It is a combined effort of educators, child psychologists, parenting experts and innovators to bring your child an all-round learning experience
                        which aims at making conceptual learning, a child’s play and part of their lifelong learning process. The program is a combination of online instructor led classes combined with Do-It-Yourself Activity box. You will receive an
                        all-inclusive learning kit, packed with activities, rhyme books, storybooks, worksheets and much more, providing holistic learning at the comfort of your own home.


                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingTwo">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Why LearningPie is doing this?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div class="card-body">
                        Pre-schooling or early education in India is a highly fragmented category. Non adherence to a standard education curriculum, or lack of training to the teachers, or lack of the right tools and techniques, or the absence of the right environment, makes
                        early learning a highly inefficient process today.
                        <br> LearningPie is conceptualized with a solitary objective of helping young children develop a sense of ‘how to learn’ through a very carefully designed curriculum, which borrows the best capabilities of some of the world’s most
                        popular curricula- viz. Montessori, Play Way, Bank Street, Waldorf, STEAM etc. <br> We want to democratize quality education for children across India by providing them well designed; scientifically curated curriculum and state
                        of the art experience to the remotest part of India- Geography is History!!

                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingThree">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Who has designed the Curriculum?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div class="card-body">
                        We continuously engage with leading educators from across the globe to continuously evolve the curriculum and the pedagogy so that our children always stay ahead of the curve.
                        <br> LearningPie@HOME is designed by pedagogy experts, child development professionals, early learning experts, psychologists and game designers at our R&D Centre. We assure that all the materials you receive are tested, child-friendly,
                        age-specific, safe and best-in-class quality!

                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="heading4">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                        What all will include in my subscription plan?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionExample">
                    <div class="card-body">
                        In your subscription plan, you will get access to 36 Live Classes, 24 Bonus sessions and an all inclusive activity box having playful & sensory kits, craft material, worksheets etc delivered at your doorstep on quarterly basis. Your child will also attend
                        special classes on Mindfulness & Gratitude, Augmented Reality/360 Excursions and you will get access to counseling sessions from our Early Education & Parenting Experts.


                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="heading5">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                        What do I get in a kit?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionExample">
                    <div class="card-body">
                        You will receive an all-inclusive learning kit, packed with activities, rhyme books, storybooks, worksheets, craft material, STEM activities, playful & sensory kits, stationary and much more, providing holistic learning at the comfort of your own home.
                        The kits are meticulously planned, in sync with the class curriculum, to develop finer skills and the achievement of tasks completion independently/in a guided manner.


                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading6">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                        Screen time?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordionExample">
                    <div class="card-body">
                        While increasing screen time in children has always been a cause of concern, the pandemic has certainly added to the woes and multiplied the time kids spend in front of the screens. Screens have become a way of life for kids. If the screen can’t be avoided,
                        why not turn it into a playful and learning time?
                        <br> With a lot of role plays, and child centric activities, the two way communication and engagement ensures that the child uses his/her time with the device in, an otherwise, productive manner.
                        <br> We have kept our daily sessions for less than an hour, and here the device (Laptop/pads) acts like a Television for kids kept at a distance with more focus on audio rather than visuals.

                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading7">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                        Is parental involvement required?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordionExample">
                    <div class="card-body">
                        Yes and No. We encourage DIY for most of the activities with an arm distance supervision. However, there are certain activities, which may include an additional support for the children. But we would love and advice for an active participation by either
                        parent, as research suggests that a kindergartner learns more when he/she receives directions from parent, this also helps increase parent – child bonding.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading8">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                        What age should we start formal learning for our child and if we start education at age 2.5, should we choose starting age 1.5-2.5 course or 2.5-3.5 course?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse8" class="collapse" aria-labelledby="heading8" data-parent="#accordionExample">
                    <div class="card-body">
                        Children are a source of almost unlimited energy. If all of it is channelized into a direction, it results in massive learning and simulation for the child. Starting early gives an edge to your child as NASA study identified that kid’s peak in creativity
                        at age 6, followed by a gradual decline as they grow up. So, you should start playful yet simulating learning for your child as early as possible.
                        <br> We have developed the age and mental capabilities wise curriculum - 1.5-2.5 years, 2.5-3.5 years, 3.5 years-4.5 years and 4.5-6 years. Each age group requires unique ways of imparting the same skills. So, you should select
                        the curricula as per current age of your child, irrespective of starting age of formal education for your child.





                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading9">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                        What all do you cover in your curriculum?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse9" class="collapse" aria-labelledby="heading9" data-parent="#accordionExample">
                    <div class="card-body">
                        We believe in holistic development of a child and has stressed equally on developing Language, Numeracy, Cognitive and Socio-Emotional skills. Writing, Speaking, Cognitive and all other skills are broken down piecemally through engaging curriculum. All
                        concepts are covered in an activity-based format along with animations, rhymes, stories, interactive quizzes, etc. The immersion of technology at the right time also facilitates course correction and customization, if any, needed
                        for any child.


                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading10">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                        What are the add-ons that you are providing?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse10" class="collapse" aria-labelledby="heading10" data-parent="#accordionExample">
                    <div class="card-body">
                        Apart from the thrice a week, online classroom learning, we have included 2 Bonus sessions per week, wherein you can give task or engage your little munchkin in various DIY activities. Completing a task gives immense sense of satisfaction, all the while
                        building and honing upon skills like reasoning, cognition, familiarity etc. <br> In addition to Rhymes, Stories, etc, we have included monthly thematic Excursions to virtual Zoos, Fairs, Farms, etc to increase
                        awareness in the child. Parents will also get access to live parenting/counseling sessions.


                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading11">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                        How many demos will you provide?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse11" class="collapse" aria-labelledby="heading11" data-parent="#accordionExample">
                    <div class="card-body">
                        On registration, you will get access to our demo portal, where in you can access the sample recorded classroom sessions, animated rhymes & stories, DIY activities videos, virtual zoo animation, a description about our Activity kit, and a small video explaining
                        about our teaching pedagogy etc. Our early child educator will get in touch with you and help you with questions you may have.

                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading12">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                        If my child miss any session, will you provide extra class?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse12" class="collapse" aria-labelledby="heading12" data-parent="#accordionExample">
                    <div class="card-body">
                        We have created an experiential learning program and also recorded them using high quality audio and video devices. If your child miss attending any session, the child can attend the recorded session of the same. Now you can travel guilt free!


                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading13">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                        Why should we trust you for our child's education?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse13" class="collapse" aria-labelledby="heading13" data-parent="#accordionExample">
                    <div class="card-body">
                        We have over 6 years of experience in teaching all these courses in schools to children. With over 1000 schools spread across India, and having physically channeled interventions for about a million kids, we bring the best of the acquired knowledge to
                        carefully designed curriculum.


                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading14">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                        Does my child get admission into schools after completing your programs?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse14" class="collapse" aria-labelledby="heading14" data-parent="#accordionExample">
                    <div class="card-body">
                        Our Early learning program is an absolute equivalent of the traditional Nursery and Kindergarten concept in India. Similar to graduating from Pre-Schools, our courses are recognized across India and a student will find it easy to get into a school of
                        their choice.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading15">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                        What is your refund policy?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse15" class="collapse" aria-labelledby="heading15" data-parent="#accordionExample">
                    <div class="card-body">
                        Though unsatisfied student is an absolute rarity with us, in the unlikely situation of your child/you not being satisfied with our program, we do facilitate a refund. Once cancelled, the refund amount for the unshipped terms will be credited to your account
                        -- to the same mode of payment chosen during enrollment, in 5-7 business days.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading16">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                        I already have a preschool, do you have an offering that I can use?
                        <i class="fas fa-chevron-down float-right"></i>
                        <!-- <i class="fas fa-chevron-up float-right"></i> -->
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div id="collapse16" class="collapse" aria-labelledby="heading16" data-parent="#accordionExample">
                    <div class="card-body">
                        We have created an omnichannel mode of training module for the thousands of entrepreneurs who have reached out to us during the last year. Your physical infrastructure can be blended with our curriculum, where you focus on the student’s learning, while
                        we take care of everything else.
                        <br> The teachers are also trained on how to effectively manage the curriculum and students at their respective physical pre-schools and education centres.
                        <br> We offer a curated curriculum with DIY kits. You get access to the same engaging content available on our website.
                        <br> During this COVID period, with preschools being shut for physical classes, our offering enables you to run classes and enable parents to effectively manage their child’s learning with regular counseling sessions and assessments.
                        <br> For more details, Contact us at contact@learningbix.com
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="blog"></section>
<!-- <app-pie-blog></app-pie-blog> -->
<!-- 
<div class="container text-center">
  <button class="btn btn-primary btn-book-orange mt-4" type="button" (click)="onOpenPieModal()">Yes book my free slot</button>
</div> -->

<!-- Faq Ends -->
<section class="footer-about">
    <img src="../../../assets/imgs/pie-footer-wave.jpg" class="w-100" alt="">
</section>
<section class="footer-top">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h3 class="foo-link-title">Our Specialities</h3>

                <ul class="foo-links Our_Specialites">
                    <li><label class="foo-nav-link">360 degree excursions.</label></li>
                    <li><label class="foo-nav-link">Robotics, STEM & DIY sessions.</label></li>
                    <li><label class="foo-nav-link">Parenting Seminars & Counselling Sessions.</label></li>
                    <li><label class="foo-nav-link">Gratitude & Mindfulness Sessions for Kids.</label>
                    </li>
                </ul>
                <ul class="social-links my-2">
                    <li>
                        <a href="https://www.facebook.com/learningpiepreschool" target="_blank"><img src="assets/imgs/facebook-f.svg" class="img-fluid" alt=""></a>
                    </li>

                    <li>
                        <a href="https://twitter.com/learningpie1" target="_blank"><img src="assets/imgs/twitter.svg" class="img-fluid" alt=""></a>
                    </li>

                    <li>
                        <a href="https://www.linkedin.com/company/learningpie/" target="_blank"><img src="assets/imgs/linkedin-in.svg" class="img-fluid" alt=""></a>
                    </li>

                    <li>
                        <a href="https://www.youtube.com/channel/UCA7Wdl0Nk5bDNSHtSsd1qVQ" target="_blank"><img src="assets/imgs/Icons/white-youtube-logo.png" class="img-fluid" alt="" style="width:40px"></a>
                    </li>

                    <li>
                        <a href="https://www.instagram.com/learningpie_/" target="_blank"><img src="assets/imgs/Icons/insta.png" class="img-fluid" alt="" style="width:40px"></a>
                    </li>
                </ul>

            </div>
            <div class="col-md-4">
                <h3 class="foo-link-title text-center">We Accept</h3>
                <div class="w-100 text-center">
                    <img class="my-2 img-fluid" src="assets/imgs/Icons/weacceptpart1.png" alt="">
                    <img class="my-2 img-fluid" src="assets/imgs/Icons/weacceptpart2.png" alt="">
                </div>


            </div>

            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12 right-footer-section">
                        <h3 class="foo-link-title">Important Links</h3>
                        <ul class="foo-links">
                            <li><a href="/contacts-us" class="foo-nav-link">Contact us</a> </li>
                            <li><a href="/page/privacy-policy" class="foo-nav-link">Privacy Policy</a> </li>
                            <li><a href="/page/terms" class="foo-nav-link">Terms & Conditions</a> </li>
                            <li><a href="https://blog.learningpie.in/" target="_blank" class="foo-nav-link">Blogs</a></li>
                            <!-- <li><a href="#" class="foo-nav-link">FAQs</a></li> -->
                        </ul>
                    </div>

                </div>
            </div>

            <div class="container text-center copyright mt-3">
                <h5>XAP EDUBOTS TECHNOLOGIES PRIVATE LIMITED</h5>
                <p class="mt-2">Plot No. 4, Bhaskar Enclave, Near OTS Chaurahan, JLN Marg, Jaipur, Rajasthan, 302017 | GST NO: 08AAACX2488E1Z7</p>
            </div>
        </div>
    </div>
</section>

<a class="whatsapp_icon" href="https://api.whatsapp.com/send/?phone=919352529267&amp;text&amp;app_absent=0" target="_blank">
    <i class="fab fa-whatsapp"></i>
</a>