import { Component, OnInit } from '@angular/core';
import { StarRatingComponent } from 'ng-starrating';
import { DatePipe } from '@angular/common';
import { BaseServiceService } from 'src/app/service';
import { ActivatedRoute, Router } from '@angular/router';
declare var Razorpay: any;
declare var $ : any;
import { CommonService } from './../../service/common.service';
import { environment } from './../../../environments/environment';
import { Subscription } from 'rxjs';
import { EnvService } from 'src/app/service/env.service';
@Component({
  selector: 'app-course-coding',
  templateUrl: './course-coding.component.html',
  styleUrls: ['./course-coding.component.scss'],
  providers: [DatePipe],
})
export class CourseCodingComponent implements OnInit {
  active = 1;
  ProjectsList = []; 
  AgeGroupList = [];
  AgeGroupCoursesList = [];
  options: any =environment.razorPayoptions;
  Amount:number=0;
  Course_Id:any;
    IndexId:any;
    AgeGroupId:any;
    subscription: Subscription;
  constructor(public activatedRoute:ActivatedRoute,public _employeeService: BaseServiceService,public common:CommonService,private _datePipe: DatePipe,public router: Router, private envService: EnvService) { }
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    alert(`Old Value:${$event.oldValue}, 
      New Value: ${$event.newValue}, 
      Checked Color: ${$event.starRating.checkedcolor}, 
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }
  ngOnInit(): void {
    this.GetProjectsList();
    this._employeeService.post('age_group_list_dropdown', {}).subscribe((res: any) => {
      if (!res.error) {
        // console.log('Response::::',res);
        this.AgeGroupList= res.data;
        this.AgeGroupId=this.AgeGroupList[0].id;
        if(res.data.length> 0){
          this.common.GetAgeCourseType().subscribe(data =>{
            // console.log('Group::ID::',data);
            this.IndexId= data;
            // this.getCoursesList(data);
            if(data == ''){
              this.getCoursesList(this.AgeGroupId);
            }else{
              this.getCoursesList(data);
            }
          })
        }
        // this.getCoursesList(this.AgeGroupList[0].id);
      }
    });
    
  }
  getCoursesList(agegroupid){
    this.IndexId= agegroupid;
    var requestData={
      age_group_id:String(agegroupid),
      featured:1
    }
    this._employeeService.post('live_courses_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupCoursesList= res.data;
        // console.log('AgeGroupCoursesList::::',this.AgeGroupCoursesList);
      }
    });
  }
  GetProjectsList() {
    var self = this;
    self._employeeService.post('group_post_list', {featured:1}).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.ProjectsList = res.data;
          // console.log('ProjectsList::::',this.ProjectsList);
          // console.log('SelfPaceDataFilter::::',this.SelfPaceDataFilter);
        } else {
          // self._employeeService.showError(res.replyMsg)
        }
      } else {
        // self._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      self._employeeService.showError("Oops! Something went wrong!");
    });
  }
  calculateSave(item){
    let fackPrice=parseInt(item.fake_price);
    let actualPrice= parseInt(item.amount);
     let discountAmount= fackPrice-actualPrice
    let percent= (discountAmount*100/fackPrice);
    return `${percent.toFixed(2)}%`
  }

  onBuy(SelfPaceObject) {
    localStorage.setItem('checkout', JSON.stringify(SelfPaceObject));
    window.location.href = `${this.envService.env}/checkout`;
    // this.router.navigate(['/checkout']);
  }
}
