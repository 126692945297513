import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  ModalServiceFlag = new BehaviorSubject<boolean>(false);
  BasicModal = new BehaviorSubject<boolean>(false);
  // Coding
  isbookDemoClassBixModalOpen = new BehaviorSubject<boolean>(false);
  isenrollnowBixModalOpen = new BehaviorSubject<boolean>(false);
  isforgotBixModalOpen = new BehaviorSubject<boolean>(false);
  isloginemailBixModalOpen = new BehaviorSubject<boolean>(false);
  isloginphoneBixModalOpen = new BehaviorSubject<boolean>(false);
  isregisterBixModalOpen = new BehaviorSubject<boolean>(false);
  isloginphoneOtpBixModalOpen = new BehaviorSubject<boolean>(false);
  isBixPieModalOpen = new BehaviorSubject<boolean>(false);
  isAppyCouponModalOpen = new BehaviorSubject<boolean>(false);
  isPreSchoolModalOpen = new BehaviorSubject<boolean>(false);
  isBookAFreeModalOpen = new BehaviorSubject<boolean>(false);
  isBookAFreeStep2ModalOpen = new BehaviorSubject<boolean>(false);
  isOfferModalOpen = new BehaviorSubject<boolean>(false);
  isloginemailPasswordBixModalOpen = new BehaviorSubject<boolean>(false);

  isbookDemoClassPieModalOpen = new BehaviorSubject<boolean>(false);
  LazyPayloadModalOpen = new BehaviorSubject<boolean>(false);
  LoaderModalOpen = new BehaviorSubject<boolean>(false);
  GuestLoginModalOpen = new BehaviorSubject<boolean>(false);
  
  GetloginemailPasswordBixModal(){return this.isloginemailPasswordBixModalOpen.asObservable()}
  SetloginemailPasswordBixModal(data){this.isloginemailPasswordBixModalOpen.next(data)}

  GetGuestLogin(){return this.GuestLoginModalOpen.asObservable()}
  SetGuestLogin(data){this.GuestLoginModalOpen.next(data)}

  GetLazyPayload(){return this.LazyPayloadModalOpen.asObservable()}
  SetLazyPayload(data){this.LazyPayloadModalOpen.next(data)}

  GetLoader(){return this.LoaderModalOpen.asObservable()}
  SetLoader(data){this.LoaderModalOpen.next(data)}

  GetModalOnOff(){return this.ModalServiceFlag.asObservable()}
  SetModalOnOff(data){this.ModalServiceFlag.next(data)}
  GetBasicModal(){return this.BasicModal.asObservable()}
  SetBasicModal(data){this.BasicModal.next(data)}
  

  setbookDemoClassBixModal(value) { this.isbookDemoClassBixModalOpen.next(value) };
  getbookDemoClassBixModal() { return this.isbookDemoClassBixModalOpen.asObservable() };

  setenrollnowBixModal(value) { this.isenrollnowBixModalOpen.next(value) };
  getenrollnowBixModal() { return this.isenrollnowBixModalOpen.asObservable() };

  setforgotBixModal(value) { this.isforgotBixModalOpen.next(value) };
  getforgotBixModal() { return this.isforgotBixModalOpen.asObservable() };

  setloginemailBixModal(value) { this.isloginemailBixModalOpen.next(value) };
  getloginemailBixModal() { return this.isloginemailBixModalOpen.asObservable() };

  setloginphoneBixModal(value) { this.isloginphoneBixModalOpen.next(value) };
  getloginphoneBixModal() { return this.isloginphoneBixModalOpen.asObservable() };

  setregisterBixModal(value) { this.isregisterBixModalOpen.next(value) };
  getregisterBixModal() { return this.isregisterBixModalOpen.asObservable() };
  
  setloginphoneOtpBixModal(value) { this.isloginphoneOtpBixModalOpen.next(value) };
  getloginphoneOtpBixModal() { return this.isloginphoneOtpBixModalOpen.asObservable() };

  setBixPieModal(value) { this.isBixPieModalOpen.next(value) };
  getBixPieModal() { return this.isBixPieModalOpen.asObservable() };

  setAppyCouponModal(value) { this.isAppyCouponModalOpen.next(value) };
  getAppyCouponModal() { return this.isAppyCouponModalOpen.asObservable() };

  setPreSchoolModal(value) { this.isPreSchoolModalOpen.next(value) };
  getPreSchoolModal() { return this.isPreSchoolModalOpen.asObservable() };

  setbookDemoClassPieModal(value) { this.isbookDemoClassPieModalOpen.next(value) };
  getbookDemoClassPieModal() { return this.isbookDemoClassPieModalOpen.asObservable() };

  setBookAFreeModal(value) { this.isBookAFreeModalOpen.next(value) };
  getBookAFreeModal() { return this.isBookAFreeModalOpen.asObservable() };

  setBookAFreeStep2Modal(value) { this.isBookAFreeStep2ModalOpen.next(value) };
  getBookAFreeStep2Modal() { return this.isBookAFreeStep2ModalOpen.asObservable() };


  SetOfferModal(value) { this.isOfferModalOpen.next(value) };
  GetOfferModal() { return this.isOfferModalOpen.asObservable() };
}