<app-header></app-header>
<section class="checkout p-0" style="background-color: #fff;    background: transparent;">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-11">
                <div class="row">
                    <div class="col-lg-8 col-sm-12 pt-3 pb-3">
                        <div class="checkout-features1" style="padding: 35px;">
                            <h3 class="text-accent">Proceed to checkout</h3>
                            <form [formGroup]="RegisterFormPie">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="fullname">Full Name</label>
                                            <input type="text" placeholder="Enter Name" appAphpaOnly
                                                class="form-control font_size-15" id="fullname" formControlName="name">
                                            <div *ngIf="submitted && fRegisterPie.name.errors" class="error_input">
                                                <div *ngIf="fRegisterPie.name.errors.required">This field is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="Email">Email</label>
                                            <input type="Email" placeholder="Enter Email"
                                                class="form-control font_size-15" id="Email" formControlName="email">
                                            <div *ngIf="submitted && fRegisterPie.email.errors">
                                                <small *ngIf="fRegisterPie.email.errors.required" class="error_input">
                                                    This Field is required
                                                </small>
                                                <small [hidden]="!fRegisterPie.email.errors.patternInvalid"
                                                    class="error_input">Please enter valid email.</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="mobile">Mobile No.</label>
                                            <input type="text" placeholder="Enter Mobile"
                                                class="form-control font_size-15" id="mobile" formControlName="phone_no"
                                                appNumbersOnly maxlength="10" [readonly]="phoneNoFlag">
                                            <div *ngIf="submitted && fRegisterPie.phone_no.errors">
                                                <small *ngIf="fRegisterPie.phone_no.errors.required"
                                                    class="error_input">
                                                    This Field is required
                                                </small>
                                                <small [hidden]="!fRegisterPie.phone_no.errors.patternMobileInvalid"
                                                    class="error_input">Phone Number should be valid and 10
                                                    digit.</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="state">Country</label>
                                            <select name="state" class="form-control font_size-15" id="state"
                                                formControlName="country"
                                                (change)="onChangeCountry($event.target.value)">
                                                <option value="" selected disabled>Select Country</option>
                                                <option [value]="country.name" *ngFor="let country of countryList">
                                                    {{country.name}}</option>

                                            </select>
                                            <div *ngIf="submitted && fRegisterPie.country.errors" class="error_input">
                                                <div *ngIf="fRegisterPie.country.errors.required">Country is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="state">State</label>
                                            <select name="state" class="form-control font_size-15" id="state"
                                                formControlName="state" (change)="onChangeState($event.target.value)">
                                                <option value="" selected disabled>Select State</option>
                                                <option [value]="state.name" *ngFor="let state of stateList">
                                                    {{state.name}}</option>

                                            </select>
                                            <div *ngIf="submitted && fRegisterPie.state.errors" class="error_input">
                                                <div *ngIf="fRegisterPie.state.errors.required">State is required</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="city">City</label>
                                            <select name="city" class="form-control font_size-15" id="city"
                                                formControlName="city">
                                                <option value="" selected disabled>Select City</option>
                                                <option [value]="city.name" *ngFor="let city of cityList">
                                                    {{city.name}}</option>

                                            </select>
                                            <div *ngIf="submitted && fRegisterPie.city.errors" class="error_input">
                                                <div *ngIf="fRegisterPie.city.errors.required">City is required</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="gst">Pin Code</label>
                                            <input type="text" placeholder="Enter Pin Code"
                                                class="form-control font_size-15" id="pincode"
                                                formControlName="pincode">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="age">Address</label>
                                            <textarea
                                                placeholder="Ex. 141- Ram Nagar, Jaipur , Rajasthan, Pincode:303101"
                                                class="form-control font_size-15" id="mobile" formControlName="address"
                                                rows="4"></textarea>
                                            <div *ngIf="submitted && fRegisterPie.address.errors" class="error_input">
                                                <div *ngIf="fRegisterPie.address.errors.required">Address is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-sm-12">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                        id="flexRadioDefault1" (click)="onSelectPaymentType(1)" checked>
                                                    <label class="form-check-label" for="flexRadioDefault1">
                                                        Netbanking/Debit Card/Credit Card/UPI/Wallets
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-12">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                        id="flexRadioDefault3" (click)="onSelectPaymentType(3)">
                                                    <label class="form-check-label" for="flexRadioDefault3">
                                                        No Cost EMI (Credit/Debit card)
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-12">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                        id="flexRadioDefault2" (click)="onSelectPaymentType(2)">
                                                    <label class="form-check-label" for="flexRadioDefault2">
                                                        Cardless EMI(No credit card required)
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                <!-- <button type="submit" class="btn btn-book-class" (click)="OnRegisterPie()"
                                    [disabled]="RegisterPiebtnsubmitted">Register</button> -->
                                <!-- <p *ngIf="!UserData" class="mt-3 mb-3 text-center seprator">or</p> -->
                                <!-- <p *ngIf="!UserData" class="register-link">Already have account? <a href="javascript:void(0);" (click)="onLoginType('pay')">Login Now</a></p>   -->

                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12 pt-3 pb-3">
                        <div class="row">
                            <div class="card card-item">
                                <div class="card-body">
                                    <div class="courese" *ngIf="checkoutValue">
                                        <h3 class="card-title" style="width: 100%;font-size: 24px;margin-bottom: 0px;">
                                            {{checkoutValue.course_name}}</h3>
                                        <span style="font-size: 15px">{{checkoutValue.tag}}</span>

                                        <div style="margin-top: 20px;"
                                            [innerHtml]="getDescrption(checkoutValue.description)"></div>
                                        <h4 class="text-center">Rs. {{checkoutValue.amount}}/- <span
                                                style="color:red; ">Rs.<s>{{checkoutValue.fake_price}}/-</s></span></h4>
                                        <h3 class="text-center">Save {{calculateSave(checkoutValue)}} </h3>
                                    </div>

                                    <div class="">

                                        <div class="form-group">
                                            <label for="couponCode"> Enter Coupon Code</label>
                                            <input type="input" placeholder="Enter Coupon Code"
                                                class="form-control font_size-15" name="couponCodeValue"
                                                [(ngModel)]="couponCodeValue"
                                                (input)="onChangeCouponCode($event.target.value)">
                                        </div>
                                        <div class="col-md-12 pl-3" *ngIf="isApply">
                                            <p *ngIf="sponsorCouponTest" style="color:red; ">* Referral credits will not be received if you
                                                apply coupon code</p>
                                            <p *ngIf="isInvalid" class="text-danger">Invalid coupon Code</p>
                                            <p *ngIf="!isInvalid"> You saved Rs. {{TotalDiscountValue}}/-</p>
                                        </div>
                                        <h4 *ngIf="isApply" style="margin: 16px;">Pay Rs. {{Amount}}/-<span
                                                style="color:red; ">Rs.<s>{{Amount+TotalDiscountValue}}/-</s></span>
                                        </h4>
                                        <button class="btn btn-light btn-accent1" (click)="ApplyCouponCode()"
                                            style="margin-top: 10px;">Apply</button>
                                        <button class="btn btn-light btn-accent1"
                                            style="float: right;background: #d8d5d5 0% 0% no-repeat padding-box;margin-top: 10px;"
                                            (click)="ClearCouponCode()" *ngIf="clearCoupon">Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2 mb-4">
                    <button class="btn btn-light btn-accent1" (click)="onPaySubmit()"
                        style="    display: block;margin: 0 auto;">Pay Now</button>
                </div>
                <div class="row mt-2 mb-4">
                    <div class="col-md-4" style="    text-align: center;
                    margin: 0 auto;">
                        <h3 class="foo-link-title text-center">We Accept</h3>
                        <div class="w-100 text-center">
                            <img class="my-2 img-fluid" src="assets/imgs/Icons/weacceptpart1.png" alt="">
                            <img class="my-2 img-fluid" src="assets/imgs/Icons/weacceptpart2.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
<div class="modal fade" id="Payment_Popup" tabindex="-1" aria-labelledby="Payment_Popup" aria-hidden="true"
    data-backdrop="false" style="backdrop-filter: blur(10px);">
    <div class="modal-dialog modal-md">
        <div class="">
            <div class="modal-body pt-0 pb-5 book-class-modal" style="height: 300px;">
                <div class="row align-items-center">
                    <div class="loading" *ngIf="Loader_Flag"></div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal popup" id="Lazy_Pay_Payment_Popup" tabindex="-1" aria-labelledby="Lazy_Pay_Payment_Popup" aria-hidden="true" data-backdrop="false" *ngIf="LazyPaymentModal">
    <div class="modal-dialog modal-md">
        <div class="modal-content"> 
            <div class="modal-header">
                <h1 style="font-size: 30px;">Enter Details to proceed </h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 20px 24px;" (click)="onCloseModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pt-0 pb-5 book-class-modal">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="couponCode"> Enter Pan Card Number</label>
                            <input type="input" placeholder="Enter Pan Card Number" class="form-control font_size-15" name="panCardNo" [(ngModel)]="panCardNo">
                        </div>
                        <div class="form-group">
                            <label for="couponCode"> Enter Father Name</label>
                            <input type="input" placeholder="Enter Father Name" class="form-control font_size-15" name="FName" [(ngModel)]="FName">
                        </div>
                        <div class="form-group">
                            <label for="couponCode"> Enter DOB</label>
                            <input type="date" placeholder="Enter DOB" class="form-control font_size-15" name="DOB" [(ngModel)]="DOB">
                        </div>
                    </div>
                    <div class="col-md-12 d-flex">
                        <button class="btn btn-light btn-accent1" (click)="PayNowPaymentSubmit()"
                        style="display: block;margin: 0 auto;">Pay Now</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>