import { BaseServiceService, TokenService } from '../../../service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EmailPatternValidator, MobilePatternValidator, NamePatternValidator, PasswordMatchPattern } from '../../../validate-pattern';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CommonService } from 'src/app/service/common.service';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { ModalService } from 'src/app/service/modal.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';

export class SocialusersRegister {
  provider: string;
  id: string;
  email: string;
  name: string;
  image: string;
  token?: string;
  idToken?: string;
}

@Component({
  selector: 'app-register-modal',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterBixModalComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  socialusers = new SocialusersRegister();
  subscription: Subscription;
  AgeGroupList: any = []; stateList = [];
  Section: boolean = false;
  countryList: any = [];
  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public token: TokenService, public _leadsquaredService: LeadsquaredService, public userService: UserService, private modalService: ModalService, private authService: SocialAuthService,public common:CommonService) {
    this.common.GetPanel().subscribe(response => {
      console.log('response::::Panel', response);
      // return this.openPiePopup = response
      if (response == 'pie') {
        this.Section = true;
        this._employeeService.post('age_group_list_dropdown', { learning: '1' }).subscribe((res: any) => {
          if (!res.error) {
            this.AgeGroupList = res.data;
          }
        });
      } else {
        this.Section = false;
        this._employeeService.post('age_group_list_dropdown', { learning: '0' }).subscribe((res: any) => {
          if (!res.error) {
            this.AgeGroupList = res.data;
          }
        });
      }
    });
  }
  getCountryList() {
    this._employeeService.post('country_list', { keyword: '' }).subscribe((res: any) => {
      if (!res.error) {
        this.countryList = res.data;
        this.onChangeCountry('India');
      }
    });
  }
  onChangeCountry(countryName) {
    var filterCountry = this.countryList.filter(x => x.name == countryName);
    console.log('filterCountry:::::', filterCountry);
    if (filterCountry.length > 0) {
      this.getStateList(filterCountry[0].id);
    }
  }
  getStateList(id) {
    var requestData = {
      country_id: id,
      keyword:''
    }
    this._employeeService.post('state_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        this.stateList = res.data;
      }
    });
  }
  ngOnInit(): void {
    this.getCountryList();
    if (this.Section) {
      this.Form = this.fb.group({
        name: ['', [Validators.required]],
        parents_name: ['', [Validators.required]],
        age_group_id: ['', [Validators.required]],
        facebook_id: [''],
        google_id: [''],
        gst: [''],
        state: [''],
        phone_no: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
        email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        password: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      });
    } else {
      this.Form = this.fb.group({
        name: ['', [Validators.required]],
        parents_name: ['', [Validators.required]],
        age_group_id: ['', [Validators.required]],
        facebook_id: [],
        google_id: [],
        gst: [],
        state: [, [Validators.required]],
        phone_no: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
        email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        password: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      });
    }

    this._employeeService.post('age_group_list_dropdown', { learning: '0' }).subscribe((res: any) => {
      if (!res.error) {
        this.AgeGroupList = res.data;
      }
    });
    // this.subscription = this._leadsquaredService.stateList().subscribe(res => {
    //   if (res) { this.stateList = res.data; }
    // });
  }
  get f() { return this.Form.controls; }

  OnRegister() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    } else {
      this.btnsubmitted = true;
      // console.log('REGISTER::::FORM', this.Form.value);
      var sendRequestData = this.Form.value;
      if (localStorage.getItem('referral_code')) {
        sendRequestData.sponsor_code = localStorage.getItem('referral_code');
      }
      console.log('sendRequestData:::::', sendRequestData);
      this.userService.userRegister('register_student', sendRequestData)
    }
  }

  onCloseModal() {
    this.modalService.setregisterBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }
  onLoginEmail() {
    this.modalService.setregisterBixModal(false);
    this.modalService.setloginemailBixModal(true);
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "F") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "G") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.authService.signIn(socialPlatformProvider);
    this.authService.authState.subscribe((user) => {
      if (user != undefined || user != null) {
        if (user.provider == "GOOGLE") { this.SocilaLogin(user, 'Google') }
        else if (user.provider == "FACEBOOK") {this.SocilaLogin(user, 'Fcebook')}
      }
    });

  }

  SocilaLogin(user, type) {
    let requestData = {
      social_type: type, // Fcebook , Google
      social_id: user.id
    }
    this.userService.userSocialLogin('social_login', requestData).then(data => {
      // console.log('SEnD:::', data);
      if (data.replyCode == "success") {
        this.Form.reset();
        this.submitted = false;
        this.btnsubmitted = false;
      }else{
        this.btnsubmitted = false;
        this._employeeService.showError(data.replyMsg)
      }
    });
  }

}
