<app-header></app-header>
<section>
    <div class="container my-4">
        <div class="row my-3">
            <div class="col-12">
                <h3 class="coading-text">Coding For Kids Course</h3>
                <p class="coading-text-desc">Learning Bix offers a range of courses for you and your child to choose from. You can opt for courses
                    focused on a specific language (like HTML, CSS, or Python), or you can choose more game-based
                    introductions algorithmic logic.</p>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-12 text-right">
               <a href="javascript:void(0)" (click)="viewAll()">View All</a>
               
            </div>
        </div>
        <div class="card course-card">
            <div class="row">
                <!-- <div class="col-md-3">ajsdghfs</div> -->
                <div class="col-12 my-4" *ngIf="ageGroupOneData">
                    <div class="mb-3"><h2>Foundation(5 - 6 Yrs)</h2></div>
                    <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-2 mb-2"  *ngFor="let selfpace of ageGroupOneData">
                                <div class="card card-item">
                                  <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{selfpace.image}}" class="card-img-top" alt="...">
                                  <div class="card-body card-fix">
                                      <div class="course-details">
                                        <span class="text-heading course-details-iner mb-2">{{selfpace.course_name}}</span>
                                        <span class="text-descrption course-details-iner mb-2" *ngIf="selfpace.sub_title != null && selfpace.sub_title != 'null'">{{selfpace.sub_title | slice:0:80}}</span>
                                        <span class="text-author course-details-iner mb-2" *ngIf="selfpace.by_teacher_name != null">By - {{selfpace.by_teacher_name}}</span>
                                        <div class="mb-2">
                                            <span class="text-rating course-details-iner">{{selfpace.dummy_rating}} / 5    <span *ngIf="selfpace.dummy_rating=='1'">
                                                <i class="fas fa-star"></i>
                                            </span>
                                            <span *ngIf="selfpace.dummy_rating >'1' && selfpace.dummy_rating <'2'"> <i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                            <span *ngIf="selfpace.dummy_rating=='2'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'2' && selfpace.dummy_rating <'3'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                            <span *ngIf="selfpace.dummy_rating=='3'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'3' && selfpace.dummy_rating <'4'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
    
                                            <span *ngIf="selfpace.dummy_rating=='4'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'4' && selfpace.dummy_rating <'5'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
    
                                            <span *ngIf="selfpace.dummy_rating=='5'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                                                                            </span>
                                            
                                        </div>
                                        <span class="text-time-level course-details-iner">{{selfpace.course_hours}} hrs | {{selfpace.lectures}} Lectures | All levels</span>
                                      </div>
                                  
                             <div class="row mb-2">
                                <div class="col-12">
                                           
                                    <div class="course-details-iner">
                                        <span class="text-author course-details-iner text-left">₹{{selfpace.amount}}</span>
                                        
                                    </div>
                                    <!-- <span class="text-time-level course-details-iner">5 Slots left</span> -->
                                </div>
                                
                             </div>
                             <div class="row">
                                <div class="col-12">
                                           
                                    <div class="course-details-iner">
                                        
                                        <button class="btn btn-light btn-accent text-right" routerLink="/description" (click)="onDetails(selfpace.id)">View Details</button>
                                    </div>
                                    <!-- <span class="text-time-level course-details-iner">5 Slots left</span> -->
                                </div>
                             </div>     
                                   
                                    
                                  </div>
                                </div>
                              </div>
                    </div>
                </div>
                <div class="col-12 my-4" *ngIf="ageGroupTwoData">
                    <div class="mb-3"><h2>Beginner(7 - 8 Yrs)</h2></div>

                    <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-2 mb-2"  *ngFor="let selfpace of ageGroupTwoData">
                                <div class="card card-item">
                                  <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{selfpace.image}}" class="card-img-top" alt="...">
                                  <div class="card-body card-fix">
                                    <div class="course-details">
                                        <span class="text-heading course-details-iner mb-2">{{selfpace.course_name}}</span>
                                        <span class="text-descrption course-details-iner mb-2" *ngIf="selfpace.sub_title != null && selfpace.sub_title != 'null'">{{selfpace.sub_title | slice:0:80}}</span>
                                        <span class="text-author course-details-iner mb-2" *ngIf="selfpace.by_teacher_name != null">By - {{selfpace.by_teacher_name}}</span>
                                        <span class="text-rating course-details-iner mb-2">{{selfpace.dummy_rating}} / 5 
                                            <span *ngIf="selfpace.dummy_rating=='1'">
                                                <i class="fas fa-star"></i>
                                            </span>
                                            <span *ngIf="selfpace.dummy_rating >'1' && selfpace.dummy_rating <'2'"> <i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                            <span *ngIf="selfpace.dummy_rating=='2'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'2' && selfpace.dummy_rating <'3'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                            <span *ngIf="selfpace.dummy_rating=='3'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'3' && selfpace.dummy_rating <'4'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>

                                            <span *ngIf="selfpace.dummy_rating=='4'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'4' && selfpace.dummy_rating <'5'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>

                                            <span *ngIf="selfpace.dummy_rating=='5'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>

                                        </span>
                                        <span class="text-time-level course-details-iner mb-2">{{selfpace.course_hours}} hrs | {{selfpace.lectures}} Lectures | All levels</span>
                                      </div>
                                     
                                  
                             <div class="row mb-2">
                                <div class="col-12">
                                           
                                    <div class="course-details-iner">
                                        <span class="text-author course-details-iner text-left">₹{{selfpace.amount}}</span>
                                        
                                    </div>
                                    <!-- <span class="text-time-level course-details-iner">5 Slots left</span> -->
                                </div>
                                
                             </div>
                             <div class="row">
                                <div class="col-12">
                                           
                                    <div class="course-details-iner">
                                        
                                        <button class="btn btn-light btn-accent text-right" routerLink="/description" (click)="onDetails(selfpace.id)">View Details</button>
                                    </div>
                                    <!-- <span class="text-time-level course-details-iner">5 Slots left</span> -->
                                </div>
                             </div>
                                   
                                    
                                  </div>
                                </div>
                              </div>
                    </div>
                </div>
                <div class="col-12 my-4" *ngIf="ageGroupThreeData">
                    <div class="mb-3"><h2>Intermediate(9 - 11 Yrs)</h2></div>

                    <div class="row">
                        
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-2 mb-2"  *ngFor="let selfpace of ageGroupThreeData">
                                <div class="card card-item">
                                  <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{selfpace.image}}" class="card-img-top" alt="...">
                                  <div class="card-body card-fix">
                                    <div class="course-details">
                                        <span class="text-heading course-details-iner mb-2">{{selfpace.course_name}}</span>
                                        <span class="text-descrption course-details-iner mb-2" *ngIf="selfpace.sub_title != null && selfpace.sub_title != 'null'">{{selfpace.sub_title | slice:0:80}}</span>
                                        <span class="text-author course-details-iner mb-2" *ngIf="selfpace.by_teacher_name != null">By - {{selfpace.by_teacher_name}}</span>
                                        <span class="text-rating course-details-iner mb-2">{{selfpace.dummy_rating}} / 5

                                            <span *ngIf="selfpace.dummy_rating=='1'">
                                                <i class="fas fa-star"></i>
                                            </span>
                                            <span *ngIf="selfpace.dummy_rating >'1' && selfpace.dummy_rating <'2'"> <i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                            <span *ngIf="selfpace.dummy_rating=='2'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'2' && selfpace.dummy_rating <'3'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                            <span *ngIf="selfpace.dummy_rating=='3'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'3' && selfpace.dummy_rating <'4'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>

                                            <span *ngIf="selfpace.dummy_rating=='4'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'4' && selfpace.dummy_rating <'5'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>

                                            <span *ngIf="selfpace.dummy_rating=='5'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                        </span>
                                        <span class="text-time-level course-details-iner mb-2">{{selfpace.course_hours}} hrs | {{selfpace.lectures}} Lectures | All levels</span>
                                      </div>
                                  
                             <div class="row mb-2">
                                <div class="col-12">
                                           
                                    <div class="course-details-iner">
                                        <span class="text-author course-details-iner text-left">₹{{selfpace.amount}}</span>
                                        
                                    </div>
                                    <!-- <span class="text-time-level course-details-iner">5 Slots left</span> -->
                                </div>
                             </div>
                             <div class="row">
                                <div class="col-12">
                                           
                                    <div class="course-details-iner">
                                        
                                        <button class="btn btn-light btn-accent text-right" routerLink="/description" (click)="onDetails(selfpace.id)">View Details</button>
                                    </div>
                                    <!-- <span class="text-time-level course-details-iner">5 Slots left</span> -->
                                </div>
                             </div>
                                  
                                   
                                    
                                  </div>
                                </div>
                              </div>
                    </div>
                </div>
                <div class="col-12 my-4" *ngIf="ageGroupFourData">
                    <div class="mb-3"><h2>Advance(12 - 14 Yrs)</h2></div>

                    <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-2 mb-2"  *ngFor="let selfpace of ageGroupFourData">
                                <div class="card card-item">
                                  <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{selfpace.image}}" class="card-img-top" alt="...">
                                  <div class="card-body card-fix">
                                    <div class="course-details">
                                        <span class="text-heading course-details-iner mb-2">{{selfpace.course_name}}</span>
                                        <span class="text-descrption course-details-iner mb-2" *ngIf="selfpace.sub_title != null && selfpace.sub_title != 'null'">{{selfpace.sub_title | slice:0:80}}</span>
                                        <span class="text-author course-details-iner mb-2" *ngIf="selfpace.by_teacher_name != null">By - {{selfpace.by_teacher_name}}</span>
                                        <span class="text-rating course-details-iner mb-2">{{selfpace.dummy_rating}} / 5
                                            <span *ngIf="selfpace.dummy_rating=='1'">
                                                <i class="fas fa-star"></i>
                                            </span>
                                            <span *ngIf="selfpace.dummy_rating >'1' && selfpace.dummy_rating <'2'"> <i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                            <span *ngIf="selfpace.dummy_rating=='2'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'2' && selfpace.dummy_rating <'3'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                            <span *ngIf="selfpace.dummy_rating=='3'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'3' && selfpace.dummy_rating <'4'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>

                                            <span *ngIf="selfpace.dummy_rating=='4'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'4' && selfpace.dummy_rating <'5'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>

                                            <span *ngIf="selfpace.dummy_rating=='5'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>

                                        </span>
                                        <span class="text-time-level course-details-iner mb-2">{{selfpace.course_hours}} hrs | {{selfpace.lectures}} Lectures | All levels</span>
                                      </div>
                                  
                             <div class="row mb-2">
                                <div class="col-12 ">
                                           
                                    <div class="course-details-iner">
                                        <span class="text-author course-details-iner text-left">₹{{selfpace.amount}}</span>
                                        
                                    </div>
                                    <!-- <span class="text-time-level course-details-iner">5 Slots left</span> -->
                                </div>
                             </div>
                             <div class="row">
                                <div class="col-12">
                                           
                                    <div class="course-details-iner">
                                        
                                        <button class="btn btn-light btn-accent text-right" routerLink="/description" (click)="onDetails(selfpace.id)">View Details</button>
                                    </div>
                                    <!-- <span class="text-time-level course-details-iner">5 Slots left</span> -->
                                </div>
                             </div>
                                   
                                    
                                  </div>
                                </div>
                              </div>
                    </div>
                </div>
                <div class="col-12 my-4" *ngIf="ageGroupFiveData">
                    <div class="mb-3"><h2>Professional(15 - 18 Yrs)</h2></div>

                    <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-2 mb-2"  *ngFor="let selfpace of ageGroupFiveData">
                                <div class="card card-item">
                                  <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{selfpace.image}}" class="card-img-top" alt="...">
                                  <div class="card-body card-fix">
                                    <div class="course-details">
                                        <span class="text-heading course-details-iner mb-2">{{selfpace.course_name}}</span>
                                        <span class="text-descrption course-details-iner mb-2" *ngIf="selfpace.sub_title != null && selfpace.sub_title != 'null'">{{selfpace.sub_title | slice:0:80}}</span>
                                        <span class="text-author course-details-iner mb-2" *ngIf="selfpace.by_teacher_name != null">By - {{selfpace.by_teacher_name}}</span>
                                        <span class="text-rating course-details-iner mb-2">{{selfpace.dummy_rating}} / 5
                                            <span *ngIf="selfpace.dummy_rating=='1'">
                                                <i class="fas fa-star"></i>
                                            </span>
                                            <span *ngIf="selfpace.dummy_rating >'1' && selfpace.dummy_rating <'2'"> <i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                            <span *ngIf="selfpace.dummy_rating=='2'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'2' && selfpace.dummy_rating <'3'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>
                                            <span *ngIf="selfpace.dummy_rating=='3'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'3' && selfpace.dummy_rating <'4'"> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>

                                            <span *ngIf="selfpace.dummy_rating=='4'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
                                            <span *ngIf="selfpace.dummy_rating >'4' && selfpace.dummy_rating <'5'"> <i class="fas fa-star"></i> <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star-half-alt"></i></span>

                                            <span *ngIf="selfpace.dummy_rating=='5'"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>

                                        </span>
                                        <span class="text-time-level course-details-iner mb-2">{{selfpace.course_hours}} hrs | {{selfpace.lectures}} Lectures | All levels</span>
                                      </div>
                                  
                             <div class="row mb-2">
                                <div class="col-12">
                                           
                                    <div class="course-details-iner">
                                        <span class="text-author course-details-iner text-left">₹{{selfpace.amount}}</span>
                                        
                                    </div>
                                    <!-- <span class="text-time-level course-details-iner">5 Slots left</span> -->
                                </div>
                             </div>
                             <div class="row">
                                <div class="col-12">
                                           
                                    <div class="course-details-iner">
                                        
                                        <button class="btn btn-light btn-accent text-right" routerLink="/description" (click)="onDetails(selfpace.id)">View Details</button>
                                    </div>
                                    <!-- <span class="text-time-level course-details-iner">5 Slots left</span> -->
                                </div>
                             </div>
                                   
                                    
                                  </div>
                                </div>
                              </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>