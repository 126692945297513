<section class="coding">
  <div class="container">
    <div class="section-title">
      <h2 class="text-accent">
        Coding
      </h2>
      <p>Coding lessons for all age groups, done with a dose of science</p>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-10">
        <div class="row">
          <div class="col-lg-5 col-sm-12">
            <img src="assets/imgs/Group 9149.svg" class="img-fluid" alt="">
          </div>
          <div class="col-lg-7 col-sm-12 pt-3 pb-3">
            <div class="coding-features1 ">
              <h3 class="text-accent">What we Provide</h3>
              <ul class="features">
                <li>Personalised Modules</li>
                <li>Flexible scheduling</li>
                <li>Industry relevance</li>
                <li>International exposure</li>
              </ul>
            </div>
            <div class="row">
              <button class="btn btn-light btn-accent1 col-6 col-sm-6 offset-3 offset-sm-3" routerLink="/courses">Explore Now</button>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <app-learning-path></app-learning-path>
    </div>
    <div id="coursefacks">
      <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" *ngFor="let age of AgeGroupList;let i =index;">
          <a class="nav-link" id="grade1-tab" [ngClass]="age.id ==IndexId ? 'active':''" (click)="getCoursesList(age.id)"><span>{{age.title}}</span> <strong>Age {{age.age_from}}-{{age.age_to}}</strong></a>
        </li>
        <!-- <li class="nav-item" role="presentation">
          <a class="nav-link" id="grade2-tab" data-toggle="tab" href="javascript:void(0);" data-target="#grade2"
            role="tab" aria-controls="grade2" aria-selected="false"><span>Grade 2</span> <strong>Age 10-12</strong></a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="grade3-tab" data-toggle="tab" href="javascript:void(0);" data-target="#grade3"
            role="tab" aria-controls="grade3" aria-selected="false"><span>Grade 3</span> <strong>Age 13-16</strong></a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="grade4-tab" data-toggle="tab" href="javascript:void(0);" data-target="#grade4"
            role="tab" aria-controls="grade4" aria-selected="false"><span>Grade 4</span> <strong>Age 17-19</strong></a>
        </li> -->
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="grade1" role="tabpanel" aria-labelledby="grade1-tab">
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-xs-12 mt-2 mb-2" *ngFor="let agecourses of AgeGroupCoursesList">
              <div class="card card-item">
                <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{agecourses.image}}" class="card-img-top" alt="...">
                <div class="card-body">
                  <h3 class="card-title" style="    width: 100%;    font-size: 24px;
                  height: 50px;">{{agecourses.course_name}}</h3>
                  <h5>Curriculum</h5>
                  <p class="card-text set-height">{{agecourses.curriculum}}</p>
                  <h5>Hands on activities</h5>
                  <p class="card-text set-height">{{agecourses.hands_on_activities}}</p>
                  <h5 class="mb-3">Achievements</h5>
                  <div class="row" *ngIf="agecourses['achievement']?.length > 0">
                    <div class="col-4 " *ngFor="let createdCoding of agecourses['achievement']">
                      <div class="row no-gutters border-custome-activit">
                        <div class="col-12 text-center">
                          <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{createdCoding.image}}" class="img-fluid img-icon" alt="">
                          <div class="my-2">
                            <p class="achivement-item mb-0">{{createdCoding.title }}</p>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                    <!-- <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/certificate-line.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Certified course</span>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <h3 class="text-center mt-4 mb-4">{{agecourses.no_of_classes ? agecourses.no_of_classes : '0'}} classes</h3>
                 
                  <h4 class="text-center mt-4 mb-4">Rs. {{agecourses.amount}}/- <span
                    style="color:red; ">Rs.<s>{{agecourses.fake_price}}/-</s></span></h4>
                <h5 class="text-center mt-4 mb-4">Save {{calculateSave(agecourses)}} </h5>
                  <div class="price-box">
                    <ul class="pricing-details" style="font-size: 15px;    padding: 10px;">
                      
                      <li><a href="javascript:void(0);" class="text-white" (click)="onBuy(agecourses)">Buy Now</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row justify-content-center">
            <div class="col-7 text-center mt-5 mb-2">
              <button type="button" class="btn btn-outline-warning btn-view-more" routerLink="/coding">View All Coding Classes</button>
            </div>
          </div> -->
        </div>
        <!-- <div class="tab-pane fade" id="grade2" role="tabpanel" aria-labelledby="grade2-tab">
          <div class="row">
            <div class="col-lg-4">
              <div class="card card-item">
                <img src="../../assets/imgs/Rectangle 71.png" class="card-img-top" alt="...">
                <div class="card-body">
                  <h3 class="card-title">Fundamantal</h3>
                  <h5>Curriculum</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5>Hands on activities</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5 class="mb-3">Achievements</h5>
                  <div class="row">
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/bx-mobile-alt.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Mobile app development</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/certificate-line.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Certified course</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="text-center mt-4 mb-4">14 classes</h5>
                  <div class="price-box">
                    <ul class="pricing-details">
                      <li>₹19,000</li>
                      <li><a href="javascript:void(0);" class="text-white">Buy Now</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-item">
                <img src="../../assets/imgs/Rectangle 71.png" class="card-img-top" alt="...">
                <div class="card-body">
                  <h3 class="card-title">Fundamantal</h3>
                  <h5>Curriculum</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5>Hands on activities</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5 class="mb-3">Achievements</h5>
                  <div class="row">
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/bx-mobile-alt.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Mobile app development</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/certificate-line.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Certified course</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="text-center mt-4 mb-4">14 classes</h5>
                  <div class="price-box">
                    <ul class="pricing-details">
                      <li>₹19,000</li>
                      <li><a href="javascript:void(0);" class="text-white">Buy Now</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-item">
                <img src="../../assets/imgs/Rectangle 71.png" class="card-img-top" alt="...">
                <div class="card-body">
                  <h3 class="card-title">Fundamantal</h3>
                  <h5>Curriculum</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5>Hands on activities</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5 class="mb-3">Achievements</h5>
                  <div class="row">
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/bx-mobile-alt.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Mobile app development</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/certificate-line.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Certified course</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="text-center mt-4 mb-4">14 classes</h5>
                  <div class="price-box">
                    <ul class="pricing-details">
                      <li>₹19,000</li>
                      <li><a href="javascript:void(0);" class="text-white">Buy Now</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="grade3" role="tabpanel" aria-labelledby="grade3-tab">
          <div class="row">
            <div class="col-lg-4">
              <div class="card card-item">
                <img src="../../assets/imgs/Rectangle 71.png" class="card-img-top" alt="...">
                <div class="card-body">
                  <h3 class="card-title">Fundamantal</h3>
                  <h5>Curriculum</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5>Hands on activities</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5 class="mb-3">Achievements</h5>
                  <div class="row">
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/bx-mobile-alt.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Mobile app development</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/certificate-line.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Certified course</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="text-center mt-4 mb-4">14 classes</h5>
                  <div class="price-box">
                    <ul class="pricing-details">
                      <li>₹19,000</li>
                      <li><a href="javascript:void(0);" class="text-white">Buy Now</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-item">
                <img src="../../assets/imgs/Rectangle 71.png" class="card-img-top" alt="...">
                <div class="card-body">
                  <h3 class="card-title">Fundamantal</h3>
                  <h5>Curriculum</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5>Hands on activities</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5 class="mb-3">Achievements</h5>
                  <div class="row">
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/bx-mobile-alt.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Mobile app development</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/certificate-line.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Certified course</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="text-center mt-4 mb-4">14 classes</h5>
                  <div class="price-box">
                    <ul class="pricing-details">
                      <li>₹19,000</li>
                      <li><a href="javascript:void(0);" class="text-white">Buy Now</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-item">
                <img src="../../assets/imgs/Rectangle 71.png" class="card-img-top" alt="...">
                <div class="card-body">
                  <h3 class="card-title">Fundamantal</h3>
                  <h5>Curriculum</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5>Hands on activities</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5 class="mb-3">Achievements</h5>
                  <div class="row">
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/bx-mobile-alt.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Mobile app development</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/certificate-line.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Certified course</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="text-center mt-4 mb-4">14 classes</h5>
                  <div class="price-box">
                    <ul class="pricing-details">
                      <li>₹19,000</li>
                      <li><a href="javascript:void(0);" class="text-white">Buy Now</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="tab-pane fade" id="grade4" role="tabpanel" aria-labelledby="grade4-tab">
          <div class="row">
            <div class="col-lg-4">
              <div class="card card-item">
                <img src="../../assets/imgs/Rectangle 71.png" class="card-img-top" alt="...">
                <div class="card-body">
                  <h3 class="card-title">Fundamantal</h3>
                  <h5>Curriculum</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5>Hands on activities</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5 class="mb-3">Achievements</h5>
                  <div class="row">
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/bx-mobile-alt.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Mobile app development</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/certificate-line.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Certified course</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="text-center mt-4 mb-4">14 classes</h5>
                  <div class="price-box">
                    <ul class="pricing-details">
                      <li>₹19,000</li>
                      <li><a href="javascript:void(0);" class="text-white">Buy Now</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-item">
                <img src="../../assets/imgs/Rectangle 71.png" class="card-img-top" alt="...">
                <div class="card-body">
                  <h3 class="card-title">Fundamantal</h3>
                  <h5>Curriculum</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5>Hands on activities</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5 class="mb-3">Achievements</h5>
                  <div class="row">
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/bx-mobile-alt.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Mobile app development</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/certificate-line.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Certified course</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="text-center mt-4 mb-4">14 classes</h5>
                  <div class="price-box">
                    <ul class="pricing-details">
                      <li>₹19,000</li>
                      <li><a href="javascript:void(0);" class="text-white">Buy Now</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-item">
                <img src="../../assets/imgs/Rectangle 71.png" class="card-img-top" alt="...">
                <div class="card-body">
                  <h3 class="card-title">Fundamantal</h3>
                  <h5>Curriculum</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5>Hands on activities</h5>
                  <p class="card-text">Sequence, Fundamentals Coding Blocks, Loops</p>
                  <h5 class="mb-3">Achievements</h5>
                  <div class="row">
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/bx-mobile-alt.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Mobile app development</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row no-gutters">
                        <div class="col-5">
                          <img src="../../assets/imgs/certificate-line.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                          <span class="achivement-item">Certified course</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="text-center mt-4 mb-4">14 classes</h5>
                  <div class="price-box">
                    <ul class="pricing-details">
                      <li>₹19,000</li>
                      <li><a href="javascript:void(0);" class="text-white">Buy Now</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div> -->
      </div>
    </div>
  </div>
</section>