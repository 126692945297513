<app-header></app-header>

<!-- Main Banner -->
<section class="main-banner py-5">
    <div class="container my-5">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
            <div class="col-lg-6 mb-3 mb-lg-0">
                <h1 class="font-weight-normal display-3">Are You <span
                        class="font-weight-bold text-warning">PASSIONATE</span> To Start Your Own Business In <span
                        class="font-weight-bold text-warning">ROBOTICS & AI</span></h1>
            </div>
            <div class="col-lg-5 mb-3 mb-lg-0">
                <div class="card p-3 rounded shadow">
                    <h4 class="font-weight-bold mb-3">FRANCHISEE ENQUIRY FORM</h4>
                    <form [formGroup]="AddFormContact">
                        <div class="form-group">
                            <label class="mb-0" for="exampleInputName">First Name</label>
                            <input placeholder="Enter your First name" type="text"
                                class="form-control bg-white border border-dark" id="exampleInputName" [ngClass]="{ 'is-invalid': submittedContact && fContact.first_name.errors }" formControlName="first_name">
                                <div *ngIf="submittedContact && fContact.first_name.errors">
                                  <small *ngIf="fContact.first_name.errors.required" class="error_class">
                                      This Field is required
                                  </small>
                              </div>
                        </div>
                        <div class="form-group">
                            <label class="mb-0" for="exampleInputName">Last Name</label>
                            <input placeholder="Enter your Last name" type="text"
                                class="form-control bg-white border border-dark" id="exampleInputName" [ngClass]="{ 'is-invalid': submittedContact && fContact.last_name.errors }" formControlName="last_name">
                                <div *ngIf="submittedContact && fContact.last_name.errors">
                                  <small *ngIf="fContact.last_name.errors.required" class="error_class">
                                      This Field is required
                                  </small>
                              </div>
                        </div>
                        <div class="form-group">
                            <label class="mb-0" for="exampleInputEmail1">Email</label>
                            <input type="email" class="form-control bg-white border border-dark"
                                placeholder="Enter your email address" id="exampleInputEmail1" [ngClass]="{ 'is-invalid': submittedContact && fContact.email.errors }" formControlName="email">
                                <div *ngIf="submittedContact && fContact.email.errors">
                                  <small *ngIf="fContact.email.errors.required" class="error_class">
                                      This Field is required
                                  </small>
                              </div>
                        </div>
                        <div class="form-group">
                            <label class="mb-0" for="exampleInputTel1">Contact No.</label>
                            <input type="tel" class="form-control bg-white border border-dark"
                                placeholder="Enter your contact no" id="exampleInputTel1" [ngClass]="{ 'is-invalid': submittedContact && fContact.contact.errors }" formControlName="contact">
                                <div *ngIf="submittedContact && fContact.contact.errors">
                                  <small *ngIf="fContact.contact.errors.required" class="error_class">
                                      This Field is required
                                  </small>
                              </div>
                        </div>
                        <div class="form-group">
                          <label class="mb-0">Select State</label>
                          <select class="form-control bg-white border border-dark" aria-label="Default select example" (change)="onChangeCity($event.target)" formControlName="state" [ngClass]="{ 'is-invalid': submittedContact && fContact.state.errors }">
                              <option selected disabled>---select---</option>
                              <option *ngFor="let state of stateList" [value]="state.name">{{state.name}}</option>
                          </select>
                          <div *ngIf="submittedContact && fContact.state.errors">
                            <small *ngIf="fContact.state.errors.required" class="error_class">
                                This Field is required
                            </small>
                        </div>
                        </div>
                        <div class="form-group">
                          <label class="mb-0">Select City</label>
                          <select class="form-control bg-white border border-dark" aria-label="Default select example" formControlName="city" [ngClass]="{ 'is-invalid': submittedContact && fContact.city.errors }">
                              <option selected disabled>---select---</option>
                              <option *ngFor="let city of cityList" [value]="city.name">{{city.name}}</option>
                          </select>
                          <div *ngIf="submittedContact && fContact.city.errors">
                            <small *ngIf="fContact.city.errors.required" class="error_class">
                                This Field is required
                            </small>
                        </div>
                        </div>
                        <div class="form-group">
                          <label class="mb-0" for="exampleInputTel1">Description</label>
                          <textarea type="tel" class="form-control bg-white border border-dark"
                              placeholder="Enter your Description" id="exampleInputTel1" [ngClass]="{ 'is-invalid': submittedContact && fContact.description.errors }" formControlName="description"></textarea>
                              <div *ngIf="submittedContact && fContact.description.errors">
                                <small *ngIf="fContact.description.errors.required" class="error_class">
                                    This Field is required
                                </small>
                            </div>
                      </div>
                        <button type="submit" class="btn btn-dark w-100" (click)="onSubmitContact()" [disabled]="btnsubmittedContact">Submit</button>
                    </form>
                </div>

            </div>
        </div>
    </div>
</section>


<!-- Section 2 -->
<div class="container py-5">
    <div class="my-5">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
            <div class="col-lg-6 mb-3 mb-lg-0">
                <img src="assets/imgs/franchise/section-1-left-img.png" alt="" class="img-fluid">
            </div>
            <div class="col-lg-6 mb-3 mb-lg-0">
                <h2 class="font-weight-bold mb-3 display-4">Interested in <span class="text-warning">Starting
                        The Right</span> Business ?</h2>
                <ul class="ml-2 list-style-none">
                    <li class="mb-3 d-flex align-items-center h5"><i class="fa fa-star checked mr-2"></i>Are you a
                        person driven by Passion, Creativity & Technology?</li>
                    <li class="mb-3 d-flex align-items-center h5"><i class="fa fa-star checked mr-2"></i>Do you wish to
                        start a business where the growth opportunities are High?</li>
                    <li class="mb-3 d-flex align-items-center h5"><i class="fa fa-star checked mr-2"></i>Are you looking
                        for an experienced Partner, who knows the market and can Support you well with their Technical &
                        Marketing skills?</li>
                    <li class="mb-3 d-flex align-items-center h5"><i class="fa fa-star checked mr-2"></i>Do you already
                        have an existing educational business and want to give it a competitive advantage?</li>
                </ul>
            </div>
        </div>
    </div>
</div>


<!-- What Enterpreneurs Choose LearningBix -->
<div class="container-fluid bg-light py-5">
    <div class="container">
        <div class="col-lg-8 mx-auto text-center mb-5">
            <h2 class="font-weight-bold mb-3 display-4">6 Reasons Why
                <span class="text-warning">Parents</span> Choose
                <span class="text-warning">LearningBix</span>
            </h2>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-1.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">21<sup>st</sup> Century Skills</h4>
                <p>Help kids inculcate the necessary STEM skills and make them ready for a tech-driven world of future
                </p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-2.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Innovative Kits</h4>
                <p>LBix kits are appreciated for being as close to real life applications as possible with readily available DIY MDF/Cardboard structures to impart desired STEM learning outcomes.</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-3.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Trusted Brand</h4>
                <p>LearningBIX is a Trusted Brand with 2000+ Partner Schools running LBIS (LearningBIX Innovation Space)</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-4.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Package Customization</h4>
                <p>Tailor-made multi-lingual curriculum to offer both Micro-Degrees and crash courses on different technologies to cater to children of different age groups from 2 to 18 years.</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-5.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Technology Intervention</h4>
                <p>World-class technology solution for Franchisee Partners, Teachers and students in form of proprietary LMS, Learning App, Play App and Web applications.</p>
            </div>
            <div class="col-md-4 col-12 text-center">
                <img src="assets\imgs\skill-6.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Award Winning Curriculum</h4>
                <p>LearningBIX Robotics and Coding courses are NEP complaint and are indexed to NCERT/CBSE/IB/ICSE Board curriculum. </p>
            </div>
        </div>
    </div>
</div>


<!-- What Support We Offer -->
<div class="container-fluid py-5">
    <div class="my-5 container">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
            <div class="col-lg-8 mb-3 mb-lg-0 order-2 order-lg-1">
                <h2 class="font-weight-bold mb-3 display-4">What <span class="text-warning">support</span> our <span
                        class="text-warning">Franchisee Partners</span> get?</h2>
                <ul class="ml-2 list-style-none">
                    <li class="mb-3">
                        <div class="d-flex">
                            <div>
                                <i class="fa fa-star checked mr-2"></i>
                            </div>
                            <div>
                                <h5 class="mb-1">Learning Management System</h5>
                                <p class="mb-0 text-warning font-weight-bold">LBix provide 3 level LMS for Franchisee
                                    Partners, Trainers and Students each, wherein</p>
                                <ul>
                                    <li>Franchisee can assign Robotics/Coding courses to students - Personalised
                                        Learning</li>
                                    <li>Track individual Trainers' &Students' progress</li>
                                    <li>Generate Progression Reports & Certificates</li>
                                    <li>Learning &Development Resources for Teachers</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="mb-3">
                        <div class="d-flex">
                            <div>
                                <i class="fa fa-star checked mr-2"></i>
                            </div>
                            <div>
                                <h5 class="mb-1">Marketing Support</h5>
                                <ul>
                                    <li>LBix is already serving to 10 Lacs + Students and 1200+ Schools and is a well
                                        known brand in the field of Technology Education</li>
                                    <li>We provide support to partners for Lead generation,Branding, Promotion and build
                                        Marketing Strategy for both online & offline channels</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="mb-3">
                        <div class="d-flex">
                            <div>
                                <i class="fa fa-star checked mr-2"></i>
                            </div>
                            <div>
                                <h5 class="mb-1">Technical Support</h5>
                                <ul>
                                    <li>LBix provide both offline Training at Franchisee’s location & at Lbix Head
                                        Quarters</li>
                                    <li>24x7 Online support for both Partners and Students by Subject Matter Experts
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 mb-3 mb-lg-0 order-1 order-lg-2">
                <img src="assets/imgs/franchise/section-1-left-img.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>

    <div class="number-tape">
        <div class="container">
            <div class="d-flex flex-wrap justify-content-between py-5">
                <div class="col-lg-3 col-md-6 mb-3 mb-lg-0">
                    <div class="mb-3">
                        <h3 class="text-warning font-weight-bold"><span class="mr-2"><i class="fa fa-graduation-cap text-warning font-weight-bold" aria-hidden="true"></i></span>2,000+</h3>
                    </div>
                    <h5 class="font-weight-bold">Partner Schools</h5>
                </div>
                <div class="col-lg-3 col-md-6 mb-3 mb-lg-0">
                    <div class="mb-3">
                        <h3 class="text-warning font-weight-bold"><span class="mr-2"><i class="fa fa-book text-warning font-weight-bold" aria-hidden="true"></i></span>10,00,000+</h3>
                    </div>
                    <h5 class="font-weight-bold">Students Enrolled</h5>
                </div>
                <div class="col-lg-3 col-md-6 mb-3 mb-lg-0">
                    <div class="mb-3">
                        <h3 class="text-warning font-weight-bold"><span class="mr-2"><i class="fa fa-map-marker text-warning font-weight-bold" aria-hidden="true"></i></span>10,000+</h3>
                    </div>
                    <h5 class="font-weight-bold">Teachers Trained</h5>
                </div>
                <div class="col-lg-3 col-md-6 mb-3 mb-lg-0">
                    <div class="mb-3">
                        <h3 class="text-warning font-weight-bold"><span class="mr-2"><i class="fa fa-hourglass-half text-warning font-weight-bold" aria-hidden="true"></i></span>25+</h3>
                    </div>
                    <h5 class="font-weight-bold">Global Locations</h5>
                </div>
            </div>
        </div>
    </div>

    <div class="my-5 container">
        <h2 class="font-weight-bold mb-3 display-4"><span class="text-warning">Product</span> Suite</h2>
        <div class="d-flex flex-wrap justify-content-between align-items-top">
            <div class="col-lg-3 col-md-6 col-12 mb-3 mb-lg-0">
                <h5 class="text-white bg-warning p-2">Students’ Play App</h5>
                <img src="assets\imgs\software-1.png" class="img-fluid">
                <ul class="ml-2 list-style-none">
                    <li class="mb-3">
                        <div class="d-flex">
                            <div>
                                <i class="fa fa-star checked mr-2"></i>
                            </div>
                            <div>
                                <h5 class="mb-1">Bix Champ Play App</h5>
                                <ul>
                                    <li>Stop-motion based structure building</li>
                                    <li>Bilingual instructional videos</li>
                                    <li>Access to all kits</li>
                                    <li>Gamified – Sense of achievement</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-6 col-12 mb-3 mb-lg-0">
                <ul class="ml-2 list-style-none">
                    <li class="mb-3">
                        <div class="d-flex">
                            <div>
                                <i class="fa fa-star checked mr-2"></i>
                            </div>
                            <div>
                                <h5 class="mb-1">LearningBix App</h5>
                                <ul>
                                    <li>Access to entire course curriculum</li>
                                    <li>Includes multilingual videos, quizzes, projects, games, etc.</li>
                                    <li>Step-by-step unlocking mechanism</li>
                                    <li>Learn anytime, anywhere</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
                <img src="assets\imgs\software-3.png" class="img-fluid">
                <h5 class="text-white bg-warning p-2 mt-3">Student Curriculum</h5>
            </div>
            <div class="col-lg-3 col-md-6 col-12 mb-3 mb-lg-0">
                <h5 class="text-white bg-warning p-2">LearningBix Web</h5>
                <img src="assets\imgs\software-4.png" class="img-fluid">
                <ul class="ml-2 list-style-none">
                    <li class="mb-3">
                        <div class="d-flex">
                            <div>
                                <i class="fa fa-star checked mr-2"></i>
                            </div>
                            <div>
                                <h5 class="mb-1">LearningBix Web Portal</h5>
                                <ul>
                                    <li>Students can access the assigned courses via Laptop/Computer/Tab</li>
                                    <li>Puzzles, Quizzes and Real Life Projects</li>
                                    <li>Monthly performance Reports</li>
                                    <li>Certificates</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-6 col-12 mb-3 mb-lg-0">
                <ul class="ml-2 list-style-none">
                    <li class="mb-3">
                        <div class="d-flex">
                            <div>
                                <i class="fa fa-star checked mr-2"></i>
                            </div>
                            <div>
                                <h5 class="mb-1">Franchisee Panel</h5>
                                <ul>
                                    <li>LMS system to create custom classrooms </li>
                                    <li>Course assignment for students & teachers</li>
                                    <li>Progression tracking for students & teachers</li>
                                    <li>Automatic certificate & report generation</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
                <img src="assets\imgs\software-2.png" class="img-fluid">
                <h5 class="text-white bg-warning p-2">B2B - Franchise Panel</h5>
            </div>
        </div>
    </div>
</div>


<!-- ALL YOU NEED TO GET STARTED -->
<div class="container-fluid bg-light py-5">
    <div class="container">
        <div class="col-lg-6 mx-auto text-center mb-5">
            <h2 class="font-weight-bold mb-3 display-4">What you
                <span class="text-warning">Need</span> to get
                <span class="text-warning">Started</span>?
            </h2>
        </div>
        <div class="d-flex flex-wrap justify-content-between mb-4">
            <div class="col-lg-3 col-md-6 col-12 mb-3 mb-lg-0 text-center">
                <img src="assets\imgs\skill-1.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Area Required</h4>
                <p>1200 sq.ft Built-up Area, at a good location accessible and visible to customers, for housing the
                    smart class setup.
                </p>
            </div>
            <div class="col-lg-3 col-md-6 col-12 mb-3 mb-lg-0 text-center">
                <img src="assets\imgs\skill-2.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Skills Required</h4>
                <p>An entrepreneurial mindset with the evidence of local market knowledge and Strong Marketing and Sales
                    skills</p>
            </div>
            <div class="col-lg-3 col-md-6 col-12 mb-3 mb-lg-0 text-center">
                <img src="assets\imgs\skill-3.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Investment</h4>
                <p>An investment of 5 to 15 Lakhs is required to be made available for Franchise Fee, Robotic and Drone
                    Kits and making the Centre Ready</p>
            </div>
            <div class="col-lg-3 col-md-6 col-12 mb-3 mb-lg-0 text-center">
                <img src="assets\imgs\skill-4.png" class="w-25 mb-3">
                <h4 class="font-weight-bold">Long Term Vision</h4>
                <p>A vision to create an impact in your society with our business and grow a long way in the network
                    along with us</p>
            </div>
        </div>
        <div class="mb-3 mb-lg-0 text-center">
            <div class="card p-0 rounded shadow">
                <div class="text-center card-header bg-warning">
                    <h4 class="mb-0 font-weight-bold">Choose Your Plan</h4>
                    <!-- <p class="mb-0">50 Classes</p> -->
                </div>
                <div class="card-body">
                    <table class="table table-bordered rounded">
                        <thead>
                            <tr class="table-warning">
                                <th class="text-left" scope="col">Franchise Plan</th>
                                <th scope="col">Master (10 Lacs & above)</th>
                                <th scope="col">Advance (3 Lacs & above)</th>
                                <th scope="col">Basic (50k & above)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-left">Space Requirement</td>
                                <td>800-1200 Sqft</td>
                                <td>500-700 Sqft</td>
                                <td>200-300 Sqft</td>
                            </tr>

                            <tr>
                                <td class="text-left">Exclusivity/ Pin Code Bound </td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                                <td><img src="assets/icons/Wrong-cross.svg"></td>
                                <td><img src="assets/icons/Wrong-cross.svg"></td>
                            </tr>

                            <tr>
                                <td class="text-left">Thematic Interior & Brand Promotion As Per LBix</td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                                <td><img src="assets/icons/Wrong-cross.svg"></td>
                                <td><img src="assets/icons/Wrong-cross.svg"></td>
                            </tr>

                            <tr>
                                <td class="text-left">Lbix LMS</td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                                <td><img src="assets/icons/Wrong-cross.svg"></td>
                            </tr>

                            <tr>
                                <td class="text-left">Mention on our website as Partner</td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                                <td><img src="assets/icons/Wrong-cross.svg"></td>
                                <td><img src="assets/icons/Wrong-cross.svg"></td>
                            </tr>

                            <tr>
                                <td class="text-left">Online Support</td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                            </tr>

                            <tr>
                                <td class="text-left">Lead Sharing</td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                                <td><img src="assets/icons/Wrong-cross.svg"></td>
                            </tr>

                            <tr>
                                <td class="text-left">Weekly Partner Mastermind Sessions</td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                                <td><img src="assets/icons/Correct-tick.svg"></td>
                                <td><img src="assets/icons/Wrong-cross.svg"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Testimonials Section -->
<section class="testimonials py-5">
    <div class="container">
        <div class="heading py-4 text-center">
            <h1 class="font-weight-bold display-4 mb-0">Testimonials</h1>
        </div>
        <div class="d-flex flex-wrap justify-content-center">
            <div class="col-12 mb-3">
                <owl-carousel-o [options]="customOpt">
                    <ng-container *ngFor="let item of testimonialList;let i=index">
                        <ng-template carouselSlide>
                            <div class="slide">
                                <div class="card bg-light p-3 br-20 position-relative" style="z-index: 1;">
                                    <div class="card circle-image-card rounded-circle p-2 bg-primary mx-auto">
                                        <div class="card rounded-circle p-3">
                                            <img src="assets\imgs\student.png" style="width: 100px; height:100px;">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center mb-3 pt-3">
                                        <img src="assets/imgs/star-yellow.png" alt="" class="star">
                                        <img src="assets/imgs/star-yellow.png" alt="" class="star">
                                        <img src="assets/imgs/star-yellow.png" alt="" class="star">
                                        <img src="assets/imgs/star-yellow.png" alt="" class="star">
                                        <img src="assets/imgs/star-yellow.png" alt="" class="star">
                                    </div>
                                    <div class="text-center mb-3 w-75 mx-auto">
                                        <p>Our students found the event fun and worthwhile. It was a truly meaningful
                                            experience for them. Thank you so much!”</p>
                                    </div>
                                    <div class="text-center">
                                        <h3 class="font-weight-bold text-primary">Manoj Modi</h3>
                                        <p>Director, Kapil Gyanpeeth, Jaipur</p>
                                    </div>
                                </div>
                                <!-- <div class="container mb-3">
                                    <div [class]="getRandomColor(i)">
                                        <p>“{{item.message}}”</p>
                                    </div>
                                </div> -->
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
            <!-- <div class="col-12 col-md-6 mb-3">
        <div class="feedback-content-gold">
          <p>“On behalf of the DLSZ community, I would like to express our gratitude to Brightchamps for the opportunity
            that you provided our students to experience a Coding Bootcamp. Our students found the event fun and
            worthwhile. It was a truly
            meaningful experience for them. Thank you so much!”</p>
        </div>
      </div> -->
        </div>
    </div>
</section>


<!-- Faq Section -->
<section class="faq bg-light">
    <div class="container">
        <div class="section-title">
            <h2 class="text-dark">Frequently asked questions</h2>
        </div>
        <div>
            <ul class="nav nav-pills justify-content-around mx-auto mb-4 px-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="btn btn-sm btn-warning nav-link font-weight-bold " id="pills-general-tab"
                        data-toggle="pill" data-target="#pills-general" type="button" role="tab"
                        aria-controls="pills-general" aria-selected="true">General</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="btn btn-sm btn-warning nav-link font-weight-bold" id="pills-technical-tab"
                        data-toggle="pill" data-target="#pills-technical" type="button" role="tab"
                        aria-controls="pills-technical" aria-selected="false">Technical</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="btn btn-sm btn-warning nav-link font-weight-bold" id="pills-financial-tab"
                        data-toggle="pill" data-target="#pills-financial" type="button" role="tab"
                        aria-controls="pills-financial" aria-selected="false">Financial</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="btn btn-sm btn-warning nav-link font-weight-bold" id="pills-marketing-tab"
                        data-toggle="pill" data-target="#pills-marketing" type="button" role="tab"
                        aria-controls="pills-marketing" aria-selected="false">Marketing</button>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-general" role="tabpanel"
                    aria-labelledby="pills-general-tab" tabindex="0">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div class="col-md-6 mb-3 mb-md-0">
                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0" type="button"
                                                data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                What is LearningBIX Innovation Space (LBIS)?
                                            </button>
                                        </h2>
                                    </div>

                                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            LBIS is a work space where young minds can give shape to their ideas through hands on do-it-yourself mode; and learn innovation skills. It's a space where teachers and students can learn and apply 21st century skills. Students learn applications on Artificial Intelligence, Machine Learning, IOT,Electronics, Robotics, Drones, 3D Printing, Agritech, etc.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0 collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                                aria-controls="collapseTwo">
                                                Why does kids need to learn about the latest technologies?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            Acquaintance with the latest technologies is important for children, especially when they start their academic journeys. The child’s mind is most receptive and curious in early years. An early exposure to things beyond the normal school curriculum helps them sharpen multiple skills. Some of the best known examples, who have benefitted from early learning interventions include, Elon Musk, Mark Zuckerberg etc
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0 collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">
                                                Who can start a Franchisee?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            Anyone with a desire to be an entrepreneur especially in the education sector. You may be a technology enthusiast, a school owner, a tutor, run a coaching centre or an educational centre with an existing student base.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3 mb-md-0">
                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0" type="button"
                                                data-toggle="collapse" data-target="#collapseFour" aria-expanded="true"
                                                aria-controls="collapseFour">
                                                What is the age group of students?
                                            </button>
                                        </h2>
                                    </div>

                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            We, at LearningBix offer a wide range of technology to make the young generation future-ready with 21st-century skills. Focused on leveraging technology in education, we provide project-based hands-on learning solutions for kids aged 2 to 18. However we have seen a tremendous interest in students in the age category of 8 to 14years.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFive">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0 collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseFive" aria-expanded="false"
                                                aria-controls="collapseFive">
                                                What is the Minimum Area required to start up?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            Owned/Rented space of minimum 200 Sq.Ft. to 1200 Sq.Ft. with comfortable seating arrangements for 10-20 students.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingSix">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0 collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseSix" aria-expanded="false"
                                                aria-controls="collapseSix">
                                                Is there any Franchisee Fee?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSix" class="collapse" aria-labelledby="headingSix"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            We do not charge any Franchisee Fee from our Franchisee Partners. The kits and curriculum can be bought from us. We share a small % of revenue generated at each centre and in turn participates in your growth, rather than charging a fixed sum. We started franchising with the idea of finding like-minded people who would work alongside us to impart technology education to our children and make them future-ready.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-technical" role="tabpanel" aria-labelledby="pills-technical-tab"
                    tabindex="0">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div class="col-12 mb-3 mb-md-0">
                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingSeven">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0" type="button"
                                                data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true"
                                                aria-controls="collapseSeven">
                                                Who will take care of the Training of Students?
                                            </button>
                                        </h2>
                                    </div>

                                    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            LearningBIX will help Franchisee’s to hire the right technical talent/resources to conduct in person physical classes at the centres. Franchisee owners can track the performance of the trainers and their learning curve with the help of Franchisee & Teacher LMS.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingEight">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0 collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseEight" aria-expanded="false"
                                                aria-controls="collapseEight">
                                                Who will take care of the Training of Trainers?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            LearningBIX conducts both physical and online training of the Trainers hired at Franchisee centre from time to time and keep on updating them on new technologies via Franchisee MasterMind Sessions periodically.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingNine">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0 collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseNine"
                                                aria-expanded="false" aria-controls="collapseNine">
                                                How are your technical courses different from others?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            We understand that one size doesn’t fit all. We have designed Level wise Micro degrees for students of different age categories who wish to enrol for a 3 months to 9 months duration program; and also kit based crash courses for summer and winter vacations.<br><br>
                                            Our curriculum helps students not only to learn Robotics/Technology but also helps them to form a grip over Science & Math concepts which they learn in Schools. Our team has curated the curriculum in a way that students become much more curious and start observing things happening around them with a scientific approach.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-financial" role="tabpanel" aria-labelledby="pills-financial-tab"
                    tabindex="0">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div class="col-12 mb-3 mb-md-0">
                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingThirteen">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0" type="button"
                                                data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="true"
                                                aria-controls="collapseThirteen">
                                                What are the areas of Revenue Generations?
                                            </button>
                                        </h2>
                                    </div>

                                    <div id="collapseThirteen" class="collapse" aria-labelledby="headingThirteen"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            can be generated through 4 areas primarily:<br><br>
                                            <ul>
                                                <li>Training Programs (Micro Degrees) at Training Centre</li>
                                                <li>Conducting summer and winter vacation camps</li>
                                                <li>Sale out of Kits and parts to interested Students</li>
                                                <li>Reaching out to schools and run a yearlong integrated Robotics Program at School premise</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFourteen">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0 collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false"
                                                aria-controls="collapseFourteen">
                                                What is the Minimum Investment required to start up?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFourteen" class="collapse" aria-labelledby="headingFourteen"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            Initial Investment varies from INR 50,000 to INR 10 Lac or higher (depending on the model) – We have 3 diff kinds of Franchisee Model
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFifteen">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0 collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseFifteen"
                                                aria-expanded="false" aria-controls="collapseFifteen">
                                                What will be the ROI?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFifteen" class="collapse" aria-labelledby="headingFifteen"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            The ROI will depend on how aggressively you promote this business in your area. Based on our experience, the payback period is as low as 8 to 10 months for the initial investment made.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-marketing" role="tabpanel" aria-labelledby="pills-marketing-tab"
                    tabindex="0">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div class="col-12 mb-3 mb-md-0">
                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingNineteen">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0" type="button"
                                                data-toggle="collapse" data-target="#collapseNineteen" aria-expanded="true"
                                                aria-controls="collapseNineteen">
                                                How will the Franchise promoted by LearningBix?
                                            </button>
                                        </h2>
                                    </div>

                                    <div id="collapseNineteen" class="collapse" aria-labelledby="headingNineteen"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            LearningBix promotes the Franchise by dedicated paid online social media campaign & Physical Marketing.<br><br>
                                            Assist Partners to formulate their marketing strategy and help prepare & design marketing collaterals like pamphlets, brochures, FB/Insta posts, etc.<br><br>
                                            Sharing of leads generated via website and paid digital campaigns.<br><br>
                                            LearningBIX is a well-established brand with technology labs running in over 2000+ schools across India with a strong Brand re-call.<br><br>
                                            Arrange demo at Partner Schools’ campuses.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwenty">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-dark font-weight-bold h4 mb-0 collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseTwenty" aria-expanded="false"
                                                aria-controls="collapseTwenty">
                                                Can we get the customers easily?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwenty" class="collapse" aria-labelledby="headingTwenty"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            Nothing is easy in this world – especially business. Unless marketing campaigns are done, obviously students will not be joining. Our team helps you to design both offline and online campaigns to reach out to maximum students in your vicinity. With the NitiAayog’s Atal Innovation Mission (AIM) program, CBSE’s AIOT curriculum for Grade VI to XII, there is much more awareness amongst the parents and students about the need to learn new age technologies.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>
