import { Component, OnInit } from '@angular/core';

import { BaseServiceService } from 'src/app/service';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvService } from 'src/app/service/env.service';
import { ModalService } from 'src/app/service/modal.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kits',
  templateUrl: './kits.component.html',
  styleUrls: ['./kits.component.scss']
})
export class KitsComponent implements OnInit {
  ageGroupList: any = [];
  ageGroupCoursesList: any = [];
  testimonialList: any = [];
  tabTitle: any;
  constructor(
    public _employeeService: BaseServiceService,
    private sanitizer: DomSanitizer, public modalService: ModalService, public envService: EnvService,public router:Router
  ) { }
  customOpt: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    margin: 20,
    navSpeed: 700,
    autoplay: false,
    autoplaySpeed: 2000,
    autoplayTimeout: 2000,
    navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    }

  }
  ngOnInit(): void {
    this.getAgeGroupList();
    this.getTestimonialList();
    this.getWebBlogCategoryList(0);
    // this.getBlogList();
    this.tabTitle = "Beginner"
  }

  getAgeGroupList() {
    this._employeeService.post('age_group_list', {}).subscribe((res: any) => {
      if (!res.error) {
        if (res.data.length > 0) {
          this.ageGroupList = res.data;
          this.ageGroupList.forEach(element => {
            element.class = "group";
            if (element.title == "Beginner") {
              element.age_from = 6
              element.age_to = 8
            }
            if (element.title == "Intermediate") {
              element.age_from = 9
              element.age_to = 12
            }
            if (element.title == "Advance") {
              element.age_from = "12"
              element.age_to = "12+"
            }
          });
          this.getAgeCourseList(this.ageGroupList[1].id);
          this.ageGroupList[1].class = "foundation";
        } else {
          this.ageGroupList = [];
        }
      }
    });
  }
  getActiveTab(ageObject: any) {
    // console.log("agessssssssssssssssssssssss :::::: ",ageObject)
    this.tabTitle = ageObject.title
    this.ageGroupList.forEach(element => element.class = "group");
    ageObject.class = "foundation";
    this.getAgeCourseList(ageObject.id);
  }
  getAgeCourseList(ageid) {
    this._employeeService.post('robotics_courses_list', {
      age_group_id: ageid,
      robotics_type: "4"
    }).subscribe((res: any) => {
      if (!res.error) {
        if (res.data.length > 0) {
          this.ageGroupCoursesList = res.data;
        } else {
          this.ageGroupCoursesList = [];
        }
      }
    });
  }
  getTestimonialList() {
    this._employeeService.post('web_student_testimonials_list', {
      learning: 5
    }).subscribe((res: any) => {
      if (!res.error) {
        if (res.data.length > 0) {
          this.testimonialList = res.data;
          // this.testimonialList.forEach(element => {
          //   element.backgroundcolor=this.getRandomColor();
          // });
        } else {
          this.testimonialList = [];
        }
      }
    });
  }
  getRandomColor(number) {
    var color = '';
    if (number % 2 == 0) {
      color = 'feedback-content-gold';
    } else {
      color = 'feedback-content-blue';
    }
    return color;
  }
  onOpenDemoPopup() {
    this.modalService.SetModalOnOff(true);
    this.modalService.setBookAFreeModal(true);
  }
  allowurl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  onBuy(SelfPaceObject) {
    localStorage.setItem('checkout', JSON.stringify(SelfPaceObject));
    window.location.href = `${this.envService.env}/checkout`;
    // this.router.navigate(['/checkout']);
  }
  filterargs = { type: 1 };
  filterargs_activity = { type: 1 };
  filterargs_skill = { type: 2 };
  filterargs_certificate = { type: 3 };
  getFilterData(objectData, type) {
    console.log('objectData:::', objectData);
    var data: any = [];
    data = objectData.filter(x => x.type == type);
    if (data.length > 0) {
      return data;
    } else {
      return data;
    }
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
  getSpaceRemoveFromString(name:any){
    var value='';
    value=name.replace(/\s/g, "").toLowerCase();
    return value;
  }
  isParentBlogCategoryList: any = [];
  isParentBlogCategoryList2: any = [];
  isParentBlogCategoryList3: any = [];
  isSelectMainCategory:any='';
  isSelectSubCategory:any='';
  isSelectSubSubCategory:any='';
  isLevel1:boolean=false;
  isLevel2:boolean=false;
  getWebBlogCategoryList(parentId: any) {
    this._employeeService.post('web_blog_category_list', {
      parent_id: parentId,
    }).subscribe((res: any) => {
      if (!res.error) {
        if (res.data.length > 0) {
          res.data.forEach(element => {
            Object.assign(element,{title:this.getSpaceRemoveFromString(element.name)})
          });
          setTimeout(() => {
            this.isParentBlogCategoryList = res.data;
            this.isSelectMainCategory=this.isParentBlogCategoryList[0].id;
            this.getBlogList(this.isSelectMainCategory);
          }, 1000);
        } else {
          this.isParentBlogCategoryList = [];
        }
      }
    });
  }



  onChangeSubCategory(categoryObject:any){
    this.isBlogList=[];
    this.isSelectMainCategory=categoryObject.id;
    this._employeeService.post('web_blog_category_list', {
      parent_id: categoryObject.id,
    }).subscribe((res: any) => {
      if (!res.error) {
        if (res.data.length > 0) {
          res.data.forEach(element => {
            Object.assign(element,{title:this.getSpaceRemoveFromString(element.name)})
          });
          setTimeout(() => {
            this.isLevel1=true;
            this.isBlogFlag=false;
            this.isParentBlogCategoryList2 = res.data;
          }, 1000);
        } else {
          this.isLevel1=true;
          this.isLevel2=true;
          this.getBlogList(categoryObject.id);
          this.isParentBlogCategoryList2 = [];
        }
      }
    });
  }

  onChangeSubSubCategory(categoryObject:any){
    this.isSelectSubCategory=categoryObject.id;
    this._employeeService.post('web_blog_category_list', {
      parent_id: categoryObject.id,
    }).subscribe((res: any) => {
      if (!res.error) {
        if (res.data.length > 0) {
          res.data.forEach(element => {
            Object.assign(element,{title:this.getSpaceRemoveFromString(element.name)})
          });
          setTimeout(() => {
            this.isBlogFlag=false;
            this.isLevel1=true;
            this.isLevel2=true;
            this.getBlogList(categoryObject.id);
            this.isParentBlogCategoryList3 = res.data;
          }, 1000);
        } else {
          this.isBlogFlag=false;
          this.isLevel1=true;
          this.isLevel2=true;
          this.getBlogList(categoryObject.id);
          this.isParentBlogCategoryList3 = [];
        }
      }
    });
  }






  isBlogList:any=[];
  isBlogFlag:boolean=false;
  getBlogList(blog_category_id:any){
    this._employeeService.post('blog_web_list', {
      blog_category_id: blog_category_id,
    }).subscribe((res: any) => {
      if (!res.error) {
        if (res.data.length > 0) {
          this.isBlogFlag=true;
          setTimeout(() => {
            this.isBlogList = res.data;
            console.log("isBlogList>>>>",this.isBlogList);
          }, 1000);
        } else {
          this.isBlogList = [];
        }
      }
    });
  }

  onRedirectUrl(id:any){
    this.router.navigate(['/blogs/'+id]);
  }
}
