<app-pie-header></app-pie-header>

<div class="pie-about-banner">
    <img src="assets/imgs/pie-about-banner.jpg">
    <div class="info-box">
        <h2>About Us</h2>
        <p>We are a leading education services provider, after having set up training labs and intervention programs for
            over a million students, spread over 1000+ schools, spread across the length and breadth of India, and after
            hundreds of hours of research with the best early age educators in India and abroad, is proud to present
            LearningPie.</p>
    </div>
</div>


<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="timeline-wrapper">
                <div class="box box-right width-90">
                    <div class="column">
                        <div class="year">2014</div>
                        <div class="circle"><span></span></div>
                        <ul>
                            <li>The Company was incorporated. Early days saw teaching Robotics and Electronics to
                                students in the Engineering degree</li>
                            <li>Launch of India’s first Prototype- solar Go-Cart and designs for ATV vehicles which also
                                took part in BAJA-SAE</li>
                        </ul>
                    </div>
                    <div class="column">
                        <div class="circle circle-small circle-mobile"></div>
                        <ul>
                            <li>Expanded the offerings to help Engineering students for developing( and also
                                conceptualizing) their major and minor BE/BTech projects and created a procurement
                                channel for electrical & electronic parts (Omni-channel) through www.studybazar.com</li>
                        </ul>
                    </div>
                </div>
                <div class="box box-left mt-10 mobile-flex">
                    <div class="column">
                        <div class="circle circle-small circle-mobile"></div>
                        <ul>
                            <li>Active partnership with 100+ Engineering Colleges for intervention program</li>
                            <li>Student enrolment crossed 25000 for Workshops & Projects</li>
                        </ul>
                    </div>
                    <div class="column">
                        <div class="year">2015</div>
                        <div class="circle circle-small"></div>
                        <ul>
                            <li>Numerous accolades from Govt Of Rajasthan for Innovation and being tech leaders in
                                Education</li>
                            <li>Organised Tech-Fest (JTF) in partnership with ISLE and ISHRAE. The Tech-fest had a
                                footfall of 2 Lac students</li>
                        </ul>
                    </div>

                </div>
                <div class="box box-right mt-10 width-90">

                    <div class="column">
                        <div class="year">2016</div>
                        <div class="circle circle-small"></div>
                        <ul>
                            <li>Developed,first of its kind, STEM curriculum based on Robotics for school students from
                                Grade 1 to Grade 12</li>
                        </ul>
                    </div>
                    <div class="column">
                        <div class="circle circle-small circle-mobile"></div>
                        <ul>
                            <li>First 25 B2B customers, for the STEM program in prestigious schools pan India</li>
                        </ul>
                    </div>

                </div>
                <div class="box box-left mt-10 width-90 mobile-flex">
                    <div class="column">
                        <div class="circle circle-small circle-mobile"></div>
                        <ul>
                            <li>The company set up the R&D facility for development of the indegenous Robotics Kits,
                                STEM
                                kits, sensors and programming boards</li>
                        </ul>
                    </div>
                    <div class="column">
                        <div class="year">2017</div>
                        <div class="circle circle-small"></div>
                        <ul>
                            <li>The company featured in the list of exclusively approved vendors for the establishment
                                of
                                Atal Tinkering Labs by NITI AAYOG.</li>
                        </ul>
                    </div>
                </div>
                <div class="box box-right mt-10 width-90">
                    <div class="column">
                        <div class="year">2018</div>
                        <div class="circle circle-small"></div>
                        <ul>
                            <li>Expanded physical presence to 15 states having offices at Indore, Bhopal, Raipur,
                                Mumbai, Pune, Delhi, Agra, etc</li>
                            <li>
                                Conceptualised & introduced Do-it-Yourself Kits for learning - Aeromodelling, Bio-tech,
                                Bio-medical, Automobile, Rocketry, Agritech, etc
                            </li>
                        </ul>
                    </div>
                    <div class="column">
                        <div class="circle circle-small circle-mobile"></div>
                        <ul>
                            <li>Successfully established 200+ Atal Tinkering Labs</li>
                            <li>Signed up for exculsive regional host of Robocup Jr. Robocup Jr is promoted by NASA, JP
                                Morgan, FESTO, etc and is conducted in 60 countries. Robocup Jr is the most pretegious
                                robotics competetion for Students below 18 years.</li>
                        </ul>
                    </div>
                </div>
                <div class="box box-left mt-10 width-90 mobile-flex">
                    <div class="column">
                        <div class="circle circle-small circle-mobile"></div>
                        <ul>
                            <li>By Year end, the Company had set up 500+ Atal Tinkering Labs (including Robotics Labs at
                                BITS Pilani) and was conducting year long STEM intervenion program in 100+ schools.</li>
                        </ul>
                    </div>
                    <div class="column">
                        <div class="year">2019</div>
                        <div class="circle circle-small"></div>
                        <ul>
                            <li>In the very first year of our association with Robocup, students trained at our academy
                                qualified for International Level held in Russia, Japan, etc. Each year students
                                enrolled for our School program give tough competition to IITians in Robotics
                                competition.</li>
                        </ul>
                    </div>
                </div>
                <div class="box border-r-b-none box-right mt-10 width-90">
                    <div class="column">
                        <div class="year">2020 and now</div>
                        <div class="circle circle-small"></div>
                        <ul>
                            <li>By March 2020, we had already engaged with over 5 Lacs students in the Age Group of 3 years to 18 years.</li>
                            <li>Covid-19 accelerated our digital strategies. We launched online intervention channels, and launched Learningbix.com, to also offer personalised 1 to 1 Live Coding classes for children</li>
                        </ul>
                    </div>
                    <div class="column">
                        <div class="border-dashed"></div>
                        <div class="circle circle-small circle-mobile"></div>
                        <ul>
                            <li>Simultaneously, we also developed self paced coding and robotics courses to serve the masses.</li>
                            <li>Enabled V2.0 for Students from  age 1.5 years to 18 years, offering Online Preschool, Live 1 to 1 Coding, Robotics/STEM kits and Self paced courses. </li>
                            <li>The offerings have been well received with over 1 Lac students learning digitally with us.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-pie-footer></app-pie-footer>