import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadsquaredService {
  public imageBase: any;

  public serviceBase: any = environment.leadsquared_api_url;
  public taskImageBase: any = environment.imageBase_api_url;
  public baseUrl = environment.api_url;
  user: any = JSON.parse(localStorage.getItem("user"));

  constructor(private router: Router, private _httpService: HttpClient, private toastrService: ToastService) {

  }

  get(url) {
    //let headers = new HttpHeaders({'Content-Type':'application/json'});
    let headers = new HttpHeaders(
      {
        'Authorization': 'Bearer ' + this.user.token,
        'Content-Type': 'application/json'
      }
    );
    //this.createAuthorizationHeader(headers);
    return this._httpService.get(this.serviceBase + url, {
      headers: headers

    });
  }

  post(url, data) {
    if (this.user != undefined && this.user != null) {
      let headers = new HttpHeaders(
        {
          'Content-Type': 'application/json'
        });
      return this._httpService.post(this.serviceBase + url, data, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders(
        {
          'Content-Type': 'application/json'
        });
      return this._httpService.post(this.serviceBase + url, data, {
        headers: headers
      });

    }
  }

  stateList(): Observable<any> {
    let requestData = { keyword: '' }
    return this._httpService.post(this.baseUrl + 'state_list', requestData)
  }

  getSponsorCode() {
    return localStorage.getItem('sponsor_code');
  }
  
  AddLeadCapture(userDetails, AgeGroupList): Observable<any> {
    let ageGroupName = '';
    let mx_Course_Interested_for = ''
    let demoClassData = `${userDetails.demo_class_date} ${userDetails.time_from}:00`;
    var startTime = new Date(demoClassData).getTime();
    var newDateTime: any = new Date(startTime).toISOString();
    var replace_date = newDateTime.replace('T', ' ');
    var split_date = replace_date.slice(0, 19);
    let requestData;
    if (AgeGroupList != null) {
      let ageGroup = AgeGroupList.filter(age => age.id == userDetails.age);
      ageGroupName = ageGroup[0].title ? ageGroup[0].title : ageGroup[0].name;
    }

    if (userDetails.learning == '0') {
      mx_Course_Interested_for = 'Coding'
    }
    if (userDetails.learning == '1') {
      mx_Course_Interested_for = 'Preschool'
    }
    if (userDetails.learning == '2 ') {
      mx_Course_Interested_for = 'Robotics'
    }
    if (userDetails.learning == '') {
      mx_Course_Interested_for = 'General'
    }
    if (localStorage.getItem('referral_code')) {
      var code = localStorage.getItem('referral_code');
    } else {
      var code = '';
    }
    if (userDetails.demo_class_date) {
      requestData = [
        {
          "Attribute": "FirstName",
          "Value": userDetails.first_name
        }, {
          "Attribute": "LastName",
          "Value": userDetails.last_name || ''
        }, {
          "Attribute": "EmailAddress",
          "Value": userDetails.email
        }, {
          "Attribute": "Phone",
          "Value": userDetails.phone_no
        }, {
          "Attribute": "SearchBy",
          "Value": "Phone"
        }, {
          "Attribute": "mx_Age_Group",
          "Value": ageGroupName
        }, {
          "Attribute": "mx_Contact_Us_Query_Message",
          "Value": ""
        }, {
          "Attribute": "mx_Street1",
          "Value": ""
        }, {
          "Attribute": "mx_Gender",
          "Value": ""
        }, {
          "Attribute": "mx_School_Name",
          "Value": ""
        }, {
          "Attribute": "Source",
          "Value": "Website Form"
        }, {
          "Attribute": "mx_Course_Interested_for",
          "Value": mx_Course_Interested_for
        }, {
          "Attribute": "mx_Demo_Date_and_Time",
          "Value": split_date
        }, {
          "Attribute": "mx_Coding_Age_Group",
          "Value": ageGroupName
        }, {
          "Attribute": "mx_User_Password",
          "Value": userDetails.password ? userDetails.password : ''
        }, {
          "Attribute": "mx_Tracking_Code",
          "Value": ''
        }, {
          "Attribute": "mx_Course_Purchased",
          "Value": ''
        }, {
          "Attribute": "mx_Price",
          "Value": ''
        }, {
          "Attribute": "mx_Delivery_Status",
          "Value": ''
        }, {
          "Attribute": "mx_Course_Start_Date",
          "Value": ''
        }, {
          "Attribute": "mx_Tracking_Link",
          "Value": ''
        }, {
          "Attribute": "mx_Refferal_Code",
          "Value": `${code}`
        }
      ]
    } else {
      requestData = [
        {
          "Attribute": "FirstName",
          "Value": userDetails.first_name
        }, {
          "Attribute": "LastName",
          "Value": userDetails.last_name || ''
        }, {
          "Attribute": "EmailAddress",
          "Value": userDetails.email
        }, {
          "Attribute": "Phone",
          "Value": userDetails.phone_no
        }, {
          "Attribute": "SearchBy",
          "Value": "Phone"
        }, {
          "Attribute": "mx_Age_Group",
          "Value": ageGroupName
        }, {
          "Attribute": "mx_Contact_Us_Query_Message",
          "Value": ""
        }, {
          "Attribute": "mx_Street1",
          "Value": ""
        }, {
          "Attribute": "mx_Gender",
          "Value": ""
        }, {
          "Attribute": "mx_School_Name",
          "Value": ""
        }, {
          "Attribute": "Source",
          "Value": "Website Form"
        }, {
          "Attribute": "mx_Course_Interested_for",
          "Value": mx_Course_Interested_for
        }, {
          "Attribute": "mx_User_Password",
          "Value": userDetails.password ? userDetails.password : ''
        }, {
          "Attribute": "mx_Tracking_Code",
          "Value": ''
        }, {
          "Attribute": "mx_Course_Purchased",
          "Value": ''
        }, {
          "Attribute": "mx_Price",
          "Value": ''
        }, {
          "Attribute": "mx_Delivery_Status",
          "Value": ''
        }, {
          "Attribute": "mx_Course_Start_Date",
          "Value": ''
        }, {
          "Attribute": "mx_Tracking_Link",
          "Value": ''
        }, {
          "Attribute": "mx_Refferal_Code",
          "Value": `${code}`
        }
      ]
    }
    console.log('requestData:::::', requestData);
    // return;
    return this._httpService.post(this.serviceBase + 'LeadManagement.svc/Lead.Capture?accessKey=u$reaa1b3cf9508ab9cbb06fa575d69cf04&secretKey=1be670b36d9cad28d6197eca35afa68eda5d57f0', requestData)
  }

  AddCourseEnquiry(courseName, relatedId): Observable<any> {
    let current_datetime = new Date()
    let day: any = current_datetime.getDate();
    var month: any = current_datetime.getMonth() + 1;
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    let formatted_date = current_datetime.getFullYear() + "-" + month + "-" + day + " " + "00:00:00";
    let requestData = {
      "ActivityEvent": 159,
      "ActivityNote": courseName,
      "ActivityDateTime": formatted_date,
      "Fields": [
        {
          "SchemaName": "mx_Custom_1",
          "Value": courseName
        },
      ]
    }
    return this._httpService.post(this.serviceBase + 'ProspectActivity.svc/Create?accessKey=u$rfa136631d2bf7fcb6b92baa3ef4327ce&secretKey=5aff89946d310f72c41fa88451144b94742006b1', requestData)
  }

  AddDemoClassBook(ageGroup, formDetails, relatedId): Observable<any> {
    let current_datetime = new Date()
    let day: any = current_datetime.getDate();
    var month: any = current_datetime.getMonth() + 1;
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    let formatted_date = current_datetime.getFullYear() + "-" + month + "-" + day + " " + "00:00:00";
    let ageGroupName = ageGroup.filter(age => age.id == formDetails.age)
    let requestData = {
      "ActivityEvent": 160,
      "ActivityNote": "Demo booked",
      "ActivityDateTime": formatted_date,
      "Fields": [
        {
          "SchemaName": "mx_Custom_1",
          "Value": ageGroupName[0].title
        },
        {
          "SchemaName": "mx_Custom_2",
          "Value": `${formDetails.demo_class_date} ${formDetails.time_from}:00`
        }
      ]
    }
    return this._httpService.post(this.serviceBase + 'ProspectActivity.svc/Create?accessKey=u$rfa136631d2bf7fcb6b92baa3ef4327ce&secretKey=5aff89946d310f72c41fa88451144b94742006b1', requestData)
  }

  AddCoursePurchase(courseDetails, courseStartDate, amount, AgeGroupList): Observable<any> {
    let ageGroupName = '';
    let mx_Course_Interested_for = ''
    let requestData;
    let userDetails = JSON.parse(sessionStorage.getItem('user'))
    let demoClassData = `${userDetails.demo_class_date} ${userDetails.time_from}:00`;
    var startTime = new Date(demoClassData).getTime();
    var newDateTime: any = new Date(startTime).toISOString();
    var replace_date = newDateTime.replace('T', ' ');
    var split_date = replace_date.slice(0, 19);
    if (AgeGroupList != null) {
      let ageGroup = AgeGroupList.filter(age => age.id == userDetails.age);
      ageGroupName = ageGroup[0].title;
    }

    if (userDetails.learning == '0') {
      mx_Course_Interested_for = 'Coding'
    }
    if (userDetails.learning == '1') {
      mx_Course_Interested_for = 'Preschool'
    }
    if (userDetails.learning == '2 ') {
      mx_Course_Interested_for = 'Robotics'
    }
    if (userDetails.learning == '') {
      mx_Course_Interested_for = 'General'
    }

    if (localStorage.getItem('referral_code')) {
      var code = localStorage.getItem('referral_code');
    } else {
      var code = '';
    }
    if (userDetails.demo_class_date) {
      requestData = [
        {
          "Attribute": "FirstName",
          "Value": userDetails.first_name
        }, {
          "Attribute": "LastName",
          "Value": userDetails.last_name || ''
        }, {
          "Attribute": "EmailAddress",
          "Value": userDetails.email
        }, {
          "Attribute": "Phone",
          "Value": userDetails.phone_no
        }, {
          "Attribute": "SearchBy",
          "Value": "Phone"
        }, {
          "Attribute": "mx_Age_Group",
          "Value": ageGroupName
        }, {
          "Attribute": "mx_Contact_Us_Query_Message",
          "Value": ""
        }, {
          "Attribute": "mx_Street1",
          "Value": ""
        }, {
          "Attribute": "mx_Gender",
          "Value": ""
        }, {
          "Attribute": "mx_School_Name",
          "Value": ""
        }, {
          "Attribute": "Source",
          "Value": "Website Form"
        }, {
          "Attribute": "mx_Course_Interested_for",
          "Value": mx_Course_Interested_for
        }, {
          "Attribute": "mx_Demo_Date_and_Time",
          "Value": split_date
        }, {
          "Attribute": "mx_Coding_Age_Group",
          "Value": ageGroupName
        }, {
          "Attribute": "mx_User_Password",
          "Value": userDetails.password
        }, {
          "Attribute": "mx_Tracking_Code",
          "Value": ''
        }, {
          "Attribute": "mx_Course_Purchased",
          "Value": courseDetails.course_name
        }, {
          "Attribute": "mx_Price",
          "Value": amount
        }, {
          "Attribute": "mx_Delivery_Status",
          "Value": ''
        }, {
          "Attribute": "mx_Course_Start_Date",
          "Value": `${courseStartDate} 00:00:00`
        }, {
          "Attribute": "mx_Tracking_Link",
          "Value": ''
        }, {
          "Attribute": "mx_Refferal_Code",
          "Value": `${code}`
        }

      ]
    } else {
      requestData = [
        {
          "Attribute": "FirstName",
          "Value": userDetails.first_name
        }, {
          "Attribute": "LastName",
          "Value": userDetails.last_name || ''
        }, {
          "Attribute": "EmailAddress",
          "Value": userDetails.email
        }, {
          "Attribute": "Phone",
          "Value": userDetails.phone_no
        }, {
          "Attribute": "SearchBy",
          "Value": "Phone"
        }, {
          "Attribute": "mx_Age_Group",
          "Value": ageGroupName
        }, {
          "Attribute": "mx_Contact_Us_Query_Message",
          "Value": ""
        }, {
          "Attribute": "mx_Street1",
          "Value": ""
        }, {
          "Attribute": "mx_Gender",
          "Value": ""
        }, {
          "Attribute": "mx_School_Name",
          "Value": ""
        }, {
          "Attribute": "Source",
          "Value": "Website Form"
        }, {
          "Attribute": "mx_Course_Interested_for",
          "Value": mx_Course_Interested_for
        }, {
          "Attribute": "mx_User_Password",
          "Value": userDetails.password
        }, {
          "Attribute": "mx_Tracking_Code",
          "Value": ''
        }, {
          "Attribute": "mx_Course_Purchased",
          "Value": courseDetails.course_name
        }, {
          "Attribute": "mx_Price",
          "Value": amount
        }, {
          "Attribute": "mx_Delivery_Status",
          "Value": ''
        }, {
          "Attribute": "mx_Course_Start_Date",
          "Value": `${courseStartDate} 00:00:00`
        }, {
          "Attribute": "mx_Tracking_Link",
          "Value": ''
        }, {
          "Attribute": "mx_Refferal_Code",
          "Value": `${code}`
        }
      ]
    }
    return this._httpService.post(this.serviceBase + 'LeadManagement.svc/Lead.Capture?accessKey=u$reaa1b3cf9508ab9cbb06fa575d69cf04&secretKey=1be670b36d9cad28d6197eca35afa68eda5d57f0', requestData)
  }

  AddLeadCaptureContact(userDetails): Observable<any> {
    let mx_Course_Interested_for = ''
    if (userDetails.learning == '0') {
      mx_Course_Interested_for = 'Coding'
    }
    if (userDetails.learning == '1') {
      mx_Course_Interested_for = 'Preschool'
    }
    if (localStorage.getItem('referral_code')) {
      var code = localStorage.getItem('referral_code');
    } else {
      var code = '';
    }
    let requestData = [
      {
        "Attribute": "FirstName",
        "Value": userDetails.first_name
      }, {
        "Attribute": "LastName",
        "Value": userDetails.last_name || ''
      }, {
        "Attribute": "EmailAddress",
        "Value": userDetails.email
      }, {
        "Attribute": "Phone",
        "Value": userDetails.contact
      }, {
        "Attribute": "SearchBy",
        "Value": "Phone"
      }, {
        "Attribute": "mx_Age_Group",
        "Value": ''
      }, {
        "Attribute": "mx_Contact_Us_Query_Message",
        "Value": userDetails.description || ''
      }, {
        "Attribute": "mx_Street1",
        "Value": ""
      }, {
        "Attribute": "mx_Gender",
        "Value": ""
      }, {
        "Attribute": "mx_School_Name",
        "Value": ""
      }, {
        "Attribute": "Source",
        "Value": "Website Form"
      }, {
        "Attribute": "mx_Course_Interested_for",
        "Value": mx_Course_Interested_for
      }, {
        "Attribute": "mx_Refferal_Code",
        "Value": `${code}`
      }
    ]
    return this._httpService.post(this.serviceBase + 'LeadManagement.svc/Lead.Capture?accessKey=u$reaa1b3cf9508ab9cbb06fa575d69cf04&secretKey=1be670b36d9cad28d6197eca35afa68eda5d57f0', requestData)
  }

  AddLeadCapturePurchase(userDetails, courseDetails, amount, start_date): Observable<any> {
    let mx_Course_Interested_for = ''
    let course_start_date = `${start_date} 03:30:00`;
    let requestData;
    if (localStorage.getItem('referral_code')) {
      var code = localStorage.getItem('referral_code');
    } else {
      var code = '';
    }
    if (userDetails.learning == '0') {
      mx_Course_Interested_for = 'Coding'
    }
    if (userDetails.learning == '1') {
      mx_Course_Interested_for = 'Preschool'
    }
    if (userDetails.learning == '2 ') {
      mx_Course_Interested_for = 'Robotics'
    }
    if (userDetails.learning == '') {
      mx_Course_Interested_for = 'General'
    }
    requestData = [
      {
        "Attribute": "FirstName",
        "Value": userDetails.first_name
      }, {
        "Attribute": "LastName",
        "Value": userDetails.last_name || ''
      }, {
        "Attribute": "EmailAddress",
        "Value": userDetails.email
      }, {
        "Attribute": "Phone",
        "Value": userDetails.phone
      }, {
        "Attribute": "SearchBy",
        "Value": "Phone"
      }, {
        "Attribute": "mx_Age_Group",
        "Value": `${courseDetails.age_group_title}`
      }, {
        "Attribute": "mx_Contact_Us_Query_Message",
        "Value": ""
      }, {
        "Attribute": "mx_Street1",
        "Value": ""
      }, {
        "Attribute": "mx_Gender",
        "Value": ""
      }, {
        "Attribute": "mx_School_Name",
        "Value": ""
      }, {
        "Attribute": "Source",
        "Value": "Website Form"
      }, {
        "Attribute": "mx_Course_Interested_for",
        "Value": mx_Course_Interested_for
      }, {
        "Attribute": "mx_User_Password",
        "Value": userDetails.password ? userDetails.password : ''
      }, {
        "Attribute": "mx_Tracking_Code",
        "Value": ''
      }, {
        "Attribute": "mx_Course_Purchased",
        "Value": `${courseDetails.course_name}`
      }, {
        "Attribute": "mx_Price",
        "Value": amount
      }, {
        "Attribute": "mx_Delivery_Status",
        "Value": ''
      }, {
        "Attribute": "mx_Course_Start_Date",
        "Value": `${course_start_date}`
      }, {
        "Attribute": "mx_Tracking_Link",
        "Value": ''
      }, {
        "Attribute": "mx_Refferal_Code",
        "Value": `${code}`
      }
    ]
    return this._httpService.post(this.serviceBase + 'LeadManagement.svc/Lead.Capture?accessKey=u$reaa1b3cf9508ab9cbb06fa575d69cf04&secretKey=1be670b36d9cad28d6197eca35afa68eda5d57f0', requestData)
  }

  BookFreeClass(phoneNo, parameter) {
    console.log('phoneNo::::', phoneNo, 'parameter::::', parameter);
    var requestData: any = [
      {"Attribute":"FirstName","Value":""},
      {"Attribute":"LastName","Value":""},
      {"Attribute":"EmailAddress","Value":""},
      {"Attribute":"Phone","Value":phoneNo || ''},
      {"Attribute":"SearchBy","Value":"Phone"},
      {"Attribute":"mx_Age_Group","Value":""},
      {"Attribute":"mx_Contact_Us_Query_Message","Value":""},
      {"Attribute":"mx_Street1","Value":""},
      {"Attribute":"mx_Gender","Value":""},
      {"Attribute":"mx_School_Name","Value":""},
      {"Attribute":"Source","Value":"Admin Form"},
      {"Attribute":"mx_Course_Interested_for","Value":""},
      {"Attribute":"mx_Course_Purchased","Value":""},
      {"Attribute":"mx_Price","Value":""},
      {"Attribute":"mx_Delivery_Status","Value":""},
      {"Attribute":"mx_Course_Start_Date","Value":""},
      {"Attribute":"mx_Tracking_Link","Value":""},
      {"Attribute":"unverified","Value":parameter}
    ]
    return this._httpService.post(this.serviceBase + 'LeadManagement.svc/Lead.Capture?accessKey=u$reaa1b3cf9508ab9cbb06fa575d69cf04&secretKey=1be670b36d9cad28d6197eca35afa68eda5d57f0', requestData)
  }

  questionQuery(phoneNo) {
    console.log('phoneNo::::', phoneNo);
    var requestData: any = [
      {"Attribute":"FirstName","Value":""},
      {"Attribute":"LastName","Value":""},
      {"Attribute":"EmailAddress","Value":""},
      {"Attribute":"Phone","Value":phoneNo || ''},
      {"Attribute":"SearchBy","Value":"Phone"},
      {"Attribute":"mx_Age_Group","Value":""},
      {"Attribute":"mx_Contact_Us_Query_Message","Value":""},
      {"Attribute":"mx_Street1","Value":""},
      {"Attribute":"mx_Gender","Value":""},
      {"Attribute":"mx_School_Name","Value":""},
      {"Attribute":"Source","Value":"Admin Form"},
      {"Attribute":"mx_Course_Interested_for","Value":""},
      {"Attribute":"mx_Course_Purchased","Value":""},
      {"Attribute":"mx_Price","Value":""},
      {"Attribute":"mx_Delivery_Status","Value":""},
      {"Attribute":"mx_Course_Start_Date","Value":""},
      {"Attribute":"mx_Tracking_Link","Value":""}
    ]
    console.log('requestData:::::', requestData);
    return this._httpService.post(this.serviceBase + 'LeadManagement.svc/Lead.Capture?accessKey=u$reaa1b3cf9508ab9cbb06fa575d69cf04&secretKey=1be670b36d9cad28d6197eca35afa68eda5d57f0', requestData)
  }
}
