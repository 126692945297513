import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/service/modal.service';
import { CommonService } from './../../service/common.service';
import { EnvService } from 'src/app/service/env.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  UserLoginFlag: boolean = false;
  showHideFlag: boolean = false;
  
  constructor(private router: Router, public common: CommonService,  public userService: UserService,private modalService: ModalService,private envService: EnvService) {
    this.common.SetPanel('bix');
  }

  ngOnInit(): void {
    this.common.GetshowhideFlag().subscribe(data => {
      if (data) {
        this.showHideFlag = data;
        console.log('showHideFlag::::::', this.showHideFlag);
      }
    })
    this.common.getLoggedInStatus().subscribe(data => {
      if (data == true) {
        this.UserLoginFlag = true;
      } else {
        this.UserLoginFlag = false;
      }
    })
  }

  onViewProduct(slug: any) {
    this.router.navigate(['/robotics/',slug]);
  }
  onRedirect(): void {
    if (localStorage.getItem('user')) {
      var userData = JSON.parse(localStorage.getItem('user'))
      if (userData.learning == 1) {
        window.location.href = `${this.envService.env}/student/dashboard-learning`;
      } else {
        window.location.href = `https://student.learningbix.com/dashboard`;
      }
    }
  }
  onOpenDemoPopup(){
    this.modalService.SetModalOnOff(true);
    this.modalService.setBookAFreeModal(true);
    // this.modalService.setbookDemoClassBixModal(true);
  }
  onLoginPanelPopup() {
    this.modalService.setloginemailBixModal(true);
  }
  
}
