import { BaseServiceService, TokenService } from '../../service';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EmailPatternValidator, MobilePatternValidator, NamePatternValidator, PasswordMatchPattern } from '../../validate-pattern';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CommonService } from './../../service/common.service';
import { HttpClient } from '@angular/common/http';
import { LeadsquaredService } from 'src/app/service/leadsquared.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  Form: FormGroup; submitted = false; btnsubmitted = false;
  Time_Slot_Array: any = [];
  // Login Start
  otpModal: FormGroup;
  LoginForm: FormGroup; Loginsubmitted = false; Loginbtnsubmitted = false;
  RegisterForm: FormGroup; Registersubmitted = false; Registerbtnsubmitted = false;
  Mobile_Flag: boolean = false;
  Email_Flag: boolean = false;
  OTP_Flag: boolean = false;
  MobileNo: any;
  AgeGroupList: any = [];
  subscription: Subscription;
  currentURL = '';

  constructor(public fb: FormBuilder, public _employeeService: BaseServiceService, public router: Router, public token: TokenService, public http: HttpClient, public common: CommonService, public _leadsquaredService: LeadsquaredService,) {
    this.currentURL = window.location.href;
    //this.onLoginType('email');
    this.getResouseLinkList();
  }
  get fLogin() { return this.LoginForm.controls; }
  get fRegister() { return this.RegisterForm.controls; }
  get fContact() { return this.AddFormContact.controls; }
  AddFormContact: FormGroup;
  submittedContact = false; btnsubmittedContact = false;
  onLoginType(type) {
    // console.log('type::::', type);
    this.submitted = false;
    this.btnsubmitted = false;
    this.OTP_Flag = false;
    if (type == 'mobile') {
      this.submitted = false;
      $('#RegisterModal').modal('hide');
      $('#loginModal').modal('hide');
      $('#loginWithMobileModal').modal('show');
      this.Mobile_Flag = true;
      this.Email_Flag = false;
      this.LoginForm = this.fb.group({
        role_id: ['2'],
        phone: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
        // otp: ['', [Validators.required]],
      })
    } else {
      this.submitted = false;
      $('#RegisterModal').modal('hide');
      $('#loginWithMobileModal').modal('hide');
      $('#loginModal').modal('show');
      this.Mobile_Flag = false;
      this.Email_Flag = true;
      this.LoginForm = this.fb.group({
        role_id: ['2'],
        email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
        learning: ['0']
      })
    }
  }
  onPreSchool(value) {
    this.common.SetRouteType('course-pie');
  }
  onEditPhone() {
    // alert();
    $('#loginOTPModal').modal('hide');
    $('#loginWithMobileModal').modal('show');
    this.LoginForm = this.fb.group({
      role_id: ['2'],
      phone: [this.LoginForm.value.phone, [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
    });
  }
  onProceed() {
    // console.log('this.LoginForm:::', this.LoginForm.value);
    var self = this;
    self.Loginsubmitted = true;
    if (self.LoginForm.invalid) {
      return;
    } else {
      this.MobileNo = this.LoginForm.value.phone;
      // console.log('this.LoginForm:::', this.LoginForm.value);
      self._employeeService.post('otp_generate', this.LoginForm.value).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            $('#loginWithMobileModal').modal('hide');
            $('#loginOTPModal').modal('show');
            this.OTP_Flag = true;
            self._employeeService.showSuccess(res.replyMsg);
            this.LoginForm = this.fb.group({
              role_id: ['2'],
              phone: [self.LoginForm.value.phone, [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
              otp: ['', [Validators.required]],
            });
            self.Loginsubmitted = false;
          } else {
            self._employeeService.showError(res.replyMsg)
          }
        } else {
          self._employeeService.showError(res.error.replyMsg)
        }
      }, error => {
        self._employeeService.showError("Oops! Something went wrong!");
      });
    }
  }
  onSubmitOtp() {
    if (this.otpModal.invalid) {
      this._employeeService.showError('Otp Not Valid')
      return;
    } else {
      // console.log(this.otpModal.value)
      this.LoginForm.controls['otp'].setValue(this.otpModal.value.otp1 + this.otpModal.value.otp2 + this.otpModal.value.otp3 + this.otpModal.value.otp4);
      // console.log('KKKKK;;;;', this.LoginForm.value);
      this._employeeService.post('otp_verify', this.LoginForm.value).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            this._employeeService.post('check_student_subscription', { sid: res.sid }).subscribe((response: any) => {
              if (!res.error) {
                if (res.replyCode == 'success') {
                  this._employeeService.showSuccess(res.replyMsg);
                  this.token.saveUser(JSON.stringify(res.data));
                  this.token.saveSid(JSON.stringify(res.sid));
                  this.token.saveLogin_Teacher(JSON.stringify(response.data));
                  this.common.setLoggedInStatus(true);
                  window.location.href = "https://student.learningbix.com/dashboard";
                } else {
                  this._employeeService.showSuccess(res.replyMsg);
                  this.token.saveUser(JSON.stringify(res.data));
                  this.token.saveSid(JSON.stringify(res.sid));
                  this.token.removeLogin_Teacher();
                  this.common.setLoggedInStatus(true);
                  window.location.href = "https://student.learningbix.com/dashboard";
                }
              }
            });
            this.LoginForm.reset();
            this.Loginsubmitted = false;
            this.Loginbtnsubmitted = false;
          } else {
            this.Loginbtnsubmitted = false;
            this._employeeService.showError(res.replyMsg)
          }
        } else {
          this.Loginbtnsubmitted = false;
          this._employeeService.showError(res.error.replyMsg)
        }
      }, error => {
        this.Loginbtnsubmitted = false;
        this._employeeService.showError("Oops! Something went wrong!");
      });
    }
  }
  onLoginPanel() {
    // console.log('LoginForm:::Value:::', this.LoginForm.value);
    var self = this;
    self.Loginsubmitted = true;
    if (self.LoginForm.invalid) {
      return;
    } else {
      this.Loginbtnsubmitted = true;
      var url;
      if (this.OTP_Flag == true) {
        url = 'otp_verify';
      } else {
        url = 'login';
      }
      // console.log('LoginForm:::Value:::', this.LoginForm.value);
      self._employeeService.post(url, this.LoginForm.value).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            self._employeeService.post('check_student_subscription', { sid: res.sid }).subscribe((response: any) => {
              if (!res.error) {
                if (res.replyCode == 'success') {
                  self._employeeService.showSuccess(res.replyMsg);
                  self.token.saveUser(JSON.stringify(res.data));
                  self.token.saveSid(JSON.stringify(res.sid));
                  self.token.saveLogin_Teacher(JSON.stringify(response.data));
                  this.common.setLoggedInStatus(true);
                  window.location.href = "https://student.learningbix.com/dashboard";
                } else {
                  self._employeeService.showSuccess(res.replyMsg);
                  self.token.saveUser(JSON.stringify(res.data));
                  self.token.saveSid(JSON.stringify(res.sid));
                  self.token.removeLogin_Teacher();
                  this.common.setLoggedInStatus(true);
                  window.location.href = "https://student.learningbix.com/dashboard";
                }
              }
            });
            // this.commonService.SetLoginUserData(res.data);
            // this.commonService.SetLoginUserImage(res.data.image);
            // localStorage.setItem('user',JSON.stringify(res.data));
            self.LoginForm.reset();
            self.Loginsubmitted = false;
            self.Loginbtnsubmitted = false;
          } else {
            self.Loginbtnsubmitted = false;
            self._employeeService.showError(res.replyMsg)
          }
        } else {
          self.Loginbtnsubmitted = false;
          self._employeeService.showError(res.error.replyMsg)
        }
      }, error => {
        self.Loginbtnsubmitted = false;
        self._employeeService.showError("Oops! Something went wrong!");
      });
    }
  }

  ngOnInit(): void {
    this.AddFormContact = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: [''],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      contact: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
      description: ['', [Validators.required]]
    })
    this._employeeService.post('age_group_list_dropdown', {}).subscribe((res: any) => {
      if (!res.error) {
        // console.log('Response::::',res);
        this.AgeGroupList = res.data;
      }
    });
    if (localStorage.getItem('user')) {
      this.common.setLoggedInStatus(true);
    } else {
      this.common.setLoggedInStatus(false);
    }
    // $('#exampleModal').modal('show');
    this.otpModal = this.fb.group({
      otp1: ['', [Validators.minLength(1)]],
      otp2: ['', [Validators.minLength(1)]],
      otp3: ['', [Validators.minLength(1)]],
      otp4: ['', [Validators.minLength(1)]]
    });
    // Register STart
    this.Form = this.fb.group({
      name: ['', [Validators.required]],
      phone_no: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      gender: [''],
      image: [''],
      age: [''],
      time_zone: [''],
      dob: [''],
      parents_name: [''],
      school_name: [''],
      subscription_type: ['0'],
      demo_class_date: ['', Validators.required],
      time_slot: ['', Validators.required],
      time_from: [''],
      time_to: [''],
    })
    // Register STart
    this.RegisterForm = this.fb.group({
      name: ['', [Validators.required]],
      phone_no: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
    })
    this.fetchSlots();
  }
  onTimeSlot(time_slot) {
    // console.log('time_slot:::', time_slot);
    if (time_slot != null || time_slot != undefined) {
      var split_time = time_slot.split('-');
      // console.log('split_time:::', split_time);
      this.Form.controls['time_from'].setValue(split_time[0]);
      this.Form.controls['time_to'].setValue(split_time[1]);
    }
  }
  pad(str, max) {
    str = str.toString();
    return str.length < max ? this.pad("0" + str, max) : str;
  }
  convertHours(mins) {
    var hour = Math.floor(mins / 60);
    mins = mins % 60;
    var converted = this.pad(hour, 2) + ':' + this.pad(mins, 2);
    return converted;
  }
  calculate_time_slot(start_time, end_time, interval = "30") {
    var i, formatted_time;
    var time_slots = new Array();
    for (var i = start_time; i <= end_time; i = i + interval) {
      formatted_time = this.convertHours(i);
      time_slots.push(formatted_time);
    }
    return time_slots;
  }
  parseTime(s) {
    var c = s.split(':');
    return parseInt(c[0]) * 60 + parseInt(c[1]);
  }
  fetchSlots() {
    this._employeeService.post('demo_class_time_slots', {}).subscribe((res: any) => {
      // console.log('res:::',res);
      var start_time = this.parseTime(res.data.time_from),
        end_time = this.parseTime(res.data.time_to);
      var interval: any = Number(res.data.class_duration);
      var times_ara = this.calculate_time_slot(start_time, end_time, interval);
      for (var i = 0; i <= times_ara.length - 2; i++) {
        // console.log(times_ara[i]+"-"+times_ara[i+1]);
        this.Time_Slot_Array.push({ value: times_ara[i] + "-" + times_ara[i + 1], name: times_ara[i] + "-" + times_ara[i + 1] });
      }
    });
  }
  get f() { return this.Form.controls; }
  onLogin() {
    // console.log(this.Form.value)
    // this.Form.controls['time_zone'].setValue(this.Form.value.time_zone|| null);
    var self = this;
    self.submitted = true;
    if (self.Form.invalid) {
      return;
    } else {
      this.btnsubmitted = true;
      var random_pwd = this.randomString(8, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

      var sendRequestData = {
        "name": this.Form.value.name,
        "phone_no": this.Form.value.phone_no,
        "email": this.Form.value.email,
        "password": random_pwd,
        "image": this.Form.value.image,
        "gender": this.Form.value.gender,
        "age": this.Form.value.age,
        "time_zone": this.Form.value.time_zone,
        "dob": this.Form.value.dob,
        "parents_name": this.Form.value.parents_name,
        "school_name": this.Form.value.school_name,
        "subscription_type": this.Form.value.subscription_type,
        "demo_class_date": this.Form.value.demo_class_date,
        "time_from": this.Form.value.time_from,
        "time_to": this.Form.value.time_to,
        "sponsor_code": ''
      }
      var split_string = this.Form.value.name.split(' ');
      var senRequestLeeddata: any = sendRequestData;
      senRequestLeeddata.first_name = split_string[0];
      if (split_string.length > 2) {
        senRequestLeeddata.last_name = split_string[1] + ' ' + split_string[2];
      } else {
        senRequestLeeddata.last_name = split_string[1] || '';
      }
      // console.log('Form:::Value:::', this.Form.value);
      if (localStorage.getItem('referral_code')) {
        sendRequestData.sponsor_code = localStorage.getItem('referral_code');
      }
      self._employeeService.post('register_student', sendRequestData).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            self._employeeService.showSuccess(res.replyMsg)
            self.token.saveSid(JSON.stringify(res.sid));
            var loginRequest = {
              "role_id": "2",
              "email": this.Form.value.email,
              "password": random_pwd
            }
            self._employeeService.post('login', loginRequest).subscribe((res: any) => {
              if (!res.error) {
                if (res.replyCode == 'success') {
                  self._employeeService.post('check_student_subscription', { sid: res.sid }).subscribe((response: any) => {
                    if (!res.error) {
                      if (res.replyCode == 'success') {
                        self.token.saveLogin_Teacher(JSON.stringify(response.data));
                        window.location.href = "https://student.learningbix.com/dashboard";
                      } else {
                        self.token.removeLogin_Teacher();
                      }
                    }
                  });
                  self._employeeService.showSuccess(res.replyMsg);
                  self.token.saveUser(JSON.stringify(res.data));
                  self.token.saveSid(JSON.stringify(res.sid));
                  // this.commonService.SetLoginUserData(res.data);
                  // this.commonService.SetLoginUserImage(res.data.image);
                  // localStorage.setItem('user',JSON.stringify(res.data));
                  // self.Form.reset();
                  // self.submitted = false;
                  // self.btnsubmitted= false;
                } else {
                  self.btnsubmitted = false;
                  self._employeeService.showError(res.replyMsg)
                }
              } else {
                self.btnsubmitted = false;
                self._employeeService.showError(res.error.replyMsg)
              }

            });
            self.Form.reset();
            self.submitted = false;
            self.btnsubmitted = false;
          } else {
            self.btnsubmitted = false;
            self._employeeService.showError(res.replyMsg)
          }
        } else {
          self.btnsubmitted = false;
          self._employeeService.showError(res.error.replyMsg)
        }
      }, error => {
        self.btnsubmitted = false;
        self._employeeService.showError("Oops! Something went wrong!");
      });
    }
  }
  OnRegister() {
    // console.log(this.RegisterForm.value)
    // this.Form.controls['time_zone'].setValue(this.Form.value.time_zone|| null);
    var self = this;
    self.Registersubmitted = true;
    if (self.RegisterForm.invalid) {
      return;
    } else {
      this.Registerbtnsubmitted = true;
      var random_pwd = this.randomString(8, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
      var sendRequestData = {
        "phone_no": this.RegisterForm.value.phone_no,
        "email": this.RegisterForm.value.email,
        "password": random_pwd,
        'name': this.RegisterForm.value.name,
        'sponsor_code': '',
      }
      // console.log('RegisterForm:::Value:::', this.RegisterForm.value);
      if (localStorage.getItem('referral_code')) {
        sendRequestData.sponsor_code = localStorage.getItem('referral_code');
      }
      self._employeeService.post('register_student', sendRequestData).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            self._employeeService.showSuccess(res.replyMsg)
            self.token.saveSid(JSON.stringify(res.sid));
            var loginRequest = {
              "role_id": "2",
              "email": this.RegisterForm.value.email,
              "password": random_pwd
            }
            self._employeeService.post('login', loginRequest).subscribe((res: any) => {
              if (!res.error) {
                if (res.replyCode == 'success') {
                  self._employeeService.post('check_student_subscription', { sid: res.sid }).subscribe((response: any) => {
                    if (!res.error) {
                      if (res.replyCode == 'success') {
                        self.token.saveLogin_Teacher(JSON.stringify(response.data));
                        window.location.href = "https://student.learningbix.com/dashboard";
                      } else {
                        self.token.removeLogin_Teacher();
                      }
                    }
                  });
                  self._employeeService.showSuccess(res.replyMsg);
                  self.token.saveUser(JSON.stringify(res.data));
                  self.token.saveSid(JSON.stringify(res.sid));
                } else {
                  self.Registerbtnsubmitted = false;
                  self._employeeService.showError(res.replyMsg)
                }
              } else {
                self.Registerbtnsubmitted = false;
                self._employeeService.showError(res.error.replyMsg)
              }

            });
            self.RegisterForm.reset();
            self.Registersubmitted = false;
            self.Registerbtnsubmitted = false;
          } else {
            self.Registerbtnsubmitted = false;
            self._employeeService.showError(res.replyMsg)
          }
        } else {
          self.Registerbtnsubmitted = false;
          self._employeeService.showError(res.error.replyMsg)
        }
      }, error => {
        self.Registerbtnsubmitted = false;
        self._employeeService.showError("Oops! Something went wrong!");
      });
    }
  }

  onSectionRedirect(type) {
    // console.log('type::::', type);
    this.common.SetRouteType(type);
  }
  onBlogRedirect() {
    window.location.href = "https://blog.learningbix.com/";
  }
  // Teacher Panel
  onSubmitContact() {
    var self = this;
    self.submittedContact = true;
    if (self.AddFormContact.invalid) {
      return;
    } else {
      let relatedId = '';
      var split_string = this.AddFormContact.value.first_name.split(' ');
      // console.log('Form::::Value::::',this.AddFormContact.value)
      // console.log('split_string::::',split_string);
      var senRequestdata = this.AddFormContact.value;
      senRequestdata.first_name = split_string[0];
      if (split_string.length > 2) {
        senRequestdata.last_name = split_string[1] + ' ' + split_string[2];
      } else {
        senRequestdata.last_name = split_string[1] || '';
      }
      const hostname = window && window.location && window.location.hostname;
      if (/^learningbix.com/.test(hostname)) {
        this.subscription = this._leadsquaredService.AddLeadCaptureContact(senRequestdata).subscribe(message => {
          relatedId = message.Message.RelatedId
        })
      }
      // console.log('Form::::Value::::senRequestdata', senRequestdata)

      self.btnsubmittedContact = true;
      var sendRequestData = self.AddFormContact.value;
      sendRequestData.page_url = this.currentURL;
      self._employeeService.post('send_feedback', sendRequestData).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            // this.subscription = this._leadsquaredService.AddCourseEnquiry('', relatedId || '').subscribe(message => {

            // })
            self._employeeService.showSuccess(res.replyMsg);
            self.AddFormContact.reset();
            self.submittedContact = false;
            self.btnsubmittedContact = false;
          } else {
            self.btnsubmittedContact = false;
            self._employeeService.showError(res.replyMsg)
          }
        } else {
          self.btnsubmittedContact = false;
          self._employeeService.showError(res.error.replyMsg)
        }
      }, error => {
        self.btnsubmittedContact = false;
        self._employeeService.showError("Oops! Something went wrong!");
      });
    }
  }
  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  isLinkSetting: any = {};
  getResouseLinkList() {
    this._employeeService.post('link_setting_details', {id:1}).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.isLinkSetting=res.data
        } else {
          this._employeeService.showError(res.replyMsg)
        }
      } else {
        this._employeeService.showError(res.error.replyMsg)
      }
    }, error => {
      this._employeeService.showError("Oops! Something went wrong!");
    });
  }
  goToLink(url: string){
    console.log(url)
    window.open(url, "_blank");
  }
}
