<section class="coding">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12 col-sm-12">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10">
            <div class="row">
              <!-- <div class="col-lg-5">
                <img src="../../assets/imgs/Group 9149.svg" class="img-fluid" alt="">
              </div> -->


              <div class="section-title responsive">
                <h2 class="text-accent">
                  Online Pre-School
                </h2>
                <p class="text-accent-desc">Helps improve kids' logic, concentration and life-skills</p>
              </div>
              <div class="coding-features">
                <h3 class="text-accent">What we Provide</h3>
                <ul class="features">
                  <li>100% DIY Activity Box</li>
                  <li>Standardized Curriculum</li>
                  <li>Counselling sessions</li>
                  <li>AR/VR Excursion</li>
                </ul>
              </div>
              <button class="btn btn-light btn-accent" style="font-size: 18px;" routerLink="/pie">Explore Now</button>
            </div>

          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12">
        <div id="early">
          <ul class="nav nav-tabs nav-fill mx-0" id="myTab" role="tablist">
            <li class="nav-item" role="presentation" *ngFor="let age of AgeGroupList;let i =index;">
              <a class="nav-link" id="grade1-tab" [ngClass]="age.id ==IndexId ? 'active':''"
                (click)="getCoursesList(age.id)"><span>{{age.title}}</span> <strong>Age
                  {{age.age_from}}-{{age.age_to}}</strong></a>
            </li>
            <!-- <li class="nav-item" role="presentation">
              <a class="nav-link" id="grade2-tab" data-toggle="tab" href="javascript:void(0);" data-target="#grade2"
                role="tab" aria-controls="grade2" aria-selected="false"><span>Grade 2</span> <strong>Age 10-12</strong></a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="grade3-tab" data-toggle="tab" href="javascript:void(0);" data-target="#grade3"
                role="tab" aria-controls="grade3" aria-selected="false"><span>Grade 3</span> <strong>Age 13-16</strong></a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="grade4-tab" data-toggle="tab" href="javascript:void(0);" data-target="#grade4"
                role="tab" aria-controls="grade4" aria-selected="false"><span>Grade 4</span> <strong>Age 17-19</strong></a>
            </li> -->
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="grade1" role="tabpanel" aria-labelledby="grade1-tab">
              <div class="row">
                <ng-container
                  *ngFor="let agecourses of AgeGroupCoursesList;; let i = index; let first = first ;let last = last;">
                  <div class="col-lg-6 col-sm-6 col-xs-12 mt-2 mb-2" *ngIf="first">
                    <div class="card card-item">
                      <!-- <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{agecourses.image}}" class="card-img-top" alt="..."> -->
                      <div class="card-body">
                        <h3 class="card-title" style="    width: 100%;    font-size: 24px;margin-bottom: 0px;
                      ">{{agecourses.course_name}}</h3>
                        <span style="font-size: 15px">{{agecourses.tag}}</span>

                        <div [innerHtml]="getDescrption(agecourses.description)" style="margin-top: 20px;"></div>
                        <h4 class="text-center mt-4 mb-4">Rs. {{agecourses.amount}}/- <span
                            style="color:red; ">Rs.<s>{{agecourses.fake_price}}/-</s></span></h4>
                        <h3 class="text-center mt-4 mb-4">Save {{calculateSave(agecourses)}} </h3>
                        <div class="price-box">
                          <ul class="pricing-details" style="font-size: 18px;    padding: 10px;">
                            <li>₹{{agecourses.amount}}</li>
                            <li><a href="javascript:void(0);" class="text-black" (click)="onBuy(agecourses)">Enroll
                                Now</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-xs-12 mt-2 mb-2" *ngIf="last">
                    <div class="card card-item">
                      <!-- <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{agecourses.image}}" class="card-img-top" alt="..."> -->
                      <div class="card-body">
                        <h3 class="card-title" style="    width: 100%;    font-size: 24px;margin-bottom: 0px;
                      ">{{agecourses.course_name}}</h3>
                        <span style="font-size: 15px">{{agecourses.tag}}</span>

                        <div [innerHtml]="getDescrption(agecourses.description)" style="margin-top: 20px;"></div>
                        <h4 class="text-center mt-4 mb-4">Rs. {{agecourses.amount}}/- <span
                            style="color:red; ">Rs.<s>{{agecourses.fake_price}}/-</s></span></h4>
                        <h3 class="text-center mt-4 mb-4">Save {{calculateSave(agecourses)}} </h3>
                        <div class="price-box">
                          <ul class="pricing-details" style="font-size: 18px;    padding: 10px;">
                            <li>₹{{agecourses.amount}}</li>
                            <li><a href="javascript:void(0);" class="text-black" (click)="onBuy(agecourses)">Enroll
                                Now</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- <div class="row justify-content-center">
                <div class="col-7 text-center mt-5 mb-2">
                  <button type="button" class="btn btn-outline-warning btn-view-more" routerLink="/coding">View All Coding Classes</button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</section>