<app-header></app-header>

<!-- Three Blocks Wrapper -->

<div class="container py-5" *ngIf="isBlogDetails">

    <div class="row mx-0 align-items-center mb-5 justify-content-between">
        <div class="col-md-5 col-12">
            <img src="https://learningbixstorage098.blob.core.windows.net/learningbixcom/{{isBlogDetails.image}}" alt="" class="img-fluid br-15">
        </div>
        <div class="col-md-6 col-12">
            <h2 class="font-weight-bold" style="font-size: 35px;">{{isBlogDetails.title}}</h2>
            <p style="color: blue;text-align: right;cursor: pointer;" *ngIf="isBlogDetails.slug"><a href="{{isBlogDetails.slug}}">Click here to read more...</a></p>
            <p [innerHTML]="isBlogDetails.html_description"></p>
        </div>
    </div>

</div>


<app-footer></app-footer>
