import { Component, OnInit } from '@angular/core';
import { EmailPatternValidator, MobilePatternValidator } from 'src/app/validate-pattern';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseServiceService } from 'src/app/service';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvService } from 'src/app/service/env.service';
import { ModalService } from 'src/app/service/modal.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-franchise',
  templateUrl: './franchise.component.html',
  styleUrls: ['./franchise.component.scss']
})
export class FranchiseComponent implements OnInit {
  cityList: any = [];
  stateList: any = [];
  testimonialList: any = [];
  tabTitle: any;
  AddFormContact: FormGroup;
  submittedContact = false; btnsubmittedContact = false;
  currentURL='';
  constructor(private fb: FormBuilder,
    public _employeeService: BaseServiceService,
    private sanitizer: DomSanitizer,public modalService:ModalService,public envService:EnvService
  ) {
    this.currentURL = window.location.href;
  }
  customOpt: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    margin: 20,
    navSpeed: 700,
    autoplay: false,
    autoplaySpeed: 2000,
    autoplayTimeout: 2000,
    navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    }

  }
  ngOnInit(): void {
    this.getTestimonialList();
    this.getStateList(101);
    this.tabTitle="Beginner";
    this.AddFormContact = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: [''],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      email: ['', [Validators.required, EmailPatternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      contact: ['', [Validators.required, MobilePatternValidator(/^(?=.*[0-9]).{10,12}$/)]],
      description: ['', [Validators.required]]
    })
  }
  get fContact() { return this.AddFormContact.controls; }
  getTestimonialList() {
    this._employeeService.post('web_student_testimonials_list', {
      learning: 5
    }).subscribe((res: any) => {
      if (!res.error) {
        if (res.data.length > 0) {
          this.testimonialList = res.data;
        } else {
          this.testimonialList = [];
        }
      }
    });
  }
  allowurl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getStateList(id:any) {
    var requestData = {
      country_id: id,
      keyword:''
    }
    this._employeeService.post('state_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        this.stateList = res.data;
        // console.log('this.stateList>>>>>>>>>>>>>',this.stateList)
      }
    });
  }
  onChangeCity(event:any){
    // console.log('State')
    var filterCity=this.stateList.filter((x:any)=>x.name == event.value);
    if(filterCity.length>0){
      this.getCityList(filterCity[0].id);
    }
  }
  getCityList(id:any) {
    var requestData = {
      state_id: id,
      keyword:''
    }
    this._employeeService.post('city_list', requestData).subscribe((res: any) => {
      if (!res.error) {
        this.cityList = res.data;
      }
    });
  }
  onSubmitContact() {
    console.log("this.AddFormContact",this.AddFormContact.value)
    var self = this;
    this.submittedContact = true;
    if (this.AddFormContact.invalid) {
      return;
    } else {
      this.btnsubmittedContact = true;
      var sendRequestData = this.AddFormContact.value;
      sendRequestData.page_url = this.currentURL;
      this._employeeService.post('send_feedback', sendRequestData).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            this._employeeService.showSuccess(res.replyMsg);
            this.AddFormContact.reset();
            this.submittedContact = false;
            this.btnsubmittedContact = false;
          } else {
            this.btnsubmittedContact = false;
            this._employeeService.showError(res.replyMsg)
          }
        } else {
          this.btnsubmittedContact = false;
          this._employeeService.showError(res.error.replyMsg)
        }
      }, error => {
        this.btnsubmittedContact = false;
        this._employeeService.showError("Oops! Something went wrong!");
      });
    }
  }
}
