<div class="modal popup" id="RegisterModal" tabindex="-1" role="dialog" aria-labelledby="RegisterModalLabel"
    aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 3px 10px;"
                    (click)="onCloseModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2" style="height: auto;">
                <div class="row">
                    <div class="col-6">
                        <img src="../../../assets/imgs/loginModalBanner.jpg" class="img-fluid w-100" alt="Login Banner"
                            style="height: 96%;">
                    </div>
                    <div class="col-6">
                        <h1 style="font-size: 30px;">Register</h1>
                        <form [formGroup]="Form">
                            <div class="form-group">
                                <label for="fullname">Parent name</label>
                                <input type="text" placeholder="Enter Parent Name" class="form-control pl-0 font_size-15"
                                    id="fullname" formControlName="parents_name" appAphpaOnly>
                                <div *ngIf="submitted && f.parents_name.errors" class="error_input">
                                    <div *ngIf="f.parents_name.errors.required">This field is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="fullname">Child Name</label>
                                <input type="text" placeholder="Enter Child Name" class="form-control pl-0 font_size-15"
                                    id="fullname" formControlName="name" appAphpaOnly>
                                <div *ngIf="submitted && f.name.errors" class="error_input">
                                    <div *ngIf="f.name.errors.required">This field is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="Email">Email</label>
                                <input type="Email" placeholder="Enter Email" class="form-control pl-0 font_size-15"
                                    id="Email" formControlName="email">
                                <div *ngIf="submitted && f.email.errors">
                                    <small *ngIf="f.email.errors.required" class="error_input">
                                        This Field is required
                                    </small>
                                    <small [hidden]="!f.email.errors.patternInvalid" class="error_input">Please
                                        enter valid
                                        email.</small>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="Mobile">Mobile</label>
                                <input type="text" placeholder="Enter Mobile" class="form-control pl-0 font_size-15"
                                    id="mobile" formControlName="phone_no" maxlength="10" minlength="10" appNumbersOnly>
                                <div *ngIf="submitted && f.phone_no.errors">
                                    <small *ngIf="f.phone_no.errors.required" class="error_input">
                                        This Field is required
                                    </small>
                                    <small [hidden]="!f.phone_no.errors.patternMobileInvalid" class="error_input">Phone
                                        Number
                                        should be valid and 10 digit.</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="Password">Password</label>
                                <input type="password" placeholder="Enter Password"
                                    class="form-control pl-0 font_size-15" id="mobile" formControlName="password"
                                    maxlength="16">
                                <div *ngIf="submitted && f.password.errors">
                                    <small *ngIf="f.password.errors.required" class="error_input">
                                        This Field is required
                                    </small>
                                    <small *ngIf="f.password.errors.minlength || f.password.errors.maxlength"
                                        class="error_input">Password
                                        length should be atleast 4 and maximum 20</small>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="age">Age Group</label>
                                <select name="age" class="form-control pl-0" id="age" formControlName="age_group_id">
                                    <option value="" selected disabled>Select Age Group</option>
                                    <option [value]="ages.id" *ngFor="let ages of AgeGroupList">{{ages.age_from}} -
                                        {{ages.age_to}} Yrs
                                    </option>

                                </select>
                                <div *ngIf="submitted && f.age_group_id.errors" class="error_input">
                                    <div *ngIf="f.age_group_id.errors.required">This field is required</div>
                                </div>
                            </div>
                            <!-- <div class="form-group" *ngIf="!Section">
                                <label for="country">Country</label>
                                <select name="country" class="form-control pl-0" id="country" 
                                    (change)="onChangeCountry($event.target.value)">
                                    <option value="" selected disabled>Select Country</option>
                                    <option [value]="country.name" *ngFor="let country of countryList">
                                        {{country.name}}</option>

                                </select>
                                <div *ngIf="submitted && f.state.errors" class="error_input">
                                    <div *ngIf="f.state.errors.required">This field is required</div>
                                </div>
                            </div> -->
                            <div class="form-group" *ngIf="!Section">
                                <label for="state">State</label>
                                <select name="state" class="form-control pl-0" id="state" formControlName="state">
                                    <option value="" selected disabled>Select State</option>
                                    <option [value]="state.name" *ngFor="let state of stateList">
                                        {{state.name}}</option>

                                </select>
                                <div *ngIf="submitted && f.state.errors" class="error_input">
                                    <div *ngIf="f.state.errors.required">This field is required</div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="!Section">
                                <label for="gst">GST Number</label>
                                <input type="gst" placeholder="Enter gst" class="form-control pl-0 font_size-15"
                                    id="mobile" formControlName="gst" maxlength="16">

                            </div>

                            <button class="btn btn-block btn-login mt-3" type="submit" (click)="OnRegister()"
                                [disabled]="btnsubmitted">Register</button>
                            <ng-container *ngIf="!Section">
                                <p class="mt-3 mb-3 text-center seprator">or</p>
                                <button type="button" class="btn btn-block btn-login-with-fb mb-4"
                                    (click)="socialSignIn('F')"><img src="../../../assets/imgs/facebook-icon.png"
                                        class="img-fluid" alt="">Register with Facebook</button>
                                <button type="button" class="btn btn-block btn-login-with-gp mb-4"
                                    (click)="socialSignIn('G')"><img src="../../../assets/imgs/google-icon.png"
                                        class="img-fluid" alt=""> Register with Google</button>
                            </ng-container>

                            <p class="register-link">Already have account? <a href="javascript:void(0);"
                                    (click)="onLoginEmail()">Login Now</a></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>