<section class="">
    <div class="section-title">
      <h2 class="">Learning path for your kids!</h2>
      <p>Helps improve kids’ logic, concentration and abstract thinking</p> 
    </div>
   
    <div id="">
      <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
        
        <li class="nav-item" role="presentation">
          <a class="nav-link active" id="grade1-tab" data-toggle="tab" href="javascript:void(0);" data-target="#grade1"
            role="tab" aria-controls="grade1" aria-selected="false"> <strong>Age 5-6</strong></a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="grade2-tab" data-toggle="tab" href="javascript:void(0);" data-target="#grade2"
            role="tab" aria-controls="grade2" aria-selected="false"> <strong>Age 7-8</strong></a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="grade3-tab" data-toggle="tab" href="javascript:void(0);" data-target="#grade3"
            role="tab" aria-controls="grade3" aria-selected="false"> <strong>Age 9-11</strong></a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="grade4-tab" data-toggle="tab" href="javascript:void(0);" data-target="#grade4"
            role="tab" aria-controls="grade4" aria-selected="false"> <strong>Age 12-14</strong></a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="grade5-tab" data-toggle="tab" href="javascript:void(0);" data-target="#grade5"
            role="tab" aria-controls="grade5" aria-selected="false"> <strong>Age 15-18</strong></a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
       

        <div class="tab-pane fade show active" id="grade1" role="tabpanel" aria-labelledby="grade1-tab">
          <div class="row">
            <div class="col-12">
              <div class="card card-item">
                <section class="learning-path">
                   
                    <div class="row">
                      <div class="col-12 text-left learning-PathText787">
                        <img src="assets/imgs/Group 9238.svg" class="img-fluid img-991" alt="">
                        <p class="mb-0">Your learning journey</p>
                        <div class="start-btn">
                          Start
                        </div>
                      </div>

                      <!-- <div class="col-md-11 col-sm-12">
                        <div class="row top-path-item">
                          <div class="col col-xs-12">
                            <div class="item">
                              <img src="assets/imgs/Icons/learningPathIcon/puzzle-removebg-preview.png" class="img-fluid" alt="">
                              <p class="mb-0">Into to Puzzle Coding</p>
                            </div>
                          </div>
                          <div class="col col-xs-12">
                            <div class="item">
                              
                              <img src="assets/imgs/Icons/learningPathIcon/pngfuel.com.png" class="img-fluid" alt="">
                              <p class="mb-0">Design Games & Stories</p>
                            </div>
                          </div>
                          <div class="col col-xs-12">
                            <div class="item">
                              <img src="assets/imgs/Icons/learningPathIcon/turtle_coding-removebg-preview.png" class="img-fluid" alt="">
                              <p class="mb-0">Drawing with Trutle Coding</p>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <div class="col-md-11 col-sm-12">
                        <div class="row bottom-path-item">
                          <div class="col col-width">
                            <div class="item">
                              <img src="assets/imgs/Icons/learningPathIcon/puzzle-removebg-preview.png" class="img-fluid" alt="">
                              <p class="mb-0">Intro to Puzzle Coding</p>
                            </div>
                          </div>
                          <div class="col col-width">
                            <div class="item">
                              
                              <img src="assets/imgs/Icons/learningPathIcon/pngfuel.com.png" class="img-fluid" alt="">
                              <p class="mb-0">Design Games & Stories</p>
                            </div>
                          </div>
                          <div class="col col-width">
                            <div class="item">
                              <img src="assets/imgs/Icons/learningPathIcon/turtle_coding-removebg-preview.png" class="img-fluid" alt="">
                              <p class="mb-0">Drawing with Trutle Coding</p>
                            </div>
                          </div>
                         
                        </div>
                      </div>
                    
                    
                    </div>
                </section>
              </div>
            </div>
           
          </div>
        </div>
        <div class="tab-pane fade" id="grade2" role="tabpanel" aria-labelledby="grade2-tab">
          <div class="row">
            <div class="col-12">
              <div class="card card-item">
                <section class="learning-path">
                   
                  <div class="row">
                    <div class="col-12 text-left learning-PathText787">
                      <img src="assets/imgs/Group 9238.svg" class="img-fluid img-991" alt="">
                      <p class="mb-0">Your learning journey</p>
                      <div class="start-btn">
                        Start
                      </div>
                    </div>

                    <div class="col-md-11 col-sm-12">
                      <div class="row top-path-item">
                        <div class="col col-xs-12">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/nemo.svg" class="img-fluid" alt="">
                            <p class="mb-0">Story & Animation Design</p>
                          </div>
                        </div>
                        <div class="col col-xs-12">
                          <div class="item">
                            
                            <img src="assets/imgs/Icons/learningPathIcon/ai.jpg" class="img-fluid" alt="">
                            <p class="mb-0">Hands On Artificial Intelligence & machine Learning</p>
                          </div>
                        </div>
                        <div class="col col-xs-12">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/robot 1.png" class="img-fluid" alt="">
                            <p class="mb-0">Script Coding for Robots</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-11 col-sm-12">
                      <div class="row bottom-path-item">
                        <div class="col col-width">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/block coding 1.png" class="img-fluid" alt="">
                            <p class="mb-0">Intro to Block Based Coding</p>
                          </div>
                        </div>
                        <div class="col col-width">
                          <div class="item">
                            
                            <img src="assets/imgs/Icons/learningPathIcon/angry_birds.svg" class="img-fluid" alt="">
                            <p class="mb-0"> Games Designing</p>
                          </div>
                        </div>
                        <div class="col col-width">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/3d model 2.png" class="img-fluid" alt="">
                            <p class="mb-0">3D Designing & Printing </p>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  
                  
                  </div>
              </section>
              </div>
            </div>
           
          </div>
        </div>
        <div class="tab-pane fade" id="grade3" role="tabpanel" aria-labelledby="grade3-tab">
          <div class="row">
            <div class="col-12">
              <div class="card card-item">
                <section class="learning-path">
                   
                  <div class="row">
                    <div class="col-12 text-left learning-PathText787">
                      <img src="assets/imgs/Group 9238.svg" class="img-fluid img-991" alt="">
                      <p class="mb-0">Your learning journey</p>
                      <div class="start-btn">
                        Start
                      </div>
                    </div>

                    <div class="col-md-11 col-sm-12">
                      <div class="row top-path-item">
                        <div class="col col-xs-12">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/ui_ux_mobile.svg" class="img-fluid" alt="">
                            <p class="mb-0">UX & UI Designing for Mobile</p>
                          </div>
                        </div>
                        <div class="col col-xs-12">
                          <div class="item">
                            
                            <img src="assets/imgs/Icons/learningPathIcon/ai ml apps.png" class="img-fluid" alt="">
                            <p class="mb-0">Hands On Artificial Intelligence & machine Learning</p>
                          </div>
                        </div>
                        <div class="col col-xs-12">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/mobile game.jpg" class="img-fluid" alt="">
                            <p class="mb-0">Build Real Game</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-11 col-sm-12">
                      <div class="row bottom-path-item">
                        <div class="col col-width">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/block coding 1.png" class="img-fluid" alt="">
                            <p class="mb-0">Intro to Block Based Coding</p>
                          </div>
                        </div>
                        <div class="col col-width">
                          <div class="item">
                            
                            <img src="assets/imgs/Icons/learningPathIcon/mobile_game.svg" class="img-fluid" alt="">
                            <p class="mb-0">Mobile Game Design</p>
                          </div>
                        </div>
                        <div class="col col-width">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/web-design 2.png" class="img-fluid" alt="">
                            <p class="mb-0">UI Designing for Website</p>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  
                  
                  </div>
              </section>
              </div>
            </div>
           
          </div>

        </div>
        <div class="tab-pane fade" id="grade4" role="tabpanel" aria-labelledby="grade4-tab">
          <div class="row">
            <div class="col-12">
              <div class="card card-item">
                <section class="learning-path">
                   
                  <div class="row">
                    <div class="col-12 text-left learning-PathText787">
                      <img src="assets/imgs/Group 9238.svg" class="img-fluid img-991" alt="">
                      <p class="mb-0">Your learning journey</p>
                      <div class="start-btn">
                        Start
                      </div>
                    </div>

                    <div class="col-md-11 col-sm-12">
                      <div class="row top-path-item">
                        <div class="col col-xs-12">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/rocket 1.png" class="img-fluid" alt="">
                            <p class="mb-0">Creative Coding for interactive Animations</p>
                          </div>
                        </div>
                        <div class="col col-xs-12">
                          <div class="item">
                            
                            <img src="assets/imgs/Icons/learningPathIcon/chatbot 1.jpg" class="img-fluid" alt="">
                            <p class="mb-0">Design Artificial Intelligence Based Chatbot</p>
                          </div>
                        </div>
                        <div class="col col-xs-12">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/website.png" class="img-fluid" alt="">
                            <p class="mb-0">UI & UX Design for Website</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-11 col-sm-12">
                      <div class="row bottom-path-item">
                        <div class="col col-width">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/js 1.png" class="img-fluid" alt="">
                            <p class="mb-0">Intro to Javascript Coding</p>
                          </div>
                        </div>
                        <div class="col col-width">
                          <div class="item">
                            
                            <img src="assets/imgs/Icons/learningPathIcon/ping pong.png" class="img-fluid" alt="">
                            <p class="mb-0">Creative Coding For Game Designing</p> 
                          </div>
                        </div>
                        <div class="col col-width">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/3d model 3.png" class="img-fluid" alt="">
                            <p class="mb-0">3D Modeling & CAD Tools</p>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  
                  
                  </div>
              </section>
              </div>
            </div>
           
          </div>

        </div>
        <div class="tab-pane fade" id="grade5" role="tabpanel" aria-labelledby="grade5-tab">
          <div class="row">
            <div class="col-12">
              <div class="card card-item">
                <section class="learning-path">
                   
                  <div class="row">
                    <div class="col-12 text-left learning-PathText787">
                      <img src="assets/imgs/Group 9238.svg" class="img-fluid img-991" alt="">
                      <p class="mb-0">Your learning journey</p>
                      <div class="start-btn">
                        Start
                      </div>
                    </div>

                    <div class="col-md-11 col-sm-12">
                      <div class="row top-path-item">
                        <div class="col col-width">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/calculator.png" class="img-fluid" alt="">
                            <p class="mb-0">Coding for Software Applications</p>
                          </div>
                        </div>
                        <div class="col col-width">
                          <div class="item">
                            
                            <img src="assets/imgs/Icons/learningPathIcon/face 1.png" class="img-fluid" alt="">
                            <p class="mb-0">Applications of Artificial Intelligence & Machine Learning</p>
                          </div>
                        </div>
                        <div class="col col-width">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/data science.jpg" class="img-fluid" alt="">
                            <p class="mb-0">Data Science & Data Analysis</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-11 col-sm-12">
                      <div class="row bottom-path-item">
                        <div class="col col-width">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/python.webp" class="img-fluid" alt="">
                            <p class="mb-0">Intro to Python Coding</p>
                          </div>
                        </div>
                        <div class="col col-width">
                          <div class="item">
                            
                            <img src="assets/imgs/Icons/learningPathIcon/python.webp" class="img-fluid" alt="">
                            <p class="mb-0">Python Games Development</p>
                          </div>
                        </div>
                        <div class="col col-width">
                          <div class="item">
                            <img src="assets/imgs/Icons/learningPathIcon/robot 2.webp" class="img-fluid" alt="">
                            <p class="mb-0">Design Intelligent Circuits & Robots</p>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  
                  
                  </div>
              </section>
              </div>
            </div>
           
          </div>

        </div>
      </div>
    </div>
</section>