<div class="modal popup" id="loginOTPModal" tabindex="-1" role="dialog" aria-labelledby="loginOTPModalLabel" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 3px 10px;" (click)="onCloseModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2" style="height: 550px;">
                <div class="row">
                    <div class="col-6">
                        <img src="../../../assets/imgs/loginModalBanner.jpg" class="img-fluid w-100" alt="Login Banner" style="height: 86%;">
                    </div>
                    <div class="col-6">
                        <h1 style="font-size: 30px;">Login</h1>

                        <form [formGroup]="Form">
                            <div class="form-group">
                                <label for="email">email</label>
                                <input type="tel" placeholder="Enter Email" class="form-control pl-0" id="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                <div *ngIf="submitted && f.email.errors">
                                    <small *ngIf="f.email.errors.required" class="text-danger">
                                        This Field is required
                                    </small>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="password">Password</label>
                                <input type="password" placeholder="Enter password" class="form-control pl-0" id="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                <div *ngIf="submitted && f.password.errors">
                                    <small *ngIf="f.password.errors.required" class="text-danger">
                                        This Field is required
                                    </small>
                                </div>
                            </div>
                            <button class="btn btn-block btn-login mt-3" (click)="onProceed()" [disabled]="btnsubmitted">Submit</button>




                            <p class="mt-3 mb-3 text-center seprator">or</p>
                            <button type="button" class="btn btn-block btn-login-with-phone mb-4" (click)="onLoginEmail()">Login
                                with Email ID</button>
                            <p class="register-link">Not registered yet? <a href="javascript:void(0);" (click)="onRegisterPopup()">Register Now</a></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>