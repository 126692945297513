<div class="modal popup" id="bookFreeClassModal" tabindex="-1" aria-labelledby="bookFreeClassModalLabel"
    aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header border-0">
                <!-- Coding Header -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pt-0 pb-5 pl-5 pr-5 book-class-modal">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <h1>Book a free class</h1>
                        <p>Only few spots!</p>
                        <form [formGroup]="Form">
                            <div class="row">
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label for="fullname">Parent Name</label>
                                        <input type="text" placeholder="Enter Parent Name" class="form-control pl-0"
                                            id="fullname" formControlName="parents_name" appAphpaOnly>
                                        <div *ngIf="submitted && f.parents_name.errors" class="error_input">
                                            <div *ngIf="f.parents_name.errors.required">This field is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label for="fullname">Child Name</label>
                                        <input type="text" placeholder="Enter Name" class="form-control pl-0"
                                            id="fullname" formControlName="name" appAphpaOnly>
                                        <div *ngIf="submitted && f.name.errors" class="error_input">
                                            <div *ngIf="f.name.errors.required">This field is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label for="Email">Email</label>
                                        <input type="Email" placeholder="Enter Email" class="form-control pl-0"
                                            id="Email" formControlName="email">
                                        <div *ngIf="submitted && f.email.errors">
                                            <small *ngIf="f.email.errors.required" class="error_input">
                                                This Field is required
                                            </small>
                                            <small [hidden]="!f.email.errors.patternInvalid" class="error_input">Please
                                                enter valid email.</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label for="learning">I am interested in (Select One)</label>
                                        <select name="learning" class="form-control pl-0" id="learning"
                                            formControlName="learning"
                                            (change)="chageLearningType($event.target.value)">
                                            <option value="" selected disabled>Select learning Type</option>
                                            <option value="0">Coding</option>
                                            <option value="1">Pre School</option>

                                        </select>
                                        <div *ngIf="submitted && f.learning.errors" class="error_input">
                                            <div *ngIf="f.learning.errors.required">This field is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label for="age">Age group</label>
                                        <select name="age" class="form-control pl-0" id="age"
                                            formControlName="age_group_id">
                                            <option value="" selected disabled>Select Age Group</option>
                                            <option [value]="ages.id" *ngFor="let ages of AgeGroupList">
                                                {{ages.age_from}} - {{ages.age_to}} Yrs</option>

                                        </select>
                                        <div *ngIf="submitted && f.age_group_id.errors" class="error_input">
                                            <div *ngIf="f.age_group_id.errors.required">This field is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12" *ngIf="!isPreSchool">
                                    <div class="form-group">
                                        <label for="ddate">Demo Date</label>
                                        <input type="date" class="form-control pl-0" id="ddate"
                                            formControlName="demo_class_date" min="{{maxDate}}"
                                            (change)="dateSelected($event.target.value)">
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12" *ngIf="!isPreSchool">
                                    <div class="form-group">
                                        <label for="dtime">Demo Time</label>
                                        <select class="form-control" id="time_slot" formControlName="time_slot"
                                            [ngClass]="{ 'is-invalid': submitted && f.time_slot.errors }"
                                            (change)="onTimeSlot($event.target.value)">
                                            <option selcted value="" disabled>Select Time Slot</option>
                                            <option [value]="time_slot.value" *ngFor="let time_slot of Time_Slot_Array">
                                                {{time_slot.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <button type="submit" class="btn btn-book-class" (click)="onLogin()" [disabled]="btnsubmitted" style="margin-top: 7%;">Book a free class</button>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label for="mobile">Mobile No.</label>
                                        <input type="text" placeholder="Enter Mobile" class="form-control pl-0" id="mobile"
                                            formControlName="phone_no" appNumbersOnly maxlength="10" readonly>
                                        <div *ngIf="submitted && f.phone_no.errors">
                                            <small *ngIf="f.phone_no.errors.required" class="error_input">
                                                This Field is required
                                            </small>
                                            <small [hidden]="!f.phone_no.errors.patternMobileInvalid"
                                                class="error_input">Phone Number should be valid and 10 digits.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>